import { FC } from "react";
import "./styles.scss";
import { DropworksButton } from "../../../../common/custom-field/dropworks-button";
import { DropworksPassword } from "../../../../common/custom-field/dropworks-password";
import { TextfieldSM } from "../../../../common/custom-field/dropstock-text";
import { Heading3 } from "../../../../common/custom-field/dropstock-heading";
import { resetPasswordForm } from "../login/modal";
import Loader from "../../../shared/components/loader/view";

export const view: FC<resetPasswordForm> = ({
  navigateTo,
  isSubmitted,
  errors,
  register,
  onSubmit,
  handleSubmit,
  loading = false,
}) => {
  return (
    <div className="recaccount page-content row align-items-pages container pb-3 m-auto h-100 px-0  min-h-800">
      <div className="recaccount_content col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
        <Heading3 className="recaccount_heading my-0 ">
          Recover Account
        </Heading3>
        <TextfieldSM className="uni-my-32 recaccount_definepassword front-pages-second-heading">
          Define your new password
        </TextfieldSM>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="uni-mb-16 margin-mobile-view-8">
            <DropworksPassword
              {...register("new_password")}
              placeholder="New Password"
            />
            {errors.new_password && (
              <p className="invalid-feedback">{errors.new_password.message}</p>
            )}
          </div>
          <div className="uni-mb-32 margin-mobile-view-16">
            <DropworksPassword
              {...register("confirm_password")}
              placeholder="Confirm Password"
            />
            {errors.confirm_password && (
              <p className="invalid-feedback">
                {errors.confirm_password.message}
              </p>
            )}
          </div>
          {loading ? (
            <Loader className="uni-mt-16" />
          ) : (
            <DropworksButton
              type="submit"
              className="recaccount-recover-btn font-weight-600"
            >
              Confirm
            </DropworksButton>
          )}
        </form>
      </div>
    </div>
  );
};
