import { FC } from "react";
import "./styles.scss";
import { DropworksInput } from "../../../../common/custom-field/dropworks-input";
import { DropworksButton } from "../../../../common/custom-field/dropworks-button";
import {
  TextfieldSM,
  TextfieldXSM,
} from "../../../../common/custom-field/dropstock-text";
import { Heading3 } from "../../../../common/custom-field/dropstock-heading";
import { recovereccountemail } from "../login/modal";
import Loader from "../../../shared/components/loader/view";

export const view: FC<recovereccountemail> = ({
  navigateTo,
  onSubmit,
  handleSubmit,
  register,
  errors,
  isSubmitted,
  loading = false,
}) => {
  return (
    <div className="recemail page-content row align-items-pages container pb-3 m-auto h-100 px-0  min-h-800">
      <div className="recemail_content col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
        <Heading3 className="recemail_heading my-0">Recover Account</Heading3>
        <TextfieldSM className="recemail_normal ">
          Input your account’s associated email address
        </TextfieldSM>
        <TextfieldXSM className="recemail_privacy pb-0 mbl-mb-16 sign-up-bottom my-0">
          An account recover email will be sent to the below email
          address​,Check the spam folder of your email box also.
        </TextfieldXSM>
        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
          <DropworksInput
            className={`recemail-bottom mbl-mt-16 uni-mt-32 form-control   ${
              isSubmitted &&
              (errors.email
                ? "is-invalid b-red-2"
                : !errors.email
                ? "is-valid  b-green-2"
                : "")
            }`}
            {...register("email")}
            disabled={loading}
            placeholder="Email"
          />
          {errors.email && (
            <p className="invalid-feedback">{errors.email.message}</p>
          )}
          {loading ? (
            <Loader className="uni-mt-16" />
          ) : (
            <>
              <DropworksButton
                className="uni-mt-16 recemail-recover-btn"
                type="submit"
              >
                Recover
              </DropworksButton>
              <p className="recemail_already_registered uni-my-32 mb-22px sign-up-top mbl-mb-8  fs-6">
                Don’t have an account?{" "}
                <span
                  className="recemail_logins"
                  onClick={navigateTo("/sign-up")}
                >
                  Register
                </span>
              </p>
              <p className="recemail_already_registered  fs-6">
                Already registered?{" "}
                <span
                  className="recemail_logins"
                  onClick={navigateTo("/login")}
                >
                  {" "}
                  Login{" "}
                </span>
              </p>
            </>
          )}
        </form>
      </div>
    </div>
  );
};
