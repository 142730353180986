import { FC, useEffect } from "react";
import Select, { components } from "react-select";
import "./styles.scss";

interface Option {
  value: string;
  label: string;
  image?: string; // Add an optional image property
}

interface DropdownProps {
  options: Option[];
  className?: string;
  placeHolderValue?: string;
  maxheight?: number;
  value: string;
  defaultValueCountry?: any;
  updateValue: (value: string) => void;
  onChangeValue?: (value: string | null) => void;
  isSearch?: boolean;
  disabled?: boolean;
}

export const view: FC<DropdownProps> = ({
  options,
  className,
  placeHolderValue,
  maxheight,
  value,
  isSearch,
  disabled,
  defaultValueCountry,
  updateValue,
  onChangeValue,
}) => {
  const singleSelectStyles = {
    control: (provided: any) => ({
      ...provided,
      "&:hover": { borderColor: "#666" }, // Add hover effect
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: "$theme-color-gray4",
      fontSize: "14px",
    }),
    menuList: (base: any) => ({
      ...base,
      color:"white", 
      backgroundColor: "#212121",
    }),
  };

  // Custom Option Component
  const CustomOption = (props: any) => {
    const { data } = props;
    return (
      <components.Option {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {data.image && (
            <img
              src={data.image}
              alt={data.label}
              className="select-arrow-dropdownImage__flag-img flag-img"
              style={{
                width: "44px",
                height: "25",
                marginRight: "8px",
                marginLeft: "8px",
              }}
            />
          )}
          {data.label}
        </div>
      </components.Option>
    );
  };

  // Custom SingleValue Component
  const CustomSingleValue = (props: any) => {
    const { data } = props;
    return (
      <components.SingleValue {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {data.image && (
            <img
              src={data.image}
              alt={data.label}
              style={{
                width: "44px",
                height: "25",
                marginRight: "8px",
                marginLeft: "9px",
              }}
            />
          )}
          {data.label}
        </div>
      </components.SingleValue>
    );
  };

  const selectedOption = options.find((option) => option.value === value);

  return (
    <>
      <div className="uni-mr-16 mbl-mb-0 uni-mb-8">
        <Select
          options={options}
          className={`${className}`}
          classNamePrefix="select-arrow-dropdownImage"
          styles={singleSelectStyles}
          isClearable={false}
          isDisabled={disabled}
          defaultValue={defaultValueCountry}
          value={selectedOption || defaultValueCountry}
          placeholder={placeHolderValue || "Socials"}
          isSearchable={isSearch !== undefined ? isSearch : false}
          components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
          onChange={(selectedOption) => {
            updateValue(selectedOption?.value || "");
            onChangeValue && onChangeValue(selectedOption?.value || null);
          }}
        />
      </div>
    </>
  );
};
