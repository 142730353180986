import { FC, useEffect, useState } from "react";
import  view  from "./view";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, updateUser } from "../../../utils/apiServices";
import { setUserData } from "../../../../../redux/reducer/userSlice";



export const DisplayLanguage:FC<any>=()=>{
    const dispatch = useDispatch()
    const [selectedLanguage,setSelectedLanguage] = useState<string>()
    const language = useSelector((state: any) => state.user.language)

    useEffect(() => {
        setSelectedLanguage(language)
    }, [language])


    const handleSelectChange = (value: string) => {
        setSelectedLanguage(value)
    };

    const openModal=()=>{
        setSelectedLanguage(language)
    }

    const submitData = async (data: { country: string }) => {
        let response: any = await updateUser(data);
        if (response) {
            const result = await getUserData(); // Call your API function
            if (result.success && result.userData) { // Adjust based on your API response
                dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
            }
        } else {
            //console.log("failLLLLLLLLL");
        }
    }
    return view({language,selectedLanguage,handleSelectChange,submitData,openModal});
}
export default DisplayLanguage