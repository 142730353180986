import React, { FC, useState, useEffect, useRef } from "react";
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6 } from "../../../common/custom-field/dropstock-heading";
import "./styles.scss";
import { TextfieldLG } from "../../../common/custom-field/dropstock-text";


const DropworksRisk: FC<any> = () => {
 
  return (
    <>
    <div className="container container-xxl min-height-cover">
     <Heading1 className="text-center">RISK DISCLOSURE STATEMENT</Heading1>
    

    <Heading4  className="uni-mt-32 uni-mb-32">Introduction </Heading4>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">This Risk Disclosure Statement ("Statement") is provided by DropWorks Markets, Inc. ("Company") to inform users of the potential risks associated with the use of the STUDIO platform ("Platform"). The Platform is an online environment that enables users to collaborate on creative projects, store and share content, and engage in various activities related to the development and distribution of creative works. By accessing and using the Platform, users acknowledge and accept the risks outlined in this Statement. Users are advised to carefully review this Statement in its entirety before participating in any Platform activities.</TextfieldLG>
    <Heading4  className="uni-mt-32 uni-mb-32">1. OPERATIONAL RISKS </Heading4>
    <Heading4  className="uni-mt-32 uni-mb-32">1.1 Platform Technical Risks</Heading4>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The Platform may experience service interruptions, delays, or failures due to various technical issues, including but not limited to software bugs, hardware malfunctions, network disruptions, and power outages. Such technical problems may result in the inability to access the Platform, loss or corruption of data, or other detrimental effects on the user experience. The Company endeavors to maintain the Platform's stability and reliability but cannot guarantee uninterrupted or error-free operation. Users acknowledge that the Platform may be subject to periodic system upgrades, maintenance, or adjustments that may result in temporary downtime or changes to functionality. Furthermore, users understand that the Platform's performance may be affected by factors such as internet connectivity, device compatibility, and third-party software or services.</TextfieldLG>
    <Heading4  className="uni-mt-32 uni-mb-32">1.2 Content Storage Risks</Heading4>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The Platform provides digital storage capabilities for user-generated content. However, users acknowledge that the storage systems utilized by the Platform may be subject to failures, malfunctions, or disruptions. While the Company implements backup systems and security measures to protect user content, there is an inherent risk of data loss or corruption. The Company relies on third-party cloud service providers for content storage, and any issues or failures experienced by these providers may impact the availability or integrity of user content. Users are advised to maintain independent backups of their critical data and to be aware of the risks associated with digital content storage. The Company shall not be held liable for any loss, damage, or inaccessibility of user content resulting from storage system failures or limitations.</TextfieldLG>
    <Heading4  className="uni-mt-32 uni-mb-32">1.3 Security Risks</Heading4>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The Platform employs security measures to protect user accounts, data, and content from unauthorized access, breaches, or cyber threats. However, users acknowledge that no system can guarantee absolute security, and there is an inherent risk of security vulnerabilities or breaches. Unauthorized individuals may attempt to gain access to user accounts or Platform systems through various means, including hacking, phishing, or social engineering. The Company shall not be held liable for any unauthorized access, theft, alteration, or misuse of user data or content resulting from security breaches or user negligence. Users are responsible for maintaining the confidentiality of their account credentials and for promptly reporting any suspected security incidents to the Company.</TextfieldLG>

    <Heading4  className="uni-mt-32 uni-mb-32">2. CREATIVE AND COLLABORATION RISKS</Heading4>
    <Heading4  className="uni-mt-32 uni-mb-32">2.1 Project Completion Risks</Heading4>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">Users engage in collaborative projects on the Platform, which involve the coordination and contribution of multiple parties. However, the successful completion of such projects is subject to various risks. Collaborators may face availability issues, such as scheduling conflicts or personal circumstances, which can impact their ability to fulfill their project responsibilities. Projects may be abandoned or left incomplete due to changes in collaborator commitment, creative differences, or other unforeseen circumstances. Timeline delays may occur due to factors such as resource constraints, technical challenges, or dependencies on external parties. Users acknowledge that the Company does not guarantee the completion or success of any collaborative projects facilitated through the Platform.</TextfieldLG>
    <Heading4  className="uni-mt-32 uni-mb-32">2.2 Creative Control Risks</Heading4>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">Collaborative projects on the Platform involve the contributions and creative input of multiple parties. Users acknowledge that creative differences may arise between collaborators regarding the direction, style, or content of the project. Disagreements over creative control can lead to conflicts, project delays, or even termination. The Platform provides tools for version control and collaboration management, but users are responsible for establishing clear communication channels, roles, and decision-making processes within their project teams. The Company shall not be held liable for any disputes, losses, or damages arising from creative control issues or disagreements between collaborators.</TextfieldLG>
    <Heading4  className="uni-mt-32 uni-mb-32">2.3 Communication Risks</Heading4>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">Effective communication is crucial for successful collaboration on the Platform. However, users acknowledge that miscommunication, misunderstandings, or lack of clarity can occur between collaborators. Factors such as language barriers, cultural differences, time zone disparities, and inadequate documentation can contribute to communication challenges. Users are responsible for establishing clear communication protocols, maintaining accurate and up-to-date project documentation, and actively seeking clarification when needed. The Company shall not be held liable for any losses, delays, or disputes arising from communication issues between collaborators.</TextfieldLG>


    <Heading4  className="uni-mt-32 uni-mb-32">3. FINANCIAL RISKS</Heading4>
    <Heading4  className="uni-mt-32 uni-mb-32">3.1 Revenue Risks</Heading4>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">Users who generate revenue through the Platform are subject to various financial risks. The actual revenue generated from creative projects may vary based on market conditions, consumer preferences, and other external factors. Revenue distribution may be subject to delays or complications due to issues with payment processing systems, currency fluctuations, or tax regulations. Users acknowledge that the Company does not guarantee any specific level of revenue or the timely distribution of funds. The Company shall not be held liable for any losses or damages resulting from revenue fluctuations, payment delays, or issues with third-party payment processors.</TextfieldLG>
    <Heading4  className="uni-mt-32 uni-mb-32"> 3.2 Cost Management Risks</Heading4>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">Engaging in creative projects on the Platform may involve various costs, including but not limited to production expenses, marketing expenditures, and technical upgrades. Users acknowledge that actual costs may exceed initial estimates due to unforeseen circumstances, changes in project scope, or external factors. The Company reserves the right to modify its fee structure or introduce new fees for Platform services, which may impact project budgets. Users are responsible for managing their project costs effectively and maintaining adequate financial resources to meet their obligations. The Company shall not be held liable for any losses or damages resulting from cost overruns, unexpected expenses, or changes in Platform fees.</TextfieldLG>
    <Heading4  className="uni-mt-32 uni-mb-32">3.3 Market Risks</Heading4>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The success and profitability of creative projects on the Platform are subject to market risks. User-generated content may be impacted by changes in market conditions, shifts in consumer preferences, increased competition, industry disruptions, and economic fluctuations. The demand for specific types of content may vary over time, and the popularity of certain genres or formats may wane. Users acknowledge that the Company does not guarantee the commercial success or marketability of any content created or distributed through the Platform. Users are responsible for adapting to changing market conditions and developing content that meets audience demands. The Company shall not be held liable for any losses or damages resulting from market risks or changes in the commercial viability of user-generated content.</TextfieldLG>
    <Heading4  className="uni-mt-32 uni-mb-32">4. LEGAL AND REGULATORY RISKS</Heading4>
    <Heading4  className="uni-mt-32 uni-mb-32">4.1 Intellectual Property Risks</Heading4>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">Users who create, upload, or share content on the Platform are responsible for ensuring that they have the necessary rights and permissions to do so. The Platform may be subject to intellectual property risks, including but not limited to copyright infringement claims, trademark disputes, patent infringement allegations, licensing complications, and issues related to the clearance of third-party rights. Users must obtain proper licenses, consents, and permissions for any third-party content or assets used in their projects. The Company reserves the right to remove or disable access to any content that is found to infringe upon the intellectual property rights of others. Users shall indemnify and hold the Company harmless from any claims, damages, or legal actions arising from intellectual property infringement or unauthorized use of third-party content.</TextfieldLG>
    <Heading4  className="uni-mt-32 uni-mb-32">4.2 Contractual Risks</Heading4>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">Users may enter into contracts or agreements with other parties in connection with their use of the Platform. Such contracts may include collaboration agreements, licensing deals, or service contracts. Users acknowledge that the enforcement and interpretation of these contracts are subject to various risks. Contractual disputes may arise due to differing interpretations of terms, disagreements over performance obligations, or alleged breaches of contract. The resolution of contractual disputes may be complex, time-consuming, and costly. The Company shall not be held liable for any losses, damages, or legal consequences arising from contractual disputes between users or between users and third parties.</TextfieldLG>
    <Heading4  className="uni-mt-32 uni-mb-32">4.3 Regulatory Risks</Heading4>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The Platform and its users are subject to various laws and regulations governing online content, data privacy, intellectual property, and other relevant areas. These laws and regulations may vary across different jurisdictions and are subject to change over time. Users are responsible for complying with all applicable laws and regulations in their use of the Platform and in the creation, distribution, and monetization of their content. Changes in legal or regulatory requirements may impact the operations of the Platform or the rights and obligations of its users. The Company strives to maintain compliance with relevant laws and regulations but cannot guarantee the Platform's immunity from legal challenges or regulatory actions. Users shall indemnify and hold the Company harmless from any claims, damages, or legal actions arising from their violation of applicable laws or regulations.</TextfieldLG>
    <Heading4  className="uni-mt-32 uni-mb-32"> 5. CONTENT DISTRIBUTION RISKS</Heading4>
    <Heading4  className="uni-mt-32 uni-mb-32">5.1 Distribution Platform Risks</Heading4>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The Platform may integrate with or rely on third-party distribution platforms, such as social media networks, streaming services, or content marketplaces, to facilitate the distribution and monetization of user-generated content. However, the availability, terms, and functionality of these distribution platforms are subject to change. Distribution platforms may modify their policies, algorithms, or technical requirements, which can impact the visibility, reach, or monetization potential of user content. Content may be subject to removal or takedown requests based on the policies or decisions of the distribution platforms. Users acknowledge that the Company has no control over the actions or policies of third-party distribution platforms and shall not be held liable for any losses or damages arising from changes or disruptions to these platforms.</TextfieldLG>
    <Heading4  className="uni-mt-32 uni-mb-32"> 5.2 Market Access Risks</Heading4>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The distribution and monetization of user-generated content may be subject to market access risks. Certain geographic regions may have restrictions or limitations on the availability or accessibility of content. Distribution platforms may have different levels of market penetration or user adoption in different countries or regions. Users acknowledge that the global reach and market access of their content may be subject to factors beyond the control of the Company. Users are responsible for complying with any applicable local laws, regulations, or content requirements when distributing their content in different markets. The Company shall not be held liable for any losses or damages arising from market access limitations or regional restrictions.</TextfieldLG>
    <Heading4  className="uni-mt-32 uni-mb-32">5.3 Monetization Risks</Heading4>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The monetization of user-generated content on the Platform or through third-party distribution channels is subject to various risks. Monetization models, such as advertising revenue sharing, subscription-based access, or direct content sales, may be subject to changes in terms, policies, or revenue distribution formulas. Third-party platforms may modify their advertising policies, alter their monetization algorithms, or introduce new restrictions on the types of content eligible for monetization. Users acknowledge that the Company does not guarantee any specific level of monetization or the stability of revenue streams. Users are responsible for adapting to changes in monetization models and exploring alternative revenue sources. The Company shall not be held liable for any losses or damages arising from changes in monetization policies or fluctuations in revenue generated from user-generated content.</TextfieldLG>
<Heading4  className="uni-mt-32 uni-mb-32">6. TECHNOLOGY RISKS</Heading4>
<Heading4  className="uni-mt-32 uni-mb-32">6.1 Integration Risks</Heading4>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The Platform may integrate with or rely on third-party software, services, or APIs to provide certain functionalities or enhance the user experience. However, these third-party integrations are subject to risks such as API changes, service discontinuation, or compatibility issues. The Company does not control the availability, performance, or reliability of third-party integrations and shall not be held liable for any losses or damages arising from their failure or disruption. Users acknowledge that the functionality or features provided by third-party integrations may be subject to change or termination without prior notice. Users are responsible for adapting their content or workflows to any changes or limitations imposed by third-party integrations.</TextfieldLG>
<Heading4  className="uni-mt-32 uni-mb-32">6.2 Performance Risks</Heading4>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The performance of the Platform may be subject to various risks and limitations. The Platform's infrastructure may have capacity constraints that impact the speed, responsiveness, or availability of certain features or services. Users may experience delays or limitations in uploading, processing, or accessing their content due to bandwidth constraints or system overload. The storage capacity of the Platform may be subject to limitations, and users are responsible for managing their storage usage within the provided limits. The Company endeavors to maintain adequate performance levels but shall not be held liable for any losses or damages arising from performance issues, slowdowns, or capacity limitations.</TextfieldLG>
<Heading4  className="uni-mt-32 uni-mb-32">7. REPUTATION RISKS</Heading4>
<Heading4  className="uni-mt-32 uni-mb-32">7.1 Brand Risks</Heading4>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">Users acknowledge that their association with or use of the Platform may have an impact on their personal or professional reputation. The Platform may host a wide range of user-generated content, and users' own content may be associated with or displayed alongside content created by other users. If the Platform or specific users are associated with controversial, offensive, or inappropriate content, it may have negative reputational consequences for other users. The Company takes reasonable efforts to maintain a safe and respectful environment on the Platform but cannot guarantee the absence of controversial or objectionable content. Users are responsible for managing their own brand reputation and making informed decisions about their association with the Platform and other users' content.</TextfieldLG>
<Heading4  className="uni-mt-32 uni-mb-32">7.2 Quality Risks</Heading4>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The quality of user-generated content on the Platform may vary and is subject to individual user's skills, expertise, and standards. The Company does not pre-screen, verify, or endorse the quality, accuracy, or reliability of user-generated content. Users acknowledge that they may encounter content of varying quality levels, including content with production flaws, factual inaccuracies, or inconsistencies. The Company shall not be held liable for any losses or damages arising from the quality or reliability of user-generated content. Users are responsible for evaluating the quality and suitability of content for their own purposes and making informed decisions about its use or reliance.</TextfieldLG>
<Heading4  className="uni-mt-32 uni-mb-32">8. MITIGATION MEASURES</Heading4>
<Heading4  className="uni-mt-32 uni-mb-32">8.1 Platform Security</Heading4>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The Company employs various security measures to protect the Platform and user data from unauthorized access, breaches, and cyber threats. These measures include regular security audits, encryption protocols, access control systems, monitoring systems, and incident response procedures. However, users acknowledge that no security system is infallible, and there remains a risk of security breaches or vulnerabilities. Users are responsible for implementing their own security practices, such as using strong passwords, enabling two-factor authentication, and regularly monitoring their accounts for suspicious activity. The Company shall not be held liable for any losses or damages arising from security breaches or unauthorized access to user accounts or data, unless caused by the Company's gross negligence or willful misconduct.</TextfieldLG>
<Heading4  className="uni-mt-32 uni-mb-32">8.2 Content Protection</Heading4>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The Company implements various measures to protect user-generated content stored on the Platform. These measures include backup systems to prevent data loss, version control mechanisms to track changes and revisions, rights management systems to enforce access controls, content verification processes to detect and prevent unauthorized modifications, and quality assurance procedures to maintain content integrity. However, users acknowledge that no content protection system is foolproof, and there remains a risk of content loss, corruption, or unauthorized access. Users are responsible for maintaining their own backups of critical content and implementing additional content protection measures as deemed necessary. The Company shall not be held liable for any losses or damages arising from content loss, corruption, or unauthorized access, unless caused by the Company's gross negligence or willful misconduct.</TextfieldLG>
<Heading4  className="uni-mt-32 uni-mb-32">8.3 Risk Management</Heading4>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The Company employs various risk management measures to mitigate potential losses or damages arising from the use of the Platform. These measures may include obtaining insurance coverage for certain risks, conducting regular legal compliance reviews, performing technical audits and vulnerability assessments, implementing financial controls and fraud prevention mechanisms, and establishing operational procedures and guidelines. However, users acknowledge that risk management measures cannot eliminate all risks associated with the use of the Platform. Users are responsible for assessing their own risks and implementing additional risk management measures as appropriate for their specific circumstances. The Company shall not be held liable for any losses or damages that exceed the scope of its risk management measures or insurance coverage.</TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">ACKNOWLEDGMENT</TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">By accessing and using the Platform, users acknowledge that they have read, understood, and agree to the terms and conditions outlined in this Risk Disclosure Statement. Users recognize that the use of the Platform involves inherent risks, and they assume full responsibility for any losses, damages, or consequences arising from their use of the Platform. Users agree to comply with all applicable laws, regulations, and Platform policies in their use of the Platform and their interactions with other users. Users understand that this Risk Disclosure Statement is not exhaustive and that other risks not specifically mentioned herein may arise. The Company reserves the right to update or modify this Risk Disclosure Statement at any time, and users are advised to regularly review the most current version available on the Platform.</TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">DISCLAIMER</TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">This Risk Disclosure Statement is provided for informational purposes only and does not constitute legal, financial, or professional advice. The Company does not guarantee the completeness, accuracy, or timeliness of the information contained herein. The Company shall not be held liable for any errors, omissions, or inaccuracies in this Statement. The use of the Platform is at the user's own risk, and the Company disclaims any warranties, express or implied, regarding the Platform's functionality, reliability, or suitability for any particular purpose. The Company shall not be held liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use of the Platform or reliance on this Risk Disclosure Statement. Users are advised to seek independent legal, financial, or professional advice for their specific circumstances.</TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">UPDATES</TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The Company reserves the right to update, modify, or amend this Risk Disclosure Statement at any time without prior notice. Updates may be made to address changes in legal or regulatory requirements, to reflect new risks or mitigating measures, or to improve the clarity and comprehensiveness of the Statement. Users are responsible for regularly reviewing the most current version of the Risk Disclosure Statement available on the Platform. Continued use of the Platform after any updates or modifications to the Statement shall constitute acceptance of the revised terms.</TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">CONTACT INFORMATION</TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">If you have any questions, concerns, or feedback regarding this Risk Disclosure Statement or the risks associated with the use of the Platform, please contact our dedicated support team at Suppports@dropworks.app. We value your input and strive to continuously improve our risk management practices and user experience.</TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">Last Updated: January 12,2025. Version: v.1.0</TextfieldLG>

    </div>
    </>
  );
};

export default DropworksRisk;
