import { FC, useState } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import { DropworksInput } from "../../../../common/custom-field/dropworks-input";
import { DropworksButton } from "../../../../common/custom-field/dropworks-button";
import { FcGoogle } from "react-icons/fc";
import { DropworksPassword } from "../../../../common/custom-field/dropworks-password";
import { TextfieldSM } from "../../../../common/custom-field/dropstock-text";
import { Heading3 } from "../../../../common/custom-field/dropstock-heading";
import Loader from "../../../shared/components/loader/view";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { loginApiGoogle } from "../../../shared/utils/apiServices";
import GoogleSignInButton from "../../google/GoogleSignInButton";

export const view: FC<viewType> = ({
  navigateTo,
  handleSubmit,
  onSubmit,
  errors,
  register,
  loading = false,
  isSubmitted,
  navigate,
  onLoginSuccessfull,
}) => {

  const [googleError,setGoogleError]=useState(false)
  const GoogleLoginAuth = async (data: any) => {
    if (data.credential) {
      const response = await loginApiGoogle(data.credential);
      if (response?.success) {
        if (response.data.mandatory_field === 6) {
          navigate("/fill-details");
        }
        if (response.data.mandatory_field === 7) {
          navigate("/setting-up");
        }
        if (response.data.mandatory_field === 0) {
          localStorage.setItem("dropworksToken", response.data.token);
          onLoginSuccessfull && onLoginSuccessfull();
          navigate("/");
        }
        if (
          response.data.mandatory_field > 0 &&
          response.data.mandatory_field < 6
        ) {
          navigate(
            `/?token=${response?.data?.token}&openPopup=${response?.data?.mandatory_field}&display_name=${response?.data?.username}&profile_image=${response?.data?.image}`
          );
        }
      }
    } else {
      setGoogleError(true);
      setTimeout(()=>{
        setGoogleError(false)
      },2000)
      console.log("not recieved token");
    }
  };
  const iframe: HTMLIFrameElement | null = document.getElementById("qJTHM") as HTMLIFrameElement;
  if (iframe) {
    // Perform actions on the iframe
    console.log("Iframe found:", iframe);
} else {
    console.error("Element with id 'container' not found.");
}

  return (
    <div className="login page-content row align-items-pages container container-xl  container-xxl  pb-3 m-auto h-100 px-0 min-h-800">
      <div className="login_content col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
        <Heading3 className="login_heading my-0">Welcome to DropWorks</Heading3>

        <TextfieldSM className="login_normal front-pages-second-heading uni-my-32">
          Use one of the options below to login
        </TextfieldSM>
        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation ">
          <div className="mobile-margin-top">
            <DropworksInput
              {...register("email")}
              disabled={loading}
              className={`form-control login-specing login-input-first  ${
                isSubmitted &&
                (errors.email
                  ? "is-invalid b-red-2"
                  : !errors.email
                  ? "is-valid  b-green-2"
                  : "")
              }`}
              placeholder="Email"
            />
          </div>
          {errors.email && (
            <p className="invalid-feedback">{errors.email.message}</p>
          )}
          <DropworksPassword
            className="mb-0 relative login-specing "
            className3={isSubmitted}
            className2={`uni-mt-16 mbl-mt-8 form-control login-cstm-pass p-0  ${
              isSubmitted &&
              (errors.password
                ? "is-invalid b-red-2"
                : !errors.password
                ? "is-valid   b-green-2"
                : "")
            }`}
            disabled={loading}
            {...register("password")}
          />
          {errors.password && (
            <p className="invalid-feedback text-danger-mobile">
              {errors.password.message}
            </p>
          )}
          {!loading && (
            <DropworksButton
              type="submit"
              className="mb-0 uni-mt-32 login_register font-weight-600 "
            >
              Login
            </DropworksButton>
          )}
        </form>
        {loading ? (
          <Loader className="uni-mt-40" />
        ) : (
          <>
            {" "}
            <TextfieldSM className="login_or uni-my-16 text-center">
              OR
            </TextfieldSM>
          
            {/* only for the google code refrence */}
            <div className="w-100 google-auth d-none">
  
              <GoogleOAuthProvider  clientId={`${process.env.REACT_APP_Client_Id}`}  >
                <GoogleLogin
                  onSuccess={(data: any) => {
                    console.log(data, "on success google");
                    GoogleLoginAuth(data);
                  }}
                  onError={() =>   setTimeout(()=>{
                    setGoogleError(false)
                  },2000)}
                  width={"100"}
                />
              </GoogleOAuthProvider>
            </div>
            <div className="uni-mb-32 mobile-mb-16">
            <GoogleSignInButton isLogin={true}/>
           {googleError && <p className="invalid-feedback">Something went wrong</p>}
            </div>
            <p className="login_already_registered uni-mb-24 fs-6">
              Don’t have an account?{" "}
              <span className="login_logins" onClick={navigateTo("/sign-up")}>
                Register
              </span>
            </p>
            <p className="login_already_registered  fs-6">
              Forgot your password?{" "}
              <span
                className="login_logins"
                onClick={navigateTo("/recover-account-email")}
              >
                Recover
              </span>
            </p>
          </>
        )}
      </div>
    </div>
  );
};
