import { FC, useEffect, useState } from "react";
import  view  from "./view";
import { getReportedUserChatCount, getTrackBeatProjectUserId, getUserById } from "../../../utils/apiServices";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setbeattrackresponse, setUserModalId } from "../../../../../redux/reducer/reportSlice";
import { RootState } from "../../../../../redux/store";



export const ReportModal1:FC<any>=()=>{

  const [select, setSelect] = useState("");
  const [selectedType, setSelectedType] = useState<string>("");
  const userDataId = useSelector((state: RootState) => state.report.userModalId);
  console.log(userDataId,'userDataId');
  const userId = useSelector((state: RootState) => state.report.reportedUser);
  const changeSelect = (value: string,type:string) => {
    setSelect(value);
    setSelectedType(type);
  };
const dispatch = useDispatch();
const [chatCount , setChatCount] = useState<any>("")


useEffect(()=>{
  const fetchTrackBeatData = async () => {
  const response = await getReportedUserChatCount(userDataId)
  if(response?.success){
    setChatCount(response?.getChatCount?.chatCount)
  }
  }
  fetchTrackBeatData()
},[userDataId])

const fetchTrackBeats = async () => {
  if (!selectedType) return; 
  const result = await getTrackBeatProjectUserId(selectedType, userDataId);
  if (result.success) {
    dispatch(setbeattrackresponse(result.getTrackBeatProjectUserId));
  }
  else{
    dispatch(setbeattrackresponse([]));
  }
};

return view(fetchTrackBeats,select,setSelect,changeSelect,chatCount);
}
export default ReportModal1