import { FC, useEffect, useRef, useState } from "react";
import view from "./view";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { setModal1 } from "../../../../../redux/reducer/modal";
import { registerFirstPopup } from "../../../utils/apiServices";
import { openPopup } from "../../logo-header/view";

export const Modal1: FC<any> = () => {
  const [role, setRole] = useState<string[]>([]);
  const [loading,setLoading]=useState<boolean>(false);
  const [message,setMessage] =useState<string>("");
    const closeModalRef = useRef<HTMLAnchorElement>(null);
  
  const dispatch =useDispatch();
  useEffect(()=>{
if(role.length){
    dispatch(setModal1(false));
  }
else{
    dispatch(setModal1(true))
}
  },[role])

const OpenPopup2= async()=>{
    setLoading(true)
const response:any = await registerFirstPopup(role);
if(response.success)
    {
        setLoading(false);
        if (closeModalRef.current) {
          closeModalRef.current.click();
        }
        openPopup("Modal2",dispatch)
}
else{
setLoading(false);
setMessage(response.message);
setTimeout(()=>{
 
setMessage("");
},3000)
}
}
  const UpdateRole = (data: string) => {
    if (role.includes(data)) {
     !loading && setRole((pre) => pre.filter((ele) => ele !== data));
    } else {
      !loading &&   setRole([...role, data]);
    }
  };
  const {modal1} = useSelector((state: RootState) => state.modal);
  return view({ role, UpdateRole,modal1 ,OpenPopup2,loading,message,closeModalRef});
};
export default Modal1;
