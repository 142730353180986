import { useRef, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { view } from "./view";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { gasFeesCalculate, isAddressValidate, setWalletDetails } from "../../../../../redux/reducer/walletSlice";
import { RootState } from "../../../../../redux/store";
import { AppDispatch } from "../../../../../redux/store";


export const WalletUsdcWithdraw = () => {
  
  return view();
}
export default WalletUsdcWithdraw;