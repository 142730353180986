import {
  TextfieldLG,
  TextfieldSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import { closeModal, CloseModalButton } from "../../logo-header/view";
import "./styles.scss";
import { FC } from "react";
import { DropworksTextArea } from "../../../../../common/custom-field/dropworks-textArea";
import download from "../../../../../assets/steps/download.svg";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { BlueTik } from "../../../../../common/all-icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import UserIcon from "../../../../../assets/steps/Group 18414.svg";
import { dateFormat } from "../../../utils/localStorage";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import { useNavigate } from "react-router-dom";

interface viewType {
  navigateTo: (path: string) => () => void;
}

const view: FC<viewType> = ({ navigateTo }) => {
  const invite = useSelector((state: RootState) => state.invite);
  const navigate = useNavigate();
  const Librarynavigate =()=>{
      navigate('/library')
         closeModal()
    }
  return (
    <div
      className="modal fade"
      id="AcceptedInviteReceived"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered uni-md-popup">
        <div className="modal-content ">
          <div className="modal-cross-icon">
            <CloseModalButton>
              <img src={crossIcon} className="fs-1 uni-mr-16" />
            </CloseModalButton>
          </div>
          <div className="modal-header ps-0 pt-0 ">
            <Heading3 className="modal-title ">
              Invite <span className="invite-received-accepted">Accepted</span>{" "}
            </Heading3>
          </div>
          <div className="modal-body ps-0 pt-0 pb-0">
            <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt ctm-md-lh">
              You accepted the invite to join{" "}
              {invite.Data[invite.selectInde]?.sent_user_id?.display_name}’s
              project. You can see this project in the library
            </TextfieldLG>
            <CloseModalButton>
              <div
                className="d-flex align-items-center uni-mb-32 invite_firstcolumn"
                onClick={navigateTo(
                  `/user-profile/${
                    invite.Data[invite.selectInde]?.sent_user_id?.slug_name
                  }`
                )}
              >
                <img
                  src={`${process.env.REACT_APP_IMGURL}/${
                    invite.Data[invite.selectInde]?.sent_user_id
                      ?.profile_image?.[0]?.thumbnail ||
                      invite.Data[invite.selectInde]?.sent_user_id
                      ?.profile_image?.[0]?.src
                  }`}
                  onError={(e) => (e.currentTarget.src = UserIcon)}
                  className="invite_firstcolumn_imagebox"
                  alt=""
                />

                <div className="invite-modal-pointer">
                  <h2 className="fw-700 text-light uni-mr-8">
                    {invite.Data[invite.selectInde]?.sent_user_id?.display_name}
                    <span>
                      {" "}
                      {Boolean(invite.Data[invite.selectInde]?.sent_user_id?.is_bluetick) && <BlueTik className2="d-flex" className="uni-ml-8"/>}
                    </span>{" "}
                  </h2>
                  <TextfieldLG className="text-light">
                    @{invite.Data[invite.selectInde]?.sent_user_id?.username}
                  </TextfieldLG>
                </div>
              </div>
            </CloseModalButton>
            <TextfieldLG className=" received-label uni-mb-16">
              
              Project
            </TextfieldLG>
            <DropworksInput
              placeholder={
                invite.Data[invite.selectInde]?.track_id?.project_name
              }
              readOnly
              className="uni-mb-32 cstm-inp-invite disable-item"
            ></DropworksInput>

            <TextfieldLG className="received-label uni-mb-16 ">
              Role
            </TextfieldLG>
            <DropworksInput
              placeholder={
                invite.Data[invite.selectInde]?.role.toLowerCase() ==
                "cover designer"
                  ? "Artwork Designer"
                  : invite.Data[invite.selectInde]?.role
              }
              readOnly
              className="uni-mb-32 cstm-inp-invite disable-item"
            ></DropworksInput>

            <TextfieldLG className=" received-label uni-mb-16 ">
              Percentage to offer
            </TextfieldLG>
            <DropworksInput
              placeholder={invite.Data[invite.selectInde]?.percentage_offered}
              readOnly
              className="uni-mb-32 cstm-inp-invite disable-item"
            ></DropworksInput>
            <TextfieldLG className=" received-label uni-mb-16 mbl-mb-8">
              Message
            </TextfieldLG>
            <DropworksTextArea
              className="uni-mb-32 cstm-txt-invite uni-p-16 disable-item mbl-mb-8 "
              hideIcon={true}
              readOnly
              placeholder={invite.Data[invite.selectInde]?.message}
            ></DropworksTextArea>
            {invite.Data[invite.selectInde]?.upload_audio ? (
              <>
                <TextfieldLG className="text-light uni-mb-16 mbl-mt-8">
                  Preview
                </TextfieldLG>
                <div className="d-flex justify-content-between uni-p-16 align-items-center uni-mb-32">
                  <TextfieldSM className="file-name">
                    {
                      invite.Data[invite.selectInde]?.upload_audio?.[0]
                        ?.fileName
                    }
                  </TextfieldSM>

                  <TextfieldSM className="file-name">
                    <a
                      href={`${process.env.REACT_APP_IMGURL}/${
                        invite.Data[invite.selectInde]?.upload_audio?.[0]?.src
                      }`}
                      download={
                        invite.Data[invite.selectInde]?.upload_audio?.[0]
                          ?.fileName
                      }
                    >
                      <img src={download} alt="" className="uni-ml-8" />
                    </a>
                  </TextfieldSM>
                </div>
              </>
            ) : (
              ""
            )}
            <div className=" uni-mb-32">
              {/* <TextfieldSM className='file-name uni-mb-8'>View <span className='collaboration'>Collaboration Agreement</span></TextfieldSM> */}
              <TextfieldSM className="received-date mbl-mt-8">
                Received on{" "}
                {dateFormat(invite.Data[invite.selectInde]?.created_at)}
              </TextfieldSM>
            </div>
            <div className="gotolibrary">
              <DropworksSmallButton className="btn-wid invite-modal-btn m-0 text-nowrap" onClick={Librarynavigate}>Go to Library</DropworksSmallButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;

export const openButton = () => {
  document.getElementById("okk")?.click();
};
