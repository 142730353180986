import { useNavigate } from "react-router-dom"
import view from "./view";
import { LoginFormInputs } from "../login/modal";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getUserData, signUpApi } from "../../../shared/utils/apiServices";
import { useState } from "react";
import { NavigationOnLogin } from "../login";
import { useDispatch } from "react-redux";
import { setMail } from "../../../../redux/reducer/mailSlice";
import { setAuthentication } from "../../../../redux/reducer/auth";
import { setUserData } from "../../../../redux/reducer/userSlice";


export const Sign_Up = () => {
  NavigationOnLogin();
  const [loading, setLoading] = useState(false)
  const schema1 = yup
    .object({
      email: yup
        .string()
        .trim()
        .required("Email is required")
        .matches(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, "Invalid email address"),
      password: yup
        .string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters long"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setError,
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(schema1),
    mode: "onSubmit",
  });

    const onLoginSuccessfull=async()=>{
      dispatch(setAuthentication(true));
      const result = await getUserData(); // Call your API function
      if (result.success && result.userData) { // Adjust based on your API response
        dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
      }
      navigate("/");
    }

  const dispatch = useDispatch();
  const onSubmit = async (data: LoginFormInputs) => {
    setLoading(true);
    let response: any = await signUpApi(data);
    if (response?.success) {
      if(response?.token)
      {
        navigate(`/?token=${response?.token}&openPopup=${response?.mandatory_field}&display_name=${response?.username}&profile_image=${response?.image}`);
      }
     else if(response?.mandatory_field===7){
        navigate("/setting-up")
      }
      else{
      dispatch(setMail(data.email))
      navigate("/fill-details");
      setLoading(false)
    }
    } else {
      setError(response?.type, { type: "custom", message: response?.message });
      setLoading(false)
    }
  };
  const navigate = useNavigate();
  const navigateTo = (value: string) => () => {
    navigate(value)
  }

  return (view({ navigateTo, handleSubmit, onSubmit, errors, register, loading, isSubmitted ,navigate,onLoginSuccessfull}))
}
