import { useNavigate } from "react-router-dom";
import { view } from "./view";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { recoverbyEmail } from "../../../shared/utils/apiServices";
export const LoginRecoverAccountEmail = () => {

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const navigateTo = (value: string) => () => {
    navigate(value);
  };
  const schema3 = yup.object({
    email: yup
      .string()
      .trim()
      .required("Email is required")
      .matches(
        /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
        "Invalid email address"
      ),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitted },
  } = useForm<any>({
    resolver: yupResolver(schema3),
    mode: "onSubmit",
  });

  const onSubmit = async (data: any) => {
    setLoading(true)
    const response: any = await recoverbyEmail(data);
    if (response.success) {
      setLoading(false)
      navigate("/verification-complete");
    } else {
      setLoading(false);
      setError(response.type, { type: "custom", message: response.message });
    }
  };

  return view({
    navigateTo,
    onSubmit,
    register,
    handleSubmit,
    errors,
    isSubmitted,
    loading,
  });
};
