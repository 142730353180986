import {
  TextfieldLG,
  TextfieldSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import {
  DropworksDisableButton,
  DropworksNormalButton,
} from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { closeModal, CloseModalButton } from "../../logo-header/view";
import uploadIcon from '../../../../../assets/steps/uploadicon.svg';
import { SelectArrowDropdown } from "../../../../../common/selection-dropdown";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import Loader from "../../loader/view";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { uploadTrackProject } from "../../../utils/apiServices";
import { fetchRereleaseTrackDetails, fetchTrackDetails } from "../../../../../redux/reducer/uploadSlice";
import ProgressBar from "../../progressBar/view";
import CustomTooltip from "../../../../../common/custom-tooltip/view";
import { RiQuestionFill } from "react-icons/ri";

const view = () => {
  const {trackId,userRole,trackSlugname,trackDetails,reReleaseTrackDetails}=useSelector((state:RootState)=>state.upload)
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [audioFileAPI, setAudioFileAPI] = useState<any>("");
  const [uploadedAudioFile, setUploadedAudioFile] = useState<any>(null);
  const [shortDescription,setShortDescription]=useState("")
  const [selectedValue,setSelectedValue]=useState("");
  const userData = useSelector((state: any) => state.user);
  const dispatch=useDispatch()
  const path = window.location.pathname;
  const [uploadProgress, setUploadProgress] = useState(0);
 
  const [optionsProducer, setOptionsProducer] = useState([
    { value: ' ', label: ' ' },
  ]);

  useEffect(() => {
    if (path?.includes("track-project-overview")) {
      const files = trackDetails?.collaborators?.[0]?.upload_file || [];
      const hasFinalMix = files.some((file: any) => file.file_type === "Final mix and master");
      const hasMixMasterMinusOne = files.some((file: any) => file.file_type === "Mix master minus one");

      setOptionsProducer([
        { value: 'Lead vocal beat', label: 'Lead vocal beat' },
        { value: 'Backing vocal beat', label: 'Backing vocal beat' },
        { value: 'Ad libs beat', label: 'Ad libs beat' },
        { value: 'Instrumental track', label: 'Instrumental track' },
        { value: 'Music minus all', label: 'Music minus all' },
        { value: 'Guided vocal track', label: 'Guided vocal track' },
        { value: 'Music minus one', label: 'Music minus one' },
        { value: 'Vocal track', label: 'Vocal track' },
        { value: 'Final mix and master', label: 'Final mix and master' },
        { value: 'Mix master minus one', label: 'Mix master minus one' },
      ])

    
      if (hasFinalMix && hasMixMasterMinusOne) {
        setOptionsProducer((prevOptions) =>
          prevOptions.filter(
            (option) =>
              option.value !== "Final mix and master" &&
              option.value !== "Mix master minus one"
          )
        );
      } else if (hasFinalMix) {
        setOptionsProducer((prevOptions) =>
          prevOptions.filter((option) => option.value !== "Final mix and master")
        );
      } else if (hasMixMasterMinusOne) {
        setOptionsProducer((prevOptions) =>
          prevOptions.filter((option) => option.value !== "Mix master minus one")
        );
      } else {
        setOptionsProducer([
          { value: 'Lead vocal beat', label: 'Lead vocal beat' },
          { value: 'Backing vocal beat', label: 'Backing vocal beat' },
          { value: 'Ad libs beat', label: 'Ad libs beat' },
          { value: 'Instrumental track', label: 'Instrumental track' },
          { value: 'Music minus all', label: 'Music minus all' },
          { value: 'Guided vocal track', label: 'Guided vocal track' },
          { value: 'Music minus one', label: 'Music minus one' },
          { value: 'Vocal track', label: 'Vocal track' },
          { value: 'Final mix and master', label: 'Final mix and master' },
          { value: 'Mix master minus one', label: 'Mix master minus one' },
        ]);
      }
    }

    if (path?.includes("track-project-rerelease")) {
      const files = reReleaseTrackDetails?.collaborators?.[0]?.upload_file || [];
      const hasFinalMix = files.some((file: any) => file.file_type === "Final mix and master");
      const hasMixMasterMinusOne = files.some((file: any) => file.file_type === "Mix master minus one");

      setOptionsProducer([
        { value: 'Final mix and master', label: 'Final mix and master' },
        { value: 'Mix master minus one', label: 'Mix master minus one' },
      ])
    
      if (hasFinalMix && hasMixMasterMinusOne) {
        setOptionsProducer((prevOptions) =>
          prevOptions.filter(
            (option) =>
              option.value !== "Final mix and master" &&
              option.value !== "Mix master minus one"
          )
        );
      } else if (hasFinalMix) {
        setOptionsProducer((prevOptions) =>
          prevOptions.filter((option) => option.value !== "Final mix and master")
        );
      } else if (hasMixMasterMinusOne) {
        setOptionsProducer((prevOptions) =>
          prevOptions.filter((option) => option.value !== "Mix master minus one")
        );
      } else {
        setOptionsProducer([
          { value: 'Final mix and master', label: 'Final mix and master' },
          { value: 'Mix master minus one', label: 'Mix master minus one' },
        ]);
      }
    }
  }, [trackDetails,reReleaseTrackDetails]);
  

  const optionsBeatMaker = [
    { value: 'Lead vocal beat ', label: 'Lead vocal beat ' },
    { value: 'Backing vocal beat', label: 'Backing vocal beat ' },
    { value: 'Ad libs beat', label: 'Ad libs beat' }
  ] 
  const optionsLyricist = [
    { value: 'Lyric', label: 'Lyric' },
    { value: 'Guided vocal track', label: 'Guided vocal track' },
  ] 
  
  const optionsVocalist = [
    { value: 'Main vocal stem', label: 'Main vocal stem' },
    { value: 'Double recording stem', label: 'Double recording stem' },
    { value: 'Backing vocal stem', label: 'Backing vocal stem' },
    { value: 'Ad libs vocal stem', label: 'Ad libs vocal stem' },
    { value: 'Vocal track', label: 'Vocal track' },
  ] 
  const optionsCoverDesigner = [
    { value: 'Artwork Cover', label: 'Artwork Cover' },
  ] 
  const schema6 = yup.object({
    file_type: yup.string().required("File type is  required"),
    short_description: yup.string().required("Short description is required").max(100, "Short description cannot exceed 100 characters"),
    upload_file: yup
    .mixed()
    .required("Audio file is required"),
});
  

  const onChange=(data:any)=>{
    clearErrors("upload_file"); 
  if(data)
  {
    setValue('file_type',data,{shouldValidate:true})
  }
  setSelectedValue(data);

 data !=selectedValue && handleRemoveAudio()
}
 

  const {register,handleSubmit,formState: { errors ,isSubmitted,isValid},setError,setValue,reset,clearErrors} = useForm<any>({resolver: yupResolver(schema6),mode: "onSubmit"});
 
   const abortControllerRef = useRef<AbortController | null>(null);

  const handleAudioUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {

  if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;


    const file = event.target.files?.[0];
    setError("upload_file", {
      type: "custom",
      message: ""})
    setLoading(true);
    if (file) {
        setUploadedAudioFile(file);
        const formData = new FormData();
        formData.append('uploadingFile', file);

        try {
            const uploadResponse = await axios.post(`${process.env.REACT_APP_URL}/upload-file`, formData,{
            signal: controller.signal,
              onUploadProgress: (progressEvent:any) => {
                  const total = progressEvent.total;
                  const current = progressEvent.loaded;
                  const percentCompleted = Math.round((current * 99) / total);
                  setUploadProgress(percentCompleted); // Update progress
              }
          });
            if (uploadResponse.data.success) {
              setLoading(false);
                setAudioFileAPI(uploadResponse?.data.file_data)
                setValue('upload_file',uploadResponse?.data.file_data,{shouldValidate:true})
                setUploadProgress(0);
            }
            else {
              setLoading(false);
              setUploadProgress(0)
                setError("upload_file", {
                  type: "custom",
                  message: uploadResponse?.data?.message|| "Error while uploading File"
                }); 

                setTimeout(()=>{
                clearErrors("upload_file")
                },1500)
            }
        }
        catch (error) {
            console.error("Catch Error in response of uploading image", error);
            setError("upload_file", {
              type: "custom",
              message: "Error while uploading File"
            }); 

            setTimeout(()=>{
            clearErrors("upload_file")
            },1500)
        }
    }
};
const handleRemoveAudio = () => {
  setUploadedAudioFile(null);
  setAudioFileAPI(null);
isSubmitted && setValue('upload_file',"",{shouldValidate:false});
 isSubmitted && setError('upload_file',{type:"custom",message:"Audio file is required"})

};

const onSubmit = async (data: any) => {
  setLoading2(true)
  data={...data,trackId:trackId};
const response: any = await uploadTrackProject(data);

if (response.success) {
 
  dispatch(fetchTrackDetails(trackSlugname) as any);
  dispatch(fetchRereleaseTrackDetails(trackSlugname) as any)

  
  closeModal();
  setLoading2(false)
} else {
  setError(response.type || "file_type" , { type: "custom", message: response.message });
}
};
const checkFileUtility =  () => {
if (!selectedValue) {
  setError("upload_file", {
    type: "custom",
    message: `Select file utility first.`
  }); 
}
};
 
const openModal=async()=>{
  reset();
  setSelectedValue("");
  setUploadedAudioFile(null);
  setAudioFileAPI(null);
  setShortDescription("");
  setLoading(false);
  if (abortControllerRef.current) {
    abortControllerRef.current.abort();
  }

  const controller = new AbortController();
  abortControllerRef.current = controller;


  const file = "randomstring";
  if (file) {
      setUploadedAudioFile(file);
      const formData = new FormData();
      formData.append('uploadingFile', file);

      try {
          const uploadResponse = await axios.post(`${process.env.REACT_APP_URL}/upload-file`, formData,{
          signal: controller.signal,
            onUploadProgress: (progressEvent:any) => {
                const total = progressEvent.total;
                const current = progressEvent.loaded;
                const percentCompleted = Math.round((current * 99) / total);
            }

          }
        );
        if(uploadResponse){
        setUploadProgress(0)
        setUploadedAudioFile(null);
        setAudioFileAPI(null);
      }
      else{
        setUploadedAudioFile(null);
        setAudioFileAPI(null);
        setUploadProgress(0)

      }
}
catch{
  setUploadedAudioFile(null);
  setAudioFileAPI(null);
  setUploadProgress(0)

}
  }
}
  return (
    <ModalWrapper modalId="UploadModal" onBackdropClick={openModal}>
    <div
      className="modal fade"
      id="UploadModal"
      aria-hidden="true"
      aria-labelledby="UploadModal"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered mx-auto uni-md-popup">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header uni-mb-32 p-0">
            <Heading3 className="modal-title">Upload a File</Heading3>
          </div>
          
          <div className="modal-body ps-0 pt-0 pb-0">
            <TextfieldLG className="text-content mt-0 ">
              File Utility
             {path?.includes("track-project-overview") && userData.id == trackDetails?.trackData?.user_id._id ?
              <CustomTooltip
              title={trackDetails?.trackData?.is_instrumental ?"Final mix and master file is required  to release this project.":"Final mix and master and mix master minus one files are required to release this project." }
              position="right"
            >
              <RiQuestionFill className="uni-ml-16 track-tooltip" />
            </CustomTooltip>:""
            }

{path?.includes("track-project-rerelease") && userData.id == reReleaseTrackDetails?.trackData?.user_id._id ?
              <CustomTooltip
              title={reReleaseTrackDetails?.trackData?.is_instrumental ?"Final mix and master file is required  to release this project.":"Final mix and master and mix master minus one files are required to release this project." }
              position="right"
            >
              <RiQuestionFill className="uni-ml-16 track-tooltip" />
            </CustomTooltip>:""
            }
            
            </TextfieldLG>
            {/* <DropworksSelect className="uni-mb-32">
              <option value="">Select</option>
            </DropworksSelect> */}
            <DropworksDisableButton className={`custom-select-dropdown form-control uni-mt-16  ${isSubmitted && (errors.file_type ? 'is-invalid b-red-2' : !errors.file_type ? 'is-valid  b-green-2' : '')}`} disabled={uploadProgress>0}>
              <SelectArrowDropdown
                options={userRole?.toLocaleLowerCase()=="producer"?optionsProducer:userRole?.toLocaleLowerCase()=="vocalist"?optionsVocalist:userRole?.toLocaleLowerCase()=="lyricist"?optionsLyricist:userRole?.toLocaleLowerCase()=="beat maker"?optionsBeatMaker:userRole?.toLocaleLowerCase()=="cover designer"?optionsCoverDesigner:[]}
                placeHolderValue="Select"
                onChangeValue={onChange}
                selectedValue={selectedValue}
                isRerelease={true}
              ></SelectArrowDropdown>
            </DropworksDisableButton>
            {errors.file_type && typeof errors.file_type.message === "string" && <p className="invalid-feedback error-red">{errors.file_type.message}</p>}

            <TextfieldLG className="text-content uni-mb-16 uni-mt-16" >
              Short descriptions
            </TextfieldLG>
            <DropworksInput placeholder="Type" value={shortDescription} onInput={(e: any) => {
                    setValue('short_description',e.target.value,{shouldValidate:true})
                    setShortDescription(e.target.value);
            }} className={`form-control ${isSubmitted && (errors.short_description ? 'is-invalid b-red-2' : !errors.short_description ? 'is-valid  b-green-2' : '')}`}/>
            {errors.short_description && typeof errors.short_description.message === "string" && <p className="invalid-feedback error-red">{errors.short_description.message}</p>}

            <TextfieldLG className="text-content uni-mb-16 uni-mt-32">
              File
            </TextfieldLG>
            <div className="audio-upload-section">
          {audioFileAPI  ? (
            <div className="audio-file-info d-flex align-items-center justify-content-between">
              <TextfieldSM className="file-name mx-0 my-0">
                {(() => {
                  const fileName = uploadedAudioFile?.name;
                  const extension = fileName?.slice(fileName?.lastIndexOf('.'));
                  const nameWithoutExtension = fileName?.slice(0, fileName.lastIndexOf('.'));

                  return nameWithoutExtension?.length > 10
                    ? `${nameWithoutExtension?.slice(0, 10)}...${extension}`
                    : fileName;
                })()}
              </TextfieldSM>

              <span className="remove-file-icon ml-2" onClick={handleRemoveAudio}>X</span>
            </div>
          ) : loading ?  <>
          <ProgressBar uploadProgress={uploadProgress}/>
      </>
          : (
            <label htmlFor="audio-uploads" className="upload-label">
              <div className={selectedValue===""?"beatEmpty_uploadbox flex-column":"cursor-pointer beatEmpty_uploadbox flex-column"} onClick={checkFileUtility}>
                <img src={uploadIcon} className="audio-img" />
                <label className="uni-pt-16 uni-pb-16 beat-text">Upload</label>
              </div>
            </label>
          )}
       <input
        id="audio-uploads"
        type="file"
        disabled={selectedValue===""}
        // accept={userRole?.toLocaleLowerCase() === "producer" ? ".mp3, .wav, .m4a, .mpeg" :
        //   userRole?.toLocaleLowerCase() === "vocalist" ? ".mp3, .wav, .m4a, .mpeg" :
        //   userRole?.toLocaleLowerCase() === "lyricist" ? ".pdf,.txt" :
        //   userRole?.toLocaleLowerCase() === "beat maker" ? ".mp3, .wav, .m4a, .mpeg" :
        //   userRole?.toLocaleLowerCase() === "cover designer" ? ".jpg, .jpeg, .png" :
        //   ""} 
        accept={selectedValue==="Final mix and master" || selectedValue==="Mix master minus one" ? ".wav":".mp3, .wav, .m4a, .mpeg ,.pdf,.txt ,.srt,.slt ,.jpg, .jpeg, .png"} 
       onChange={(e) => {
       const file = e.target.files?.[0];
        if (file) {
          const maxFileSize = 150 * 1024 * 1024; // 150 MB in bytes
          if (file.size > maxFileSize) {
            e.target.value = ""; 
            setError("upload_file", {
              type: "custom",
              message: `The file size exceeds 150 MB.Select a smaller file.`
            }); 
            return ;
          }

        else if(selectedValue==="Final mix and master" || selectedValue==="Mix master minus one" ){
            const validTypes = [
        'audio/wav'
         ];
         if (validTypes.includes(file.type)) {
          handleAudioUpload(e); 
        } else {
          setError("upload_file", {
            type: "custom",
            message: `Invalid file type. Upload a valid file ${selectedValue==="Final mix and master" || selectedValue==="Mix master minus one" ?"    .wav": ".mp3, .wav, .m4a, .mpeg, .pdf, .txt, .jpg, .jpeg, .png, .srt, .slt"}`
          }); 
        }
         }else{
          const validTypes = [
            'audio/mp3', 'audio/wav', 'audio/m4a', 'audio/mpeg',
            'image/jpeg', 'image/png', 'video/mpeg', 'audio/x-m4a',
            'text/plain', 'application/pdf',
            'application/x-subrip',
            'application/octet-stream' 
             ];
             if (validTypes.includes(file.type)) {
              handleAudioUpload(e); 
            } else {
              setError("upload_file", {
                type: "custom",
                message: `Invalid file type. Upload a valid file ${userRole?.toLocaleLowerCase() === "producer" ? ".mp3, .wav, .m4a, .mpeg, .pdf, .txt, .jpg, .jpeg, .png, .srt, .slt" :
                  userRole?.toLocaleLowerCase() === "vocalist" ? ".mp3, .wav, .m4a, .mpeg, .pdf, .txt, .jpg, .jpeg, .png, .srt, .slt" :
                  userRole?.toLocaleLowerCase() === "lyricist" ? ".mp3, .wav, .m4a, .mpeg, .pdf, .txt, .jpg, .jpeg, .png, .srt, .slt" :
                  userRole?.toLocaleLowerCase() === "beat maker" ? ".mp3, .wav, .m4a, .mpeg, .pdf, .txt, .jpg, .jpeg, .png, .srt, .slt" :
                  userRole?.toLocaleLowerCase() === "cover designer" ? ".mp3, .wav, .m4a, .mpeg, .pdf, .txt, .jpg, .jpeg, .png, .srt, .slt" :
                  " (.mp3, .wav, .m4a, .mpeg, .pdf, .txt, .jpg, .jpeg, .png, .srt, .slt"}`
              }); 
            }
        
         }
      
   
    }
    e.target.value = ''; // Reset the input's value
  }}
  className="d-none"
/>
        </div>
        {errors.upload_file && typeof errors.upload_file.message === "string" && (
  <p className="invalid-feedback error-red">{errors.upload_file.message}</p>
)}
          
          </div>
          <div className="modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72 uni-mt-32">
            {loading2?<Loader className="uni-mt-16"/>:<>
            <CloseModalButton className="buttoncomponent-mobile-modle">
            <DropworksDisableButton className="btn-wid social-link-modal-btns btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">
              Cancel  
            </DropworksDisableButton>
            </CloseModalButton>
            {shortDescription.length<=100&&shortDescription.length>0 &&selectedValue&&audioFileAPI?
            <DropworksNormalButton className="btn-wid social-link-modal-btns m-0" onClick={handleSubmit(onSubmit)}>
              Upload
           </DropworksNormalButton>:
           <DropworksNormalButton disabled className="btn-wid social-link-modal-btns m-0 custom-inactive-btn text-secondary disable-interactions" onClick={handleSubmit(onSubmit)}>
              Upload
           </DropworksNormalButton>
           }
           </>}
          </div>
          
        </div>
      </div>
    </div>
    </ModalWrapper>
  );
};

export default view;
