import { FC, useState } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import iconImg from "../../../assets/img/upload-icon.png";
import { DropworksInput } from "../../../common/custom-field/dropworks-input";
import { DropworksTextArea } from "../../../common/custom-field/dropworks-textArea";
import {
  DropworksButton,
  DropworksNormalButton,
  GradientActiveBtn,
} from "../../../common/custom-field/dropworks-button";
import { DropworksInputIcon } from "../../../common/custom-field/dropworks-input-icon";
import { RiQuestionFill } from "react-icons/ri";
import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
  TextfieldXSM,
} from "../../../common/custom-field/dropstock-text";
import {
  Heading0,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
} from "../../../common/custom-field/dropstock-heading";
import Tabs from "../../../common/tabs";
import TabsComponent from "../../../common/tabs-component/view";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { DropworksInputMarketplace } from "../../../common/custom-field/dropworks-input-marketplace";
import TabsMarketplace from "../../../common/tabs-marketPlace";
import { SelectArrowDropdownMarketplace } from "../../../common/selection-dropdown-marketplace";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const view: FC<viewType> = ({ navigateTo }) => {
  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
  };
  const tableData = [
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Cancel",
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Cancel",
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Cancel",
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Cancel",
    },
  ];

  const tableData2 = [
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Canceled",
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Canceled",
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Cancel",
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Filled",
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Filled",
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Filled",
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Filled",
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Filled",
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Filled",
    },
    {
      date: "08/23/2024",
      time: "18:23:17",
      product: "DWS",
      type: "Buy",
      price: "$3.10",
      quantity: 1000,
      total: "3100 USD",
      filled: "20.3%",
      action: "Filled",
    },
  ];

  const tableData4 = [
    {
      assest: "USD",
      price: "-",
      quantity: 1000,
      total: "1000 USD",
      avgCost: "-",
    },
    {
      assest: "USD",
      price: "-",
      quantity: 1000,
      total: "1000 USD",
      avgCost: "-",
    },
    {
      assest: "USD",
      price: "-",
      quantity: 1000,
      total: "1000 USD",
      avgCost: "-",
    },
  ];

  const [tabsValue2, setTabValue2] = useState(0);
  const checkTabState2 = (value: number) => {
    setTabValue2(value);
  };

  const [tabsValue3, setTabValue3] = useState(0);
  const checkTabState3 = (value: number) => {
    setTabValue3(value);
  };

  const priceData = [
    { price: 3.5, quantity: 1000, total: 4050 },
    { price: 3.4, quantity: 1000, total: 4020 },
    { price: 3.3, quantity: 1000, total: 3990 },
    { price: 3.2, quantity: 1000, total: 3320 },
    // Add more data here...
  ];

  const chartData = {
    labels: [
      "08/14",
      "08/15",
      "08/16",
      "08/17",
      "08/18",
      "08/19",
      "08/20",
      "08/14",
      "08/15",
      "08/16",
      "08/17",
      "08/18",
      "08/19",
      "08/20",
      "08/14",
      "08/15",
      "08/16",
      "08/17",
      "08/18",
    ],
    datasets: [
      {
        label: "Price Over Time",
        data: [
          1, 4, 1, 4, 6, 8, 6, 6, 10, 11, 8, 6, 6, 10, 8, 6, 6, 10, 8, 6, 6, 6,
          8, 10, 12,
        ],
        fill: false,
        backgroundColor: "yellow",
        borderColor: "yellow",
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderWidth: 1,
      },
    ],
  };

  const chartData2 = {
    labels: [
      "08/14",
      "08/15",
      "08/16",
      "08/17",
      "08/18",
      "08/19",
      "08/20",
      "08/14",
      "08/15",
      "08/16",
      "08/17",
      "08/18",
      "08/19",
      "08/20",
      "08/14",
      "08/15",
      "08/16",
      "08/17",
      "08/18",
    ],
    datasets: [
      {
        label: "Price Over Time",
        data: [
          1, 2, 5, 8, 10, 9, 12, 13, 15, 17, 14, 19, 18, 17, 19, 20, 23, 26, 30,
        ],
        fill: false,
        backgroundColor: "yellow",
        borderColor: "yellow",
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderWidth: 1,
      },
    ],
  };

  const chartData3 = {
    labels: [
      "08/14",
      "08/15",
      "08/16",
      "08/17",
      "08/18",
      "08/19",
      "08/20",
      "08/14",
      "08/15",
      "08/16",
      "08/17",
      "08/18",
      "08/19",
      "08/20",
      "08/14",
      "08/15",
      "08/16",
      "08/17",
      "08/18",
    ],
    datasets: [
      {
        label: "Price Over Time",
        data: [
          3.15, 3.2, 3.15, 3.2, 3.3, 3.3, 3.4, 3.3, 3.3, 3.5, 3.3, 3.3, 3.35,
          3.3, 3.4, 3.3, 3.45, 3.5, 3.6,
        ],
        fill: false,
        backgroundColor: "yellow",
        borderColor: "yellow",
        tension: 0,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };
  const options = [
    { value: "1", label: "DropWorks Composite" },
    { value: "2", label: "DropWorks Select" },
  ];
  const [selectedOption, setSelectedOption] = useState<string>("Classic");

  const handleClick = (option: string) => {
    setSelectedOption(option);
  };

  const selectoption = useSelector(
    (state: RootState) => state.dropWorksSelectOption
  );

  const [select, setSelect] = useState<string>();

  return (
    <div className="container marketplace-dropworksSelect-main mt-0 uni-mb-120">
      <div className="d-flex justify-content-between marketplace-dropworksSelect-main-head align-items-center">
        <div className="marketplace-dropworksSelect-main-head-first">
          <SelectArrowDropdownMarketplace
            className="custom-select-marketplace"
            options={options}
            placeHolderValue={"DropWorks Select"}
            onChange={(selected) => {
              setSelect(selected?.value);

              if (selected?.value == "1") {
                navigateTo("/dropworks-composite");
              }
              if (selected?.value == "2") {
                navigateTo("/dropworks-select");
              }
            }}
          ></SelectArrowDropdownMarketplace>
          <TextfieldSM className="marketplace-dropworksSelect-main-head-first-subheading mx-0 my-0">
            DWS
          </TextfieldSM>
          <Heading6
            className="marketplace-dropworksSelect-main-head-first-text3 mb-0 cursor-pointer mx-0 uni-mt-8"
            onClick={() => navigateTo("/select-portfolio")}
          >
            Portfolio Tracks Information
          </Heading6>
        </div>
        <div className="d-flex gap-4 text-end marketplace-dropworksSelect-main-head-second marektplace-second-head-mobile">
          <div>
            <TextfieldLG className="marketplace-dropworksSelect-main-head-gray4 m-0">
              Starting at
            </TextfieldLG>
            <Heading5 className="m-0 marektplace-second-head-mobile-heading">
              $3.22
            </Heading5>
          </div>
          <div>
            <TextfieldLG className="marketplace-dropworksSelect-main-head-gray4 m-0">
              YTD returns
            </TextfieldLG>
            <Heading5 className="m-0 marektplace-second-head-mobile-heading">
              2.52%
            </Heading5>
          </div>
          <div>
            <TextfieldLG className="marketplace-dropworksSelect-main-head-gray4 m-0">
              Tracks
            </TextfieldLG>
            <Heading5 className="m-0 marektplace-second-head-mobile-heading">
              238
            </Heading5>
          </div>
          <div>
            <TextfieldLG className="marketplace-dropworksSelect-main-head-gray4 m-0">
              Issued shares
            </TextfieldLG>
            <Heading5 className="m-0 marektplace-second-head-mobile-heading">
              50K
            </Heading5>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end marketplace-dropworksSelect-main-option">
        <TextfieldXSM
          className={`uni-mr-24 marketplace-dropworksSelect-main-option-text  my-0 ${
            selectedOption === "Classic" ? "selectedoption" : ""
          }`}
          onClick={() => handleClick("Classic")}
        >
          Classic
        </TextfieldXSM>{" "}
        <TextfieldXSM
          className={`mx-0 my-0 marketplace-dropworksSelect-main-option-text ${
            selectedOption === "Traditional" ? "selectedoption" : ""
          }`}
          onClick={() => handleClick("Traditional")}
        >
          Traditional
        </TextfieldXSM>
      </div>

      {selectedOption == "Classic" ? (
        <div>
          <div className="row marketplace-dropworksSelect-options">
            <div className="col-12 col-lg-6 classic-col">
              <div className="d-flex justify-content-center uni-mb-16">
                <Heading5 className="m-0 heading5-trade">Buy Orders</Heading5>
              </div>
              <div className="classic-table">
                <table className="d-table w-100 marketplace-dropworksSelect-table2 table-fixed">
                  <thead className="">
                    <tr>
                      <th className="text-wh uni-pl-24">Orders</th>
                      <th className="text-center text-wh">Shares</th>
                      <th className="text-end text-wh uni-pr-24">BID</th>
                    </tr>
                  </thead>
                  <tbody className="umi-mt-8 marketplace-dropworksSelect-tbody">
                    <tr>
                      <td className="td-top-p pl-70 marketplace-dropworksSelect-td-first">
                        5
                      </td>
                      <td className="text-center td-top-p uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end td-top-p uni-pr-24 marketplace-dropworksSelect-td-last">
                        $31.54
                      </td>
                    </tr>
                    <tr>
                      <td className="pl-70 marketplace-dropworksSelect-td-first">
                        5
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        $31.54
                      </td>
                    </tr>{" "}
                    <tr>
                      <td className="pl-70 marketplace-dropworksSelect-td-first">
                        5
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        $31.54
                      </td>
                    </tr>{" "}
                    <tr>
                      <td className="pl-70 marketplace-dropworksSelect-td-first">
                        5
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        $31.54
                      </td>
                    </tr>{" "}
                    <tr>
                      <td className="pl-70 marketplace-dropworksSelect-td-first">
                        5
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        $31.54
                      </td>
                    </tr>{" "}
                    <tr>
                      <td className="pl-70 marketplace-dropworksSelect-td-first">
                        5
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        $31.54
                      </td>
                    </tr>{" "}
                    <tr>
                      <td className="pl-70 marketplace-dropworksSelect-td-first">
                        5
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        $31.54
                      </td>
                    </tr>{" "}
                    <tr>
                      <td className="pl-70 marketplace-dropworksSelect-td-first">
                        5
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        $31.54
                      </td>
                    </tr>{" "}
                    <tr>
                      <td className="pl-70 marketplace-dropworksSelect-td-first">
                        5
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        $31.54
                      </td>
                    </tr>{" "}
                    <tr>
                      <td className="pl-70 marketplace-dropworksSelect-td-first">
                        5
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        $31.54
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center uni-mt-16 Classic-table-bottom uni-mx-24">
                <TextfieldXSM className="mx-0 my-0">
                  Total Shares: 6000
                </TextfieldXSM>
              </div>
            </div>

            <div className="col-12 col-lg-6 classic-col">
              <div className="d-flex justify-content-center uni-mb-16">
                <Heading5 className="m-0 heading5-trade">Sell Orders</Heading5>
              </div>
              <div className="traditional-table">
                <table className="d-table w-100 marketplace-dropworksSelect-table2 table-fixed">
                  <thead className="uni-pb-32">
                    <tr>
                      <th className="text-wh uni-pl-24">ASK</th>
                      <th className="text-center text-wh">Shares</th>
                      <th className="text-end text-wh uni-pr-24">Orders</th>
                    </tr>
                  </thead>
                  <tbody className="uni-mt-8">
                    <tr>
                      <td className="td-top-p uni-pl-24 marketplace-dropworksSelect-second-td-first">
                        $31.23
                      </td>
                      <td className="text-center td-top-p uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end td-top-p uni-pr-24 marketplace-dropworksSelect-td-last">
                        6
                      </td>
                    </tr>
                    <tr>
                      <td className="uni-pl-24 marketplace-dropworksSelect-second-td-first">
                        $31.23
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        6
                      </td>
                    </tr>
                    <tr>
                      <td className="uni-pl-24 marketplace-dropworksSelect-second-td-first">
                        $31.23
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        6
                      </td>
                    </tr>
                    <tr>
                      <td className="uni-pl-24 marketplace-dropworksSelect-second-td-first">
                        $31.23
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        6
                      </td>
                    </tr>
                    <tr>
                      <td className="uni-pl-24 marketplace-dropworksSelect-second-td-first">
                        $31.23
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        6
                      </td>
                    </tr>
                    <tr>
                      <td className="uni-pl-24 marketplace-dropworksSelect-second-td-first">
                        $31.23
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        6
                      </td>
                    </tr>
                    <tr>
                      <td className="uni-pl-24 marketplace-dropworksSelect-second-td-first">
                        $31.23
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        6
                      </td>
                    </tr>
                    <tr>
                      <td className="uni-pl-24 marketplace-dropworksSelect-second-td-first">
                        $31.23
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        6
                      </td>
                    </tr>
                    <tr>
                      <td className="uni-pl-24 marketplace-dropworksSelect-second-td-first">
                        $31.23
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        6
                      </td>
                    </tr>
                    <tr>
                      <td className="uni-pl-24 marketplace-dropworksSelect-second-td-first">
                        $31.23
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        6
                      </td>
                    </tr>
                    <tr>
                      <td className="uni-pl-24 marketplace-dropworksSelect-second-td-first">
                        $31.23
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        6
                      </td>
                    </tr>
                    <tr>
                      <td className="uni-pl-24 marketplace-dropworksSelect-second-td-first">
                        $31.23
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        6
                      </td>
                    </tr>
                    <tr>
                      <td className="uni-pl-24 marketplace-dropworksSelect-second-td-first">
                        $31.23
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        6
                      </td>
                    </tr>
                    <tr>
                      <td className="uni-pl-24 marketplace-dropworksSelect-second-td-first">
                        $31.23
                      </td>
                      <td className="text-center uni-pl-24 marketplace-dropworksSelect-td-second">
                        1000
                      </td>
                      <td className="text-end uni-pr-24 marketplace-dropworksSelect-td-last">
                        6
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center uni-mt-16 traditional-table-bottom">
                <TextfieldXSM className=" mx-0 my-0">
                  Total Shares: 6000
                </TextfieldXSM>
              </div>
            </div>
          </div>

          <div className="marketplace-dropworksSelect-options2 tabs-1-limit">
            <Tabs
              tabsTitle={["Limit", "Market"]}
              id="3"
              checkTabState={checkTabState2}
              className=" uni-ml-32 tabs-1"
            />
            <TabsComponent
              tabsValue={tabsValue2}
              components={[
                <>
                  <div className="row">
                    <div className="col-12 col-lg-6 uni-p-32 uni-pt-16 uni-pr-40 mobile-view-dw-limit-market">
                      {/* <DropworksInput placeholder="Price" /> */}
                      <DropworksInputMarketplace
                        placeholder="Price"
                        unit="USD"
                        className="mb-0 placeholder-text"
                      />
                      <div className="uni-mt-8 uni-mb-16 mobile-mt-8-mb-8">
                        <DropworksInputMarketplace
                          placeholder="Quantity"
                          unit="DWS"
                        />
                      </div>
                      <DropworksInputMarketplace
                        placeholder="Total"
                        unit="USD"
                      />
                      <div className="d-flex justify-content-between uni-mb-16 uni-mt-16">
                        <TextfieldXSM className="mx-0 my-0 text-available">
                          Available
                        </TextfieldXSM>
                        <TextfieldXSM className="mx-0 my-0">
                          1000 USD
                        </TextfieldXSM>
                      </div>
                      <DropworksNormalButton className="btn-green uni-mt-16">
                        Buy
                      </DropworksNormalButton>
                    </div>

                    <div className="col-12 col-lg-6 uni-p-32 uni-pt-16 uni-pl-40 mobile-view-dw-limit-market">
                      <DropworksInputMarketplace
                        placeholder="Price"
                        unit="USD"
                        className="mb-0"
                      />
                      <div className="uni-mt-8 uni-mb-16 mobile-mt-8-mb-8">
                        <DropworksInputMarketplace
                          placeholder="Quantity"
                          unit="DWS"
                        />
                      </div>
                      <DropworksInputMarketplace
                        placeholder="Total"
                        unit="USD"
                      />
                      <div className="d-flex justify-content-between uni-mb-16 uni-mt-16">
                        <TextfieldXSM className="mx-0 my-0 text-available">
                          Available
                        </TextfieldXSM>
                        <TextfieldXSM className="mx-0 my-0">
                          1000 USD
                        </TextfieldXSM>
                      </div>
                      <DropworksNormalButton className="btn-red uni-mt-16">
                        Sell
                      </DropworksNormalButton>
                    </div>
                  </div>
                </>,
                <>
                  <div className="row">
                    <div className="col-12 col-lg-6  uni-p-32 uni-pt-16 mobile-view-dw-limit-market">
                      <div className="uni-mb-16 mobile-mb-8">
                        <DropworksInputMarketplace
                          placeholder="Price"
                          unit="DWS"
                          className="mb-0"
                        />
                      </div>

                      <DropworksInputMarketplace
                        placeholder="Price"
                        unit="USD"
                      />

                      <div className="d-flex justify-content-between uni-mb-16 uni-mt-16">
                        <TextfieldXSM className="mx-0 my-0 text-available">
                          Available
                        </TextfieldXSM>
                        <TextfieldXSM className="mx-0 my-0">
                          1000 USD
                        </TextfieldXSM>
                      </div>
                      <DropworksNormalButton className="btn-green uni-mt-16">
                        Buy
                      </DropworksNormalButton>
                    </div>
                    <div className="col-12 col-lg-6 uni-p-32 uni-pt-16 mobile-view-dw-limit-market">
                      <div className="uni-mb-16 mobile-mb-8">
                        <DropworksInputMarketplace
                          placeholder="Price"
                          unit="DWS"
                          className="mb-0"
                        />
                      </div>

                      <DropworksInputMarketplace
                        placeholder="Price"
                        unit="USD"
                      />

                      <div className="d-flex justify-content-between uni-mb-16 uni-mt-16">
                        <TextfieldXSM className="mx-0 my-0 text-available">
                          Available
                        </TextfieldXSM>
                        <TextfieldXSM className="mx-0 my-0">
                          1000 USD
                        </TextfieldXSM>
                      </div>
                      <DropworksNormalButton className="btn-red uni-mt-16">
                        Sell
                      </DropworksNormalButton>
                    </div>
                  </div>
                </>,
              ]}
            />
          </div>
        </div>
      ) : (
        <div className="row  marketplace-dropworksSelect-main-col1">
          <div className="col-lg-3 col-md-3 col-sm-12  col-12  table-main uni-p-16 ">
            <table className="marketplace-dropworksSelect-main-col1-table">
              <thead>
                <tr>
                  <th className="">
                    <TextfieldXSM className="m-0"> Price</TextfieldXSM>
                  </th>
                  <th className="text-center">
                    <TextfieldXSM className="m-0">Quantity</TextfieldXSM>
                  </th>
                  <th className="text-end">
                    <TextfieldXSM className="m-0">Total</TextfieldXSM>
                  </th>
                </tr>
              </thead>
              <tbody>
                {[...Array(11)].map((_, index) => (
                  <tr key={index}>
                    <td className="colour-red">
                      <TextfieldXSM className="m-0">$4.50</TextfieldXSM>
                    </td>
                    <td className="colour-g4 text-center">
                      <TextfieldXSM className="m-0">1000</TextfieldXSM>
                    </td>
                    <td className="text-end colour-g4">
                      <TextfieldXSM className="m-0">4500.00</TextfieldXSM>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="d-flex align-items-center uni-mt-8 uni-mb-8 marketplace-dropworksSelect-table-text">
              <TextfieldLG className="mx-0 my-0">$3.22 &nbsp;</TextfieldLG>
              <TextfieldSM className="mx-0 my-0 colour-g3">
                Current price
              </TextfieldSM>
            </div>
            <table className="marketplace-dropworksSelect-main-col1-table">
              <tbody>
                {[...Array(5)].map((_, index) => (
                  <tr key={index}>
                    <td className="colour-green">
                      <TextfieldXSM className="m-0">$3.21</TextfieldXSM>
                    </td>
                    <td className="colour-g4">
                      <TextfieldXSM className="m-0 text-center">
                        1000
                      </TextfieldXSM>
                    </td>
                    <td className="text-end colour-g4">
                      <TextfieldXSM className="m-0">4500.00</TextfieldXSM>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12 col-12  second-col px-0">
            <div className="chart-main">
              <div className="d-flex align-items-center">
                <div className="marketplace-dropworksSelect-graph-left-text">
                  <TextfieldXSM className="uni-mb-40 mt-0 colour-g4">
                    $3.50
                  </TextfieldXSM>
                  <TextfieldXSM className="uni-mb-40  colour-g4">
                    $3.50
                  </TextfieldXSM>
                  <TextfieldXSM className="uni-mb-40 colour-g4">
                    $3.50
                  </TextfieldXSM>
                  <TextfieldXSM className="colour-g4">$3.50</TextfieldXSM>
                </div>
                <div className="w-100">
                  <div className="d-flex uni-mb-8 marketplace-dropworksSelect-graph-tab">
                    <TextfieldXSM className=" my-0 text-nowrap uni-mr-24 colour-g3">
                      Time scale
                    </TextfieldXSM>
                    <TabsMarketplace
                      tabsTitle={["Week", "Month", "Year"]}
                      checkTabState={checkTabState3}
                    />
                  </div>
                  <TabsComponent
                    tabsValue={tabsValue3}
                    components={[
                      <>
                        <div className="uni-ml-8 uni-mb-8 chart-height">
                          <Line
                            data={chartData}
                            options={chartOptions}
                            className="w-100"
                          />
                        </div>
                      </>,
                      <>
                        <div className="uni-ml-8 uni-mb-8 chart-height">
                          <Line
                            data={chartData2}
                            options={chartOptions}
                            className="w-100"
                          />
                        </div>
                      </>,
                      <>
                        <div className="uni-ml-8 uni-mb-8 chart-height">
                          <Line
                            data={chartData3}
                            options={chartOptions}
                            className="w-100"
                          />
                        </div>
                      </>,
                    ]}
                  />
                </div>
              </div>
              <div className="d-flex chart-bottom justify-content-between">
                <TextfieldXSM className="colour-g4 m-0">08/15</TextfieldXSM>
                <TextfieldXSM className="colour-g4 m-0">08/16</TextfieldXSM>
                <TextfieldXSM className="colour-g4 m-0">08/17</TextfieldXSM>
                <TextfieldXSM className="colour-g4 m-0">08/18</TextfieldXSM>
                <TextfieldXSM className="colour-g4 m-0">08/19</TextfieldXSM>
                <TextfieldXSM className="colour-g4 m-0">08/20</TextfieldXSM>
              </div>
            </div>

            <div className="tabs-1-limit">
              <Tabs
                id="7"
                tabsTitle={["Limit", "Market"]}
                checkTabState={checkTabState2}
                className="uni-mb-16 uni-pl-32 tabs-1"
              />
              <TabsComponent
                tabsValue={tabsValue2}
                components={[
                  <>
                    <div className="row uni-mr-24 marketplace-placeholders-mobile">
                      <div className="col-12 col-lg-6 uni-p-32 uni-pt-16  mobile-view-dw-limit-market">
                        {/* <DropworksInput placeholder="Price" /> */}

                        <DropworksInputMarketplace
                          placeholder="Price"
                          unit="USD"
                        />

                        <div className="uni-mb-16 uni-mt-8 mobile-mt-8-mb-8">
                          <DropworksInputMarketplace
                            placeholder="Quantity"
                            unit="DWS"
                          />
                        </div>
                        <DropworksInputMarketplace
                          placeholder="Total"
                          unit="USD"
                        />
                        <div className="d-flex justify-content-between uni-mb-16 uni-mt-8">
                          <TextfieldXSM className="mx-0 my-0 text-available">
                            Available
                          </TextfieldXSM>
                          <TextfieldXSM className="mx-0 my-0">
                            1000 USD
                          </TextfieldXSM>
                        </div>
                        <DropworksNormalButton className="btn-green uni-mt-16">
                          Buy
                        </DropworksNormalButton>
                      </div>

                      <div className="col-12 col-lg-6 uni-p-32 uni-pt-16 mobile-view-dw-limit-market">
                        <DropworksInputMarketplace
                          placeholder="Price"
                          unit="USD"
                        />
                        <div className="uni-mb-16 uni-mt-8 mobile-mt-8-mb-8">
                          <DropworksInputMarketplace
                            placeholder="Quantity"
                            unit="DWS"
                          />
                        </div>
                        <DropworksInputMarketplace
                          placeholder="Total"
                          unit="USD"
                        />
                        <div className="d-flex justify-content-between uni-mb-16 uni-mt-8">
                          <TextfieldXSM className="mx-0 my-0 text-available">
                            Available
                          </TextfieldXSM>
                          <TextfieldXSM className="mx-0 my-0">
                            1000 USD
                          </TextfieldXSM>
                        </div>
                        <DropworksNormalButton className="btn-red uni-mt-16">
                          Sell
                        </DropworksNormalButton>
                      </div>
                    </div>
                  </>,
                  <>
                    <div className="row">
                      <div className="col-12 col-lg-6 uni-p-32 uni-pt-16  mobile-view-dw-limit-market">
                        <div className="uni-mb-16 mobile-mb-8">
                          <DropworksInputMarketplace
                            placeholder="Price"
                            unit="DWS"
                          />
                        </div>
                        <DropworksInputMarketplace
                          placeholder="Price"
                          unit="USD"
                        />

                        <div className="d-flex justify-content-between uni-mb-16 uni-mt-16">
                          <TextfieldXSM className="mx-0 my-0 text-available">
                            Available
                          </TextfieldXSM>
                          <TextfieldXSM className="mx-0 my-0">
                            1000 USD
                          </TextfieldXSM>
                        </div>
                        <DropworksNormalButton className="btn-green uni-mt-16">
                          Buy
                        </DropworksNormalButton>
                      </div>
                      <div className="col-12 col-lg-6 uni-p-32 uni-pt-16  mobile-view-dw-limit-market">
                        <div className="uni-mb-16 mobile-mb-8">
                          <DropworksInputMarketplace
                            placeholder="Price"
                            unit="DWS"
                          />
                        </div>
                        <DropworksInputMarketplace
                          placeholder="Price"
                          unit="USD"
                        />

                        <div className="d-flex justify-content-between uni-mb-16 uni-mt-16">
                          <TextfieldXSM className="mx-0 my-0 text-available">
                            Available
                          </TextfieldXSM>
                          <TextfieldXSM className="mx-0 my-0">
                            1000 USD
                          </TextfieldXSM>
                        </div>
                        <DropworksNormalButton className="btn-red uni-mt-16">
                          Sell
                        </DropworksNormalButton>
                      </div>
                    </div>
                  </>,
                ]}
              />
            </div>
          </div>
        </div>
      )}

      <div className="marketplace-dropworksSelect-main-table-content">
        <Tabs
          tabsTitle={[
            "Active Orders",
            "Order History",
            "Transaction History",
            "Assets",
          ]}
          id="8"
          className="uni-mb-24 tab-bottom"
          checkTabState={checkTabState}
        />

        <TabsComponent
          tabsValue={tabsValue}
          components={[
            <>
              <div className="marketplace-dropworksSelect-table-container">
                <table className="d-table w-100 marketplace-dropworksSelect-table">
                  <thead className="uni-pb-32">
                    <tr>
                      <th scope="col" className=" w-170">
                        Date <RiArrowDropDownLine />
                      </th>
                      <th scope="col">
                        Product <RiArrowDropDownLine />
                      </th>
                      <th scope="col">
                        Type <RiArrowDropDownLine />
                      </th>
                      <th scope="col">
                        Price <RiArrowDropDownLine />
                      </th>
                      <th scope="col">
                        Quantity <RiArrowDropDownLine />
                      </th>
                      <th scope="col">
                        Total <RiArrowDropDownLine />
                      </th>
                      <th scope="col">Filled</th>
                      <th scope="col" className="colour-yellow text-end">
                        Cancel all
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <span className="text-wh">{row.date}</span>{" "}
                          <span className="time">{row.time}</span>
                        </td>
                        <td className="text-wh">{row.product}</td>
                        <td className="colour-green">{row.type}</td>
                        <td>{row.price}</td>
                        <td>{row.quantity}</td>
                        <td>{row.total}</td>
                        <td>{row.filled}</td>
                        <td className="colour-yellow text-end">{row.action}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>,
            <>
              <div className="marketplace-dropworksSelect-table-container">
                <table className="d-table w-100 marketplace-dropworksSelect-table">
                  <thead className="uni-pb-32">
                    <tr>
                      <th scope="col" className=" w-170">
                        Date <RiArrowDropDownLine />
                      </th>
                      <th scope="col">
                        Product <RiArrowDropDownLine />
                      </th>
                      <th scope="col">
                        Type <RiArrowDropDownLine />
                      </th>
                      <th scope="col">
                        Price <RiArrowDropDownLine />
                      </th>
                      <th scope="col">
                        Quantity <RiArrowDropDownLine />
                      </th>
                      <th scope="col">
                        Total <RiArrowDropDownLine />
                      </th>
                      <th scope="col">Filled</th>
                      <th scope="col" className=" text-end">
                        State
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData2.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <span className="text-wh">{row.date}</span>{" "}
                          <span className="time">{row.time}</span>
                        </td>
                        <td className="text-wh">{row.product}</td>
                        <td className="colour-green">{row.type}</td>
                        <td>{row.price}</td>
                        <td>{row.quantity}</td>
                        <td>{row.total}</td>
                        <td>{row.filled}</td>
                        <td className=" text-end">{row.action}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>,
            <>
              <div className="marketplace-dropworksSelect-table-container">
                <table className="d-table w-100 marketplace-dropworksSelect-table table-fixed">
                  <thead className="uni-pb-32">
                    <tr>
                      <th scope="col ">
                        Date <RiArrowDropDownLine />
                      </th>
                      <th scope="col">
                        Product <RiArrowDropDownLine />
                      </th>
                      <th scope="col">
                        Type <RiArrowDropDownLine />
                      </th>
                      <th scope="col">
                        Price <RiArrowDropDownLine />
                      </th>
                      <th scope="col" className="text-center">
                        Quantity <RiArrowDropDownLine />
                      </th>
                      <th scope="col" className="text-end">
                        Total <RiArrowDropDownLine />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <span className="text-wh">{row.date}</span>{" "}
                          <span className="time">{row.time}</span>
                        </td>
                        <td className="text-wh">{row.product}</td>
                        <td className="colour-green">{row.type}</td>
                        <td>{row.price}</td>
                        <td className="text-center">{row.quantity}</td>
                        <td className="text-end">{row.total}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>,
            <>
              <div className="marketplace-dropworksSelect-table-container">
                <table className="d-table w-100 marketplace-dropworksSelect-table table-fixed">
                  <thead className="uni-pb-32">
                    <tr>
                      <th scope="col">Asset</th>
                      <th scope="col" className="text-center">
                        Price
                      </th>
                      <th scope="col" className="text-center">
                        Quantity
                      </th>
                      <th scope="col" className="text-center">
                        Total
                      </th>
                      <th scope="col " className="text-end">
                        Avg. Cost
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData4.map((row, index) => (
                      <tr key={index}>
                        <td className="text-wh">{row.assest}</td>
                        <td className="text-center">{row.price}</td>
                        <td className="text-center">{row.quantity}</td>
                        <td className="text-center">{row.total}</td>
                        <td className="text-end">{row.avgCost}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>,
          ]}
        />
      </div>
    </div>
  );
};

export default view;
