import { FC, useRef, useState } from "react";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { view } from "./view";
import { useNavigate } from "react-router-dom";
import { changePassword, deleteAccount } from "../../../utils/apiServices";


export const DeleteAccount=()=>{
    const [loading, setLoading] = useState(false);
    const [passwordToken,setPasswordToken]=useState("")
    const closeModalRef = useRef<HTMLAnchorElement>(null); 
    const navigate = useNavigate(); 
    const navigateTo = (value: string) => () => {
        navigate(value);
      };
    const schema4 = yup.object({
        username: yup.string().required("Username required"),
        password: yup.string().required("Password required"),
        reason: yup.string().required("Reason required"),
       
      });
      const handleCloseModalClick = () => {
        if (closeModalRef.current) {
          closeModalRef.current.click(); 
        }
      };
           
    const onSubmit = async (data: any) => {
        setLoading(true)
        const response: any = await deleteAccount({data,passwordToken});

        if (response.success) {
            localStorage.removeItem('dropworksToken')
          setLoading(false);
          handleCloseModalClick()
          navigate("/login");
        } else {
            setLoading(false);
          setError(response.type || "username" , { type: "custom", message: response.message });
        }
      };



    const {
        register,
        handleSubmit,
        setError,
        reset,
        setValue,
        formState: { errors, isSubmitted },
      } = useForm<any>({
        resolver: yupResolver(schema4),
        mode: "onSubmit",
      });
      const openModal=()=>{
       reset();
      }

    return view({errors,isSubmitted,loading,register,onSubmit,handleSubmit,navigateTo,closeModalRef,setValue,openModal});
}
export default DeleteAccount