import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import * as Sentry from '@sentry/react';

export interface walletDetails {

  type?: string,
  receiverAddress?: string,
  pin?: string,
  amount?: string | string[],
  withdraw?: string,
  deposit?: string,
  trackNFTId?: string | string[],
  networkFee?: string
  dropStocksCount?: string
  usdcCount?: string
  cryptoPrice?: string
  cryptoSymbol?: string
  fiatPrice?: string
  fiatSymbol?: string
  status?: string,
  message?: string,
  netAmount?: string
  trackDescription?: string
  trackTitle?: string
  selectedDropStocks?:any[]
}
// export interface WalletTransactions {
//   page?: string,
//   limit?: string,
//   sourceType?: string,
// }

export interface WalletDetailsDropstocks {
  address: string,
  dropstocks: {
    royality: {
      balance: number,
      symbol: string,
    },
  },
  dwc: {
    balance: string,
    royality: {
      balance: number,
      symbol: string,
    },
  },
  dws: {
    balance: string,
    royality: {
      balance: number,
      symbol: string,
    },
  },
  usdc: {
    balance: string,
    symbol: string,
  },
  network: {
    balance: string,
    symbol: string,
  },
}
export interface AddressValidate {
  receiverAddress?: string,
}
export interface GasFeesCalculate {
  receiverAddress?: string,
  type: string,
  pin: string,
  trackNFTId?: string | string[],
  amount: string | string[],
}

export interface WalletState {
  walletData: walletDetails
  walletDropStocks: WalletDetailsDropstocks;
  walletTransactions: any[];
  walletDetails: any[];

}
const initialState: WalletState = {
  walletData:
  {
    type: "",
    receiverAddress: "",
    pin: "",
    amount: "",
    withdraw: "USDC",
    deposit: "USDC",
    trackNFTId: "",
    networkFee: "",
    dropStocksCount: "",
    usdcCount: "",
    cryptoPrice: "0.00",
    cryptoSymbol: "USDC",
    fiatPrice: "0.00",
    fiatSymbol: "USDC",
    status: "waiting",
    netAmount: "0.00",
    trackDescription: "",
    trackTitle: "",
    selectedDropStocks:[],
    message: ""
  },
  walletDropStocks: {
    address: "",
    dropstocks: {
      royality: {
        balance: 0,
        symbol: "USDC",
      },
    },
    dwc: {
      balance: "0",
      royality: {
        balance: 0,
        symbol: "USDC",
      },
    },
    dws: {
      balance: "0",
      royality: {
        balance: 0,
        symbol: "USDC",
      },
    },
    usdc: {
      balance: "0.0",
      symbol: "USDC",
    },
    network: {
      balance: "0.0",
      symbol: "ETH",
    },
  },
  walletDetails: [],
  walletTransactions: [],
};

// check is Address Validate
export const isAddressValidate = createAsyncThunk(
  "wallet/isAddressValidate",
  async (body: AddressValidate, { rejectWithValue }) => {

    try {
      const response: any = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/users/receiver-address-validate`,
        body,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        });
      if (response.data) {
        return response.data || [];
      } else {

        return []

      }
    } catch (err: any) {
      return []
    }
  }
);

// to get the Network Fee

export const gasFeesCalculate = createAsyncThunk(
  "wallet/gasFeesCalculate", // Action type
  async (body: GasFeesCalculate, { rejectWithValue }) => {

    try {
      const response: any = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/users/gas-fees-calculate`,
        body,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        });
      if (response.data) {
        return response.data || [];

      } else {

        return []

      }
    } catch (err: any) {
      return []
    }
  }
);



// transfer Usdc Or Dropstock

export const transferUsdcOrDropstock = createAsyncThunk(
  "wallet/transferUsdcOrDropstock", // Action type
  async (body: GasFeesCalculate, { rejectWithValue }) => {

    try {
      const response: any = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/users/transfer-usdc-or-dropstock`,
        body,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        });
      if (response.data) {
        return response.data || [];

      } else {

        return []

      }
    } catch (err: any) {
      return []
    }
  }
);


// get Wallet Details 
export const getWalletDetails = createAsyncThunk(
  "wallet/getWalletDetails", // Action type
  async (body, { rejectWithValue }) => {

    try {
      const response: any = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/users/get-user-wallet-assets`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        });
      if (response.data) {
        return response.data.data || [];

      } else {

        return []

      }
    } catch (err: any) {
      Sentry.captureException(err);
      return [];
    }
  }
);


// get Wallet DropStocks
export const getWalletDropStocks = createAsyncThunk(
  "wallet/getWalletDropStocks", // Action type
  async (body, { rejectWithValue }) => {

    try {
      const response: any = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/users/user-wallet-overview-page`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        });
      if (response.data) {
        return response.data.data || [];

      } else {

        return [];

      }
    } catch (err: any) {
      Sentry.captureException(err);
      return []
    }
  }
);

// get Wallet DropStocks
export const getWalletTransactions = createAsyncThunk(
  "wallet/getWalletTransactions", // Action type
  async (body?:string) => {

    try {
      const sourceType = body || ""; // Ensure body defaults to an empty string if not provided
      const response: any = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/users/get-all-transaction?sourceType=${sourceType|| ""}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        });
      if (response.data.success) {
        return response.data.data || [];

      } else {

        return [];

      }
    } catch (err: any) {
      Sentry.captureException(err);
      return []
    }
  }
);


const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWalletDetails: (state, action: PayloadAction<walletDetails>) => {
      if (action.payload) {
        state.walletData = { ...state.walletData, ...action.payload }
      }

    },
    clearWalletDetails: (state) => {
      // if (action.payload) {
      //   state.walletData = { ...state.walletData, ...action.payload }
      // }
      state.walletData = {
        type: "",
        receiverAddress: "",
        pin: "",
        amount: "",
        withdraw: "USDC",
        deposit: "USDC",
        trackNFTId: "",
        networkFee: "",
        dropStocksCount: "",
        usdcCount: "",
        cryptoPrice: "0.00",
        cryptoSymbol: "USDC",
        status: "waiting",
        trackDescription: "",
        trackTitle: "",
        selectedDropStocks:[]
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(gasFeesCalculate.pending, (state) => {

      })
      .addCase(gasFeesCalculate.fulfilled, (state, action) => {

      })
      .addCase(gasFeesCalculate.rejected, (state, action) => {
      });

    builder
      .addCase(isAddressValidate.pending, (state) => {

      })
      .addCase(isAddressValidate.fulfilled, (state, action) => {

      })
      .addCase(isAddressValidate.rejected, (state, action) => {
      });

    builder
      .addCase(transferUsdcOrDropstock.pending, (state) => {

      })
      .addCase(transferUsdcOrDropstock.fulfilled, (state, action) => {

      })
      .addCase(transferUsdcOrDropstock.rejected, (state, action) => {
      });

    builder
      .addCase(getWalletDetails.pending, (state) => {

      })
      .addCase(getWalletDetails.fulfilled, (state, action) => {
        state.walletDetails = action.payload;
      })
      .addCase(getWalletDetails.rejected, (state, action) => {
      });

    builder
      .addCase(getWalletDropStocks.pending, (state) => {

      })
      .addCase(getWalletDropStocks.fulfilled, (state, action) => {
        state.walletDropStocks = action.payload;
      })
      .addCase(getWalletDropStocks.rejected, (state, action) => {
      });

    builder
      .addCase(getWalletTransactions.pending, (state) => {

      })
      .addCase(getWalletTransactions.fulfilled, (state, action) => {
        state.walletTransactions = action.payload;
      })
      .addCase(getWalletTransactions.rejected, (state, action) => {
      });
  }

});


export const { setWalletDetails, clearWalletDetails } = walletSlice.actions;



export const walletReducer = walletSlice.reducer;
