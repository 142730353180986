import React, { FC } from 'react'

const Loader:FC<any> = ({className,className2,isWhiteLoader=false}) => {
  return (
    <div className={className2?`w-100 ${className2}`:"w-100  d-flex justify-content-center align-items-center"}> 
    <div className={className?`${isWhiteLoader? 'loader-white' :'loader'} mx-auto ${className}`:`${isWhiteLoader? 'loader-white' :'loader'} mx-auto`} />
    </div> 
  )
}

export default Loader;