import React, { useState, useRef } from "react";

import { view } from "./view";

interface AudioPlayerProps {
  src: string; // U
  is_recieved?:boolean
  messageTime?:string
  seen_unseen?:number
  allAudioData?:any
}

const  MessageChatAudio: React.FC<AudioPlayerProps> = ({ src ,is_recieved=false,messageTime="",seen_unseen=1,allAudioData}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  // Play or pause the audio
  const togglePlay = () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Update current time of audio
  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  // Set duration of audio
  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  // Format time in mm:ss
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60).toString(); // Do not pad minutes
    const seconds = Math.floor(time % 60).toString().padStart(2, "0"); // Pad seconds with leading zero
    return `${minutes}:${seconds}`;
  }

  return view({
    src,
    togglePlay,
    handleTimeUpdate,
    handleLoadedMetadata,
    formatTime,
    audioRef,
    isPlaying,
    currentTime,
    duration,
    is_recieved,
    messageTime,
    seen_unseen,
    setDuration  });
};

export default MessageChatAudio;
