import { FC } from 'react'
import "./styles.scss";
import CustomTooltip from '../custom-tooltip/view';


const view: FC<any> = ({ icon, className = '', iconkey, position = 'right',className2="" }) => {

  return (
    <>
      {iconkey === "yellowtick" ? (
        <CustomTooltip title="Tied to the project" className={`w-auto ${className2}`} position={position}>
          <img src={icon} className={className || 'yellowtick'} />
        </CustomTooltip>
      ) : iconkey === "bluetick" ?
        <CustomTooltip title="Verified account."  className={`w-auto ${className2}`}  position={position}>
          <img src={icon} className={className || 'bluetick'} />
        </CustomTooltip>
        :
        (
          <img src={icon} className={className || ''} />
        )}
    </>
  );
}

export default view