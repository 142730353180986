import { FC, useEffect, useState } from "react";
import  view  from "./view";
import { reportUser } from "../../../utils/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { setReportedUserId } from "../../../../../redux/reducer/reportSlice";
import { selectedBlockedId, selectedreportedUserId } from "../../../../../redux/reducer/messageChatSlice";



export const ReportRecentChatModal:FC<any>=()=>{

    const ReportedUserIds = useSelector((state: RootState) => state.report.userModalId);
    const ReportMessageChat = useSelector((state: RootState)=> state?.messageChat?.Data?.id);
    const beatPage = useSelector((state: RootState) => state.report.beatPage);
    const dispatch = useDispatch();


    const [userReport, setUserReport] = useState();
    const [selectedReason, setSelectedReason] = useState<string>("");
    

      const reportedUserById = async (sendReason:string) => {
        try {
          const result = await reportUser(ReportMessageChat||ReportedUserIds, {
            report_type: "Recent messages this user sent you", 
            reason: sendReason, 
          });
          if (result.success) {
            setUserReport(result.reportedUser.data);
            dispatch(setReportedUserId(ReportMessageChat));
            // dispatch(selectedreportedUserId(ReportMessageChat));
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
  
    return (view(reportedUserById,selectedReason,setSelectedReason,beatPage));
}
export default ReportRecentChatModal