import React, { FC, useEffect, useState } from "react";
import { view } from "./view";

interface Option {
  value: string;
  label: string;
  image?: string; // Add optional image property
}

interface DropdownProps extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "value" | "onChange"> {
  options: Option[];
  className?: string;
  defaultValueCountry?: any;
  placeHolderValue?: string;
  maxheight?: number;
  selectedValue?: string; // Ensure this is a string
  onChangeValue?: (value: string | null) => void;
  isSearch?: boolean;
}

export const SelectArrowDropdownImage: FC<DropdownProps> = ({
  maxheight,
  disabled,
  selectedValue = "",
  defaultValueCountry,
  options,
  ...props
}) => {
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    setValue(selectedValue || "");
  }, [selectedValue]);

  const updateValue = (newValue: string) => {
    setValue(newValue);
    if (props.onChangeValue) {
      props.onChangeValue(newValue || null);
    }
  };
  return view({
    value,
    updateValue,
    options,
    disabled,
    defaultValueCountry,
    maxheight,
    ...props,
  });
};
