import { FC, useState } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import iconImg from "../../../assets/img/upload-icon.png";
import { DropworksInput } from "../../../common/custom-field/dropworks-input";
import { DropworksTextArea } from "../../../common/custom-field/dropworks-textArea";
import {
  DropworksButton,
  DropworksMediumButton,
  DropworksNormalButton,
  GradientActiveBtn,
} from "../../../common/custom-field/dropworks-button";
import { DropworksInputIcon } from "../../../common/custom-field/dropworks-input-icon";
import { RiQuestionFill } from "react-icons/ri";
import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
  TextfieldXSM,
} from "../../../common/custom-field/dropstock-text";
import {
  Heading0,
  Heading3,
  Heading4,
} from "../../../common/custom-field/dropstock-heading";
import TabsComponent from "../../../common/tabs-component/view";
import Tabs from "../../../common/tabs";
import InfiniteImageScroll from "../../../common/infinite-image-scroll";
import { ButtonComponent } from "../../shared/components/logo-header/view";
import { setDropworksSelectoptin } from "../../../redux/reducer/dropworksSelect";
import { useDispatch } from "react-redux";
const view: FC<viewType> = ({ navigateTo }) => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 2, // Adjust to 2, since you are using 2 rows and want to fit the grid
    speed: 500,
    rows: 3, // 2 rows of slides
    slidesPerRow: 1, // Display 2 slides per row
    autoplay: false, // Enable autoplay
    autoplaySpeed: 1000, // Time between slides in ms
    arrows: false,
  };
  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
  };
  const dispatch = useDispatch();

  return (
    <div className=" marketplace-dw-composite-main overflow-hidden ">
      <div className="d-flex marketplace-dw-composite-main-firstrow">
        {/* Left Section */}
        <div className="marketplace-dw-composite-main-firstrow-col1 px-0 uni-px-24">
          <div className="marketplace-dw-composite-main-firstrow-col1-content">
            <Heading0 className="text-wrap uni-mb-40 marketplace-select-head">
              <div data-aos="fade-right"> DropWorks</div>

              <div className="gradient-text-2" data-aos="fade-left">
                Composite
              </div>
            </Heading0>
            <TextfieldMD className="uni-mb-40 marketplace-dw-composite-main-firstrow-col1-content-sm px-0 mb-32-mbl mx-0">
              Upon a Track is created and released through your Project, you
              have the unique opportunity to apply your Track to be included in
              DropWorks Composite’s portfolio. This allows your Track to be pre
              owned by your fans as a part of their collection portfolio.​
            </TextfieldMD>
            <div className="d-flex marketplace-dw-btns ">
              <DropworksNormalButton className="w-auto uni-px-72 mb-0 fs-18 home-mobileview-btn">
                Coming Soon
              </DropworksNormalButton>
              {/* <DropworksNormalButton className="marketplaceProduct-main-thirdrow-content-title-tradebtn  fs-18  gradient-btn-2 fs-18 home-mobileview-btn trade-btn uni-px-72 uni-ml-56 w-auto mb-0 " onClick={() => {
                 
                    navigateTo("/dropworks-composite");
                  
                  }}>
              Trade Now!
            </DropworksNormalButton> */}
            </div>
          </div>
        </div>

        <div className=" marketplace-dw-composite-main-firstrow-col2 pe-0 ">
          <InfiniteImageScroll />
        </div>
      </div>

      <div className="marketplace-dw-composite-main-thirdrow mx-auto justify-content-center px-0">
        <div
          className="marketplace-dw-composite-main-thirdrow-left mx-auto px-0"
          data-aos="fade-right"
        >
          {/* <Heading4 className="text-center uni-mb-16 marketplace-dw-head4 marketplace-dw-content-space">
            Ownership Opportunity
          </Heading4>
          <TextfieldMD className="text-center uni-mb-56 marketplace-dw-subcontent-space marketplace-dw-MD">
            If You Hold DropStocks of a DropWorks Original Track, You Have the
            Unique Opportunity to Propose Your Track for Inclusion in DropWorks
            Composite. This Allows Your Music to Reach a Wider Audience and
            Potentially Increase in Value.
          </TextfieldMD> */}
          <Heading4 className="text-center uni-mb-16 marketplace-dw-head4 marketplace-dw-content-space">
            Submit an Application
          </Heading4>
          <TextfieldMD className="text-center uni-mb-56">
            Producers can apply their Track to include in DropWorks Select
            portfolio by submitting Track metadata and performance information.
          </TextfieldMD>
          <Heading4 className="text-center uni-mb-16 marketplace-dw-head4 marketplace-dw-content-space">
            Evaluate Your Proposal
          </Heading4>
          <TextfieldMD className="text-center uni-mb-56">
            The application submission will be assessed timely.
          </TextfieldMD>
          <Heading4 className="text-center uni-mb-16 marketplace-dw-head4 marketplace-dw-content-space">
            Approve and Integrate
          </Heading4>
          <TextfieldMD className="text-center">
            Upon proposal approval, your Track will be featured in DropWorks
            Select through purchasing of your DropStocks by DropWorks, of which
            DropWorks Select will be available for Musicpreneurs as collections.
          </TextfieldMD>
        </div>
        <div
          className="marketplace-dw-composite-main-thirdrow-right "
          data-aos="fade-left"
        >
          <div className="marketplace-dw-composite-main-thirdrow-right-content">
            <Heading0 className="uni-mb-40">
              Raise Your Track's Visibility
            </Heading0>
            <DropworksNormalButton className="w-auto uni-px-72  fs-18  home-mobileview-btn mb-0 ">
              Coming Soon
            </DropworksNormalButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
