import React, { FC, useState, useEffect, useRef } from "react";
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6 } from "../../../common/custom-field/dropstock-heading";
import "./styles.scss";
import { TextfieldLG } from "../../../common/custom-field/dropstock-text";


const DropworksPrivacy: FC<any> = () => {
 
  return (
    <>
    <div className="container container-xxl min-height-cover">
     <Heading1 className="text-center">Privacy Policy</Heading1>
    <Heading2 className="text-center"><strong>Last Updated:</strong> January 12, 2025</Heading2>

    
    <Heading4  className="uni-mt-32 uni-mb-32">1.	Introduction </Heading4>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">DropWorks Markets Inc. (“Company”, “us” or “we”) is committed to protecting your privacy through this privacy policy.  This privacy policy describes the types of information we may collect from you or that you may provide to us when you visit our website [https://dropworks.app/](the “Website” and together with our proprietary website application located therein, the “Services”), when you create an account with us to use the Services and our data collection and handling practices. </TextfieldLG>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">This privacy policy is part of theTerms of Useapplicable to the use of our Services. Please review this policy together with theTerms of Useto understand all of your rights and obligations in respect of your use of theServices.</TextfieldLG>
<Heading5 >YOU UNDERSTAND, ACKNOWLEDGE AND AGREE THAT WE USE SERVICE PROVIDERS TO MAKE OUR SERVICES AVAILABLE TO YOU, AND THAT THESE SERVICE PROVIDERS MAY INTERCEPT YOUR COMMUNICATIONS OR TRACK YOUR ACTIVITIES.  BY USING OUR SERVICES, YOU SPECIFICALLY CONSENT TO THIS INTERCEPTION, TRACKING AND DATA COLLECTION BY OUR THIRD PARTY SERVICE PROVIDERS.</Heading5>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 ">This privacy policy applies to information we collect:</TextfieldLG>

<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>On and through the Services;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>In email, text and other messages between you and the Services, or otherwise transmitted on the Services; </li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>Through any mobile and desktop applications that you download from the Services; </li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>When you interact with our advertising and applications on thirdparty websites and services, wheresuch advertising or applications include links to this policy; and </li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>Through any other means associated with, or relating to, the Services. </li></TextfieldLG>

<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 ">This policy does not apply to information collected by:</TextfieldLG>

<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>Us offline or through any other means other than as set out above, including on any other website operated by the Company or any third party (including our affiliates and subsidiaries); or</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>Any third party, including through any application or content (including advertising) that may link to the Services or isaccessible from or on the Services.</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 ">Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with thesepolicies and practices, your choice is not to use our Services. By accessing or using theServices, you agree to the terms of this privacy policy.</TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 ">This privacy policy may change from time to time. Your continued use of theServices after we make changes is deemed to be acceptance of those changes, so please check the privacy policy periodically for updates.</TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 ">It is our policy to post any changes we make to this privacy policy on this page. If we make material changes as to how we treat our users' personal information, we will notify you by email to the email address specified in your account, if applicable. We may also post a notice on the Website’s home page. The date of last revision of this privacy policy is stated at the top of this page.</TextfieldLG>











     <Heading4 className="uni-mb-32 uni-mt-32">2.	Information about persons under 18</Heading4>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 ">Our Servicesare not intended for persons under eighteen (18) years of age. No one under age eighteen (18) may provide any personal information to us or on or through the Services.</TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 ">We do not knowingly collect personal information from persons under the age of sixteen (16). If you are under sixteen (16), do not use or provide any information on theServices or on or through any of its features or register on the Services, make any purchases on or through the Services, use any of the interactive features of theServices  or provide any information about yourself to us, including your name, email address, telephone number or residential address or other contact details, or any screen name or username you may use.</TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 ">If we learn we have collected or received personal information from a person under sixteen (16) years of age, we will delete that information. If you believe we might have any information from or about a person under the age of sixteen (16), please contact us at Suppports@dropworks.app.</TextfieldLG>




     <Heading4 className="uni-mb-32 uni-mt-32">3.	How We Use Your Information</Heading4>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 ">We may use information that we collect about you or that you provide to us, including any personal information:</TextfieldLG>

 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  To providethe Services and their contents to you;</li></TextfieldLG>
 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  To provide you with information, products or services that you request fromus;</li></TextfieldLG>
 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  To fulfill any other purpose for which you have provided it or which we have disclosed to you through this privacy notice at the time you provide the information;</li></TextfieldLG>
 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  To provide customer support, where such features are available; </li> </TextfieldLG>
 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  To provide features such as cross-posting or cross-service authentication, to the extent we collect information from accounts of other services that you connect to your account;</li></TextfieldLG>
 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  To help others find your account, including through thirdparty services and client applications;</li></TextfieldLG>
 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  To enable you to engage in transactions; </li></TextfieldLG>
 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  To provide you with notices about your account and the Services;</li></TextfieldLG>
 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including the Terms of Use;</li></TextfieldLG>
 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  To notify you about changes to our Services;</li></TextfieldLG>
 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  To allow you to participate in social and interactive features on our Services;</li> </TextfieldLG>
 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  To develop and improve the Services;</li></TextfieldLG>
 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  For security and anti-fraud purposes;</li></TextfieldLG>
 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  For behavioral tracking, profiling and advertising; </li></TextfieldLG>
 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  For any other purpose as required for our business;</li></TextfieldLG>
 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  For any other purpose with your consent;</li></TextfieldLG>
 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  To contact you about our own and thirdparties' goods and services that we think may be of interest to you; and</li></TextfieldLG>
 <TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>  To enable us to display advertisements to our advertisers' target audiences.</li></TextfieldLG>





 <Heading4 className="uni-mb-32 uni-mt-32">4.	Information We Collect About You and How We Collect It</Heading4>
 <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">We may collect information of various types from and about users of our Services (some of which is regarded as “personal information” under applicable law), including: </TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Identifiers such as name, display name, username, date of birth, residentialaddress, password, email address, telephone number or any other information that the Services collect and/orwhich applicable law may consider personally identifiable, personal information, personal data and other such designations; </li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Information about the devices you use to access the Services;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Information about your cryptocurrency wallet, transaction history, trading data and associated fees paid;</li></TextfieldLG> 
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Information about the transactions you make on or through the Services, such as the type of transaction, transaction amount, transaction signatures and timestamp;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Your activity on the Services, including your posts and any information contained in them;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Your interactions with other users’ content, and mentions or tags of you made by other users;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> How you interact with others on the platform, such as people you follow and people who follow you, including the contents of messages, recipients, and date and time of messages;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> We collect information on links you interact with across our services, including in our emails sent to you;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Information about your social media accounts, including username and profile information;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Thirdparty single sign-in information (if used to access the Services);</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Your IP address, Identifier for Advertisers (“IDFA”), Android/Google Advertising ID, International Mobile Equipment Identity (“IMEI”) or other unique identifiers; </li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Your device characteristics and functionality (including information about your operating system, hardware, mobile network, internet connection and provider, andbrowser);</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Referring and exit web pages and URLs; </li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Your browsing history including the areas within our Services that you visit and your activities there, including remembering you and your preferences;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Your device location and other data (including the time of day you visit our Services) or other geolocation information.</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">We collect this information:  </TextfieldLG>

<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> 	Directly from you when you provide it to us, such as when filling in forms on the Services (including when you register for an account orsubscribe to a service) and when you correspond with us or our agents;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> 	Automatically as you navigate the Services, including through the use of cookies, web beacons, and other tracking technologies and analytics services; </li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> 	From third parties, such as our business partners and service providers, and the social media platforms for any social media accounts that you connect to the Services;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> 	When you engage in transactions on our Services</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> 	When youinteract with other users through the Services; and</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> 	When you run searches on theServices.</li></TextfieldLG>

<Heading4 className="uni-mb-32 uni-mt-32">5.	Automatic Data Collection Technologies</Heading4>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">As you navigate and interact with our Services, we may use automatic data collection technologies to collect certain information about you, including: </TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> 	Details of your visits to our Services, including traffic data, browsing patterns, location data, logs, other communication data and the resources that you access and use on the Services; and</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> 	Information about your device, internet connection and service provider. </li></TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">You acknowledge, understand and agree that our service providers will be collecting information about you, and may monitor and intercept your communications and actions on our Services as you browse, interact with or use functionality.  These service providers may use the information they collect for their own benefit without restriction.  You specifically agree to this arrangement, and consent to your communications and information being intercepted by our third party providers.  </TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The information we collect automatically may be statistical data and may also include personal information, or we may maintain it or associate it with personal information we collect or which we receive from third parties. This information enables us to: </TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Allow you to use and access the Services;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Prevent fraudulent activity and improve security functionality;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Assess and improve the performance of the Services;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Offer enhanced functionality when accessing the Services, including identifying you when you sign into our Services and keeping track of your specified preferences; </li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Deliver content relevant to your interests on our Services and thirdparty sites based on your interactions with our advertisements and/or content;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Estimate our audience size and usage patterns and perform market research;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Speed up your searches; and</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li> Analyze our services and products.</li></TextfieldLG>

<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">When you visit or leave our Services by clicking a hyperlink or when you view a thirdparty site that includes our plugins or cookies (or similar technology), we may automatically receive the URL of the site from which you came or the one to which you are directed. </TextfieldLG >
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">We may also receive location data passed to us from thirdparty services or GPS-enabled devices that you have set up, which we may use for fraud prevention and security purposes. We may also use this information to provide customized services and content to you. You may disable the location features on your device. Please note that if you disable such features, your ability to access certain features, services, content, or products may be limited or disabled. </TextfieldLG >
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The technologies we use for automatic data collection include (or may include): </TextfieldLG >
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. Note that while you can set your browser to not allow cookies, we may not be able to honor that request, and may track your activity and collect information about you and your online activities even when the browser is set to “Do Not Track” (see below);</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>Flash Cookies. Certain features of our Services may use local stored objects (also known asFlash cookies) to collect and store information about your preferences and navigation to, from, and on theServices. Flash cookies are not managed by the same browser settings as are used for browser cookies and you may not be able to shut down our collection of and use of information through Flash cookies;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>Web Beacons, Pixels and Tags. Pages of our Services and our emails may contain small electronic files known as web beacons (also known as clear gifs, pixel tags orsingle-pixel gifs) that permit us to gather website-related statistics (e.g., to count users who have visited those pages or opened communications, verifying system integrity);</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>Embedded Scripts. An embedded script is programming code that collects information about userinteractions with the Services;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>ETag, or entity tag. An entity tag (also known as an Etag) is a feature of the cache in browsers. It is an opaque identifier assigned by a web server to a specific version of a resource found at a URL. It is a mechanism that HTTP provides for web cache validation. These allow websites to be more efficient and not serve content again;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>Fingerprinting. Fingerprinting refers to the collection and analysis of information from your device for the purpose of identification, such as operating system, plug-ins andsystem fonts;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>Recognition Technologies. Recognition technologies are features used by websiteswhich attempt to recognize or make assumptions about users and devices (e.g., that a user of multiple devices is the same user); and</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>Log Files. Log filestrack actions occurring on theServices, and help us collect your IP address, browser type, internet service provider, the webpages from which you came or to which you go after visiting our Services, and the date and time of your visits.</li></TextfieldLG>
<Heading4 className="uni-mb-32 uni-mt-32">6.	Do Not Track</Heading4>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">“Do Not Track” is a concept promoted by certain regulatory authorities and industry groups for development and implementation of a mechanism that would allow internet users to control the tracking of their online activities across websites. Various browsers offer a Do Not Track option that allows a user to set a preference in the browser to not have theironline activities tracked. When a user’s browser is set to Do Not Track, some cookies and other tracking technologies may become inactive, depending on how the website visited responds to Do Not Track browser settings. If that occurs, the website visited will not recognize you upon return to that website orsave your passwords or usernames, and some other features of thatwebsite may become unavailable or not function correctly.</TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">Given the lack of a standard in the industry or any clear regulatory guidance, we do not comply with Do Not Track signals from your browser at this time.</TextfieldLG>
<Heading4 className="uni-mb-32 uni-mt-32">7.	Third-Party Use of Cookies and Other Tracking Technologies</Heading4>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">Some content or applicationson the Services may be served by thirdparties, including advertisers, ad networks and servers, content providers and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you access or use theServices. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and services. They may use this information to provide you with behavioral advertising or other targeted content. </TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">We do not control the technology of these third parties or how they may be used. If you have any queries or concernsabout an advertisement or other targeted content, you should contact the responsible provider directly. </TextfieldLG>
<Heading4 className="uni-mb-32 uni-mt-32">8.	Disclosure of Your Information </Heading4>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">We may disclose aggregated information about our users, and information that does not identify any individual, without restriction. </TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">We may disclose personal information that we collect or you provide as described in this privacy policy: </TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>	To other users and the general public;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>	To our affiliates;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>	To contractors, service providers and other third parties we use to support our business and assist us in providing the Services;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>	To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of Company's assets or shares, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Company about our Services users is among the assets transferred;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>	To third parties to market their products andservices to you;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>	To fulfill the purposes for which you have provided it or any other purposes disclosed by us to you through this privacy policy; and</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>	With your consent. </li></TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">We may also disclose your personal information: </TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>	To comply with any court order, law or legal process, including responding to any government or regulatory request;</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>	To enforce or apply theTerms of Use, including this privacy policy, and any other agreements between us; and</li></TextfieldLG>
<TextfieldLG className="uni-mb-16 uni-mt-16 privacy-line-height uni-fw-400 uni-ml-56"><li>	If we believe disclosure is necessary or appropriate to protect the rights, property or safety of the Company, our customers or other persons. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction. </li></TextfieldLG>
<Heading4 className="uni-mb-32 uni-mt-32">9.	International Data Transfers</Heading4>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">We and our affiliates, licensors and thirdparty providers may process your personal information outside of your home country. Data privacy laws in countries to which your personal information is transferred may not be equivalent to, or as protective as, data privacylaws in your home country.</TextfieldLG>
<Heading4 className="uni-mb-32 uni-mt-32">10.	Data Deletion Requests</Heading4>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">You may request the deletion of your personal information by contacting us at Suppports@dropworks.app.  We will considereach request and may comply with such requests at our discretion. </TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">Please note that we reserve the right to retain personal information as permitted or required by law.</TextfieldLG>
<Heading4 className="uni-mb-32 uni-mt-32">11.	Data Security </Heading4>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">We have implemented measures intended to secure your personal information from accidental loss and from unauthorized access, use, alteration and disclosure while you use our Services (but please note that all of your trading information and digital asset creation will also be available on BASE Network, and if you connected your social media accounts to the Services, all your activities will also be seen on that social media page, and we have no control over such features or other technologies or services).</TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">The safety and security of your information also depends on you. Where you have chosen, or we have given you, a password for access to certain parts of our Services, you are responsible for keeping your password confidential and secure. We ask you not to share your password with anyone and to change your password from time to time, using appropriate password security (e.g., not using the same password for multiple sites, using passwords which are not easily guessed). </TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">Unfortunately, the transmission of information via the internet is not completely secure. Although we try to protect your personal information, we cannot guarantee the security of your personal information transmitted or collected through the Services. Any transmission of your personal information is at your own risk. </TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">We are not responsible for circumvention of any security measures or privacy settings contained on the Services. </TextfieldLG>
<Heading4 className="uni-mb-32 uni-mt-32">12.	California Residents</Heading4>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">California Civil Code Section § 1798.83, known as the “Shine The Light” law, permits users of our Site who are California residents to request and obtain from us a list of what Personal Information (if any) we have disclosed to third parties for direct marketing purposes in the preceding year, together withthe names and addresses of those third parties.  You may request this information from us no more than once a year, but such request will be handled by us free of charge.  Please send such requests by email to Supports@dropworks.app.</TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">Contact Information </TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">To raise any questions or comments about this privacy policy, please contact us at:</TextfieldLG>
<TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">Email: Suppports@dropworks.app</TextfieldLG>




    </div>
    </>
  );
};

export default DropworksPrivacy;
