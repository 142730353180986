import React, { FC, useEffect, useState } from "react";
import "./styles.scss";
import { UserLable } from "../../../common/user-labels";
import { MeatballsMenuDropdown } from "../../../common/icons";
import { BlueTik, YelloTik } from "../../../common/all-icons";
import { DropworksSmallButton } from "../../../common/custom-field/dropworks-button";
// import projectImage from "../../../assets/img/temporary-images/project-image.svg";
import { openPopup } from "../../shared/components/logo-header/view";
import { Heading1 } from "../../../common/custom-field/dropstock-heading";
import { HeartIcon } from "../../../common/heart-icon";
import { MoreMobile } from "../../../common/moreMobile";
import { View } from "./modal";
import {
  setbeatPage,
  setUserModalId,
} from "../../../redux/reducer/reportSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import image1 from "../../../assets/img/project-image/image1.png";
import image2 from "../../../assets/img/project-image/image2.png";
import image3 from "../../../assets/img/project-image/image3.png";
import image4 from "../../../assets/img/project-image/image4.png";
import image5 from "../../../assets/img/project-image/image5.png";
import image6 from "../../../assets/img/project-image/image6.png";
import image7 from "../../../assets/img/project-image/image7.png";
import image8 from "../../../assets/img/project-image/image8.png";
import image9 from "../../../assets/img/project-image/image9.png";
import image10 from "../../../assets/img/project-image/image10.png";
import image11 from "../../../assets/img/project-image/image11.png";
import image12 from "../../../assets/img/project-image/image12.png";
import image13 from "../../../assets/img/project-image/image13.png";
import image14 from "../../../assets/img/project-image/image14.png";
import image15 from "../../../assets/img/project-image/image15.png";
import image16 from "../../../assets/img/project-image/image16.png";
import image17 from "../../../assets/img/project-image/image17.png";
import image18 from "../../../assets/img/project-image/image18.png";
import image19 from "../../../assets/img/project-image/image19.png";
import image20 from "../../../assets/img/project-image/image20.png";
import image21 from "../../../assets/img/project-image/image21.png";
import image22 from "../../../assets/img/project-image/image22.png";
import { ErrorImg, filteredArtist, setLocalStorageObject } from "../../shared/utils/localStorage";
import { useNavigate } from "react-router-dom";
import { setuserListData } from "../../../redux/reducer/messageChatSlice";
import { TextfieldMD } from "../../../common/custom-field/dropstock-text";

export const view: FC<View> = ({ projectData, navigateTo ,callOnLikeUnlike}) => {
  const user = useSelector((state: RootState) => state.user);
  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState(["Report"]);
  const [selectedImage, setSelectedImage] = useState<any>();
  const userData = useSelector((state: any) => state.user);

  const navigate = useNavigate();

  // useEffect(()=>{
  //   if(projectData?.art_work_image?.src){
  //     dispatch(setProjectBannerImg(projectData?.art_work_image))
  //   }else{
  //     dispatch(setProjectBannerImg(selectedImage))
  //   }

  // },[projectData])

  const projectImage = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
    image20,
    image21,
    image22,
  ];

  useEffect(() => {
    const getRandomImage = () => {
      const randomIndex = Math.floor(Math.random() * projectImage.length);
      setSelectedImage(projectImage[randomIndex]);
    };

    getRandomImage();
  }, []);

  const OnClickMeatBalls = async (value: any, item: any) => {
    if (value == "Report") {
      openPopup("ReportModal1", dispatch);
      dispatch(setUserModalId(item));
    }
    dispatch(setbeatPage(false));
  };

  const clickMeat = async (value: any) => {
    if (value === "Report") {
      dispatch(setbeatPage(true));
      openPopup("ProjectReportModal", dispatch);
    }
  };
  const dispatch = useDispatch();
  const [songTeam, setSongTeam] = useState([
    {
      img: ``,
      fullName: "",
      title: "",
      goldTik: false,
      blueTik: false,
      slugName: "",
      user_id: "",
    },
    // {
    //   img: `${userImage}`,
    //   fullName: "King Roland",
    //   title: "Producer",
    //   goldTik: true,
    //   blueTik: true,
    // },
    // {
    //   img: `${userImage}`,
    //   fullName: "Maba",
    //   title: "Vocalist",
    //   goldTik: true,
    //   blueTik: true,
    // },
    // {
    //   img: `${userImage}`,
    //   fullName: "Kahino",
    //   title: "Beat Maker",
    //   goldTik: true,
    //   blueTik: true,
    // },
    // {
    //   img: `${userImage}`,
    //   fullName: "Lala Baby",
    //   title: "Lyricist ",
    //   goldTik: false,
    //   blueTik: true,
    // },
  ]);

  useEffect(() => {
    if (Array.isArray(projectData?.collaborators)) {
      setSongTeam(
        projectData?.collaborators.map((item: any) => ({
          fullName: item.receiver_name,
          title: item.role,
          img: item.profile_image?.[0].thumbnail || item.profile_image?.[0].src,
          slugName: item.slug_name,
          goldTik:  Boolean(item.isyellow_tick),
          blueTik: Boolean(item.is_bluetick),
          collaborator_id: item.receiver_user_id,
        }))
      );
    }
  }, [projectData?.collaborators]);

  const [projectId, setProjectId] = useState({
    project_id: "",
  });
  useEffect(() => {
    setProjectId({ project_id: projectData?._id });
  }, [projectData]);

  const isUserInTeam = songTeam.some((item) => item?.user_id === user?.id);
  const clickMeatMobile = async (value: any) => {
    if (value === "Report") {
      dispatch(setbeatPage(true));
      openPopup("ProjectReportModal", dispatch);
    }
  };

  return (
    <div className="ProjectPage container container-xxl min-height-cover">
      <div className="ProjectPage-body">
        <div>
          <img
            src={
              projectData?.art_work_image[0]?.src
                ? `${process.env.REACT_APP_IMGURL}/${projectData?.art_work_image[0]?.src}`
                : `${process.env.REACT_APP_IMGURL}${projectData?.default_artwork}`
            }
            className="ProjectPage-image"
            alt="user-image"
            onError={(e) => (e.currentTarget.src = ErrorImg)}
          />
        </div>
        <div className="ProjectPage-content">
          <div className="w-100">
            <div className="ProjectPage-details">
              <Heading1>
                {projectData?.project_name
                  ? projectData?.project_name
                  : projectData?.beat_name} 
              </Heading1>
              <div className="uni-py-8 heart-icon-hide-pc-project ">
            <HeartIcon
            liked={projectData?.isLiked}
            onClickHeart={callOnLikeUnlike}
             />
            </div>
            </div>
            <div className="ProjectPage-song-title link-btm">
                  {/* {projectData?.collaborators &&
                    projectData.collaborators.map((data: any, index: number) => (
                      <React.Fragment key={data.slugName}>
                        <TextfieldMD
                          className="underline-opening mb-0 mt-0 m-0"
                          onClick={navigateTo(
                            `/user-profile/${data.slug_name}`
                          )}
                        >
                          {data.receiver_name}
                        </TextfieldMD>
                        {index < projectData.collaborators.length - 1 && ", "}
                      </React.Fragment>
                    ))} */}
                     { projectData?.collaborators &&
                                          projectData?.collaborators?.length &&  filteredArtist(projectData?.collaborators)?.map((data: any, index: number, arr: any) => (
                                            <React.Fragment key={data.slugName}>
                                              <TextfieldMD
                                                className="underline-opening mb-0 mt-0 m-0"
                                                onClick={() =>
                                                  userData.id == data?.receiver_user_id
                                                    ? navigate(`/profile`)
                                                    : navigate(`/user-profile/${data?.slug_name}`)
                                                }
                                              >
                                                {data.receiver_name}
                                              </TextfieldMD>
                                              {index < arr?.length - 1 && ", "}
                                            </React.Fragment>
                                          ))}
                </div>
          </div>
          <div className="d-flex gap-3 mt-2 w-100 cursor-pointer">
            <UserLable>Project</UserLable>
          </div>
        </div>
      </div>
      <div className="d-flex gap-3 mbl-mb-8 uni-mt-mb-64 ProjectPage-bottons">
        {projectData?.user_id?._id === user?.id ? (
          ""
        ) : isUserInTeam ? (
          ""
        ) : (
          <DropworksSmallButton
            className="width-210 text-nowrap"
            onClick={() => {
            const userPayload = {
            userImg: projectData?.user_id?.profile_image?.[0]?.thumbnail || projectData?.user_id?.profile_image?.[0]?.src || "",
            userName: projectData?.user_id?.display_name || "",
            alluserListId: projectData?.user_id?._id || "",
            chattingTab: "user",
            tabValue: 0,
            username:projectData?.user_id?.username
            };
             dispatch(setuserListData(userPayload));
              navigate("/message-chat");
            }}
          >
            Ask to join
          </DropworksSmallButton>
        )}
        <div className="uni-py-8  heart-icon-project heart-icon-hide-project-mbl">
          <HeartIcon
            liked={projectData?.isLiked}
            onClickHeart={callOnLikeUnlike}
          />
        </div>
        {projectData?.user_id?._id === user?.id ? (
          ""
        ) : (
          <MoreMobile className="px-0 mobile-p-0 cstm-project-mbl-btn"
          pagename="call-function"
          arr={["Report"]}
          onClickOptions={(value: any) => clickMeatMobile(value)}
          />
        )}
        <span className="convert-more d-flex align-items-center">
          {projectData?.user_id?._id === user?.id ? (
            ""
          ) : (
            <MeatballsMenuDropdown
              pagename="call-funtion"
              className="p-0"
              arr={MeatballsMenuOptions}
              height={"6px"}
              onClickOptions={(value: any) => clickMeat(value)}
            ></MeatballsMenuDropdown>
          )}
        </span>
      </div>
      <div className="d-flex justify-content-between flex-wrap  project-page-main">
        {songTeam.map((item: any, index: number) => (
          <div
            key={index}
            className="col-xxl-5 col-xl-5 col-md-5 mbl-mb-0 col-12 uni-mb-16 project-page-main-sub"
          >
            <div className="track-page-col">
              <div>
                <h4 className="ProjectPage-subheading mbl-mb-16 d-flex">
                  {/* {item.title} */}

                  {index==0?"Creator":item.title}
                  {item.goldTik ? (
                    <div className="uni-ml-8">
                      <YelloTik position="top" />
                    </div>
                  ) : (
                    ""
                  )}
                </h4>
              </div>
              <div
                className="ProjectPage-sub-content mb-4 cursor-pointer">
                <img
                  src={`${process.env.REACT_APP_IMGURL}/${item.img}`}
                  className="ProjectPage-sub-content-img"
                  alt=""
                  onClick={
                    item?.collaborator_id === user?.id
                      ? navigateTo(`/profile`)
                      : navigateTo(`/user-profile/${item.slugName}`)
                  }
                />
                <div className="ProjectPage-sub-details">
                  <h5 className="ProjectPage-sub-song-name"
                  onClick={
                  item?.collaborator_id === user?.id
                  ? navigateTo(`/profile`)
                  : navigateTo(`/user-profile/${item.slugName}`)
                  }
                  >{item.fullName}</h5>

                  {item.blueTik ? (
                    <div className="uni-ml-8">
                      <BlueTik position="top" />
                    </div>
                  ) : (
                    ""
                  )}

                  <span className="drop-project">
                    {item?.collaborator_id === user?.id ? (
                      ""
                    ) : (
                      <MeatballsMenuDropdown
                        pagename="call-funtion"
                        onClickOptions={(value: any) =>
                          OnClickMeatBalls(value, item.collaborator_id)
                        }
                        arr={MeatballsMenuOptions}
                        height={"5px"}
                        className="track-metaballsmenudropdown"
                      />
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
