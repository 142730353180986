import { FC, useEffect, useState } from "react";
import  view  from "./view";
import { deletedUser, oneToOneChatHistroy } from "../../../utils/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { getAllUserStatus } from "../../../../../redux/reducer/messageChatSlice";
import { AppDispatch } from "../../../../../redux/store";

export const DeleteChat:FC<any>=()=>{
    const DeletedUserId = useSelector((state: RootState) => state.messageChat);
    const dispatch=useDispatch<AppDispatch>();
    const[userDelete,setUserDelete] = useState();
    const[userChat,setUserChat] = useState<any>(true);

    const doDeleteUser = async () =>{
         try{
            const result = await   deletedUser(DeletedUserId.Data.id);
            if(result){
                setUserDelete(result.deleteUser.data);
                dispatch(getAllUserStatus())
                setUserChat(false);
            }
         }
         catch(error){
            console.error('Error fetching unread message', error);
         }
    }
    useEffect(()=>{
        const fetchChatHistory = async () => {
            try {
                const result = await oneToOneChatHistroy(DeletedUserId.Data.id);
                if (result) { 
                    setUserChat(result.onChatHistroy.data); 
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        if (DeletedUserId.Data.id) { 
            fetchChatHistory();
        }
    },[DeletedUserId?.Data?.id])

    return (view((doDeleteUser)));
}
export default DeleteChat