import React from "react";
import ChatComponent from "../../shared/components/documentdocxfile";
import MessageChatAudio from "../../shared/components/message-audiofile";
import VideoMessage from "./videomessage";
import { ArrowDropdown } from "../../../common/icons";
import { PiCheckBold, PiChecksBold } from "react-icons/pi";
import doubletick2 from "../../../assets/steps/Double-tick2.svg";
import "./styles.scss";
import { getExtension } from "../../shared/utils/localStorage";

type FileAttachment = {
  fileName?: string;
  fileSize?: number;
  pageCount?: number;
  src?: string;
};
type MessageItem = {
  is_attached: boolean;
  is_attached_file?: FileAttachment[];
  message?: string;
  time?: string;
  delivered?: number;
  is_delete?: boolean;
};
type Props = {
  item: MessageItem;
  index: number;
  handleDownload: (
    e: React.MouseEvent<HTMLAnchorElement>,
    item: MessageItem
  ) => void;
  getFileTypeImage: (fileName?: string, src?: string) => string;
  deleteuserchat: (index: number) => void;
  handleDropdownToggle: (index: number) => void;
  activeDropdown: number | null;
  loaderSvg: string;
  ErrorImg: string;
  onDelete: (data: number) => void;
  mainIndex: number;
};

const NewMessageSender: React.FC<Props> = ({
  item,
  handleDownload,
  getFileTypeImage,
  deleteuserchat,
  handleDropdownToggle,
  activeDropdown,
  loaderSvg,
  ErrorImg,
  onDelete,
  mainIndex,
}) => {
  let check = item.delivered;


  const onOptionChange = (value: any, e?: any, item?: any) => {
    if (value === "Delete") {
      onDelete(mainIndex);
    } else if (value === "Download" && e && item) {
      handleDownload(e, item);
    }
  };


    // console.log(getFileTypeImage(item.is_attached_file?.[0]?.fileName, item.is_attached_file?.[0]?.src)==='/static/media/pdf-image.0b351a47b2d721c49d20.png',getFileTypeImage(item.is_attached_file?.[0]?.fileName, item.is_attached_file?.[0]?.src),"responseeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
  const renderFileAttachment = () => {
    const attachedFile = item.is_attached_file?.[0];
    const fileName = attachedFile?.fileName || "Unknown File";
    const fileSize = attachedFile?.fileSize
    ? `${(attachedFile.fileSize / 1024).toFixed(2)} KB`
    : "Unknown Size";
    const extensionName=getExtension(fileName || " ") || "";

  if (extensionName && ['mp3','mpeg','wav'].includes(extensionName)) {
      return (
        <MessageChatAudio
          src={ `${process.env.REACT_APP_IMGURL}/${attachedFile?.src}` || ""}
          messageTime={item.time || "00:00"}
          seen_unseen={check}
        />
      );
    } else if (extensionName && ["mp4", "avi", "mov", "wmv", "mkv", "flv", "webm", "mpeg", "mpg", "3gp", "ogv"].includes(extensionName)) {
      return (
        <div className="chat-container">
        <div className="message-bubble-send-image">
        <VideoMessage
          videoUrl={attachedFile?.src || ""}
          messageTime={item.time || "00:00"}
          seen_unseen={check}
        />
        </div>
        </div>

      );
    }  else if(extensionName &&  ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'tif', 'webp', 'svg', 'heic', 'heif', 'ico', 'raw'].includes(extensionName)) {
      return (
        <div className="chat-container">
        <div className="message-bubble-send-image">
        <div className="sender-image">
         
          <a onClick={(e) => handleDownload(e, item)}>
            <img
              src={getFileTypeImage(fileName, attachedFile?.src)}
              alt="File Preview"
              className="message-image"
              onError={(e) => (e.currentTarget.src = loaderSvg)}
            />
          </a>
         
          <div className="message-meta text-end">
            <span className="message-time fss-16">{item.time || "00:00"}</span>
            <span className="delivery-status">{renderDeliveryStatus()}</span>
          </div>
        </div>
        </div>
        </div>
      );
    }
    else if(extensionName ){
      return (
        <ChatComponent
          fileName={fileName}
          fileType={extensionName}
          fileSize={fileSize}
          messageTime={item.time || "00:00"}
          pageCount={
            fileName.endsWith(".docx")
              ? attachedFile?.pageCount || 0
              : undefined
          }
          src={
            item.is_attached_file?.[0]?.src
              ? item.is_attached_file?.[0]?.src
              : ""
          }
          seen_unseen={check}
        />
      );
    }
  };

  const renderDeliveryStatus = () => {
    switch (item.delivered) {
      case 3:
        return (
          <img src={doubletick2} className="dobule-tick" alt="Delivered" />
        );
      case 2:
        return <PiChecksBold className="uni-ml-8 mbl-delivery-status" />;
      case 1:
        return <PiCheckBold className="uni-ml-8 mbl-delivery-status" />;
      default:
        return <PiCheckBold className="uni-ml-8 mbl-delivery-status" />;
    }
  };

  return (
    <div className="newmessage-sender justify-content-end">
      {item.is_attached ? (
        <ArrowDropdown
          pagename="call-funtion"
          className2="parent-arrow"
          arr={["Delete","Download"]}
          onClickOptions={(stelected: any) =>
            onOptionChange(stelected, { preventDefault: () => {} }, item)
          }
        />
      ) : (
        <ArrowDropdown
          pagename="call-funtion"
          className2="parent-arrow position-absolute"
          arr={["Delete"]}
          onClickOptions={(stelected: any) =>
            onOptionChange(stelected, { preventDefault: () => {} }, item)
          }
        />
      )}
      {item.is_attached ? (
        renderFileAttachment()
      ) : (
        <div className="newmessage-sender-message">
          <span className="newmessage-sender-message-sender-message">
            {item.message}
          </span>
          <span className="uni-fw-400 newmessage-sender-message-time">
            {item.time} {renderDeliveryStatus()}
          </span>
        </div>
      )}
      {!item.is_attached_file?.[0]?.fileName &&
        getFileTypeImage(
          item.is_attached_file?.[0]?.fileName,
          item.is_attached_file?.[0]?.src
        ) === "/static/media/pdf-image.0b351a47b2d721c49d20.png" && (
          <p className="newmessage-sender-chat-triangle"></p>
        )}
    </div>
  );
};

export default NewMessageSender;
