import { FC } from "react";
import  view  from "./view";
import { useSelector } from "react-redux";



export const InviteModal:FC<any>=()=>{

    const project = useSelector((state: any) => state.user.projects);
    
    
    return view(project);
}
export default InviteModal