import { useRef, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { view } from "./view";
import { verifyPin } from "../../../utils/apiServices";
import { useNavigate } from "react-router-dom";
import { openPopup } from "../../logo-header/view";
import { setValidateValue } from "../../../../../redux/reducer/auth";
import { useDispatch, useSelector } from "react-redux";

export const WalletDropstocksDeposit = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const closeModalRef = useRef<HTMLAnchorElement>(null);
  const navigate = useNavigate();
  const navigateTo = (value: string) => () => {
    navigate(value);
  };
  const schema4 = yup.object({
    pin: yup.string().required("PIN is required").min(4, "The PIN must contain exactly 4 or 6 digits. Letters and special characters are not allowed.").max(6, "The PIN must contain exactly 4 or 6 digits. Letters and special characters are not allowed.").matches(/^\d{4}$|^\d{6}$/, "Pin should be 4 or 6 digits.")
  });

  const {
    register,
    handleSubmit,
    setError,
    reset,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm<any>({
    resolver: yupResolver(schema4),
    mode: "onSubmit",
  });

  const handleCloseModalClick = () => {
    if (closeModalRef.current) {
      closeModalRef.current.click();
    }
  };

  const onSubmit = async (data: any) => {
    const { pin } = data
    if (pin) {
      setLoading(true)
      const response: any = await verifyPin({ data });
      if (response.success) {
        dispatch(setValidateValue({ ...data })); // Clone data before dispatch
        openPopup("ChangePinModal", dispatch);
        setLoading(false);
        handleCloseModalClick()
      } else {
        setLoading(false);
        if (pin) {
          setError(response.type || "pin", { type: "custom", message: response.message });
        }
      }
    }
  };

  const openModal = () => {
    reset()
    setValue("pin", "");
    setError("pin", { type: "custom", message: "" });
    setLoading(false);
  }
  return view({ errors, isSubmitted, loading, register, onSubmit, handleSubmit, navigateTo, closeModalRef, openModal });
}
export default WalletDropstocksDeposit;