import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { setLocalStorageObject } from "../../app/shared/utils/localStorage";


interface YellowDotProject{
  trackId:string,
  show:boolean
}

interface messageSlice {
  Data:any,
  usersMessageList:[] | any[],
  userId: any,
  userImg:any,
  userName: any,
  alluserListId: any,
  chattingTab_redux?:string,
  tabValue:number;
  showRightSide:boolean;
  username:string;
  messageRecievedProject?:messageRecieved;
  showMessageYellowDot:boolean;
  showMessageYellowDotProject:YellowDotProject[]
  messageCount:number
}
const initialState: messageSlice = {
    Data:"",
    usersMessageList:[],
    userId: null,
    userImg: null,
    userName: null,
    alluserListId: null,
    chattingTab_redux:"",
    tabValue:0,
    showRightSide:false,
    username:"",
    showMessageYellowDot:false,
    messageRecievedProject: {
    actionType: -1,
    message: "",
    time: "",
    id: "",
    imgUrl: "",
    read_seen: "",
    sender_name:"",
    delivered: -1,
    is_delete: false,
    is_attached: false,
    is_attached_file: false,
    },
    showMessageYellowDotProject:[{
      trackId:"",
      show:false
    }],
    messageCount:0
};
interface messageRecieved{
  actionType: number,
  message: string,
  time: string,
id: string,
imgUrl: string,
read_seen: string,
sender_name:string,
delivered: number,
is_delete: boolean,
is_attached: boolean,
is_attached_file: boolean,
}

export const getAllUserStatus = createAsyncThunk("users/userlistmessage",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/chat/get-user-Group-Combined-List`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dropworksToken")}`,
          },
        }
      );
      if (response.data.success) {
 
        return response.data.data || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching invitations:", error);
      return [];
    }
  }
);
export const getAllMessageNotificationCount = createAsyncThunk("message/count",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/chat/get-all-message-count`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dropworksToken")}`,
          },
        }
      );
      if (response.data.success) {
        return response.data.count || 0;
      } else {
        return 0 ;
      }
    } catch (error) {
      console.error("Error fetching invitations:", error);
      return 0;
    }
  }
);

const messageSlice = createSlice({
  name: "blockedUser",
  initialState,
  reducers: {
    setShowMessageYellowDotProject: (state, action: PayloadAction<YellowDotProject>) => {
      const existingIndex =  state.showMessageYellowDotProject.findIndex(item => item.trackId === action.payload.trackId)

  if (existingIndex !== -1) {
    const updatedArray = [...state.showMessageYellowDotProject];
    updatedArray[existingIndex] = action.payload;
    state.showMessageYellowDotProject=updatedArray;
  } else {
    state.showMessageYellowDotProject= [...state.showMessageYellowDotProject, action.payload];
  }
  },
    setShowMessageYellowDot: (state, action: PayloadAction<boolean>) => {
      state.showMessageYellowDot = action.payload
  },
    selectedBlockedId: (state, action: PayloadAction<any>) => {
        state.Data = action.payload
    },
    setMessageRecievedProject: (state, action: PayloadAction<messageRecieved>) => {
      state.messageRecievedProject = action.payload
  },
    selectedreportedUserId: (state, action: PayloadAction<any>) => {
      state.userId = action.payload
    },
    setShowRightSide:(state,action:PayloadAction<boolean>)=>{
state.showRightSide=action.payload
    },
    setuserListData: (state, action: PayloadAction<any>) => {
      state.userImg = action.payload.userImg
      state.userName = action.payload.userName
      state.alluserListId = action.payload.alluserListId
      state.chattingTab_redux=action.payload.chattingTab
      state.tabValue=action.payload.tabValue || 0
      state.username=action.payload.username
      
      setLocalStorageObject("chat-tab",{
        userImg : action.payload.userImg,
        userName : action.payload.userName,
        alluserListId : action.payload.alluserListId,
       chattingTab : action.payload.chattingTab,
       tabValue:action.payload.tabValue || 0,
       username:action.payload.username
    })
    },
    setMessageCount: (state, action: PayloadAction<number>) => {
      let value =state.messageCount + action.payload ;
      if(value<0)
      {
        state.messageCount = 0;
      }
      else{
        state.messageCount = value;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserStatus.pending, (state) => {
       
      })
      .addCase(getAllUserStatus.fulfilled, (state, action) => {

        state.usersMessageList = action.payload;
      })
      .addCase(getAllUserStatus.rejected, (state, action) => {
        // state.usersMessageList = []; 
      });
      builder.addCase(getAllMessageNotificationCount.pending, (state) => {
       state.messageCount=state.messageCount
      })
      .addCase(getAllMessageNotificationCount.fulfilled, (state, action) => {
        state.messageCount=action.payload;
      })
      .addCase(getAllMessageNotificationCount.rejected, (state, action) => {
        state.messageCount=state.messageCount
      });
      
}});

export const { selectedBlockedId ,selectedreportedUserId,setuserListData,setShowRightSide,setMessageRecievedProject,setShowMessageYellowDot,setShowMessageYellowDotProject,setMessageCount} = messageSlice.actions;
export const messageReducer = messageSlice.reducer;