import { FC, useEffect, useState } from "react";
import view from "./view";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../../redux/store";
import { setWalletDetails } from "../../../../../redux/reducer/walletSlice";

export const WalletWithdrawTypeModal = () => {
    const dispatch = useDispatch();
    const [walleTypeName, setWalleTypeName] = useState("USDC");
    
    useEffect(() => {
        dispatch(setWalletDetails({ withdraw: "USDC" }))
    }, [])

    const setWalleType = (name: string) => {
        setWalleTypeName(name);
        dispatch(setWalletDetails({ withdraw: name }))
    }

    return view({ setWalleType, walleTypeName });
}
export default WalletWithdrawTypeModal;