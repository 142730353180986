import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import "./styles.scss"
import { ButtonComponent } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { useState } from 'react';
import { DropworksRadioCheckbox } from '../../../../../common/custom-field/dropworks-radio-checkbox';
import ModalWrapper from '../../../../../common/open-modal/ModalWrapper';

const view = (reportedUserById: any, selectedReason: string, setSelectedReason: (reason: string) => void, beatPage: boolean) => {
  const [showTextarea, setShowTextarea] = useState(false);
  const [customReason, setCustomReason] = useState("");
  const [isNextDisabled, setIsNextDisabled] = useState(true);



  const handleReasonSelect = (reason: string) => () => {

    setSelectedReason(reason);
    setIsNextDisabled(false);
    if (reason === "Something else") {
      setShowTextarea(true);
    } else {
      setShowTextarea(false);
      setCustomReason("");
    }
  };
  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCustomReason(e.target.value);
    setIsNextDisabled(e.target.value.trim() === "");
  };
  const openModal = () => {
    setSelectedReason("")
    setCustomReason("");
    setIsNextDisabled(true);
    setShowTextarea(false);
  }

  const handleNextClick = () => {
    if (showTextarea && customReason.trim() === "") {
      // alert("Please provide a reason."); 
      return;
    }
    const reasonToSubmit = showTextarea ? customReason : selectedReason;
    reportedUserById(reasonToSubmit);

  };

  // const handleReasonSelect = (reason: string) => () => {
  //   setSelectedReason(reason); // Set the selected reason
  // };
  return (
    <ModalWrapper modalId="ReportRecentChatModal" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="ReportRecentChatModal"
        aria-hidden="true"
        aria-labelledby="ReportRecentChatModal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-width">
          <div className="modal-content ">
            <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16' /></CloseModalButton></div>
            <div className="modal-header ps-0 pt-0 uni-pr-72 uni-mb-40">
              <Heading3 className="modal-title-fs-32">Report recent chat with this user</Heading3>
            </div>

            <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72   uni-mb-56">
              <p className='d-flex  popup-space-mobile justify-content-between uni-p-16 p-lh uni-mb-8' onClick={handleReasonSelect("It is offensive")} style={{ cursor: 'pointer' }}>

                <TextfieldSM className=" font-col-wh my-0 mx-0 pop-up-max-width">It is offensive</TextfieldSM>
                <DropworksRadioCheckbox checked={"It is offensive" === selectedReason} />
              </p>
              <p className='d-flex popup-space-mobile justify-content-between uni-p-16 p-lh uni-mb-8' onClick={handleReasonSelect("Bullying or harassment")} style={{ cursor: 'pointer' }}>

                <TextfieldSM className=" font-col-wh my-0 mx-0 pop-up-max-width">Bullying or harassment</TextfieldSM>
                <DropworksRadioCheckbox checked={"Bullying or harassment" === selectedReason} />
              </p>
              <p className='d-flex popup-space-mobile justify-content-between uni-p-16 p-lh uni-mb-8' onClick={handleReasonSelect("Hate speech or symbols")} style={{ cursor: 'pointer' }}>

                <TextfieldSM className=" font-col-wh my-0 mx-0 pop-up-max-width">Hate speech or symbols</TextfieldSM>
                <DropworksRadioCheckbox checked={"Hate speech or symbols" === selectedReason} />
              </p>
              <p className='d-flex justify-content-between popup-space-mobile uni-p-16 p-lh' onClick={handleReasonSelect("Nudity or sexual activity")} style={{ cursor: 'pointer' }}>

                <TextfieldSM className=" font-col-wh my-0 mx-0 pop-up-max-width">Nudity or sexual activity</TextfieldSM>
                <DropworksRadioCheckbox checked={"Nudity or sexual activity" === selectedReason} />

              </p>
              <p className='d-flex justify-content-between uni-p-16 p-lh popup-space-mobile' onClick={handleReasonSelect("Violence abuse or dangerous activities")} style={{ cursor: 'pointer' }}>

                <TextfieldSM className=" font-col-wh my-0 mx-0 pop-up-max-width">Violence abuse or dangerous activities</TextfieldSM>
                <DropworksRadioCheckbox checked={"Violence abuse or dangerous activities" === selectedReason} />

              </p>
              <p className='d-flex justify-content-between uni-p-16 p-lh popup-space-mobile' onClick={handleReasonSelect("Intellectual property violation")} style={{ cursor: 'pointer' }}>
                <TextfieldSM className=" font-col-wh my-0 mx-0 pop-up-max-width">Intellectual property violation</TextfieldSM>
                <DropworksRadioCheckbox checked={"Intellectual property violation" === selectedReason} />

              </p>
              <p className='d-flex justify-content-between uni-p-16 p-lh popup-space-mobile' onClick={handleReasonSelect("Something else")} style={{ cursor: 'pointer' }}>
                <TextfieldSM className=" font-col-wh my-0 mx-0 pop-up-max-width">Something else</TextfieldSM>
                <DropworksRadioCheckbox checked={"Something else" === selectedReason} />
              </p>
              {showTextarea && (
                <div className="uni-mt-16">
                  <textarea
                    className="form-control custom-textarea"
                    value={customReason}
                    onChange={handleTextareaChange}
                    placeholder="Specify your reason"
                    rows={3}
                  />
                </div>
              )}
            </div>
            <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72 mbl-modal-footer">
              {beatPage ? (
                <CloseModalButton className='mbl-anchor-full-btn'>
                  <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16  uni-mr-16 mbl-full-width-btn">
                    Cancel
                  </DropworksSmallButton>
                </CloseModalButton>
              ) : (
                <ButtonComponent id="ReportModal1" className='mbl-anchor-full-btn'>
                  <DropworksSmallButton className="btn-wid   btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 mbl-full-width-btn">Back</DropworksSmallButton>
                </ButtonComponent>
              )}
              <ButtonComponent id="ThankYouModal" className='mbl-anchor-full-btn'>
                <DropworksSmallButton className="btn-wid  mbl-full-width-btn  m-0" onClick={handleNextClick} disabled={selectedReason === "Something else" && customReason.length === 0 ? true : isNextDisabled}>Next</DropworksSmallButton>
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default view;


