import {
  TextfieldLG,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import { CloseModalButton } from "../../logo-header/view";
import "./styles.scss";
import { ButtonComponent } from "../../logo-header/view";
import {
  Heading3,
  Heading4,
} from "../../../../../common/custom-field/dropstock-heading";
import { useState } from "react";
import { DropworksRadioCheckbox } from "../../../../../common/custom-field/dropworks-radio-checkbox";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";

const view = (
  reportedBeats: any,
  selectedReason: string,
  setSelectedReason: (reason: string) => void,
  beatPage: boolean
) => {
  const [showTextarea, setShowTextarea] = useState(false);
  const [customReason, setCustomReason] = useState("");
  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const handleReasonSelect = (reason: string) => () => {
    setSelectedReason(reason);
    setIsNextDisabled(false);
    if (reason === "Something else") {
      setShowTextarea(true);
    } else {
      setShowTextarea(false);
      setCustomReason("");
    }
  };
  const openModal = () => {
    setSelectedReason("");
    setCustomReason("");
    setShowTextarea(false);
    setIsNextDisabled(true);
  };
  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCustomReason(e.target.value);
    setIsNextDisabled(e.target.value.trim() === "");
  };
  const handleNextClick = () => {
    if (showTextarea && customReason.trim() === "") {
      return;
    }
    const reasonToSubmit = showTextarea ? customReason : selectedReason;
    reportedBeats(reasonToSubmit);
  };

  return (
    <ModalWrapper modalId="BeatReportModal" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="BeatReportModal"
        aria-hidden="true"
        aria-labelledby="BeatReportModal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header ps-0 pt-0 uni-pr-72 uni-mb-40">
              <Heading4 className="modal-title-fs-32">
                Why are you reporting this beat
              </Heading4>
            </div>
            <div className="modal-body ps-0 pt-0 pb-0 uni-mb-56">
              <p
                className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8 popup-space-mobile "
                onClick={handleReasonSelect("It is offensive")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  It is offensive
                </TextfieldSM>
                <DropworksRadioCheckbox
                  checked={"It is offensive" === selectedReason}
                />
              </p>
              <p
                className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8  popup-space-mobile"
                onClick={handleReasonSelect("Bullying or harassment")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  Bullying or harassment
                </TextfieldSM>
                <DropworksRadioCheckbox
                  checked={"Bullying or harassment" === selectedReason}
                />
              </p>
              <p
                className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8  popup-space-mobile"
                onClick={handleReasonSelect("Hate speech or symbols")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  Hate speech or symbols
                </TextfieldSM>
                <DropworksRadioCheckbox
                  checked={"Hate speech or symbols" === selectedReason}
                />
              </p>
              <p
                className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8  popup-space-mobile"
                onClick={handleReasonSelect("Nudity or sexual activity")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  Nudity or sexual activity
                </TextfieldSM>
                <DropworksRadioCheckbox
                  checked={"Nudity or sexual activity" === selectedReason}
                />
              </p>
              <p
                className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8  popup-space-mobile"
                onClick={handleReasonSelect(
                  "Violence abuse or dangerous activities"
                )}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  Violence abuse or dangerous activities
                </TextfieldSM>
                <DropworksRadioCheckbox
                  checked={
                    "Violence abuse or dangerous activities" === selectedReason
                  }
                />
              </p>
              <p
                className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8  popup-space-mobile"
                onClick={handleReasonSelect("Intellectual property violation")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  Intellectual property violation
                </TextfieldSM>
                <DropworksRadioCheckbox
                  checked={"Intellectual property violation" === selectedReason}
                />
              </p>
              <p
                className="d-flex justify-content-between uni-p-16 p-lh  popup-space-mobile"
                onClick={handleReasonSelect("Something else")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  Something else
                </TextfieldSM>
                <DropworksRadioCheckbox
                  checked={"Something else" === selectedReason}
                />
              </p>
              {showTextarea && (
                <div className="uni-mt-16">
                  <textarea
                    className="form-control custom-textarea"
                    value={customReason}
                    onChange={handleTextareaChange}
                    placeholder="Specify your reason"
                    rows={3}
                  />
                </div>
              )}
            </div>
            <div className="modal-footer ps-0 mbl-modal-footer pt-0 pb-0 ">
              {beatPage ? (
                <CloseModalButton className="mbl-anchor-full-btn">
                  <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 mbl-full-width-btn uni-mr-16">
                    Cancel
                  </DropworksSmallButton>
                </CloseModalButton>
              ) : (
                <ButtonComponent id="SelectBeat" className="mbl-anchor-full-btn">
                  <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 mbl-full-width-btn uni-mr-16" 
                  disabled={false}>
                    Back
                  </DropworksSmallButton>
                </ButtonComponent>
              )}
              <ButtonComponent id="ThankYouModal" className="mbl-anchor-full-btn">
                <DropworksSmallButton
                  className="btn-wid m-0 mbl-full-width-btn"
                  onClick={(e:any)=>{e.preventDefault();handleNextClick();}}
                  disabled={
                    selectedReason === "Something else" &&
                    customReason.length === 0
                      ? true
                      : isNextDisabled
                  }
                >
                  Next
                </DropworksSmallButton>
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default view;
