import React from "react";
import ChatComponent from "../../shared/components/documentdocxfile";
import MessageChatAudio from "../../shared/components/message-audiofile";
import VideoMessage from "./videomessage";
import "./styles.scss";
import { getExtension } from "../../shared/utils/localStorage";

interface ChatMessageProps {
  chattingTab: string;
  item: any;
  handleDownload: (e: any, item: any) => void;
  deleteChat: (index: number) => void;
  handleDropdownToggle: (index: number) => void;
  activeDropdown: number | null;
  index: number;
  truncateStringWithExtension: (str: string) => string;
  getFileTypeImage: (fileName: string, fileSrc: string) => string;
  loaderSvg: string;
  ErrorImg: string;
}

const MessageRecieved: React.FC<ChatMessageProps> = ({
  chattingTab,
  item,
  handleDownload,
  deleteChat,
  handleDropdownToggle,
  activeDropdown,
  index,
  truncateStringWithExtension,
  getFileTypeImage,
  loaderSvg,
  ErrorImg,
}) => {
  const renderFileContent = () => {
    const attachedFile = item.is_attached_file?.[0];
    const fileSrc = item.is_attached_file?.[0]?.src || "";
    const fileName = attachedFile?.fileName || "Unknown File";
    const fileSize = attachedFile?.fileSize
    ? `${(attachedFile.fileSize / 1024).toFixed(2)} KB`
    : "Unknown Size";
    const extensionName=getExtension(fileName || " ") || "";
 
 
  if (extensionName && ['mp3','mpeg','wav'].includes(extensionName)) {
      return (
        <MessageChatAudio
          src={ `${process.env.REACT_APP_IMGURL}/${attachedFile?.src}` || ""}
          messageTime={item.time || "00:00"}
          is_recieved={true}
          allAudioData={item}
        />
      );
    } else if (extensionName && ["mp4", "avi", "mov", "wmv", "mkv", "flv", "webm", "mpeg", "mpg", "3gp", "ogv"].includes(extensionName)) {
      return (
        <VideoMessage videoUrl={fileSrc} is_recieved={true} />
 
      );
    }  else if(extensionName &&  ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'tif', 'webp', 'svg', 'heic', 'heif', 'ico', 'raw'].includes(extensionName)) {
      return (
        <a onClick={(e) => handleDownload(e, item)}>
        <img
          src={getFileTypeImage(fileName, fileSrc)}
          alt="File Preview"
          className="message-image"
          onError={(e) => (e.currentTarget.src = loaderSvg)}
        />
      </a>
      );
    }
    else if(extensionName ){
      return (
        <ChatComponent
        is_recieved={true}
          fileName={fileName}
          fileType={extensionName}
          fileSize={fileSize}
          messageTime={item.time || "00:00"}
          pageCount={
            fileName.endsWith(".docx")
              ? attachedFile?.pageCount || 0
              : undefined
          }
          src={
            item.is_attached_file?.[0]?.src
              ? item.is_attached_file?.[0]?.src
              : ""
          }
          sender_name={item.sender_name}
        />
      );
    }
  };

  return (
    <div className="newmessage-receiver">
      {chattingTab === "Artist" && (
        <img
          src={`${process.env.REACT_APP_IMGURL}/${item.imgUrl}` || ""}
          className={
            chattingTab === "Artist"
              ? "img-profilepic uni-mr-8"
              : "img-profilepic-project"
          }
          onError={(e) => (e.currentTarget.src = ErrorImg)}
          alt="image"
        />
      )}
      <p className="newmessage-receiver-chat-triangle"></p>

      {item.is_attached ? (
        <div className="newmessage-receiver-message d-block">
            <div className="sender-name">{item.sender_name}</div>
          <div>{renderFileContent()}</div>
          <div className="newmessage-receiver-message-receiver-message  custom-received-msg uni-mt-8 w-100">
            <div>
              {truncateStringWithExtension(
                item.is_attached_file?.[0]?.fileName
              )}
            </div>
            <div className="d-flex justify-content-end ">
              <span className="uni-fw-400 uni-ml-8 newmessage-sender-message-time d-flex">
                <span className="uni-pr-8 fs-gray3">{item.time}</span>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="newmessage-receiver-message">
          <span className="newmessage-receiver-message-received-message">
            <div className="sender-name">{item.sender_name}</div>
            <span>{item.message}</span>
          </span>
          <span className="fs-gray3 uni-fw-400 newmessage-receiver-message-time text-end">
            {item.time}
          </span>
        </div>
      )}
    </div>
  );
};

export default MessageRecieved;
