import { useEffect } from "react";
import {
  Navigate,
  RouteObject,
  useLocation,
  useRoutes,
} from "react-router-dom";
import { Login } from "../content/guest-pages/login";
import { MessageChat } from "../content/messages-chat";
import LogoHeader from "../shared/components/logo-header";
import { Sign_Up } from "../content/guest-pages/sign-up";
import { Setting_Up_Page } from "../content/guest-pages/settingup-page";
import { Sign_Up_Fill_Details } from "../content/guest-pages/signup-filldetails";
import UnderMaintenance from "../content/under-maintenance";
import { Search } from "../content/search";
import { RecoverAccount } from "../content/guest-pages/recover-account";
import { LoginRecoverAccountEmail } from "../content/guest-pages/recover-account-email";
import { Invite } from "../content/invite";
import { Library } from "../content/studio/library";
import { Favorites } from "../content/studio/favorites";
import { MainNotification } from "../content/notification";
import { UserProfile } from "../content/user-profile";
import { DemoModal } from "../content/test-modal";
import { NewStudioheader } from "../shared/components/studio-header";
import { ProfilSetting } from "../content/guest-pages/profile-setting";
import { BeatesPage } from "../content/beat-page";
import { NewWallet } from "../content/studio/wallet";
import { BeatEmpty } from "../content/beat-empty";
import MarketplaceFeatured from "../content/featured-studio";
import { TrackPage } from "../content/track-page";
import { ProjectPage } from "../content/project-page";
import { TrackProjectOverview } from "../content/track-project-overview";
import { NewMarketplaceheader } from "../shared/components/marketplace-header";
import { MarketPlaceProducts } from "../content/marketplace-products";
import { MarketPlaceDropworksSelect } from "../content/marketplace-dropworks-select";
import { SelectPortfolio } from "../content/select-portfolio";
import { CompositePortfolio } from "../content/composite-portfolio";
import { PersonalPortfolio } from "../content/personal-portfolio";
import { MarketPlaceDropworkSelect } from "../content/marketplace-products-dw-select";
import { MarketPlaceDropworkComposite } from "../content/marketplace-products-dw-composite";

import { HomePage } from "../content/guest-pages/home";
import { TrackProjectOverview2 } from "../content/track-project-overview-2";
import { MarketPlaceDropworksComposite } from "../content/marketplace-dropworks-composite";
import VerificationComplete from "../content/guest-pages/verification-complete/VerificationComplete";
import { Profile } from "../content/profile";
import PageNotFound from "../content/404-not-found";
import InitialHeader from "../shared/components/initial-header/InitialHeader";
import { NewCreate } from "../content/studio/new-create";
import ParentHeader from "../shared/components/ParentHeader";
import DropworksMarketplacePrivacy from "../content/dropworks-marketplace-privacy/view";
import DropworksPrivacy from "../content/dropworks-privacy/view";
import DropworksRisk from "../content/dropworks-risk/view";

const publicRoutesConfig: RouteObject[] = [
  {
    element: <InitialHeader />,
    children: [
      {
        path: "/test-modal",
        element: <DemoModal />,
      },
      {
        path: "/sign-up",
        element: <Sign_Up />,
      },
      {
        path: "/setting-up",
        element: <Setting_Up_Page />,
      },
      {
        path: "/fill-details",
        element: <Sign_Up_Fill_Details />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/recover-account",
        element: <RecoverAccount />,
      },
      {
        path: "/recover-account-email",
        element: <LoginRecoverAccountEmail />,
      },
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/verification-complete",
        element: <VerificationComplete />,
      },
      {
        path: "*",
        element: <Navigate to="/login" replace />,
      },
    ],
  },
];
const privateRoutesConfig: RouteObject[] = [
  {
    element: <ParentHeader />,
    children: [
      {
        path: "/notification",
        element: <MainNotification />,
      },
      {
        path: "/message-chat",
        element: <MessageChat />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/wallet",
        element: <NewWallet />,
      },
      {
        path: "/profile-setting",
        element: <ProfilSetting />,
      },
      {
        path: "/favorites",
        element: <Favorites />,
      },
      {
        path: "/dropworks-terms",
        element: <DropworksMarketplacePrivacy />,
      },
      {
        path: "/dropworks-privacy-policy",
        element: <DropworksPrivacy />,
      },
      {
        path: "/dropworks-risk",
        element: <DropworksRisk />,
      },
    ],
  },
  {
    element: <LogoHeader />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/PageNotFound",
        element: <PageNotFound />,
      },
    ],
  },

  {
    element: <NewStudioheader />,
    children: [
      {
        path: "/marketplace-products",
        element: <MarketPlaceProducts />,
      },
      {
        path: "/marketplace-dropworks-select",
        element: <MarketPlaceDropworkSelect />,
      },
      {
        path: "/marketplace-dropworks-composite",
        element: <MarketPlaceDropworkComposite />,
      },
      {
        path: "/dropworks-select",
        element: <MarketPlaceDropworksSelect />,
      },
      {
        path: "/dropworks-composite",
        element: <MarketPlaceDropworksComposite />,
      },

      {
        path: "/user-profile/:slugName",
        element: <UserProfile />,
      },

      {
        path: "/select-portfolio",
        element: <SelectPortfolio />,
      },
      {
        path: "/composite-portfolio",
        element: <CompositePortfolio />,
      },
      {
        path: "/personal-portfolio",
        element: <PersonalPortfolio />,
      },
    ],
  },
  {
    element: <NewMarketplaceheader />,
    children: [
      {
        path: "/track-project-overview",
        element: <TrackProjectOverview />,
      },
      {
        path: "/featured",
        element: <MarketplaceFeatured />,
      },
      {
        path: "/track-project-overview/:slugName",
        element: <TrackProjectOverview />,
      },
      {
        path: "/track-project-rerelease",
        element: <TrackProjectOverview2 />,
      },
      {
        path: "/track-project-rerelease/:slugName",
        element: <TrackProjectOverview2 />,
      },
      {
        path: "/beat-empty",
        element: <BeatEmpty />,
      },
      {
        path: "/beat-empty/:slugName",
        element: <BeatEmpty />,
      },
      {
        path: "/create",
        element: <NewCreate />,
      },
      {
        path: "/library",
        element: <Library />,
      },

      {
        path: "/invite",
        element: <Invite />,
      },
      {
        path: "/project-page/:slugName",
        element: <ProjectPage />,
      },
      {
        path: "/track-page/:slugName",
        element: <TrackPage />,
      },
      {
        path: "/beats-page/:slugName",
        element: <BeatesPage />,
      },
    ],
  },

  {
    path: "/under-maintenance",
    element: <UnderMaintenance />,
  },
  {
    path: "/test-modal",
    element: <DemoModal />,
  },
  {
    path: "/sign-up",
    element: <Navigate to="/" replace />,
  },
  {
    path: "/setting-up",
    element: <Navigate to="/" replace />,
  },
  {
    path: "/fill-details",
    element: <Navigate to="/" replace />,
  },
  {
    path: "/login",
    element: <Navigate to="/" replace />,
  },
  {
    path: "/recover-account",
    element: <Navigate to="/" replace />,
  },
  {
    path: "/recover-account-email",
    element: <Navigate to="/" replace />,
  },
  {
    path: "/verification-complete",
    element: <Navigate to="/" replace />,
  },
  {
    path: "*",
    element: <Navigate to="/PageNotFound" replace />,
  },
];

const MainRoutes = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Enable smooth scrolling
    });
    
  }, [pathname]);
  const token = localStorage.getItem("dropworksToken");
  const config = token ? privateRoutesConfig : publicRoutesConfig;
  const routes = useRoutes(config);
  return routes;
};

export default MainRoutes;
