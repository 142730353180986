import {
  TextfieldLG,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import { CloseModalButton } from "../../logo-header/view";
import { DropworksRadioButton } from "../../../../../common/custom-field/dropworks-radio-button";
import "./styles.scss";
import { ButtonComponent } from "../../logo-header/view";
import { Heading3, Heading4 } from "../../../../../common/custom-field/dropstock-heading";
import { useState } from "react";
import { DropworksRadioCheckbox } from "../../../../../common/custom-field/dropworks-radio-checkbox";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";

const view = (
  reportedTracks: any,
  selectedReason: string,
  setSelectedReason: (reason: string) => void,
  trackPage: boolean
) => {
  const [showTextarea, setShowTextarea] = useState(false);
  const [customReason, setCustomReason] = useState("");
  const [isNextbtnDisabled, seIsNextbtnDisabled] = useState(true);

  const handleReasonSelect = (reason: string) => () => {
    setSelectedReason(reason);
    seIsNextbtnDisabled(false);
    if (reason === "Something else") {
      setShowTextarea(true);
    } else {
      setShowTextarea(false);
      setCustomReason("");
    }
  };
  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCustomReason(e.target.value);
    seIsNextbtnDisabled(e.target.value.trim() === "");
  };

  const handleNextClick = () => {
    if (showTextarea && customReason.trim() === "") {
      // alert("Please provide a reason.");
      return;
    }
    const reasonToSubmit = showTextarea ? customReason : selectedReason;
    reportedTracks(reasonToSubmit);
  };
  const openModal = () => {
    setSelectedReason("");
    setCustomReason("");
    setShowTextarea(false);
    seIsNextbtnDisabled(true);
  };

  // const handleReasonSelect = (reason: string) => () => {
  //   setSelectedReason(reason); // Set the selected reason
  // };
  return (
    <ModalWrapper modalId="TrackReportModal" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="TrackReportModal"
        aria-hidden="true"
        aria-labelledby="TrackReportModal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content ">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header ps-0 pt-0 uni-pr-72 uni-mb-40">
              <Heading4 className="modal-title-fs-32">
                Why are you reporting this track
              </Heading4>
            </div>

            <div className="modal-body ps-0 pt-0 pb-0  uni-mb-56">
              <p
                className="d-flex justify-content-between align-items-center uni-p-16 p-lh uni-mb-8  popup-space-mobile"
                onClick={handleReasonSelect("It is offensive")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  It is offensive
                </TextfieldSM>
                <DropworksRadioCheckbox
                  checked={"It is offensive" === selectedReason}
                />
              </p>
              <p
                className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8 align-items-center  popup-space-mobile"
                onClick={handleReasonSelect("Bullying or harassment")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  Bullying or harassment
                </TextfieldSM>
                <DropworksRadioCheckbox
                  checked={"Bullying or harassment" === selectedReason}
                />
              </p>
              <p
                className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8 align-items-center popup-space-mobile"
                onClick={handleReasonSelect("Hate speech or symbols")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  Hate speech or symbols
                </TextfieldSM>
                <DropworksRadioCheckbox
                  checked={"Hate speech or symbols" === selectedReason}
                />
              </p>
              <p
                className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8 align-items-center popup-space-mobile"
                onClick={handleReasonSelect("Nudity or sexual activity")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  Nudity or sexual activity
                </TextfieldSM>
                <DropworksRadioCheckbox
                  checked={"Nudity or sexual activity" === selectedReason}
                />
              </p>
              <p
                className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8 align-items-center popup-space-mobile"
                onClick={handleReasonSelect(
                  "Violence abuse or dangerous activities"
                )}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  Violence abuse or dangerous activities
                </TextfieldSM>
                <DropworksRadioCheckbox
                  checked={
                    "Violence abuse or dangerous activities" === selectedReason
                  }
                />
              </p>
              <p
                className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8  popup-space-mobile align-items-center"
                onClick={handleReasonSelect("Intellectual property violation")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  Intellectual property violation
                </TextfieldSM>
                <DropworksRadioCheckbox
                  checked={"Intellectual property violation" === selectedReason}
                />
              </p>
              <p
                className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8  popup-space-mobile align-items-center"
                onClick={handleReasonSelect("Something else")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  Something else
                </TextfieldSM>
                <DropworksRadioCheckbox
                  checked={"Something else" === selectedReason}
                />
              </p>
              {showTextarea && (
                <div className="uni-mt-16">
                  <textarea
                    className="form-control custom-textarea"
                    value={customReason}
                    onChange={handleTextareaChange}
                    placeholder="Specify your reason"
                    rows={3}
                  />
                </div>
              )}
            </div>
            <div className="modal-footer ps-0 mbl-modal-footer pt-0 pb-0">
              {trackPage ? (
                <CloseModalButton className="mbl-anchor-full-btn">
                  <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 mbl-full-width-btn">
                    Cancel
                  </DropworksSmallButton>
                </CloseModalButton>
              ) : (
                <ButtonComponent
                  id="SelectTrack"
                  className="mbl-anchor-full-btn"
                >
                  <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 mbl-full-width-btn">
                    Back
                  </DropworksSmallButton>
                </ButtonComponent>
              )}

              <ButtonComponent
                id="ThankYouModal"
                className="mbl-anchor-full-btn"
              >
                <DropworksSmallButton
                  className="btn-wid mbl-full-width-btn m-0"
                  onClick={handleNextClick}
                  disabled={
                    selectedReason === "Something else" &&
                    customReason.length === 0
                      ? true
                      : isNextbtnDisabled
                  }
                >
                  Next
                </DropworksSmallButton>
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default view;
