import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import {
  DropworksDisableButton,
  DropworksSmallButton,
} from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { closeModal, CloseModalButton } from '../../logo-header/view';
import "./styles.scss";
import { DropworksTextArea } from "../../../../../common/custom-field/dropworks-textArea";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { FC, useEffect } from "react";
import { deleteAccountForm } from "../../../../content/guest-pages/login/modal";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import Loader from "../../loader/view";

export const view :FC<deleteAccountForm>= ({errors,isSubmitted,loading,register,onSubmit,handleSubmit,navigateTo,closeModalRef,setValue,openModal}) => {
  return (
    <>
    <ModalWrapper modalId="DeleteAccount" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="DeleteAccount"
        aria-hidden="true"
        aria-labelledby="DeleteAccount"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
            <div className="modal-header ps-0 pt-0 uni-pr-72">
              <Heading3 className="modal-title">Delete Account</Heading3>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body ps-0 pt-0 pb-0">
              <TextfieldLG className="uni-mt-16 ctm-txt ctm-md-lh delete-account-modalcontent">
                Your account will be permanently deleted
              </TextfieldLG>
              <TextfieldXSM className="uni-mb-56 ds-ctm-txt">
              Withdraw all assets from your account before deletion. 
               Assets in deleted account are not transferable
              </TextfieldXSM>

              <label htmlFor="" className="text-light delete-acount-labels uni-mb-16">
                Username
              </label>
              <DropworksInput
                placeholder="Username"
                className={`delete-account-input-modal form-control ${errors.username? "is-invalid b-red-2":""} `}
                {...register("username")}
               
              ></DropworksInput>
                    {errors.username && <p className="invalid-feedback error-red">{errors.username.message}</p>}

              <label htmlFor="" className="text-light  delete-acount-labels delete-acount-labels-pass uni-mb-16 uni-mt-32">
                Password
              </label>
              <DropworksInput
                placeholder="Password"
                className={`form-control ${errors.password? "is-invalid b-red-2":""}`}
                {...register("password")}
              ></DropworksInput>
               {errors.password && <p className="invalid-feedback error-red">{errors.password.message}</p>}

              <label htmlFor="" className="text-light delete-account-space delete-acount-labels uni-mb-16 uni-mt-32">
              Why do you want to delete your account? 
              </label>
              <DropworksTextArea
              hideIcon={true}
                className={`form-control ctm-textarea-delete mb-0 ${errors.reason? "is-invalid b-red-2":""}`}
                placeholder="Reason"
                {...register("reason")}
                onChange={(e:any)=>setValue("reason",e.target.value,{shouldValidate:true})}
              />
                   {errors.reason && <p className="invalid-feedback error-red">{errors.reason.message}</p>}
            </div>
            <div className="modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72 uni-mt-56">
            {loading ? (
                  <Loader className="uni-mt-16" />
                ) : (
                  <>
                <DropworksDisableButton className="btn-wid  social-link-modal-btns delete-account-btn my-0 ml-0 uni-mr-16 " onClick={(e:any)=>{e.preventDefault();closeModal()}}> Cancel</DropworksDisableButton>
                <DropworksSmallButton className="btn-wid social-link-modal-btns m-0" type="submit">
                  Confirm
                </DropworksSmallButton>
                </>)}
            </div>
            </form>
            <CloseModalButton ref={closeModalRef} className='d-none'>close</CloseModalButton>
          </div>
        </div>
      </div>
      </ModalWrapper>
    </>
  );
};


