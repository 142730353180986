import { view } from "./view"
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllBeatsTrackByUserId } from "../../shared/utils/apiServices";
import { RootState } from "../../../redux/store";

export const Profile = () => {
  const [beatsData, setBeatsData] = useState<any>()
  const [trackData, setTrackData] = useState<any>()
  const [recentReleasesData, setRecentReleasesData] = useState<any>()
  const [loading ,setLoading] =useState(true);
  const [linksArray, setLinkAyyay] = useState<any>([
  ]);


  const fetchAllBeatsTrackBy = async () => {
    const result = await getAllBeatsTrackByUserId();
    if (result.success) {
      setBeatsData(result.BeatsTrackData.data.beatsData)
      setTrackData(result.BeatsTrackData.data.trackData)
      setRecentReleasesData(result.BeatsTrackData.data.recentReleasesdata)
      setLoading(false)
    }
    else{
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchAllBeatsTrackBy();
  }, []);

  const {username:userName,displayName ,role ,bioGraphy , DWPreferences ,social_links:socialLinks ,imgUrl ,
id,blueTick,displayBeats,displayReleases} = useSelector((state: any) => state.user)
  const {musicPlayer} = useSelector((state: RootState) => state.music)

  useEffect(() => {
    if (socialLinks?.length > 0) {
      setLinkAyyay(socialLinks.map((data: any) => (
        {
          name: data.name,
          link: data.link,
        }
      )))
    }
  }, [socialLinks])

  const changeData=()=>{
    if(musicPlayer.musicType ==="track" && Boolean(trackData.length) && musicPlayer.musicId )
    {
      let newUpdatedData=trackData.map((ele:any)=>{
        if(ele?._id && ele?._id === musicPlayer.musicId)
          {
            let newData={favoriteData:musicPlayer.musicLiked?{_id:"abc"}:""};
            return {...ele,...newData}
          }
          else{
            return ele ;
          }
        })
        setTrackData(newUpdatedData);
      }
      else if(musicPlayer.musicType ==="beat" && Boolean(beatsData.length  && musicPlayer.musicId )) {
      let newUpdatedData=beatsData.map((ele:any)=>{
        if(ele._id && ele?._id === musicPlayer.musicId)
        {
          let newdata= {favoriteData:musicPlayer.musicLiked?{_id:"abcd"}:""};
          return {...ele,...newdata}
        }
        else{
          return ele ;
        }
      })
     
     setBeatsData(newUpdatedData);  
   
    }
  }
 
  // useEffect(()=>{
  //   changeData();
  // },[musicPlayer.musicId , musicPlayer.musicLiked])


  return (view({
      blueTick,  userName, role, bioGraphy, DWPreferences, beatsData, trackData,
    displayBeats, displayReleases, recentReleasesData, linksArray, socialLinks, imgUrl, id,displayName,loading
  }))
}