import React, { useEffect, useState } from 'react'
import { getLocalStorageObject } from '../utils/localStorage';
import LogoHeader from './logo-header';
import { NewStudioheader } from './studio-header';
import { NewMarketplaceheader } from './marketplace-header';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';

const ParentHeader = () => {
const {headerValue}=useSelector((state:RootState)=>state.auth)
  return (
    <>
    {headerValue ==3 ?<NewMarketplaceheader/>:headerValue == 2 ? <NewStudioheader/>:<LogoHeader/>}
    </>
  )
}

export default ParentHeader