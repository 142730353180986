import { FC, useEffect, useState } from "react";
import  view  from "./view";
import { getBeat, getTrackProjectBeatsDetailsById, reportBeats } from "../../../utils/apiServices";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setBeat } from "../../../../../redux/reducer/beatSlice";
import { RootState } from "../../../../../redux/store";
import { setbeatModalId } from "../../../../../redux/reducer/reportSlice";



export const BeatReportModal:FC<any>=()=>{

    const beatDataid = useSelector((state: RootState) => state.report.beatModalId);
    const beatPage = useSelector((state: RootState) => state.report.beatPage);
    const [trackReport, setTrackReport] = useState();
    const [selectedReason, setSelectedReason] = useState<string>("");
   
    const reportedBeats = async (sendReason:string) => {
        try {
          const result = await reportBeats(beatDataid, {
            report_type: "Recent messages this user sent you", 
            reason: sendReason, 
          });
          if (result.success) {
            setTrackReport(result.reportedUser.data);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

    return (view(reportedBeats,selectedReason,setSelectedReason,beatPage));
  
}
export default BeatReportModal