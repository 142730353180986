import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { CloseModalButton } from "../../logo-header/view";
import "./styles.scss";
import { DropworksRadioCheckbox } from "../../../../../common/custom-field/dropworks-radio-checkbox";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { FC } from "react";
import { modal1Type } from "../popup-modal";
import Loader from "../../loader/view";

const view: FC<modal1Type> = ({
  role,
  UpdateRole,
  modal1 = false,
  OpenPopup2,
  loading = false,
  message = "",
  closeModalRef,
}) => {
  return (
    <div
      className="modal fade"
      id="Modal1"
      aria-hidden="true"
      aria-labelledby="Modal1"
      tabIndex={-1}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered uni-md-popup">
        <CloseModalButton
          ref={closeModalRef}
          className="d-none"
        ></CloseModalButton>
        <div className="modal-content modal-content-boxes-modal">
          <div className="modal-cross-icon">
            {/* <CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton> */}
          </div>
          <div className="modal-header">
            <Heading3 className="modal-title">Create your profile</Heading3>
          </div>
          <div className="modal-body ps-0 pt-0 pb-0">
            <TextfieldLG className=" uni-mt-16 ctm-txt">
              Pick your roles{" "}
            </TextfieldLG>
            <TextfieldXSM className="uni-mb-56 select-atleast ">
              (Select at least one)
            </TextfieldXSM>
            <div className="investor-box-wrapper">
              <div className="d-flex uni-mb-24 investor-box-parent flex-wrap">
                <div
                  className="investor-boxs-modal-1 cursor-pointer"
                  onClick={() => UpdateRole("Music Preneur")}
                >
                  <p className="investor-boxs-under-modal-1">Music Preneur</p>
                  <p className="investor-boxs-radiobutton">
                    <DropworksRadioCheckbox
                      checked={role.includes("Music Preneur")}
                    />
                  </p>
                </div>
                <div
                  className="investor-boxs-modal-1  cursor-pointer"
                  onClick={() => UpdateRole("Producer")}
                >
                  <p className="investor-boxs-under-modal-1">Producer</p>
                  <p className="investor-boxs-radiobutton">
                    <DropworksRadioCheckbox
                      checked={role.includes("Producer")}
                    />
                  </p>
                </div>
                <div
                  className="investor-boxs-modal-1  cursor-pointer"
                  onClick={() => UpdateRole("Vocalist")}
                >
                  <p className="investor-boxs-under-modal-1">Vocalist</p>
                  <p className="investor-boxs-radiobutton">
                    <DropworksRadioCheckbox
                      checked={role.includes("Vocalist")}
                    />
                  </p>
                </div>

                {/* <div className="d-flex uni-mb-24 investor-box-parent"> */}
                <div
                  className="investor-boxs-modal-1 cursor-pointer"
                  onClick={() => UpdateRole("Lyricist")}
                >
                  <p className="investor-boxs-under-modal-1">Lyricist</p>
                  <p className="investor-boxs-radiobutton">
                    <DropworksRadioCheckbox
                      checked={role.includes("Lyricist")}
                    />
                  </p>
                </div>
                <div
                  className="investor-boxs-modal-1  cursor-pointer"
                  onClick={() => UpdateRole("Beat Maker")}
                >
                  <p className="investor-boxs-under-modal-1">Beat Maker</p>
                  <p className="investor-boxs-radiobutton">
                    <DropworksRadioCheckbox
                      checked={role.includes("Beat Maker")}
                    />
                  </p>
                </div>
                <div
                  className="investor-boxs-modal-1  cursor-pointer"
                  onClick={() => UpdateRole("Curator")}
                >
                  <p className="investor-boxs-under-modal-1">Curator</p>
                  <p className="investor-boxs-radiobutton">
                    <DropworksRadioCheckbox
                      checked={role.includes("Curator")}
                    />
                  </p>
                </div>
                {/* </div> */}
                {/* <div className="d-flex  investor-box-parent"> */}
                <div
                  className="investor-boxs-modal-1  cursor-pointer"
                  onClick={() => UpdateRole("Artwork Designer")}
                >
                  <p className="investor-boxs-under-modal-1">
                    Artwork Designer
                  </p>
                  <p className="investor-boxs-radiobutton">
                    <DropworksRadioCheckbox
                      checked={role.includes("Artwork Designer")}
                    />
                  </p>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>

          <div className="modal-footer mobile-modal-footer ps-0 pt-0 pb-0">
            {/* <DropworksSmallButton  className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16">Back</DropworksSmallButton> */}

            {/* <ButtonComponent id="Modal2" className={modal1?' disable-interactions':''}> */}
            {loading ? (
              <Loader />
            ) : (
              <DropworksSmallButton
                disabled={modal1}
                className={
                  modal1
                    ? "btn-wid  modal-btns m-0 custom-inactive-btn text-secondary disable-interactions"
                    : "btn-wid  modal-btns m-0"
                }
                onClick={OpenPopup2}
              >
                Next
              </DropworksSmallButton>
            )}
            {/* </ButtonComponent> */}
          </div>
          {message && (
            <p className="text-danger w-100 text-center uni-mt-16">{message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default view;
