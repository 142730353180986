import React, { FC, useState, useRef, useEffect } from "react";
import "./styles.scss";
import meatballsIcon from "../../assets/img/icons/meatballs-menu-icon.svg"
import { ButtonComponent } from "../../app/shared/components/logo-header/view";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { closeArrowDropdown } from "../../app/content/messages-chat/view";
 
interface DropdownProps extends React.InputHTMLAttributes<HTMLInputElement> {
  arr: string[];
  height?: string;
  icon?: any;
  className?: string;
  pagename?: string;
  isOpen?: boolean;
  className2?:string;
  onClickOptions?:(data:any)=>void
  onToggle?: () => void; // Function to toggle dropdown state
}
export const view: FC<DropdownProps> = ({ arr, icon = meatballsIcon, className, height, pagename,isOpen,onToggle,onClickOptions,className2="" }) => {
  const handleButtonClick = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
  
    // Close any other open dropdowns
    document.querySelectorAll('.dropdown-menu.show').forEach((menu) => {
      menu.classList.remove('show');
    });
  
    // Find the dropdown menu for the current component
    const dropdownMenu = e.currentTarget.closest('.dropdown')?.querySelector('.dropdown-menu');
    if (dropdownMenu) {
      // Toggle visibility explicitly
      if (!dropdownMenu.classList.contains('show')) {
        dropdownMenu.classList.add('show');
      }
    }
  
    // Run your additional function
    console.log('Function executed during delay');
  };
  
  
  

  return (
    <div className={`dropdown d-flex ${className2}`}  onClick={(e: any) => e.stopPropagation()}  >
      <div
        tabIndex={2}
        className={`meatballs-menu  dropdown-toggle ${className}`}
        data-bs-toggle={pagename==="user-profile"?"":"dropdown"}
        aria-expanded={isOpen}
        onClick={onToggle}
      >
 
        <img src={icon} className={`meatballs-menu-image `} style={{ height: `${height}` }} alt="img" />
 
      </div>
      <ul className={`dropdown-menu dropdown-menu-meatballs-Icon p-0 ${(pagename==="profile" ||pagename==="user-profile") &&  'user-profiles-select-buttons'}`}>
        {(pagename==="profile" ||pagename==="user-profile") ? (
          arr.length > 0 ? (
            arr.map((data:any, i:number) => (
              <li key={i} className="dropdown-menu-meatballs-Icon-li cursor-pointer" onClick={() => window.open(data?.link, "_blank")}>
        <a className="dropdown-item dropdown-item-meatballs" target="_blank" rel="noopener noreferrer" href={data.link}>
          {data.name}
        </a>
      </li>
    ))
  ) : (
    <li  className="dropdown-menu-meatballs-Icon-li" >
    <a className="dropdown-item dropdown-item-meatballs" >
      No social links available
    </a>
  </li>
  )
):
            arr.map((data,i) => {
          if (pagename === "user-profile") {
            return (
              <li key={i} className="dropdown-menu-meatballs-Icon-li  cursor-pointer">
                <Link className="dropdown-item dropdown-item-meatballs" to={data === "Spotify" ? "https://open.spotify.com/" : data === "Apple Music" ? "https://music.apple.com/us/artist/web-player/1559317097" : data === "Tidal" ? "https://tidal.com/browse/artist/24034301" : data === "Deezer" ? "https://www.deezer.com/soon" : data === "Instagram" ? "https://www.instagram.com" : data === "X" ? "https://x.com/home" : data === "Youtube" ? "https://www.youtube.com/" : ""}>
                  {data}
                </Link>
              </li>
            );
          }
          else if(pagename === "call-funtion")
          {
            {return<li key={i} className="dropdown-menu-meatballs-Icon-li cursor-pointer" onClick={()=> {onClickOptions && onClickOptions(data) ; closeArrowDropdown();}}>
            <a className="dropdown-item dropdown-item-meatballs">
              {data}
            </a>
          </li>
          }
          }
          else if(pagename === "track")
            return (
              <ButtonComponent
                id={
                  data === "Report"
                    ? "TrackReportModal"
                    : ""
                }
              >
                <li key={i} className="dropdown-menu-meatballs-Icon-li  cursor-pointer">
                  <a className="dropdown-item dropdown-item-meatballs" href="#">
                    {data}
                  </a>
                </li>
              </ButtonComponent>
            );
            else if(pagename === "beat")
              return (
                <ButtonComponent
                  id={
                    data === "Report"
                      ? "BeatReportModal"
                      : data === "Delete"
                      ? "DeleteModal"
                      : ""
                  }
                >
                  <li key={i} className="dropdown-menu-meatballs-Icon-li  cursor-pointer">
                    <a className="dropdown-item dropdown-item-meatballs" href="#">
                      {data}
                    </a>
                  </li>
                </ButtonComponent>
              );
              else if(pagename === "project")
                return (
                  <ButtonComponent
                    id={
                      data === "Report"
                        ? "ProjectReportModal"
                        : ""
                    }
                  >
                    <li key={i} className="dropdown-menu-meatballs-Icon-li  cursor-pointer">
                      <a className="dropdown-item dropdown-item-meatballs" href="#">
                        {data}
                      </a>
                    </li>
                  </ButtonComponent>
                );
          else {
            return (
              <ButtonComponent
                id={
                  data === "Report"
                    ? "ReportModal1"
                    : data === "Block"
                      ? "BlockedModal"
                      : data === "Change Alias"
                        ? "ChangeAlias"
                        : data === "Remove"
                          ? "RemoveCollaborator"
                          : data === "Delete"
                            ? pagename === "message"
                              ? "DeleteChat"
                              : "DeleteFile"
                            : data === "Approve"
                              ? "ApproveFile"
                              : data === "Decline"
                                ? "DeclineFile"
                                : "#"
                }
              >
                <li key={i} className="dropdown-menu-meatballs-Icon-li  cursor-pointer" onClick={()=>console.log()}>
                  <a className="dropdown-item dropdown-item-meatballs cursor-pointer" href="#">
                    {data}
                  </a>
                </li>
              </ButtonComponent>
            );
          }
        })}
      </ul>
    </div>
  );
};
 
