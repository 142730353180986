import { FC } from "react";
import { view } from "./view";

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    hideIcon?: boolean;
    isAutoSave?: boolean;
    maxCharacter?:number | undefined,
    className2?:string | undefined,
    value?:string | undefined,
}


export const DropworksTextArea:FC<TextareaProps>=({hideIcon,...props})=>{
    return view({hideIcon,...props});
}