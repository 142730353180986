import { Component, ReactNode } from 'react';
import * as Sentry from '@sentry/react';


interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    };
    
    public static getDerivedStateFromError(_: Error): State {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: any) {
        Sentry.captureException(error, {
            extra: errorInfo,
            tags: {
                label: 'Error boundary'
            }
        });
    }

    public render() {
        if (this.state.hasError) {
        }

        return this.props.children;
    }
}

export default ErrorBoundary;