import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setLocalStorageObject } from '../../app/shared/utils/localStorage';
// import { User } from 'oidc-client-ts';

export interface verifyPinFormInputs1 {
  pin: string;
  phrase: string;
  privateKey: string;
}
export interface ChangePinBy {
  name: string
}

export interface AuthState {
  authentication: boolean | undefined
  isFirstTime: boolean
  userdata: any | null
  headerValue: any
  isFirstName_displayName?: string,
  isFirstName_ImgUrl?: string,
  validateValue: verifyPinFormInputs1,
  changePinBy?: string,
  receiverAddress?:string,
  walletPinset:boolean
}
export interface isFirstTimeLoggedIn {
  displayName: string,
  ImgUrl: string
}

const initialState: AuthState = {
  authentication: false,
  isFirstTime: false,
  userdata: null,
  headerValue: 3,
  isFirstName_displayName: "",
  validateValue: {
    pin: '',
    phrase: '',
    privateKey: ''
  },
  changePinBy: "",
  isFirstName_ImgUrl: "",
  receiverAddress: "",
  walletPinset:false
};


export const getUserDetails = createAsyncThunk('auth/getUserDetails', async () => {
  const response = await fetch('/api/userdetails');
  const data = await response.json();
  return data;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPinBy: (state, action: PayloadAction<string>) => {
      console.log(action.payload, "action.payloadaction.payload");
      state.changePinBy = action.payload; // Create a new object
    },
    setValidateValue: (state, action: PayloadAction<verifyPinFormInputs1>) => {
      state.validateValue = { ...action.payload }; // Create a new object
    },
    setWalletAddress: (state, action: PayloadAction<string>) => {
      state.receiverAddress = action.payload; // Create a new object
    },
    setAuthentication: (state, action: PayloadAction<boolean>) => {
      state.authentication = action.payload;
      if (action.payload && !state.userdata) {
        getUserDetails()
      }
    },
    setHeaderValue: (state, action: PayloadAction<any>) => {
      setLocalStorageObject("dw-header", action.payload);
      state.headerValue = action.payload
    },
    setIsFirstTime: (state, action: PayloadAction<isFirstTimeLoggedIn>) => {
      state.isFirstTime = true
      state.isFirstName_displayName = action.payload.displayName;
      state.isFirstName_ImgUrl = `${process.env.REACT_APP_IMGURL}/${action.payload.ImgUrl}`
    },
    setWalletpinset: (state, action: PayloadAction<boolean>) => {
      state.walletPinset = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.userdata = action.payload;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        console.error('Failed to fetch user details:', action.error);
        state.userdata = null;
      });
  },
});

export const { setAuthentication, setIsFirstTime, setValidateValue, setPinBy, setHeaderValue ,setWalletAddress,setWalletpinset } = authSlice.actions;

export default authSlice.reducer;


