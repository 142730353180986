import { FC, useEffect, useState } from "react";
import "../user-profile/styles.scss"
import { TrackIcon } from "../../../common/track-icon";
import { BeatesIcon } from "../../../common/beats-icon";
import { ProjectIcon } from "../../../common/project-icon";
import backBtn from "../../../assets/img/icons/backBtn.svg";
import { viewType } from "./modal";
import { UserLable } from "../../../common/user-labels";
import { DropworksSmallButton } from "../../../common/custom-field/dropworks-button";
import { ArrowDropdownGray4 } from "../../../common/icons";
import { Heading3 } from "../../../common/custom-field/dropstock-heading";
import { BlueTik } from "../../../common/all-icons";
import { TextfieldXSM } from "../../../common/custom-field/dropstock-text";
import { useDispatch, useSelector } from "react-redux";
import { getformatDate, mixMasterAudio} from "../../shared/utils/localStorage";
import { useNavigate } from "react-router-dom";
import Tabs from "../../../common/tabs";
import TabsComponent from "../../../common/tabs-component/view";
import { setuserListData } from "../../../redux/reducer/messageChatSlice";
import UserIcon from "../../../assets/steps/Group 18414.svg";
import { RootState } from "../../../redux/store";

export const view: FC<viewType> = ({
  userName,
  displayName,
  role,
  bioGraphy,
  DWPreferences,
  beatsData,
  trackData,
  imgUrl,
  id,
  recentReleasesData,
  linksArray,
  socialLinks,
  blueTick,
  displayBeats,
  displayReleases,
  loading
}) => {
  const publicProfile = useSelector((state: any) => state.user.profileImg);
  const publicProfileThumbnail = useSelector((state: any) => state.user.profileThumbnailImg);
  const user =useSelector((state:RootState)=>state.user);
  const [trackarr, setTrackarr] = useState([
    {
      heading: "",
      subheading: "",
      image: "",
    },
  ]);


  useEffect(() => {
    if (Array.isArray(trackData)) {
      setTrackarr(
        trackData?.map((item: any) => ({
          heading: item.track_name,
          subheading: item.description,
          image: item.art_work_image[0]?.thumbnail || item.art_work_image[0]?.src,
          slugName: item.slug_name,
          createrName:item?.collaborators,
          audioUrl:mixMasterAudio(item.upload_file),
          audioLiked:Boolean(item?.favoriteData?._id),
          audioPath:`track-page/${item?.slug_name}`,
          audioName:item?.track_name,
          fullTrackData:[item],
          audioByMe:item?.user_id?._id === user.id,
          id:item._id,
          trackDetailsReleaseDate: getformatDate(item?.created_at),
          trackDeatilsCreatorList: [item?.creator_user_name],
          trackDetailsUniqueId: item?.unique_id

        }))
      );
    }
  }, [trackData]);

  const [recentReleases, setRecentReleases] = useState([
    {
      heading: "",
      subheading: "",
      image: "",
    },
  ]);

  useEffect(() => {
    if (Array.isArray(recentReleasesData)) {
      setRecentReleases(
        recentReleasesData?.map((item: any) => ({
          heading: item.project_name,
          subheading: item.description,
          image: item.art_work_image[0]?.thumbnail || item.art_work_image[0]?.src,
          collaborators:item?.collaborators,
          slugName: item.slug_name,
        }))
      );
    }
  }, [recentReleasesData]);

  const [beatsarr, setBeatsarr] = useState([
    { heading: "", subheading: "", image: "", beatName: "",id:"" },
  ]);

  useEffect(() => {
    if (Array.isArray(beatsData)) {
      setBeatsarr(
        beatsData?.map((item: any) => ({
          heading: item.beat_name,
          subheading: item?.user_id?.display_name,
          image: item.art_work_image[0]?.thumbnail || item.art_work_image[0]?.src,
          beatName: item.beat_slug_name,
          artistSlug:item?.user_id?.slug_name,
          fullBeatData: [item],
          id:item._id
        }))
      );
    }
  }, [beatsData]);

  const [button, setButton] = useState("user-profile");

  const handleButtonClick = (type: string) => {
    setButton(type);
  };

  const navigate = useNavigate();
const dispatch =useDispatch();
  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
  };

  return (
    <div className="user-profile container container-xxl min-height-cover">
      <div
        className={`user-profile-body ${
          button === "tracks" || button === "beats"
            ? "user-profile-body-h100"
            : ""
        }`}
      >
        <div>
          <img
            src={publicProfileThumbnail || publicProfile}
            className="user-profile-image"
            alt="user-image"
            onError={(e: any) => (e.target.src = UserIcon)}
          />
          
        </div>
        <div className="user-profile-content">
          <div className="w-100">
            <div className="user-profile-details">
              <Heading3 className="uni-mr-8 mb-0">
                {displayName}
              </Heading3>
              {blueTick ? <BlueTik /> : ""}
            </div>
            <div className="user-profile-id">@{userName}</div>
          </div>
          {button === "user-profile" ? (
            <>
              <div className="d-flex flex-wrap gap-3 mt-2 all-lables">
                {role?.map((role: any) => (
                  <UserLable className="user-label-style user-label-profile">
                    {role}
                  </UserLable>
                ))}
                {DWPreferences.length > 0
                  ? DWPreferences.map((DWPreferences: any) => (
                      <UserLable className="user-label-style user-label-profile">
                        {DWPreferences}
                      </UserLable>
                    ))
                  : ""}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {button === "user-profile" ? (
            <>
              <div className="d-flex flex-wrap gap-3 mt-2 all-lables all-lables-mobile">
                {role?.map((role: any) => (
                  <UserLable className="user-label-style user-label-profile">
                    {role}
                  </UserLable>
                ))}
                {DWPreferences.length > 0
                  ? DWPreferences.map((DWPreferences: any) => (
                      <UserLable className="user-label-style user-label-profile">
                        {DWPreferences}
                      </UserLable>
                    ))
                  : ""}
              </div>
            </>
          ) : (
            ""
          )}
      

      {button === "user-profile" ? (
        <>
          <div className="d-flex gap-3  uni-mt-32 user-profile-bottons mobile-mt-0">
            {socialLinks?.length ? (
              <DropworksSmallButton
                className="user-profiles-select-buttons uni-bg-disable"
                data-bs-toggle="dropdown"
              >
                <TextfieldXSM className="m-0">Socials</TextfieldXSM>
                <ArrowDropdownGray4
                  pagename="profile"
                  className="px-0 "
                  arr={linksArray}
                />
              </DropworksSmallButton>
            ) : (
              ""
            )}
            <DropworksSmallButton
              className="user-profiles-Message-buttons uni-bg-disable"
              onClick={() => {
              const userPayload = {
              userImg: imgUrl || "",
              userName: displayName || "",
              alluserListId: id || "",
              chattingTab: "user",
              tabValue: 0,
              username:userName
              };
                                                dispatch(setuserListData(userPayload));
                navigate("/message-chat");
              }}
            >
              Message
            </DropworksSmallButton>
          </div>
        </>
      ) : (
        ""
      )}
      <div className="user-profile-button flex-wrap mb-4">
        {button === "tracks" || button === "beats" ? (
          <>
            <div className="w-100 d-flex">
              <button
                className={`user-profile-button-backBtn`}
                onClick={() => handleButtonClick("user-profile")}
              >
                <img
                  className="user-profile-button-image"
                  src={backBtn}
                  alt="backBtn"
                />
              </button>
              <Tabs
                className="uni-mb-56"
                tabsTitle={
                  displayReleases && displayBeats
                    ? ["Tracks", "Beats"]
                    : displayReleases
                    ? ["Tracks"]
                    : displayBeats
                    ? ["Beats"]
                    : []
                }
                id="20"
                checkTabState={checkTabState}
              />
            </div>
            <TabsComponent
              tabsValue={tabsValue}
              components={
                displayReleases && displayBeats
                  ? [
                      <>
                        <div className="library-usericon">
                          {!loading && <TrackIcon
                            arr={trackarr}
                            className="all-icon-mobile uni-pl-24 full-width-child"
                            className2="track-icon-mobile-view"
                          />}
                        </div>
                      </>,
                      <>
                        <div className="library-usericon">
                        {!loading && <BeatesIcon
                            arr={beatsarr}
                            className="all-icon-mobile uni-pl-24 full-width-child"
                            className2="track-icon-mobile-view"
                          />}
                        </div>
                      </>,
                    ]
                  : displayReleases
                  ? [
                      <>
                        <div className="library-usericon">
                        {!loading &&<TrackIcon
                            arr={trackarr}
                            className="all-icon-mobile uni-pl-24 full-width-child"
                            className2="track-icon-mobile-view"
                          />}
                        </div>
                      </>,
                    ]
                  : displayBeats
                  ? [
                      <>
                        <div className="library-usericon">
                        {!loading && <BeatesIcon
                            arr={beatsarr}
                            className="all-icon-mobile uni-pl-24 full-width-child"
                            className2="track-icon-mobile-view"
                          />}
                        </div>
                      </>,
                    ]
                  : []
              }
            />
          </>
        ) : (
          ""
        )}
      </div>
      {button === "user-profile" ? (
        <>
          {bioGraphy ? (
            <>
              <div className="user-profile-subheading uni-mt-56 mobile-mt-16 ">
                Biography
              </div>
              <div className="user-profile-para uni-mb-56 mobile-mb-16 bio-para-text">
                {bioGraphy}{" "}
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ) : null}

      {button === "user-profile" ? (
        <>
          {displayReleases ? (
            <>
              <div
                className="user-profile-subheading-link"
                onClick={() => handleButtonClick("tracks")}
              >
                Top Tracks
              </div>

              <div className="user-profile-usericon">
              {!loading &&<TrackIcon
                  arr={
                    button === "user-profile" ? trackarr.slice(0, 6) : trackarr
                  }
                  className="all-icon-mobile uni-pl-24 full-width-child"
                  className2="track-icon-mobile-view"
                />}
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ) : null}
      {button === "user-profile" ? (
        <>
          {displayReleases ? (
            <>
              <div
                className="user-profile-subheading-link"
                onClick={() => handleButtonClick("tracks")}
              >
                Recent Releases
              </div>

              <div className="user-profile-usericon">
              {!loading &&<ProjectIcon
                  arr={
                    button === "user-profile"
                      ? recentReleases.slice(0, 6)
                      : recentReleases
                  }
                  className="all-icon-mobile uni-pl-24 full-width-child"
                  className2="track-icon-mobile-view"
                />}
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ) : null}

      {button === "user-profile" ? (
        <>
          {displayBeats ? (
            <>
              <div
                className="user-profile-subheading-link"
                onClick={() => handleButtonClick("beats")}
              >
                Beats
              </div>

              <div className="user-profile-usericon">
              {!loading && <BeatesIcon
                  arr={
                    button === "user-profile" ? beatsarr.slice(0, 6) : beatsarr
                  }
                  className="all-icon-mobile uni-pl-24 full-width-child"
                  className2="beats-icon-mobile-view"
                />}
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ) : null}
    </div>
  );
};
