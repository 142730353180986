import { FC, useState } from "react";
import view from "./view";
import { useDispatch } from "react-redux";
import { setPinBy } from "../../../../../redux/reducer/auth";

export const SelectPinTypeModal: FC<any> = () => {
  const dispatch = useDispatch()
  const [projectreportReason, setProjectReportReason] = useState<string>("");
  const reportedProject = async (sendReason: string) => {
    dispatch(setPinBy(sendReason));
  };

  return (view(reportedProject, projectreportReason, setProjectReportReason));
}
export default SelectPinTypeModal