import { TextfieldSM } from "../../../../../common/custom-field/dropstock-text";
import { DropworksRadioButton } from "../../../../../common/custom-field/dropworks-radio-button";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import {
  Heading3,
  Heading4,
} from "../../../../../common/custom-field/dropstock-heading";
import { DropworksRadioCheckbox } from "../../../../../common/custom-field/dropworks-radio-checkbox";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";

const view = (
  fetchTrackBeats: any,
  select: any,
  setSelect: any,
  changeSelect: any,
  chatCount: any
) => {
  const handleSelect = (option: string, type: string) => {
    changeSelect(option, type);
    setSelect(option);
  };
  const openModal = () => {
    setSelect("");
  };

  return (
    <ModalWrapper modalId="ReportModal1" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="ReportModal1"
        aria-hidden="true"
        aria-labelledby="ReportModal1"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header uni-mb-40">
              <Heading4 className="modal-title-fs-32">
                What do you want to report
              </Heading4>
            </div>
            <div className="modal-body ps-0 pt-0 pb-0  uni-mb-56">
              <p
                className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8 popup-space-mobile"
                onClick={() => handleSelect("SelectTrack", "track")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  A specific track
                </TextfieldSM>
                <DropworksRadioCheckbox checked={"SelectTrack" === select} />
              </p>
              <p
                className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8 popup-space-mobile"
                onClick={() => handleSelect("SelectProject", "project")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  A specific project
                </TextfieldSM>
                <DropworksRadioCheckbox checked={"SelectProject" === select} />
              </p>
              <p
                className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8 popup-space-mobile"
                onClick={() => handleSelect("SelectBeat", "beat")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  A specific beat
                </TextfieldSM>
                <DropworksRadioCheckbox checked={"SelectBeat" === select} />
              </p>
              {chatCount > 0 ? (
                <p
                  className="d-flex justify-content-between uni-p-16 p-lh uni-mb-8 popup-space-mobile "
                  onClick={() => changeSelect("ReportRecentChatModal")}
                  style={{ cursor: "pointer" }}
                >
                  <TextfieldSM className=" font-col-wh my-0 mx-0">
                    Recent messages this user sent you
                  </TextfieldSM>
                  <DropworksRadioCheckbox
                    checked={"ReportRecentChatModal" === select}
                  />
                </p>
              ) : (
                ""
              )}
              <p
                className="d-flex justify-content-between uni-p-16 p-lh popup-space-mobile "
                onClick={() => changeSelect("UserReportReasonModal")}
                style={{ cursor: "pointer" }}
              >
                <TextfieldSM className=" font-col-wh my-0 mx-0">
                  Something about this user
                </TextfieldSM>
                <DropworksRadioCheckbox
                  checked={"UserReportReasonModal" === select}
                />
              </p>
            </div>
            <div className="modal-footer mbl-modal-footer ps-0 pt-0 pb-0">
              <CloseModalButton className="mbl-anchor-full-btn">
                <DropworksSmallButton className="btn-wid mbl-full-width-btn btn-bg-gray3 my-0 ml-0 uni-mr-16 bg-dark">
                  Cancel
                </DropworksSmallButton>
              </CloseModalButton>
              <ButtonComponent id={select || "disabled"} className="mbl-anchor-full-btn">
                <DropworksSmallButton
                  className="btn-wid m-0 mbl-full-width-btn"
                  onClick={fetchTrackBeats}
                  disabled={!select}
                >
                  Next
                </DropworksSmallButton>
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default view;
