import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { FC, useRef, ReactNode, useState, useEffect, forwardRef } from "react";
import "./styles.scss";
import { viewType } from "./modal";
import { Footer } from "../footer";
import iconimage from "../../../../assets/img/dropworklogo.png";
import {
  DropworksSecondaryButton,
  DropworksSmallButton,
} from "../../../../common/custom-field/dropworks-button";
import { IoSearchSharp } from "react-icons/io5";
import { MdKeyboardArrowDown } from "react-icons/md";
import { AiOutlineMenu } from "react-icons/ai";
import BellIcon from "../../../../assets/steps/bell.svg";
import NotificationIcon from "../../../../assets/steps/envelope.svg";
import UserIcon from "../../../../assets/steps/Group 18414.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { changeMenu, logoutUser } from "../../utils/localStorage";
import { setNotificationAfterClick } from "../../../../redux/reducer/uploadSlice";
import { clearAllNotifiactionByType } from "../../utils/apiServices";
import { openPopupRedux } from "../../../../redux/reducer/modal";
import { AppDispatch } from "../../../../redux/store";
import ParentPopUp from "../ParentPopUp";

interface PopUpId {
  popupId?: string;
}

const view: FC<viewType> = ({
  navigateTo,
  isLoggedin = false,
  profileImg = "",
  displayName,
}) => {
  const userData = useSelector((state: RootState) => state.user);
  const [menuOpen, setMenuOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { notificationData } = useSelector((state: RootState) => state.upload);
  const navigate = useNavigate();
  const {showMessageYellowDot , messageCount}=useSelector((state:RootState)=>state.messageChat)

  const handleClickOutside = (event: any) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  const toggleDropdown = (e:any) => {
    e.stopPropagation(); // Prevent the click from triggering navigation
    setIsDropdownOpen((prev) => !prev);
  };

  const handleProductClick = () => {
    navigate("/marketplace-products");
  };

  useEffect(() => {
    // Add event listener for outside clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up event listener on unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    // Add/remove the no-scroll and blur effect when menu is open
    if (menuOpen) {
      document.body.classList.add("no-scroll");

      // Apply blur to both elements with IDs 'main-content' and 'main-content2'
      document.getElementById("main-content")?.classList.add("blur");
      document.getElementById("main-content2")?.classList.add("blur");
    } else {
      document.body.classList.remove("no-scroll");

      // Remove blur from both elements
      document.getElementById("main-content")?.classList.remove("blur");
      document.getElementById("main-content2")?.classList.remove("blur");
    }

    // Clean up on component unmount
    return () => {
      document.body.classList.remove("no-scroll");
      document.getElementById("main-content")?.classList.remove("blur");
      document.getElementById("main-content2")?.classList.remove("blur");
    };
  }, [menuOpen]);

  useEffect(() => {
    setMenuOpen(false); // Close the sidebar when the route changes
  }, [location]);

  useEffect(() => {
    const pagesItem = document.querySelector(".home-nav-ul-studio");
    if (pagesItem) {
      pagesItem.addEventListener("mouseenter", handlePagesHover);
    }
    return () => {
      if (pagesItem) {
        pagesItem.removeEventListener("mouseenter", handlePagesHover);
      }
    };
  }, []);

  const handlePagesHover = () => {
    const galleryLink = document.querySelector(".pj-1 a") as HTMLAnchorElement;
    if (galleryLink) {
      galleryLink?.blur(); // Remove focus from Gallery + when hovering over Pages +
    }
    const galleryLink2 = document.querySelector(".pj-2 a") as HTMLAnchorElement;
    if (galleryLink2) {
      galleryLink2?.blur(); // Remove focus from Gallery + when hovering over Pages +
    }
  };
  function updateInviteData(data: any, type: string) {
    const updatedData = {
      ...data,
      [type]: {
        // Use dynamic key based on 'type'
        ...data[type],
        inviteCount: 0,
        items: data.invites.items.map((item: any, index: number) =>
          index === index ? { ...item, read_count: 0 } : { ...item }
        ),
      },
      allCount:
        data.allCount > 0 &&
        data.invites.items.map((item: any, index: number) =>
          index === index ? { ...item, read_count: 0 } : { ...item }
        )
          ? data.allCount - data.invites.inviteCount
          : data.allCount,
    };
    return updatedData;
  }
  const dispatch = useDispatch();
  const clearAllNotification = async () => {
    const type = "invites";
    const data = {
      type: type,
    };
    let newNotification = updateInviteData(notificationData, type);
    dispatch(setNotificationAfterClick(newNotification));
    const response = await clearAllNotifiactionByType(data);
    if (response.success) {
    } else {
    }
  };

  return (
    <>
      <ParentPopUp />

      {window.location.pathname === "/profile" && (
        <div
          className="newstudioheader-dynamic-image studio-header newstudioheader-backgrounduserimg"
          style={{
            backgroundImage: `url(${
              userData?.bannerImg || userData?.profileImg
            })`,
            maskSize: `100%`,
            maskImage: "linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0))",
            WebkitMaskImage: "linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0))",
            // WebkitMaskImage:  `url(${
            //   `${maskImage}`
            // })`,
            // maskImage:  `url(${
            //   `${maskImage}`
            // })`,
            WebkitMaskSize: `100%`,
            maskPosition: `center bottom`,
            WebkitMaskPosition: `center bottom`,
          }}
        ></div>
      )}

      <div className="task container-fluid px-0">
        <div
          className={`home-main-nav ${
            window.location.pathname === "/PageNotFound" && "bg-black"
          }`}
          id="main-content"
        >
          <div
            onClick={() => setMenuOpen(true)}
            className="home-nav-sidevar-menu  cursor-pointer"
          >
            <AiOutlineMenu />
          </div>
          <span className="home-nav-image">
            <Link to="/">
              <img src={iconimage} />
            </Link>
          </span>
          <div
            className="home-nav-sidevar-search  cursor-pointer"
            onClick={navigateTo("/search")}
          >
            <IoSearchSharp className="cursor-pointer" />
          </div>
          <>
            <nav className="home-nav">
              <ul className="home-nav-ul">
                <li className="home-nav-ul-marketplace">
                  <Link to="/marketplace-products">
                    Marketplace{" "}
                    <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                  </Link>
                  <div className="dropdown-container-header">
                    <ul className="home-nav-dropdown home-nav-ul-marketplace-list">
                      <li>
                        <Link to="/marketplace-products">
                          {" "}
                          <div className="uni-p-18">
                            Product{" "}
                            <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                          </div>
                        </Link>
                        <ul className="home-nav-dropdown2">
                          <li
                            className="cursor-pointer"
                            onClick={navigateTo(
                              "/marketplace-dropworks-composite"
                            )}
                          >
                            <Link to="/marketplace-dropworks-composite">
                              DropWorks Composite{" "}
                            </Link>
                          </li>
                          <li
                            className="cursor-pointer"
                            onClick={navigateTo(
                              "/marketplace-dropworks-select"
                            )}
                          >
                            <Link to="/marketplace-dropworks-select">
                              DropWorks Select{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* <li>
                        <Link to="#">
                          <div className="uni-p-18">
                            Trade{" "}
                            <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                          </div>
                        </Link>
                        <ul className="home-nav-dropdown2">
                          <li
                            className="cursor-pointer"
                            onClick={navigateTo("/dropworks-composite")}
                          >
                            <Link to="/dropworks-composite">
                              DropWorks Composite{" "}
                            </Link>
                          </li>
                          <li
                            className="cursor-pointer"
                            onClick={navigateTo("/dropworks-select")}
                          >
                            <Link to="/dropworks-select">
                              DropWorks Select{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/personal-portfolio">
                          <div className="uni-p-18">Portfolio </div>
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </li>
                <li className="home-nav-ul-studio">
                  <Link to="/featured">
                    Studio{" "}
                    <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                  </Link>
                  <ul className="home-nav-dropdown home-nav-hover-effect  home-nav-ul-studio-list">
                    <li>
                      <Link to="/featured">
                        {" "}
                        <div className="uni-p-18">Featured </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/library">
                        {" "}
                        <div className="uni-p-18">Library </div>
                      </Link>
                    </li>
                    <li onClick={clearAllNotification}>
                      <Link to="/invite">
                        {" "}
                        <div className="uni-p-18">
                        Invites
                          {notificationData?.invites?.items?.some(
                            (data: any) => 1 === data?.read_count
                          ) ? (
                            <span className="notify-yellow-dot uni-ml-8"></span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/create">
                        {" "}
                        <div className="uni-p-18">Create </div>
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <Link to="">Bookings</Link>
                </li> */}

                <li>
                  <Link to="search">
                    <IoSearchSharp className="cursor-pointer" />
                  </Link>
                </li>
              </ul>
            </nav>

            {isLoggedin ? (
              <div className="nav-right  studio-nav-image">
                <ul>
                  <li className="mar-mr-12">
                    <Link to="/notification" className="position-relative">
                      <img src={BellIcon} />
                      {notificationData?.allCount > 0 ? (
                        <span className="notify-yellow-dot header-yellow-dot uni-ml-8"></span>
                      ) : (
                        ""
                      )}
                    </Link>
                  </li>
                  <li>
                    <Link to="/message-chat" className="position-relative">
                      <img src={NotificationIcon} />
                  {Boolean(messageCount) && <span className="notify-yellow-dot header-yellow-dot uni-ml-8"></span>}

                    </Link>
                  </li>

                  <li className="uni-mx-24 truncate-text profile-main">
                    <Link to="/profile">{displayName || "#USERNAME"}</Link>

                    <ul className="home-nav-dropdown-profile  profile-main-list home-nav-hover-effect-profile text-center ml-50">
                      <li onClick={changeMenu}>
                        <Link to="/wallet">
                          {" "}
                          <div className="uni-p-18">Wallet </div>
                        </Link>
                      </li>
                      <li onClick={changeMenu}>
                        <Link to="/profile">
                          {" "}
                          <div className="uni-p-18">Profile </div>
                        </Link>
                      </li>
                      <li onClick={changeMenu}>
                        <Link to="/profile-setting">
                          {" "}
                          <div className="uni-p-18">Settings </div>
                        </Link>
                      </li>
                      <li onClick={changeMenu}>
                        <Link to="/favorites">
                          {" "}
                          <div className="uni-p-18">Favorites </div>
                        </Link>
                      </li>
                      {/* <li onClick={changeMenu}>
                        <Link to="#">
                          {" "}
                          <div className="uni-p-18">Demo Account </div>
                        </Link>
                      </li> */}
                      <li onClick={changeMenu}>
                        <Link to="/" onClick={()=>logoutUser(dispatch)}>
                          {" "}
                          <div className="uni-p-18">Logout </div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/profile">
                      <img
                        className="profile-img"
                        src={profileImg || UserIcon}
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            ) : (
              <div className="home-nav-buttons">
                <DropworksSmallButton
                  className="mb-0 home-nav-buttons-login"
                  onClick={navigateTo("/login")}
                >
                  Login
                </DropworksSmallButton>
                <DropworksSecondaryButton
                  className="mb-0 home-nav-buttons-signup"
                  onClick={navigateTo("/sign-up")}
                >
                  Signup
                </DropworksSecondaryButton>
              </div>
            )}
          </>
        </div>
        <div
          className={`home-nav-sidevar ${menuOpen ? "open" : ""}`}
          ref={sidebarRef}
        >
          {isLoggedin ? (
            <div className="home-nav-sidevar-profile">
              <ul>
                <span>
                  <li>
                    <Link to="/profile">
                      {/* <img src={UserIcon} className="user-img-sidevar" /> */}
                      <img
                        src={profileImg || UserIcon}
                        className="user-img-sidevar profile-img"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link to="/profile">{displayName || "#USERNAME"}</Link>
                  </li>
                </span>
                <span>
                  <li>
                    <Link to="/notification">
                      <img src={BellIcon} />
                    </Link>
                  </li>
                  <li>
                    <Link to="/message-chat">
                      <img src={NotificationIcon} />
                    </Link>
                  </li>
                </span>
              </ul>
            </div>
          ) : (
            <div className="home-nav-sidevar-buttons">
              <DropworksSmallButton
                className=" home-nav-sidevar-buttons-login uni-mb-16"
                onClick={navigateTo("/login")}
              >
                Login
              </DropworksSmallButton>
              <DropworksSecondaryButton
                className="mb-0 home-nav-sidevar-buttons-signup"
                onClick={navigateTo("/sign-up")}
              >
                Signup
              </DropworksSecondaryButton>
            </div>
          )}
          <div className="home-nav-sidevar-menu1">
            <ul className="home-nav-sidevar-ul">
              <li>
                <Link to="/marketplace-products" className="font-wh-sidevar">
                  Marketplace
                </Link>
              </li>
              <li>
      <span tabIndex={0}>
        <span onClick={handleProductClick} style={{ cursor: "pointer" }}>
          Products
        </span>
        <MdKeyboardArrowDown
          className="uni-ml-8 dropdown-arrow-header"
          onClick={toggleDropdown}
          style={{ cursor: "pointer" }}
        />
      </span>
      {isDropdownOpen && (
        <ul className="home-nav-sidevar-dropdown">
          <li onTouchStart={() => navigate("/marketplace-dropworks-composite")}>
            <Link to="/marketplace-dropworks-composite">
              DropWorks Composite
            </Link>
          </li>
          <li onTouchStart={() => navigate("/marketplace-dropworks-select")}>
            <Link to="/marketplace-dropworks-select">DropWorks Select</Link>
          </li>
        </ul>
      )}
    </li>
              {/* <li>
                <span tabIndex={0}>
                  Trade{" "}
                  <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                </span>
                <ul className="home-nav-sidevar-dropdown">
                  <li onTouchStart={() => navigate("/dropworks-composite")}>
                    <Link to="/dropworks-composite">DropWorks Composite</Link>
                  </li>
                  <li onTouchStart={() => navigate("/dropworks-select")}>
                    <Link to="/dropworks-select">DropWorks Select</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/personal-portfolio">Portfolio </Link>
              </li> */}
              <li>
                <Link to="/featured" className="font-wh-sidevar">
                  Studio{" "}
                </Link>
              </li>
              <li>
                <Link to="/featured">Featured </Link>
              </li>
              <li>
                <Link to="/library">Library </Link>
              </li>
              <li>
                <Link to="/invite">Invites </Link>
              </li>
              <li>
                <Link to="/create">Create </Link>
              </li>
              <hr className="hr-sidevar" />
              <li className="pt-0">
                <Link to="/wallet">Wallet </Link>
              </li>
              <li>
                <Link to="/profile">Profile </Link>
              </li>
              <li>
                <Link to="/profile-setting">Settings </Link>
              </li>
              <li>
                <Link to="/favorites">Favorites </Link>
              </li>
              {/* <li>
                <Link to="#">Demo Account </Link>
              </li> */}
              {isLoggedin ? (
                <li>
                  <Link to="/" onClick={()=>logoutUser(dispatch)}>
                    Logout
                  </Link>
                </li>
              ) : null}
            </ul>
          </div>
        </div>

        <div className="task_outlet d-flex " id="main-content2">
          <Outlet />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default view;

interface ButtonComponentProps {
  id: string;
  name?: string;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
}
interface ButtonComponentPropsClose {
  name?: string;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
}

export const ButtonComponent = forwardRef<
  HTMLAnchorElement,
  ButtonComponentProps
>(({ id, name, children, className, disabled = false }, ref) => {
  const localRef = useRef<HTMLAnchorElement>(null); // Local ref to handle click if no ref is provided
  const buttonRef = ref || localRef;
  const dispatch = useDispatch<AppDispatch>();
  return (
    <a
      ref={buttonRef}
      className={`open-modal m-0  ${className}`}
      // data-bs-toggle={disabled ? "" : "modal"}
      // href={disabled ? "#" : `#${id}`}
      role="button"
      onClick={() => {
        if (!disabled || !id) {
          dispatch(openPopupRedux(id));
        }
      }}
    >
      {children || name}
    </a>
  );
});

export const CloseModalButton = forwardRef<
  HTMLAnchorElement,
  ButtonComponentPropsClose
>(({ name, children, className }, ref) => {
  const buttonRef = useRef<HTMLAnchorElement>(null);
  const combinedRef = ref || buttonRef; // Use the provided ref or local ref
  return (
    <a
      ref={combinedRef}
      className={`open-modal m-0 ${className}`}
      data-bs-dismiss="modal" // Bootstrap attribute to dismiss the modal
      role="button"
    >
      {children || name}
    </a>
  );
});

export function closeModal() {
  // Find the open modal element
  const openModal = document.querySelector(".modal.show");

  if (openModal) {
    // Find the close button inside the open modal
    const closeButton = openModal.querySelector('[data-bs-dismiss="modal"]');

    if (closeButton) {
      // Trigger a click on the close button to close the modal
      (closeButton as HTMLElement).click();
    }
  }
}

export const openPopup = (popUpId: string, dispatch?: AppDispatch) => {
  if (popUpId && dispatch) {
    dispatch(openPopupRedux(popUpId));
  }

  // const modalElement = document.getElementById(popUpId);

  // if (modalElement) {
  //   // Create or retrieve an existing modal instance and open it
  //   let modalInstance = (window as any).bootstrap.Modal.getInstance(modalElement);
  //   if (!modalInstance) {
  //     modalInstance = new (window as any).bootstrap.Modal(modalElement);
  //   }
  //   modalInstance.show();
  // }
};
