import React, { FC, useState } from "react";
import "./styles.scss";
import { RiQuestionFill } from "react-icons/ri";
import CustomTooltip from "../../custom-tooltip/view";

interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  hideIcon?: boolean;
  className2?: string | undefined;
  maxCharacter?: any;
  value?: any;
}
export const view: FC<TextareaProps> = ({
  className = "",
  hideIcon = false,
  className2,
  maxCharacter,
  value,
  ...rest
}) => {
  const [char, setChar] = useState(value);
  const remainingChars = maxCharacter
    ? maxCharacter - value?.length
    : undefined;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setChar(e.target.value);
    if (rest.onChange) {
      rest.onChange(e); // Propagate the onChange event if provided
    }
  };

  return (
    <>
      <div className={`custom_inp_icon_container  ${className2}`}>
        <textarea
          className={` custom_textarea_inp ${className}`}
          {...rest}
          maxLength={maxCharacter}
          value={value}
          onChange={handleChange}
        />
        {!hideIcon && (
          <span className="question-icon">
            <CustomTooltip
              title="Small biography that will be displayed on your profile. In case you do not want to display it then just leave it blank. Can be changed in the settings."
              position="right"
            >
              <RiQuestionFill className="hover-icon-color" />
            </CustomTooltip>
          </span>
        )}
      </div>
      {maxCharacter !== undefined && (
        <p
          className={`ds-ctm-txt r-text m-0 uni-mb-16 ${
            maxCharacter - char?.length === 0 ? "text-danger" : "gray4"
          }`}
        >
          {maxCharacter - char?.length >= 0 ? (
            <>{remainingChars} characters remaining</>
          ) : (
            <>{maxCharacter} characters remaining</>
          )}
        </p>
      )}
    </>
  );
};
