import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import "./styles.scss";
import { DropworksPassword } from "../../../../../common/custom-field/dropworks-password";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";

import { FC } from "react";
import { useForm } from "react-hook-form";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import { changePinForm } from "../../../../content/guest-pages/login/modal";
import { TextfieldXSM } from "../../../../../common/custom-field/dropstock-text";

export const view: FC<changePinForm> = ({
  errors,
  isSubmitted,
  loading,
  register,
  onSubmit,
  handleSubmit,
  closeModalRef,
  openModal,
}) => {
  const { watch } = useForm();

  const newPassword = watch("pin");
  const confirmPassword = watch("newPin");

  // Determine if all fields are filled
  const allFieldsFilled = newPassword && confirmPassword;

  return (
    <ModalWrapper modalId="ChangePinModal" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="ChangePinModal"
        aria-hidden="true"
        aria-labelledby="ChangePinModal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-width">
          <div className="modal-content ">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header ps-0 pt-0 uni-pr-72">
              <Heading3 className="modal-title ">New Pin</Heading3>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
                <TextfieldXSM className="uni-mb-56 ds-ctm-txt">
                  Remember, never share your PIN with anyone
                </TextfieldXSM>
                <div className="custom-confirm-pass-space">
                  <DropworksPassword
                    {...register("pin")}
                    placeholder="New Pin"
                    maxLength={6}
                  />
                  {errors.pin && (
                    <p className="invalid-feedback error-red">
                      {errors.pin.message}
                    </p>
                  )}
                </div>
                <div className="uni-mb-56 custom-confirm-pass-space">
                  <DropworksPassword
                    {...register("newPin")}
                    placeholder="Confirm New Pin"
                    maxLength={6}
                  />
                  {errors.newPin && (
                    <p className="invalid-feedback error-red">
                      {errors.newPin.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="modal-footer mbl-modal-footer ps-0 pt-0 pb-0">
                {loading ? (
                  <Loader className="uni-mt-16" />
                ) : (
                  <>
                    <ButtonComponent id="VerifyPin" className="mbl-anchor-full-btn">
                      <CloseModalButton >
                        <DropworksSmallButton className="btn-wid  mbl-full-width-btn btn-gray3 my-0 ml-0 uni-mr-16 btn-bg-gray3">
                          Back
                        </DropworksSmallButton>
                      </CloseModalButton>
                    </ButtonComponent>{" "}
                    <DropworksSmallButton
                      className={
                        allFieldsFilled
                          ? "btn-wid  modal-btns m-0 custom-inactive-btn mbl-full-width-btn text-secondary disable-interactions"
                          : "btn-wid  modal-btns m-0"
                      }
                      type="submit"
                    >
                      Finish
                    </DropworksSmallButton>
                  </>
                )}
              </div>
            </form>
            <CloseModalButton ref={closeModalRef} className="d-none">
              close
            </CloseModalButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
