import {
  DropworksDisableButton,
  DropworksSmallButton,
} from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import "./styles.scss";
import { DropworksPassword } from "../../../../../common/custom-field/dropworks-password";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import { useRef, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePassword, verifyPin } from "../../../utils/apiServices";
import { useNavigate } from "react-router-dom";
import { openPopup } from "../../logo-header/view";
import { setValidateValue } from "../../../../../redux/reducer/auth";
import { RootState } from "../../../../../redux/store";

import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { choosePinBy } from "../../../../content/guest-pages/login/modal";
import { useDispatch, useSelector } from "react-redux";

export const view= () => {

  const dispatch=useDispatch();
  const { changePinBy } = useSelector((state: RootState) => state.auth)
  const [loading, setLoading] = useState(false);
  const closeModalRef = useRef<HTMLAnchorElement>(null);
  const navigate = useNavigate();
  const navigateTo = (value: string) => () => {
    navigate(value);
  };
  const schema4 = yup.object({
        privateKey: yup.string().required("Private Key is required"),
      });

  

  const {
    register,
    handleSubmit,
    setError,
    unregister,
    reset,
    clearErrors,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm<any>({
    resolver: yupResolver(schema4),
    mode: "onSubmit",
  });

  const handleCloseModalClick = () => {
    if (closeModalRef.current) {
      closeModalRef.current.click();
    }
  };

  const onSubmit = async (data: any) => {
    const { privateKey } = data
    if (privateKey) {
      setLoading(true)
      const response: any = await verifyPin({ data });
      if (response?.success) {
        dispatch(setValidateValue({ ...data })); // Clone data before dispatch
        openPopup("ChangePinModal", dispatch);
        setLoading(false);
        handleCloseModalClick()
      } else {
        setLoading(false);
          setError(response.type || "privateKey", { type: "custom", message: response.message });
      }
    }
  };

  const openModal = () => {
    setLoading(false);
    reset()
  }




  return (
    <ModalWrapper modalId="VerifyPinPrivateKey" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="VerifyPinPrivateKey"
        // aria-hidden="true"
        aria-labelledby="VerifyPinPrivateKey"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-width">
          <div className="modal-content ">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header ps-0 pt-0 uni-pr-72">
              <Heading3 className="modal-title">Change Pin</Heading3>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
                <TextfieldLG className="uni-mt-16 ctm-txt ctm-md-lh">
                  Enter {changePinBy?.toLocaleLowerCase()} to change your PIN.
                </TextfieldLG>
                <TextfieldXSM className="uni-mb-56 ds-ctm-txt">
                  Remember, never share your PIN with anyone
                </TextfieldXSM>
      
                    <div className="uni-mb-56 custom-confirm-pass-space">
                      <DropworksPassword
                        {...register("privateKey")}
                        placeholder="Private Key"
                        className="mb-0 relative login-specing "
                        className3={isSubmitted}
                        className2={`uni-mt-16 form-control login-cstm-pass p-0  ${
                          isSubmitted &&
                          (errors.privateKey
                            ? "is-invalid b-red-2"
                            : !errors.privateKey
                            ? "is-valid   b-green-2"
                            : "")
                        }`}
                      />
                      {errors.privateKey && typeof errors?.privateKey?.message === "string" && (
                        <p className="invalid-feedback error-red">
                          {errors.privateKey.message}
                        </p>
                      )}
                    </div>
              </div>
              <div className="modal-footer social-mobile-modal-footer   ps-0 pt-0 pb-0 uni-pr-72">
                {loading ? (
                  <Loader className="uni-mt-16"/>
                ) : (
                  <>
                    <ButtonComponent id="SelectPinTypeModal" className='buttoncomponent-mobile-modle'>
                      <DropworksSmallButton  className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 social-link-modal-btns">
                        Back
                      </DropworksSmallButton>
                    </ButtonComponent>{" "}
                    <DropworksSmallButton
                      className="btn-wid m-0 my-0 social-link-modal-btns"
                      type="submit"
                    >
                      Next
                    </DropworksSmallButton>
                  </>
                )}
              </div>
            </form>
            <CloseModalButton ref={closeModalRef} className="d-none">
              close
            </CloseModalButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
