import { useNavigate } from "react-router-dom"
import { view } from "./view"
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchPersonalPortfolio } from "../../../redux/reducer/uploadSlice";

export const PersonalPortfolio = () => {
    const navigate = useNavigate();
    const navigateTo = (value: string) => () => {
        navigate(value)
    }
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchDetails = async () => {

            dispatch(fetchPersonalPortfolio() as any)

        };
        fetchDetails();
    }, [])

    return (view({ navigateTo }))
}