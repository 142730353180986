import { FC, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import iconimage from "../../../../assets/img/dropworklogo.png";
import { IoSearchSharp } from "react-icons/io5";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Footer } from "../footer";
import BellIcon from "../../../../assets/steps/bell.svg";
import NotificationIcon from "../../../../assets/steps/envelope.svg";
import UserIcon from "../../../../assets/steps/Group 18414.svg";
import maskImage from "../../../../assets/img/temporary-images/beat_image_png.png";
import trackImage from "../../../../assets/img/temporary-images/track-image.svg";
import projectImage from "../../../../assets/img/temporary-images/project-image.svg";
import { viewType } from "./modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { AiOutlineMenu } from "react-icons/ai";
import {
  DropworksSecondaryButton,
  DropworksSmallButton,
} from "../../../../common/custom-field/dropworks-button";
import { changeMenu, logoutUser } from "../../utils/localStorage";
import { clearAllNotifiactionByType } from "../../utils/apiServices";
import { setNotificationAfterClick } from "../../../../redux/reducer/uploadSlice";
import ParentPopUp from "../ParentPopUp";
import MessageChatModal from "../pop-ups/message-chat-modal";

export const view: FC<viewType> = ({
  navigateTo,
  isLoggedin = false,
  displayName,
  profileImg = "",
}) => {
  const userData = useSelector((state: RootState) => state.user);
  const { notificationData } = useSelector((state: RootState) => state.upload);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const location2 = useLocation();
  const navigate = useNavigate();

  const projectBanner = useSelector(
    (state: RootState) => state.upload.projectBannerImg
  );
  const trackBanner = useSelector(
    (state: RootState) => state.upload.trackBannerImg
  );
  const beatBanner = useSelector(
    (state: RootState) => state.upload.beatData?.[0]?.art_work_image?.[0]?.src
  );

  const dispatch = useDispatch();

  const [menuOpen, setMenuOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const handleClickOutside = (event: any) => {
    // Check if click is outside the sidebar
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener for outside clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up event listener on unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    // Add/remove the no-scroll and blur effect when menu is open
    if (menuOpen) {
      document.body.classList.add("no-scroll");

      // Apply blur to both elements with IDs 'main-content' and 'main-content2'
      document.getElementById("main-content")?.classList.add("blur");
      document.getElementById("main-content2")?.classList.add("blur");
    } else {
      document.body.classList.remove("no-scroll");

      // Remove blur from both elements
      document.getElementById("main-content")?.classList.remove("blur");
      document.getElementById("main-content2")?.classList.remove("blur");
    }

    // Clean up on component unmount
    return () => {
      document.body.classList.remove("no-scroll");
      document.getElementById("main-content")?.classList.remove("blur");
      document.getElementById("main-content2")?.classList.remove("blur");
    };
  }, [menuOpen]);


  const toggleDropdown = (e:any) => {
    e.stopPropagation(); // Prevent the click from triggering navigation
    setIsDropdownOpen((prev) => !prev);
  };

  const handleProductClick = () => {
    navigate("/marketplace-products");
  };

  useEffect(() => {
    setMenuOpen(false); // Close the sidebar when the route changes
  }, [location2]);

  function updateInviteData(data: any, type: string) {
    const updatedData = {
      ...data,
      [type]: {
        // Use dynamic key based on 'type'
        ...data[type],
        inviteCount: 0,
        items: data.invites.items.map((item: any, index: number) =>
          index === index ? { ...item, read_count: 0 } : { ...item }
        ),
      },
      allCount:
        data.allCount > 0 &&
        data.invites.items.map((item: any, index: number) =>
          index === index ? { ...item, read_count: 0 } : { ...item }
        )
          ? data.allCount - data.invites.inviteCount
          : data.allCount,
    };
    return updatedData;
  }

  const clearAllNotification = async () => {
    const type = "invites";
    const data = {
      type: type,
    };
    let newNotification = updateInviteData(notificationData, type);
    dispatch(setNotificationAfterClick(newNotification));
    const response = await clearAllNotifiactionByType(data);
    if (response.success) {
    } else {
    }
  };

  // const handleTouchStart = (e:any) => {
  //   const touchStartX = e.touches[0].clientX;

  //   const handleTouchMove = (e:any) => {
  //     const touchEndX = e.touches[0].clientX;

  //     // Swipe right to open
  //     if (touchEndX > touchStartX + 50) {
  //       setMenuOpen(true);
  //       window.removeEventListener('touchmove', handleTouchMove);
  //     }

  //     // Swipe left to close
  //     if (touchEndX < touchStartX - 50) {
  //       setMenuOpen(false);
  //       window.removeEventListener('touchmove', handleTouchMove);
  //     }
  //   };

  //   window.addEventListener('touchmove', handleTouchMove);

  //   // Clean up the event listener
  //   window.addEventListener('touchend', () => {
  //     window.removeEventListener('touchmove', handleTouchMove);
  //   }, { once: true });
  // };

  // useEffect(() => {
  //   // Add touch start listener
  //   window.addEventListener('touchstart', handleTouchStart);
  //   return () => {
  //     // Clean up the listener
  //     window.removeEventListener('touchstart', handleTouchStart);
  //   };
  // }, []);
const {openModal}=useSelector((state:RootState)=>state.modal)

  return (
    <div className="newmarketplace">
{openModal === "MessageChatModal" && <MessageChatModal />}
      <ParentPopUp />
      {window.location.pathname === "/profile" && (
        <div
          className="newstudioheader-dynamic-image studio-header newstudioheader-backgrounduserimg"
          style={{
            backgroundImage: `url(${
              userData?.bannerImg || userData?.profileImg
            })`,
            maskSize: `100%`,
            // WebkitMaskImage:  `url(${
            //   `${maskImage}`
            // })`,
            // maskImage:  `url(${
            //   `${maskImage}`
            // })`,
            maskImage: "linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0))",
            WebkitMaskImage: "linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0))",
            WebkitMaskSize: `100%`,
            maskPosition: `center bottom`,
            WebkitMaskPosition: `center bottom`,
          }}
        ></div>
      )}
      {window.location.pathname?.includes("/beats-page") && (
        <div
          className="newmarketplace-dynamic-image-beats"
          style={{
            backgroundImage: `url(${
              `${process.env.REACT_APP_IMGURL}/${beatBanner}` || maskImage
            })`,
            // maskSize: `100%`,
            // WebkitMaskImage:  `url(${
            //   `${maskImage}`
            // })`,
            // maskImage:  `url(${
            //   `${maskImage}`
            // })`,
            maskImage: "linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0))",
            WebkitMaskImage: "linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0))",
            WebkitMaskSize: `100%`,
            maskPosition: `center bottom`,
            WebkitMaskPosition: `center bottom`,
          }}
        ></div>
      )}
      {window.location.pathname?.includes("/track-page") && (
        <div
          className="newmarketplace-dynamic-image-track"
          style={{
            backgroundImage: `url(${trackBanner || trackImage})`,
            maskSize: `100%`,
            // WebkitMaskImage:  `url(${
            //   `${maskImage}`
            // })`,
            // maskImage:  `url(${
            //   `${maskImage}`
            // })`,
            maskImage: "linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0))",
            WebkitMaskImage: "linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0))",
            WebkitMaskSize: `100%`,
            maskPosition: `center bottom`,
            WebkitMaskPosition: `center bottom`,
          }}
        ></div>
      )}
      {window.location.pathname?.includes("/project-page") && (
        <div
          className="newmarketplace-dynamic-image-project"
          style={{
            backgroundImage: `url(${projectBanner || projectImage})`,
            maskSize: `100%`,
            // WebkitMaskImage:  `url(${
            //   `${maskImage}`
            // })`,
            // maskImage:  `url(${
            //   `${maskImage}`
            // })`,
            maskImage: "linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0))",
            WebkitMaskImage: "linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0))",
            WebkitMaskSize: `100%`,
            maskPosition: `center bottom`,
            WebkitMaskPosition: `center bottom`,
          }}
        ></div>
      )}

      <div className="home-main-nav" id="main-content">
        <div
          onClick={() => setMenuOpen(true)}
          className="home-nav-sidevar-menu"
        >
          <AiOutlineMenu />
        </div>
        <span className="studio-nav-image">
          <Link to="/">
            <img src={iconimage} />
          </Link>
        </span>
        <div
          className="home-nav-sidevar-search"
          onClick={navigateTo("/search")}
        >
          <IoSearchSharp className="cursor-pointer" />
        </div>
        <nav className="home-nav">
          <ul className="home-nav-ul">
            <li>
              <Link to="/featured">Featured</Link>
            </li>
            <li>
              <Link to="/library">Library </Link>
            </li>
            <li onClick={clearAllNotification}>
              <Link to="/invite">
                Invites
                {notificationData?.invites?.inviteCount > 0 &&
                notificationData?.invites ? (
                  <span className="notify-yellow-dot uni-ml-8 "></span>
                ) : (
                  ""
                )}
              </Link>
            </li>
            <li>
              <Link to="/create">Create </Link>
            </li>
            <li className="home-nav-ul-marketplace">
              <Link to="/marketplace-products">
                Marketplace{" "}
                <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
              </Link>
              <div className="dropdown-container-header">
                <ul className="home-nav-dropdown home-nav-ul-marketplace-list">
                  <li onClick={navigateTo("/marketplace-products")}>
                    <Link to="#">
                      {" "}
                      <div className="uni-p-18" >
                        Product{" "}
                        <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                      </div>
                    </Link>
                    <ul className="home-nav-dropdown2">
                      <li
                        className="cursor-pointer"
                        onClick={navigateTo("/marketplace-dropworks-composite")}
                      >
                        <Link to="/marketplace-dropworks-composite">
                          DropWorks Composite{" "}
                        </Link>
                      </li>
                      <li
                        className="cursor-pointer"
                        onClick={navigateTo("/marketplace-dropworks-select")}
                      >
                        <Link to="/marketplace-dropworks-select">
                          DropWorks Select{" "}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* <li>
                    <Link to="#">
                      <div className="uni-p-18">
                        Trade{" "}
                        <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                      </div>
                    </Link>
                    <ul className="home-nav-dropdown2">
                      <li
                        className="cursor-pointer"
                        onClick={navigateTo("/dropworks-composite")}
                      >
                        <Link to="/dropworks-composite">
                          DropWorks Composite{" "}
                        </Link>
                      </li>
                      <li
                        className="cursor-pointer"
                        onClick={navigateTo("/dropworks-select")}
                      >
                        <Link to="/dropworks-select">DropWorks Select </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/personal-portfolio">
                      <div className="uni-p-18">Portfolio </div>
                    </Link>
                  </li> */}
                </ul>
              </div>
            </li>
            <li>
              <Link to="/search">
                <IoSearchSharp className="cursor-pointer" />
              </Link>
            </li>
          </ul>
        </nav>
        {isLoggedin ? (
          <div className="nav-right studio-nav-image">
            <ul>
              <li className="mar-mr-12">
                <Link to="/notification" className="position-relative">
                  <img src={BellIcon} />
                  {notificationData?.allCount > 0 ? (
                    <span className="notify-yellow-dot header-yellow-dot uni-ml-8"></span>
                  ) : (
                    ""
                  )}
                </Link>
              </li>
              <li>
                <Link to="/message-chat">
                  <img src={NotificationIcon} />
                </Link>
              </li>

              <li className="uni-mx-24 truncate-text profile-main">
                <Link to="/profile">{displayName || "#USERNAME"}</Link>

                <ul className="home-nav-dropdown-profile home-nav-hover-effect-profile profile-main-list text-center ml-50 ">
                  <li onClick={changeMenu}>
                    <Link to="/wallet">
                      {" "}
                      <div className="uni-p-18">Wallet </div>
                    </Link>
                  </li>
                  <li onClick={changeMenu}>
                    <Link to="/profile">
                      {" "}
                      <div className="uni-p-18">Profile </div>
                    </Link>
                  </li>
                  <li onClick={changeMenu}>
                    <Link to="/profile-setting">
                      {" "}
                      <div className="uni-p-18">Settings </div>
                    </Link>
                  </li>
                  <li onClick={changeMenu}>
                    <Link to="/favorites">
                      {" "}
                      <div className="uni-p-18">Favorites </div>
                    </Link>
                  </li>
                  <li onClick={changeMenu}>
                    <Link to="#">
                      {" "}
                      <div className="uni-p-18">Demo Account </div>
                    </Link>
                  </li>
                  <li onClick={changeMenu}>
                    <Link to="/" onClick={()=>logoutUser(dispatch)}>
                      {" "}
                      <div className="uni-p-18">Logout </div>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="profile-main">
                <Link to="/profile">
                  <img src={profileImg || UserIcon} className="profile-img" />
                </Link>
              </li>
            </ul>
          </div>
        ) : (
          <div className="home-nav-buttons">
            <DropworksSmallButton
              className="mb-0 home-nav-buttons-login"
              onClick={navigateTo("/login")}
            >
              Login
            </DropworksSmallButton>
            <DropworksSecondaryButton
              className="mb-0 home-nav-buttons-signup"
              onClick={navigateTo("/sign-up")}
            >
              Signup
            </DropworksSecondaryButton>
          </div>
        )}
      </div>
      <div
        className={`home-nav-sidevar ${menuOpen ? "open" : ""}`}
        ref={sidebarRef}
      >
        {isLoggedin ? (
          <div className="home-nav-sidevar-profile ">
            <ul>
              <span>
                <li>
                  <Link to="/profile">
                    <img
                      src={profileImg || UserIcon}
                      className="user-img-sidevar profile-img"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="/profile">{displayName || "#USERNAME"}</Link>
                </li>
              </span>
              <span>
                <li>
                  <Link to="/notification">
                    <img src={BellIcon} />
                  </Link>
                </li>
                <li>
                  <Link to="/message-chat">
                    <img src={NotificationIcon} />
                  </Link>
                </li>
              </span>
            </ul>
          </div>
        ) : (
          <div className="home-nav-sidevar-buttons">
            <DropworksSmallButton
              className=" home-nav-sidevar-buttons-login uni-mb-16"
              onClick={navigateTo("/login")}
            >
              Login
            </DropworksSmallButton>
            <DropworksSecondaryButton
              className="mb-0 home-nav-sidevar-buttons-signup"
              onClick={navigateTo("/sign-up")}
            >
              Signup
            </DropworksSecondaryButton>
          </div>
        )}
        <div className="home-nav-sidevar-menu1">
          <ul className="home-nav-sidevar-ul">
            <li>
              <Link to="/marketplace-products" className="font-wh-sidevar">
                Marketplace
              </Link>
            </li>
            {/* <li>
              <span tabIndex={0}>
                Products{" "}
                <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
              </span>
              <ul className="home-nav-sidevar-dropdown">
                <li
                  onTouchStart={() =>
                    navigate("/marketplace-dropworks-composite")
                  }
                >
                  <Link to="/marketplace-dropworks-composite">
                    DropWorks Composite
                  </Link>
                </li>
                <li
                  onTouchStart={() => navigate("/marketplace-dropworks-select")}
                >
                  <Link to="/marketplace-dropworks-select">
                    DropWorks Select
                  </Link>
                </li>
              </ul>
            </li> */}

<li>
      <span tabIndex={0}>
        <span onClick={handleProductClick} style={{ cursor: "pointer" }}>
          Products
        </span>
        <MdKeyboardArrowDown
          className="uni-ml-8 dropdown-arrow-header"
          onClick={toggleDropdown}
          style={{ cursor: "pointer" }}
        />
      </span>
      {isDropdownOpen && (
        <ul className="home-nav-sidevar-dropdown">
          <li onTouchStart={() => navigate("/marketplace-dropworks-composite")}>
            <Link to="/marketplace-dropworks-composite">
              DropWorks Composite
            </Link>
          </li>
          <li onTouchStart={() => navigate("/marketplace-dropworks-select")}>
            <Link to="/marketplace-dropworks-select">DropWorks Select</Link>
          </li>
        </ul>
      )}
    </li>
            {/* <li>
              <span tabIndex={0}>
                Trade{" "}
                <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
              </span>
              <ul className="home-nav-sidevar-dropdown">
                <li onTouchStart={() => navigate("/dropworks-composite")}>
                  <Link to="/dropworks-composite">DropWorks Composite</Link>
                </li>
                <li onTouchStart={() => navigate("/dropworks-select")}>
                  <Link to="/dropworks-select">DropWorks Select</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/personal-portfolio">Portfolio </Link>
            </li> */}
            <li>
              <Link to="/featured" className="font-wh-sidevar">
                Studio{" "}
              </Link>
            </li>
            <li>
              <Link to="/featured">Featured </Link>
            </li>
            <li>
              <Link to="/library">Library </Link>
            </li>
            <li onClick={clearAllNotification}>
              <Link to="/invite">
                Invites
                {notificationData?.invites?.inviteCount > 0 &&
                notificationData?.invites ? (
                  <span className="notify-yellow-dot uni-ml-8 "></span>
                ) : (
                  ""
                )}{" "}
              </Link>
            </li>
            <li>
              <Link to="/create">Create </Link>
            </li>
            <hr className="hr-sidevar" />
            <li className="pt-0">
              <Link to="/wallet">Wallet </Link>
            </li>
            <li>
              <Link to="/profile">Profile </Link>
            </li>
            <li>
              <Link to="/profile-setting">Settings </Link>
            </li>
            <li>
              <Link to="/favorites">Favorites </Link>
            </li>
            <li>
              <Link to="#">Demo Account </Link>
            </li>
            <li>
              <Link to="/" onClick={()=>logoutUser(dispatch)}>
                Logout{" "}
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div
        className={`newmarketplace_outlet ${
          window.location.pathname?.includes("/track-project-overview") &&
          "backdrop-none"
        }`}
        id="main-content2"
      >
        <Outlet />
        <Footer />
      </div>
      {/* {music.Data && <>
                <div
                    className="d-flex  w-100 "
                    style={{ background: "rgb(0 0 0)", height: "97px" }}
                ></div>
                <AudioPlayer />
            </>} */}
    </div>
  );
};
