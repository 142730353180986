import { FC, useRef, useState } from "react";
import "./styles.scss";
import playmusic from "../../assets/img/play-music-icon.png";
import { apionMusicPlay, getBeat } from "../../app/shared/utils/apiServices";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setData,
  setMusicPlayerData,
  setMusicTrack,
  setMusicTrackData,
} from "../../redux/reducer/musicSlice";
import { setOpenPopup } from "../../redux/reducer/reportSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import { RootState } from "../../redux/store";
import { TextfieldMD } from "../custom-field/dropstock-text";
export const view: FC<any> = ({
  arr,
  className,
  className2,
  showSwiper = false,
  isLibraryText=false
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const submitData = async (data: any) => {
    navigate(`/beats-page/${data}`);
  };
  const [isHovered, setIsHovered] = useState(false);
  const prevButtonRef = useRef<HTMLDivElement>(null);
  const nextButtonRef = useRef<HTMLDivElement>(null);
  const user = useSelector((state: RootState) => state.user);

  const playButtonClick = (e: any, data: any) => {
    e.stopPropagation();
    dispatch(setData(true));
    dispatch(setMusicTrackData(data?.fullBeatData));
    dispatch(setMusicTrack(data?.fullBeatData?.[0]?.audio_file?.[0]?.src));
    dispatch(setOpenPopup("beat"));
    dispatch(
      setMusicPlayerData({
        musicName: data?.fullBeatData?.[0]?.beat_name,
        musicAudioSrc: data?.fullBeatData?.[0]?.audio_file?.[0]?.src,
        musicImgSrc: data?.fullBeatData?.[0]?.art_work_image?.[0]?.thumbnail || data?.fullBeatData?.[0]?.art_work_image?.[0]?.src,
        showOptions: true,
        musicLiked: Boolean(data?.fullBeatData?.[0]?.favoriteData?._id),
        showMusicBar: true,
        musicCreator:[{receiver_name:data?.fullBeatData?.[0]?.user_id?.display_name,slug_name:data?.fullBeatData?.[0]?.user_id?.slug_name, receiver_user_id:data?.fullBeatData?.[0]?.user_id?._id}],
        musicOwnedByMe: data?.fullBeatData?.[0]?.user_id?._id === user.id,
        musicDescription: data?.fullBeatData?.[0]?.description,
        musicId: data?.fullBeatData?.[0]?._id,
        musicPath: `beats-page/${data?.fullBeatData?.[0]?.beat_slug_name}`,
        musicType: "beat",
      })
    );
    apionMusicPlay({ name: "beat_id", value: data.id });
  };
  const swiperRef = useRef<any>(null);
  return (
    <div className="icon-hover-use">
      {showSwiper && arr.length > 0 && (
        <>
          <div className="swiper-button-prev" ref={prevButtonRef}></div>
          <div className="swiper-button-next" ref={nextButtonRef}></div>
        </>
      )}
      <div
        className={`row ${className}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {!showSwiper ? (
          arr.length > 0 ? (
            <>
              {arr.map((data: any, index: any) => (
                <div
                  key={index}
                  className={`col-6 col-sm-6 col-md-3 col-lg-2 BeatesIcon-icon ${className2}`}
                >
                  <div
                    className="BeatesIcon-icon-container"
                    onClick={() => submitData(data.beatName)}
                  >
                    {data.image ? (
                      <img
                        className="BeatesIcon-icon-box"
                        src={`${process.env.REACT_APP_IMGURL}/${data.image}`}
                        alt={data.heading}
                      />
                    ) : null}
                    <img
                      className="BeatesIcon-icon-box-icon"
                      src={playmusic}
                      alt="Play"
                      onClick={(e) => playButtonClick(e, data)}
                    />
                  </div>
                  <div className="BeatesIcon-icon-heading">
                    {data.heading} &nbsp;
                  </div>
                  <div className="BeatesIcon-icon-subheading link-btm">
                      <TextfieldMD
                       className="underline-opening mb-0 mt-0 m-0"
                       onClick={()=>navigate(`/user-profile/${data.artistSlug}`)}>
                        {data.subheading}
                   </TextfieldMD>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <span className="no-data-found">
            {!isLibraryText ?'No beats available' :'Waiting for the beat to drop?'}</span>
          )
        ) : null}

        {showSwiper ? (
          arr.length > 0 ? (
            <Swiper
              modules={[Autoplay, Navigation]}
              autoplay={{ delay: 10000, disableOnInteraction: false }}
              navigation={{
                prevEl: prevButtonRef.current,
                nextEl: nextButtonRef.current,
              }}
              onBeforeInit={(swiper: any) => {
                swiper.params.navigation.prevEl = prevButtonRef.current;
                swiper.params.navigation.nextEl = nextButtonRef.current;
              }}
              spaceBetween={0}
              slidesPerView={2}
              speed={2000}
              loop={true}
              breakpoints={{
                640: { slidesPerView: 2 },
                768: { slidesPerView: 5 },
                1024: { slidesPerView: 6 },
                1440: { slidesPerView: 6 },
              }}
              onSwiper={(swiper: any) => {
                swiperRef.current = swiper;
              }}
              onAutoplayStart={(swiper: any) => console.log("Autoplay started")}
              onAutoplayStop={(swiper: any) => console.log("Autoplay stopped")}
              className="swiper-container"
            >
              {arr.map((data: any, index: any) => (
                <SwiperSlide
                  key={index}
                  onMouseEnter={() => swiperRef.current?.autoplay?.stop()}
                  onMouseLeave={() => swiperRef.current?.autoplay?.start()}
                  className={`col-6 col-sm-6 col-md-3 col-lg-2 BeatesIcon-icon ${className2}`}
                >
                  <div
                    className="BeatesIcon-icon-container"
                    onClick={() => submitData(data.beatName)}
                  >
                    {data.image ? (
                      <img
                        className="BeatesIcon-icon-box"
                        src={`${process.env.REACT_APP_IMGURL}/${data.image}`}
                        alt={data.heading}
                      />
                    ) : null}
                    <img
                      className="BeatesIcon-icon-box-icon"
                      src={playmusic}
                      alt="Play"
                      onClick={(e) => playButtonClick(e, data)}
                    />
                  </div>
                  <div className="BeatesIcon-icon-heading">
                    {data.heading} &nbsp;
                  </div>
                  <div className="BeatesIcon-icon-subheading link-btm">
                      <TextfieldMD
                        className="underline-opening mb-0 mt-0 m-0"
                        onClick={() => navigate(
                          `/user-profile/${data?.userDetails?.slug_name}`
                        )}
                      >
                        {data?.userDetails?.display_name}
                      </TextfieldMD>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <span className="no-data-found">
            No beats available</span>
          )
        ) : null}
      </div>
    </div>
    // </div>
  );
};
