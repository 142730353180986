import "./App.css";
import { BrowserRouter } from "react-router-dom";
import MainRoutes from "./app/routing/MainRoutes";
import "../src/styles/variablesstyles.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useRef } from "react";
import { generateToken, messaging } from "./notification/firebase";
import { onMessage } from "firebase/messaging";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./redux/store";
import {
  connectSocket,
  onInviteReceived,
  onMessageReceived,
} from "./app/shared/utils/socketServices";
import {
  fetchNotification,
  fetchReceviedInvite,
  fetchSentInvite,
  setSocketNotification,
} from "./redux/reducer/uploadSlice";
import { getLocalStorageObject } from "./app/shared/utils/localStorage";
import { setHeaderValue } from "./redux/reducer/auth";
import { AudioPlayer } from "./app/shared/components/audio-player";
import { setInvite, setSentInvite } from "./redux/reducer/inviteSlice";
import "../src/common-css/common-css.css";
import { ReceivedMessage } from "./app/content/messages-chat/modal";
import {

  setMessageRecievedProject,
  setShowMessageYellowDot,
  setShowMessageYellowDotProject,
  setMessageCount
} from "./redux/reducer/messageChatSlice";
import { showReportDialog } from "@sentry/react";
import { getMaintenanceDetails } from "./redux/reducer/maintenanceSlice";
import ScheduledMaintenance from "./app/content/scheduled-maintenance";

function App() {
  const { authentication } = useSelector((state: RootState) => state.auth);
  const music = useSelector((state: RootState) => state.music);
  const isMaintenance = useSelector(
    (state: RootState) => state.maintenance.isMaintenance
  );
  // const dispatch = useDispatch();
  const dispatch = useDispatch<AppDispatch>();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const { openModal } = useSelector((state: RootState) => state.modal);
  useEffect(() => {
    let auth_token = localStorage.getItem("dropworksToken");
    let device_token = localStorage.getItem("dw_fb_token");

    if (auth_token && !device_token) {
      generateToken();
      // onMessage(messaging, (payload) =>{
      //    console.log(payload);
      // });
    }
    const unsubscribe = onMessage(messaging, (payload) => {
      // localStorage.setItem("notification","true");
    });

    if (auth_token) {
      let value = Number(getLocalStorageObject("dw-header"));
      dispatch(setHeaderValue(value));
      if (authentication) {
        connectSocket(auth_token);
      }
      dispatch(fetchNotification() as any);
      onInviteReceived((msg: ReceivedMessage) => {
        if (msg) {
          if (audioRef.current) {
            audioRef.current.play().catch((error) => {
              console.error("Audio play error:", error);
            });
          }
          dispatch(fetchReceviedInvite() as any).then((res: any) =>
            dispatch(setInvite(res.payload))
          );
          dispatch(fetchSentInvite() as any).then((res: any) =>
            dispatch(setSentInvite(res.payload))
          );
          dispatch(fetchNotification() as any);
        }
      });
      onMessageReceived((msg: ReceivedMessage) => {
        console.log(msg, "in app.tsx");
        // dispatch(
        //   // setShowMessageYellowDotProject({ trackId: msg.to, show: true })
        // );


        if (window.location.pathname !== "/message-chat") {
          dispatch(setMessageCount(1))

        }
        let lastMessageRecieved = {
          actionType: msg.actionType || 3,
          message: msg.message,
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          id: msg.to,
          imgUrl:
            msg?.profile_image?.[0]?.thumbnail || msg?.profile_image?.[0]?.src,
          read_seen: "",
          sender_name: msg.senderName,
          delivered: 0,
          is_delete: false,
          is_attached: msg.is_attached,
          is_attached_file: msg?.is_attached_file,
        };
        dispatch(setMessageRecievedProject(lastMessageRecieved));
      });
    }

    return () => unsubscribe();
  }, [authentication]);
  useEffect(() => {
    AOS.init({ duration: 1250, once: true });

    dispatch(getMaintenanceDetails());
    setInterval(() => {
      dispatch(getMaintenanceDetails());
    }, 5000);
  }, []);

  return (
    <BrowserRouter>
      {isMaintenance === false ? (
        <ScheduledMaintenance />
      ) : (
        <>
          <MainRoutes />
          {music.Data && (
            <>
              <div
                className="d-flex  w-100 "
                style={{ background: "rgb(0 0 0)", height: "97px" }}
              ></div>
              <AudioPlayer />
            </>
          )}
          <audio
            ref={audioRef}
            src="\message-incoming-dropworks.mp3"
            preload="auto"
          />
        </>
      )}
    </BrowserRouter>
  );
}

export default App;
