import React, { FC } from "react";
import "./styles.scss";
import { TabsInterface2 } from "./modal";
import { motion } from "framer-motion";
const View: FC<TabsInterface2> = ({ tabsTitle, currentSection, changeState, className = "",home=false,id }) => {

  let backgroundColour=['linear-gradient(298.83deg, #A91079 2.95%, #3730EC 85.66%)','linear-gradient(298.83deg, #FCEE21 2.95%, #A91079 85.66%)'];
  return (
    <div className={`d-flex flex-wrap tabs-mobile tabs w-100 ${className}`}>
      <div className="tabs-container ">
        {tabsTitle.map((item, index) => (
          <p
            key={index}
            className={`invite-button-all uni-mr-8 ${currentSection === index ? "active-invite" : ""}`}
            onClick={() => changeState && changeState(index)}
          >
            {item}
            {currentSection === index && (
              <motion.div
                className="underline"
                layoutId={id || "underline"}
                style={{ background: home ? backgroundColour[currentSection%2] : '#fff' }} // Adjust gradient as needed
                transition={{ type: 'spring', stiffness: 300, damping: 30 }}
              />
            )}
          </p>
        ))}
      </div>
    </div>
  );
};

export default View;
