import { FC, useEffect, useState } from "react";
import  view  from "./view";
import { getAllSearch } from "../../../utils/apiServices";



export const UserListModal:FC<any>=()=>{

    const [allUserData,setAllUserData] = useState<string[]>([])

    const [userarr, setUserarr] = useState([
        { heading: "", subheading: "" , image: "" , slugName:"" },
    
      ]);

      const getAllSearchData = async (value:any) => {
        const result = await getAllSearch(value);
        if (result?.success) { 
          setAllUserData(result?.allSearchData?.data?.userDatData)
        }
    };
    useEffect(()=>{
        getAllSearchData("")
      },[])


    const getSearchValue=(value:string)=>{
        // setSearchResult(value)
        getAllSearchData(value)
    }

    useEffect(()=>{
        if (Array.isArray(allUserData)) {
          setUserarr(allUserData.map((item:any)=>(
        {
         heading:item.display_name,
         subheading:item.username,
         image:item.profile_image[0]?.src,
         slugName:item.slug_name,
         id:item._id
        })))
    }
      },[allUserData])
    return (view(setUserarr,userarr,getSearchValue,allUserData));
    
}
export default UserListModal