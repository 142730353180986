import { setAuthentication } from "../../../redux/reducer/auth";
import { setData } from "../../../redux/reducer/musicSlice";
import { AppDispatch, resetStore } from "../../../redux/store";
import { logoutApi } from "./apiServices";

export const getLocalStorageObject = (key: string) => {
    const json = localStorage.getItem(key);
    if (!json || json === 'undefined') {
        return undefined;
    }

    return JSON.parse(json);
};
export const ErrorImg ="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxqCQMRexiN9Iu6yVJQt3py5bPb13DRIqCXA&s";
export const setLocalStorageObject = (key: string, object: unknown): void => {
    const json = JSON.stringify(object);
    localStorage.setItem(key, json);
};

export const removeLocalStorageObject = (key: string): void => {
    localStorage.removeItem(key);
};



export const urlUpdated=(url:string)=>{
    return `${process.env.REACT_APP_URL}${url}`
}
export const CheckAuthentication=():boolean=>{
    let token =localStorage.getItem("dropworksToken");
    if(token)
    {
 return true;
    }  
    else{
       return false;
    }
}

export const dateFormat = (data: any)=> {
    return new Date(data).toLocaleString("en-US", {
       timeZone: "UTC",
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
    });
  };

 export const todaysDate = () => {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(today.getDate()).padStart(2, '0');
    const year = today.getFullYear();
   
    return `${month}/${day}/${year}`;
  };
   
  export const currentTime = () => {
    const now = new Date();
    let hours = now.getHours();
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const amPm = hours >= 12 ? 'PM' : 'AM';
   
    // Convert 24-hour format to 12-hour format
    hours = hours % 12 || 12;
   
    return `${String(hours).padStart(2, '0')}:${minutes} ${amPm}`;
  };


export const getformatDate=(isoString: string): string=> {
    const date = new Date(isoString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
}
export const capitalizeAllWords = (str: string): string => {
  return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
};


export const  getformatTime=(isoString: string): string =>{
    const date = new Date(isoString);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
    return `${hours}:${minutes} ${ampm}`;
}


  
 export  const changeMenu = () => {
    const menutoggle = document.querySelector('.home-nav-ul-marketplace-list')as HTMLAnchorElement;
    const menutoggle1 = document.querySelector('.home-nav-ul-studio-list')as HTMLAnchorElement;
    const menutoggle2 = document.querySelector('.profile-main-list')as HTMLAnchorElement;

   
    menutoggle &&  menutoggle.classList.toggle('actived');
    menutoggle1 &&  menutoggle1.classList.toggle('actived');
    menutoggle2 &&  menutoggle2.classList.toggle('actived');
  
    setTimeout(()=>{
      menutoggle &&  menutoggle.classList.toggle('actived');
      menutoggle1 &&  menutoggle1.classList.toggle('actived');
    menutoggle2 &&  menutoggle2.classList.toggle('actived');

    },200)
  };



  // utils/preloadImages.ts
export const preloadImages = (imageUrls: string[],isEnvIncluded: boolean = false) => {
    imageUrls.forEach((url) => {
        console.log(!isEnvIncluded ?`${process.env.REACT_APP_IMGURL}/${url}`:url,"alll")
      const link = document.createElement('link');
      link.rel = 'preload';
      link.href = !isEnvIncluded ?`${process.env.REACT_APP_IMGURL}/${url}`:url;
      link.as = 'image';
      document.head.appendChild(link);
  
      // Optional cleanup to avoid duplicates
    //   return () => {
    //     document.head.removeChild(link);
    //   };
    });
  };
  
  export const formatNumberWithCommas = (number:number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  export const mixMasterAudio=(data:any)=>{
    const res = data.filter((ele:any)=>ele.file_type==="Final mix and master");
    return res?.[0]?.src || ""
  }

export  const logoutUser = (dispatch:AppDispatch) => {
        localStorage.clear();
        logoutApi();
        dispatch(resetStore());
    }


    export const getExtension=(fileName: string): string | undefined =>{
      return fileName?.split('.').pop()?.toLowerCase();
    }




    export const filteredArtist=(data:any)=>{
      if (Array.isArray(data)) {
        const filteredPrimaryArtists = data
        .filter((data: any) => data.credit_to === "Primary Artist")
        .sort((a: any, b: any) => {
          if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1;
          if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1;

          return (
            new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
          );
        });
     
      const filteredFeaturedArtist = data
        .filter((data: any) => data.credit_to === "Featured Artist")
        .sort((a: any, b: any) => {
          if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1;
          if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1;

          return (
            new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
          );
        });

      const filteredHiddenArtist = data
        .filter((data: any) => data.credit_to === "Hidden")
        .sort((a: any, b: any) => {
          if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1;
          if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1;

          return (
            new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
          );
        });

      const filteredNonCreditArtist = data
        .filter(
          (data: any) =>
            data.credit_to == null && data.role.toLowerCase() != "curator"
        )
        .sort((a: any, b: any) => {
          if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1;
          if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1;

          return (
            new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
          );
        });


        return [...filteredPrimaryArtists,...filteredFeaturedArtist,...filteredHiddenArtist,...filteredNonCreditArtist]

      }
    }