import { useNavigate } from "react-router-dom";
import { view } from "./view";
import React from "react";

interface ChatComponentProps {
  fileName?: string;
  fileType?: string;
  fileSize?: string;
  messageTime?: string;
  pageCount?: string | number;
  src?:string;
  is_recieved?:boolean;
  seen_unseen?:number;
  sender_name?: string;
}

const ChatComponent: React.FC<ChatComponentProps> = ({ pageCount, fileName, fileType, fileSize, messageTime,src ,is_recieved=false,seen_unseen=1, sender_name}) => {
  const navigate = useNavigate();

  const navigateTo = (value: string) => () => {
    navigate(value);
  };

  // Pass the props to the `view` function
  return view({
    fileName,
    fileType,
    fileSize,
    messageTime,
    pageCount,
    src,
    is_recieved,
    seen_unseen,
    sender_name
  });
};

export default ChatComponent;
