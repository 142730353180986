import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton, openPopup } from '../../logo-header/view';
import './styles.scss';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import { Heading3, Heading5 } from '../../../../../common/custom-field/dropstock-heading';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUploadBeatImg, setUploadBeatImgAPI } from '../../../../../redux/reducer/uploadSlice';
import axios from 'axios';

const view = () => {
  const [value,setValue]=useState<any>("")
  const userData = useSelector((state: any) => state.user);
  
  const dispatch=useDispatch()

  const save=async ()=>{
    if(value===1){
      // dispatch(setUploadBeatImgAPI(userData.profileImg));
      dispatch(setUploadBeatImg(userData.profileImg))

      try {
        const response = await fetch(userData.profileImg);
        const blob = await response.blob();
   
        const fileName = `${Date.now()}.jpg`;
   
        const file = new File([blob], fileName, { type: blob.type });
   
        const formData = new FormData();
   
        formData.append('uploadingFile',file);
   
    try{
      const uploadResponse = await axios.post(`${process.env.REACT_APP_URL}/upload-file`,formData);  
      if(uploadResponse.data.success)
      {
        dispatch(setUploadBeatImgAPI(uploadResponse.data.file_data));
      }
      else{
        console.error("Else Error in response of uploading image", uploadResponse.data.message);
      }
    }   
    catch(error){
      console.error("Catch Error in response of uploading image", error);
    }


      } catch (error) {
        console.error("Error processing the cropped image:", error);
      }   

    }
    else if(value===2){
      openPopup("beatartwork", dispatch)
    }

  }

  return (
    <div
    className="modal fade"
    id="BeatArtWorkFirst"
    aria-hidden="true"
    aria-labelledby="BeatArtWorkFirst"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered uni-692-popup">
      <div className="modal-content custom-mobile-email-width ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header ps-0 pt-0 uni-pr-72">
          <Heading3 className="modal-title">Beat Artwork</Heading3>
        </div>
        
        <div className="modal-body  ps-0 pt-0 pb-0">
          <TextfieldLG className=" modal-second-head-font uni-mt-16 uni-mb-56 ctm-txt">To proceed select one option</TextfieldLG>
          <div className='d-flex investor-box-parent-deposit  uni-mb-40 uni-mt-8 '>
               <label className='investor-boxs-beat-artwork cursor-pointer' onClick={()=>setValue(1)}>
                    <Heading5 className='investor-boxs-under-modal2-beat'>Use Profile Picture</Heading5>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </label>
               <label className='investor-boxs-beat-artwork cursor-pointer'  onClick={()=>setValue(2)}>
                    <Heading5 className='investor-boxs-under-modal2-beat'>Upload an Artwork</Heading5>
                    <p className='investor-boxs-radiobutton'><DropworksRadioButton/></p>
               </label>
          </div>
        </div>  
        
        <div className="modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0">
        <CloseModalButton disabled={!value}>
        <DropworksSmallButton disabled={!value} className={!value ? "btn-wid  modal-btns m-0 custom-inactive-btn text-secondary disable-interactions":"btn-wid m-0  social-link-modal-btns"} onClick={save}>Next</DropworksSmallButton>
        </CloseModalButton> 
        </div>
      </div>
    </div>
  </div>
  )
}

export default view;

