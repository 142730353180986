import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import "./styles.scss";
import { DropworksPassword } from "../../../../../common/custom-field/dropworks-password";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { FC } from "react";
import { useForm } from "react-hook-form";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import { WalletPin } from "./modal";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

export const view: FC<WalletPin> = ({
  errors,
  isSubmitted,
  loading,
  register,
  onSubmit,
  handleSubmit,
  closeModalRef,
  openModal,
}) => {
  const withdraw = useSelector(
    (state: RootState) => state.wallet.walletData.withdraw
  );

  return (
    <ModalWrapper modalId="WalletPinModal" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="WalletPinModal"
        aria-hidden="true"
        aria-labelledby="WalletPinModal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content ">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header uni-mb-56">
              <Heading3 className="modal-title">Pin</Heading3>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body uni-mb-56">
                <div className="">
                  <DropworksPassword
                    {...register("pin")}
                    placeholder="PIN"
                    disabled={loading}
                    maxLength={6}
                    className="mb-0 relative login-specing "
                    className3={isSubmitted}
                    className2={`uni-mt-16 form-control login-cstm-pass p-0  ${
                      isSubmitted &&
                      (errors.pin
                        ? "is-invalid b-red-2"
                        : !errors.pin
                        ? "is-valid   b-green-2"
                        : "")
                    }`}
                  />
                  {errors.pin && (
                    <p className="invalid-feedback error-red">
                      {errors.pin.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="modal-footer mbl-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
                {loading ? (
                  <Loader className="uni-mt-16" />
                ) : (
                  <>
                    <ButtonComponent
                      id="WalletWithdrawTypeModal"
                      className="buttoncomponent-mobile-modle"
                    >
                      <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 social-link-modal-btns">
                        Back
                      </DropworksSmallButton>
                    </ButtonComponent>{" "}
                    {withdraw === "USDC" ? (
                      <DropworksSmallButton
                        className="btn-wid m-0 my-0 social-link-modal-btns"
                        type="submit"
                      >
                        Next
                      </DropworksSmallButton>
                    ) : (
                      <DropworksSmallButton
                        className="btn-wid m-0 my-0 social-link-modal-btns"
                        type="submit"
                      >
                        Next
                      </DropworksSmallButton>
                    )}
                  </>
                )}
              </div>
            </form>
            <CloseModalButton ref={closeModalRef} className="d-none">
              close
            </CloseModalButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
