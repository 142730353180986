import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import './styles.scss';

import { CloseModalButton } from '../../logo-header/view';
import { DropworksRadioCheckbox } from '../../../../../common/custom-field/dropworks-radio-checkbox';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import ModalWrapper from '../../../../../common/open-modal/ModalWrapper';

const view = ({UpdateRole,roles,submitData,openModal}:any) => {

  return (
     <ModalWrapper modalId="RolesModal" onBackdropClick={openModal}>
    <div
    className="modal fade"
    id="RolesModal"
    aria-hidden="true"
    aria-divledby="RolesModal"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered uni-md-popup">
      <div className="modal-content modal-content-boxes-modal">
      <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header ps-0 pt-0">
          <Heading3 className="modal-title">Your preferred Roles:</Heading3>
        </div>
        
        <div className="modal-body ps-0 pt-0 pb-0 uni-mb-56">
          <TextfieldLG className=" modal-second-head-font uni-mt-16 uni-mb-56 ctm-txt mb-16-mbl">Select at least one </TextfieldLG>
           <div className='investor-box-wrapper'>
          <div className='d-flex flex-wrap investor-box-parent-roles'>
               <div className='investor-boxs-roles cursor-pointer ' onClick={()=>UpdateRole("Music Preneur")}>
                    <p className='investor-boxs-under'>Music Preneur</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox  checked={roles.includes("Music Preneur")}/></p>
               </div>
               <div className='investor-boxs-roles cursor-pointer'  onClick={()=>UpdateRole("Producer")}>
                    <p className='investor-boxs-under'>Producer</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={roles.includes("Producer")}/></p>
               </div>
               <div className='investor-boxs-roles cursor-pointer' onClick={()=>UpdateRole("Vocalist")}>
                    <p className='investor-boxs-under'>Vocalist</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={roles.includes("Vocalist")}/></p>
               </div>
               {/* <div className='investor-boxs cursor-pointer investor-mobile-ui' onClick={()=>UpdateRole("Lyricist")}>
                    <p className='investor-boxs-under'>Lyricist</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={roles.includes("Lyricist")}/></p>
               </div> */}
              
          
          {/* <div className='d-flex uni-mb-24 investor-box-parent'> */}
               <div className='investor-boxs-roles cursor-pointer' onClick={()=>UpdateRole("Lyricist")}>
                    <p className='investor-boxs-under'>Lyricist</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={roles.includes("Lyricist")}/></p>
               </div>
               <div className='investor-boxs-roles cursor-pointer' onClick={()=>UpdateRole("Beat Maker")}>
                    <p className='investor-boxs-under'>Beat Maker</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={roles.includes("Beat Maker")}/></p>
               </div>
               <div className='investor-boxs-roles cursor-pointer' onClick={()=>UpdateRole("Curator")}>
                    <p className='investor-boxs-under'>Curator</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={roles.includes("Curator")}/></p>
               </div>
               {/* <div className='investor-boxs cursor-pointer investor-mobile-ui' onClick={()=>UpdateRole("Artwork Designer")}>
                    <p className='investor-boxs-under'>Artwork Designer</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={roles.includes("Artwork Designer")}/></p>
               </div> */}
          {/* </div> */}
          {/* <div className='d-flex  investor-box-parent'> */}
               <div className='investor-boxs-roles cursor-pointer' onClick={()=>UpdateRole("Artwork Designer")}>
                    <p className='investor-boxs-under'>Artwork Designer</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={roles.includes("Artwork Designer")}/></p>
               </div>
          </div>

        </div>  
        </div>
        
        <div className="modal-footer  social-mobile-modal-footer  ps-0 pt-0 pb-0 uni-pr-72">
        {/* <DropworksSmallButton  className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16">Back</DropworksSmallButton> */}
        
       
        <DropworksSmallButton disabled={roles.length<=0} className={roles.length>0?"btn-wid  social-link-modal-btns m-0 btn-mo-mr-8":"btn-wid btn-mo-mr-8 social-link-modal-btns m-0 custom-inactive-btn text-secondary disable-interactions"} onClick={()=>submitData({ role: roles })}>Save</DropworksSmallButton>
        </div>
      </div>
    </div>
  </div>
  </ModalWrapper>
  )
}

export default view;

