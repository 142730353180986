import { FC, useEffect, useState } from "react";
import view from "./view";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, updateUser } from "../../../utils/apiServices";
import { setUserData } from "../../../../../redux/reducer/userSlice";
import { closeModal } from "../../logo-header/view";

export const RolesModal: FC<any> = () => {
    const [roles,setRoles] = useState<string[]>([]);
    const [loading,setLoading]=useState<boolean>(false)
    const role = useSelector((state: any) => state.user.role)

    const dispatch = useDispatch()

    useEffect(()=>{
      setRoles(role)
    },[role])
    const UpdateRole = (data: string) => {
        if (roles.includes(data)) {
         !loading && setRoles((pre) => pre.filter((ele) => ele !== data));
        } else {
          !loading &&   setRoles([...roles, data]);
        }
      };

      const openModal=()=>{
        setRoles(role)
    }

      const submitData = async(data: { roles: any })=>{
        let response: any = await updateUser(data);
        if (response) {
            closeModal()
            const result = await getUserData(); // Call your API function
            if (result.success && result.userData) { // Adjust based on your API response
                dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
            }
          } else {
            //console.log("fail");
          }
    }

    return view(
        {roles,UpdateRole,submitData,openModal}
    );
}
export default RolesModal