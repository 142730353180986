import { FC } from "react";
import  view  from "./view";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";



export const SelectBeat:FC<any>=()=>{
 
    const beatData = useSelector((state: RootState) => state.report);
   
    return view();
}
export default SelectBeat