import { TextfieldLG} from '../../../../../common/custom-field/dropstock-text'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss"
import { DropworksSearchInput } from '../../../../../common/custom-field/dropworks-search-input';
import { closeModal, CloseModalButton } from '../../logo-header/view';
import ModalWrapper from '../../../../../common/open-modal/ModalWrapper';
import { Heading2, Heading4 } from '../../../../../common/custom-field/dropstock-heading';
import { ErrorImg, setLocalStorageObject } from '../../../utils/localStorage';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setShowRightSide, setuserListData } from '../../../../../redux/reducer/messageChatSlice';
import { useState } from 'react';
const view = (setUserarr:any,userarr:any,getSearchValue:any,allUserData:any) => {

  const openModal=()=>{

  }

  const [chattingTab, setChattingTab] = useState('');
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleMessageClick = (index: number) => {
    const selectedUser = userarr[index];
    const userPayload = {
      userImg: selectedUser?.image || '',
      userName: selectedUser?.heading || '',
      alluserListId: selectedUser?.id || '',
      chattingTab:"user",
      tabValue:0
    };
    dispatch(setuserListData(userPayload));
    dispatch(setShowRightSide(true))
    setChattingTab('user')
    closeModal();
  };

  return (
    <ModalWrapper modalId="UserListModal" onBackdropClick={openModal}>
    <div
      className="modal fade"
      id="UserListModal"
      aria-hidden="true"
      aria-labelledby="UserListModal"
      tabIndex={-1}
      data-bs-theme="dark"
    >                  
      <div className="modal-dialog modal-dialog-centered uni-md-popup">
        <div className="modal-content">
        <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0">
            <Heading4 className="modal-title">User List</Heading4>
          </div>
               <div className="modal-body ps-0 pt-0 pb-0">
                    <DropworksSearchInput className="uni-my-40 search-custm-input-userlist" getSearchValue={getSearchValue}/>
                    <div className='user-item-list col-12'>
                    {userarr.map((data: any, index: number) => (
                    <div key={data.id || index} className="user-item cursor-pointer"  onClick={() => handleMessageClick(index)}>
                      <div className='d-flex gap-3 align-items-center pb-3'>
                    <img src={`${process.env.REACT_APP_IMGURL}/${data.image}`} alt={data.heading}  onError={(e) => (e.currentTarget.src = ErrorImg)} className="user-image-user-list img-fluid" />
                    <TextfieldLG className="user-icon-heading mt-0 text-start">{data.heading}</TextfieldLG>
                    {/* <DropworksSmallButton className="message-userlist-buttons uni-bg-disable">Message</DropworksSmallButton > */}
                    </div>
                  </div> 
                ))}
                </div>
          </div>
        
        </div>
      </div>
    </div>
    </ModalWrapper>
  )
}
 
export default view;
 
 