import { FC, useState } from "react";
import { view } from "./view";

export const CopyBox: FC<any> = ({ text, className = "", ...props }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isShow, setIsShow] = useState(props.showButton == true ? false : true);
  
  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000); // Reset after 3 seconds
  };

  const handleShow = () => {
    setIsShow(!isShow);
  };

  return view({ text, className, handleCopy,handleShow,isShow, isCopied,props });
};
