import { Link, Outlet, useNavigate } from "react-router-dom";
import iconimage from "../../../../assets/img/dropworklogo.png";
import { Footer } from "../footer";
import { AiOutlineMenu } from "react-icons/ai";
import { IoSearchSharp } from "react-icons/io5";
import { MdKeyboardArrowDown } from "react-icons/md";
import { DropworksSecondaryButton, DropworksSmallButton } from "../../../../common/custom-field/dropworks-button";
import { useEffect, useRef, useState } from "react";
import SettingUpPopup from "../pop-ups/setting-up-popup";
import Modal1 from "../pop-ups/modal-1";
import Modal2 from "../pop-ups/modal-2";
import SecureYourAccount from "../pop-ups/secure-your-account";
import Modal4 from "../pop-ups/modal-4";
import AccountSecured from "../pop-ups/acc-secured";
import BellIcon from "../../../../assets/steps/bell.svg";
import NotificationIcon from "../../../../assets/steps/envelope.svg";
import UserIcon from "../../../../assets/steps/Group 18414.svg";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";

const InitialHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const {isFirstName_displayName, isFirstName_ImgUrl, isFirstTime } = useSelector((state: RootState) => state.auth) || false;
  const allowedRoutes = [
    '/',
    '/search',
    '/marketplace-dropworks-select',
    '/marketplace-dropworks-composite',
    '/dropworks-terms',
    '/marketplace-products',
    '/user-profile/:slugName',
    '/project-page/:slugName',
    '/track-page/:slugName',
    '/beats-page/:slugName',
    '/featured',
    '/dropworks-privacy-policy',
    '/dropworks-risk'
  ];

  const isAllowedRoute = (path:any) => {

    return allowedRoutes.some(route => {
    
    const routePattern = route.replace(/:[^\s/]+/g, '[^/]+'); // Replace parameters with regex
    
    const regex = new RegExp(`^${routePattern}$`);
    
    return regex.test(path);
    
    });
    
  };

    const navigate=useNavigate();
    
      const handleClickOutside = (event: any) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          setMenuOpen(false);
        }
      };

      const handleMenuItemClick = (navigateToRoute?: string) => {
        setMenuOpen(false); 
        if (navigateToRoute) {
          navigate(navigateToRoute); 
        }
      };
    
      useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);
      useEffect(() => {
        if (menuOpen) {
          document.body.classList.add("no-scroll");
          document.getElementById("main-content")?.classList.add("blur");
          document.getElementById("main-content2")?.classList.add("blur");
        } else {
          document.body.classList.remove("no-scroll");
          document.getElementById("main-content")?.classList.remove("blur");
          document.getElementById("main-content2")?.classList.remove("blur");
        }
        return () => {
          document.body.classList.remove("no-scroll");
          document.getElementById("main-content")?.classList.remove("blur");
          document.getElementById("main-content2")?.classList.remove("blur");
        };
      }, [menuOpen]);
    
      useEffect(() => {
        setMenuOpen(false); // Close the sidebar when the route changes
      }, [location]);
    
      const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
      useEffect(() => {
        const setwidth = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener("resize", setwidth);
        return () => {
          window.removeEventListener("resize", setwidth);
        };
      }, []);


      useEffect(() => {
        const pagesItem = document.querySelector(".home-nav-ul-studio");
        if (pagesItem) {
          pagesItem.addEventListener("mouseenter", handlePagesHover);
        }
        return () => {
          if (pagesItem) {
            pagesItem.removeEventListener("mouseenter", handlePagesHover);
          }
        };
      }, []);
    
      const handlePagesHover = () => {
        const galleryLink = document.querySelector(".pj-1 a") as HTMLAnchorElement ;
        if (galleryLink) {
          galleryLink?.blur(); // Remove focus from Gallery + when hovering over Pages +
        }
        const galleryLink2 = document.querySelector(".pj-2 a") as HTMLAnchorElement ;
        if (galleryLink2) {
          galleryLink2?.blur(); // Remove focus from Gallery + when hovering over Pages +
        }
      };

  return (
    <>
     <SettingUpPopup/>  
     <Modal1/>
     <Modal2/>
     <SecureYourAccount/>
     <Modal4/>
     <AccountSecured/>
{/* 
     {openModal === "settinguppopup" && <SettingUpPopup/>  }
  {openModal === "Modal1" && <Modal1/>}
    {openModal === "Modal2" &&  <Modal2/>}
      {openModal === "SecureYourAccount" && <SecureYourAccount/>}
        {openModal === "Modal4" && <Modal4/>}
          {openModal === "acc-secured" &&  <AccountSecured/>} */}

      <div className="task container-fluid px-0"> 
        <div className="home-main-nav" id="main-content">
          {isAllowedRoute(window.location.pathname)  && (
            <div
            onClick={() => setMenuOpen(true)}
              className="home-nav-sidevar-menu cursor-pointer"
              >
              <AiOutlineMenu />
            </div>
          )}
          <span className="home-nav-image">
            <Link to="/">
              <img src={iconimage} />
            </Link>
          </span>
          {isAllowedRoute(window.location.pathname) && (
            <div
              className="home-nav-sidevar-search cursor-pointer"
              onClick={()=>navigate("/search")}
            >
              <IoSearchSharp className="cursor-pointer" />
            </div>
          )}
          {isAllowedRoute(window.location.pathname)  && (
            <>
              <nav className="home-nav">
                <ul className="home-nav-ul">
                  <li className="home-nav-ul-marketplace">
                    <Link to="/marketplace-products">
                      Marketplace{" "}
                      <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                    </Link>
                    <ul className="home-nav-dropdown  home-nav-ul-marketplace-list">
                      <li className="pj-1">
                        <Link to="/marketplace-products">
                          {" "}
                          <div className="uni-p-18">
                            Product{" "}
                            <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                          </div>
                        </Link>
                        <ul className="home-nav-dropdown2">
                          <li>
                            <Link to="/marketplace-dropworks-composite">
                              DropWorks Composite{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/marketplace-dropworks-select">
                              DropWorks Select{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                      {/* <li className="pj-2">
                        <Link to="#">
                          <div className="uni-p-18">
                            Trade{" "}
                            <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                          </div>
                        </Link>
                        <ul className="home-nav-dropdown2">
                          <li>
                            <Link to="/dropworks-composite">
                              DropWorks Composite{" "}
                            </Link>
                          </li>
                          <li>
                            <Link to="/dropworks-select">
                              DropWorks Select{" "}
                            </Link>
                          </li>
                        </ul>
                      </li> */}
                      {/* <li>
                        <Link to="/personal-portfolio">
                          <div className="uni-p-18">Portfolio</div>{" "}
                        </Link>
                      </li> */}
                    </ul>
                  </li>
                  <li className="home-nav-ul-studio">
                    <Link to="/featured">
                      Studio{" "}
                      <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                    </Link>
                    <ul className="home-nav-dropdown home-nav-hover-effect   home-nav-ul-studio-list">
                      <li>
                        <Link to="/featured">
                          {" "}
                          <div className="uni-p-18">Featured </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/library">
                          {" "}
                          <div className="uni-p-18">Library </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/invite">
                          {" "}
                          <div className="uni-p-18">Invites </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/create">
                          {" "}
                          <div className="uni-p-18">Create </div>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* <li>
                    <Link to="">Bookings</Link>
                  </li> */}

                  <li>
                    <Link to="search">
                      <IoSearchSharp className="cursor-pointer" />
                    </Link>
                  </li>
                </ul>
              </nav>

              { isFirstTime ? (
                <div className="nav-right  studio-nav-image">
                  <ul>
                    <li className="mar-mr-12">
                      <Link to="/notification" className="position-relative">
                        <img src={BellIcon} />
                      </Link>
                    </li>
                    <li>
                      <Link to="/message-chat">
                        <img src={NotificationIcon} />
                      </Link>
                    </li>

                    <li className="uni-mx-24 truncate-text profile-main">
                      <Link to="/profile">
                        { isFirstName_displayName || "#USERNAME"}
                      </Link>
                    </li>
                    <li>
                      <Link to="/profile">
                        <img
                          className="profile-img"
                          src={ isFirstName_ImgUrl }
                          onError={(e: any) => (e.target.src = UserIcon)}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="home-nav-buttons">
                  <DropworksSmallButton
                    className="mb-0 home-nav-buttons-login"
                    onClick={()=>{navigate("/login");setMenuOpen(false)}}
                  >
                    Login
                  </DropworksSmallButton>
                  <DropworksSecondaryButton
                    className="mb-0 home-nav-buttons-signup"
                    onClick={()=>{navigate("/sign-up");setMenuOpen(false)}}
                  >
                    Signup
                  </DropworksSecondaryButton>
                </div>
              )}
            
            </>
          )}
        </div>
        <div
          className={`home-nav-sidevar ${menuOpen ? "open" : ""}`}
          ref={sidebarRef}
        >
       
            <div className="home-nav-sidevar-buttons">
              <DropworksSmallButton
                className=" home-nav-sidevar-buttons-login uni-mb-16"
                onClick={()=>{navigate("/login");setMenuOpen(false)}}
              >
                Login
              </DropworksSmallButton>
              <DropworksSecondaryButton
                className="mb-0 home-nav-sidevar-buttons-signup"
                onClick={()=>{navigate("/sign-up");setMenuOpen(false)}}
              >
                Signup
              </DropworksSecondaryButton>
            </div>
          
          <div className="home-nav-sidevar-menu1">
            <ul className="home-nav-sidevar-ul">
           
              <li>
                <Link to="/marketplace-products"  onClick={() => handleMenuItemClick("/marketplace-products")} className="font-wh-sidevar">
                  Marketplace
                </Link>
              </li>
              <li>
                <span tabIndex={0}>
                  Products{" "}
                  <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                </span>
                <ul className="home-nav-sidevar-dropdown">
                  <li>
                    <Link to="/marketplace-dropworks-composite"  onClick={() => handleMenuItemClick("/marketplace-dropworks-composite")}>
                      DropWorks Composite
                    </Link>
                  </li>
                
                  <li>
                    <Link to="/marketplace-dropworks-select" onClick={() => handleMenuItemClick("/marketplace-dropworks-select")}>
                      DropWorks Select
                    </Link>
                  </li>
                </ul>
              </li>
              {/* <li>
                <span tabIndex={0}>
                  Trade{" "}
                  <MdKeyboardArrowDown className="uni-ml-8 dropdown-arrow-header" />
                </span>
                <ul className="home-nav-sidevar-dropdown">
                  <li>
                    <Link to="/dropworks-composite" onClick={() => handleMenuItemClick("/dropworks-composite")}>DropWorks Composite</Link>
                  </li>
                  <li>
                    <Link to="/dropworks-select"  onClick={() => handleMenuItemClick("/dropworks-select")}>DropWorks Select</Link>
                  </li>
                </ul>
              </li> */}
            
              {/* <li>
                <Link to="/personal-portfolio" onClick={() => handleMenuItemClick("/personal-portfolio")} >Portfolio </Link>
              </li> */}
              <li>
                <Link to="/featured"  onClick={() => handleMenuItemClick("/featured")} className="font-wh-sidevar">
                  Studio{" "}
                </Link>
              </li>
              <li>
               <Link to="/featured" onClick={() => handleMenuItemClick("/featured")}>
                  Featured
                  </Link>
                 </li>
              <li>
                <Link to="/library" onClick={() => handleMenuItemClick("/library")}>Library </Link>
              </li>
              <li>
                <Link to="/invite" onClick={() => handleMenuItemClick("/invite")} >Invites </Link>
              </li>
              <li>
                <Link to="/create"  onClick={() => handleMenuItemClick("/create")}>Create </Link>
              </li>
              <hr className="hr-sidevar" />
              <li className="pt-0">
                <Link to="" onClick={() => handleMenuItemClick("")} >Booking</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="task_outlet d-flex " id="main-content2">
          <Outlet />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default InitialHeader;

