import { FC, useEffect, useState } from "react";
import  view  from "./view";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { getTrackProjectBeatsDetailsById, reportTrack } from "../../../utils/apiServices";
import { useParams } from "react-router-dom";



export const TrackReportModal:FC<any>=()=>{

    const trackDataid = useSelector((state: RootState) => state.report.trackModalId);
    const trackPage = useSelector((state: RootState) => state.report.beatPage);
    const slugName = useParams()

    const [selectedReason, setSelectedReason] = useState<string>("");

  
    const reportedTracks = async (sendReason:string) => {
        try {
          const result = await reportTrack(trackDataid, {
            report_type: "Recent messages this user sent you", 
            reason: sendReason, 
          });
          if (result.success) {
            console.log("success in report of track")
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

    return (view(reportedTracks,selectedReason,setSelectedReason,trackPage));
}
export default TrackReportModal