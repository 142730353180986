import { FC, useState } from "react";
import "./styles.scss";
import Tabs from "../../../../common/tabs";
import {
  DropworkGhostButton,
  DropworksDisableButton,
  DropworksSmallButton,
} from "../../../../common/custom-field/dropworks-button";
import { RiArrowDropDownLine } from "react-icons/ri";
import { TextfieldLG } from "../../../../common/custom-field/dropstock-text";
import TabsComponent from "../../../../common/tabs-component/view";
import { viewType } from "./modal";
import {
  Heading2,
  Heading5,
} from "../../../../common/custom-field/dropstock-heading";
import { ButtonComponent } from "../../../shared/components/logo-header/view";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import CustomTooltip from "../../../../common/custom-tooltip/view";
import {
  capitalizeAllWords,
  formatNumberWithCommas,
} from "../../../shared/utils/localStorage";
import { useNavigate } from "react-router-dom";
import { Tag } from "primereact/tag";

type SortOrder = 1 | -1 | null;

export const view: FC<viewType> = ({
  filterBy,
  navigateTo,
  handleSourceTypeChange,
}) => {
  const [tabsValue, setTabValue] = useState(0);
  const [sortField, setSortField] = useState<string | undefined>(undefined); // Initialize as undefined
  const [sortOrder, setSortOrder] = useState<SortOrder>(null);

  const { walletDetails, walletDropStocks, walletTransactions } = useSelector(
    (state: RootState) => state.wallet
  );
  const checkTabState = (value: number) => {
    setTabValue(value);
  };

  const handleSort = (e: any) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };

  const TrackLink = ({ slugName, name }: any) => {
    const navigate = useNavigate();

    return (
      <div
        onClick={() => navigate(`/track-page/${slugName}`)}
        className="cursor-pointer"
      >
        {name || "-"}
      </div>
    );
  };

  const LiquidAssets = [
    // {
    //   id: 1,
    //   currency: "DropWorks Composite",
    //   balance: walletDropStocks?.dwc?.balance || "-",
    //   price: "-",
    //   avgCost: "-",
    //   royalties: `${walletDropStocks?.dwc?.royality?.symbol || ""} ${
    //     walletDropStocks?.dwc?.royality?.balance || "-"
    //   }`,
    // },
    // {
    //   id: 2,
    //   currency: "DropWorks Select",
    //   balance: walletDropStocks?.dws?.balance || "-",
    //   price: "-",
    //   avgCost: "-",
    //   royalties: `${walletDropStocks?.dws?.royality?.symbol || ""} ${
    //     walletDropStocks?.dws?.royality?.balance || "-"
    //   }`,
    // },
    {
      id: 3,
      currency: "USD",
      balance: `${walletDropStocks?.usdc?.symbol || ""} ${
        walletDropStocks?.usdc?.balance || "-"
      }`,
      price: "-",
      avgCost: "-",
      royalties: "-",
    },
  ];

  const getBtnColor = (value: string) => {
    console.log(value, "value");
    switch (value) {
      case "In":
        return "success";

      case "Out":
        return "danger";

      case "Deposit":
        return "success";

      case "Withdraw":
        return "danger";

      case "Royalty":
        return "secondary";

      case "Gas":
        return "info";

      case "DWC":
        return "warning";

      case "DWS":
        return "warning";

      default:
        return null;
    }
  };
  const statusBodyTemplate = (rowData: any) => {
    return (
      <Tag
        value={rowData.source_type}
        severity={getBtnColor(rowData.source_type)}
      ></Tag>
    );
  };
  return (
    <div className="new-wallet container container-xl container-xxl min-height-cover">
      <Heading2 className="new-wallet-heading uni-mb-40 ">Wallet</Heading2>
      <Tabs
        id="13"
        // tabsTitle={["Overview", "Transactions", "Orders"]}
        tabsTitle={["Overview", "Transactions"]}
        className="uni-mb-40"
        checkTabState={checkTabState}
      />
      <TabsComponent
        tabsValue={tabsValue}
        components={[
          <div>
            <div className="overview">
              <div className="overview-total-balance uni-mt-40 uni-mb-40 ">
                <div className="wallet-amount-section">
                  <TextfieldLG className="d-grid">
                    Total Balance
                    <div className="balance-amount d-flex">
                      {walletDropStocks?.usdc?.symbol || ""}{" "}
                      {walletDropStocks?.usdc?.balance || ""}
                      <div className="d-flex uni-ml-56 withdraw-deposit gap-3">
                        <ButtonComponent id="WalletWithdrawTypeModal">
                          <DropworksDisableButton className="withdraw-button wallet-btns">
                            Withdraw
                          </DropworksDisableButton>
                        </ButtonComponent>
                        <ButtonComponent id="WalletDepositTypeModal">
                          <DropworksSmallButton className="deposit-button wallet-btns">
                            Deposit
                          </DropworksSmallButton>
                        </ButtonComponent>
                      </div>
                    </div>
                  </TextfieldLG>
                </div>
              </div>
              <Heading5 className="liquid-asstes-head uni-mb-32">
                Liquid assets
              </Heading5>
              <div className="liquid-assets uni-mb-56">
                {/* <div className="table-view">
                  <div className="table-bg-parent">
                    <div className="table-wrapper mobile-view-wrapper">
                      <table className="table custom-table-1 mb-0">
                        <thead className="text-dark d-flex align-items-center justify-content-between mobile-table-thead">
                          <tr className="d-flex align-items-center justify-content-between table-row-dropstock">
                            <th
                              scope="col "
                              className="space-table-head asset-wid"
                            >
                              Asset
                            </th>
                            <th scope="col" className="space-table-head">
                              Quantity/ Value
                            </th>
                            <th scope="col" className="space-table-head">
                              Price/ Avg. Cost
                            </th>
                            <th scope="col" className="space-table-head">
                              Royalties
                            </th>
                          </tr>
                        </thead>
                        <tbody className="uni-mt-32">
                          {/* <tr className="d-flex align-items-center justify-content-between table-row-dropstock">
                            <td className="date-time-2">
                              <div className="d-flex gap-3 align-items-center bottom-table">
                                <span className="select-box wallet-select-box"></span>
                                <div
                                  className="d-flex cursor-pointer"
                                  onClick={navigateTo("/track-page")}
                                >
                                  <TextfieldLG className="my-0  p-0 select-title">
                                    DropWorks Composite
                                  </TextfieldLG>
                                </div>
                              </div>
                            </td>
                            <td className="quantity">
                              <span className="table-data">
                                {walletDropStocks?.dwc?.balance || ""}
                              </span>
                              <span className="table-subdata">-</span>
                            </td>
                            <td className="price">
                              <span>-</span>
                              <span className="table-subdata">-</span>
                            </td>
                            <td>
                              {walletDropStocks?.dwc?.royality.symbol || ""}{" "}
                              {walletDropStocks?.dwc?.royality.balance}
                            </td>
                          </tr>
                          <tr className="d-flex align-items-center justify-content-between table-row-dropstock">
                            <td className="date-time-2">
                              <div className="d-flex gap-3 align-items-center bottom-table">
                                <span className="select-box wallet-select-box"></span>
                                <div
                                  className="d-flex cursor-pointer"
                                  onClick={navigateTo(
                                    "/marketplace-dropworks-select"
                                  )}
                                >
                                  <TextfieldLG className="my-0  p-0 select-title">
                                    DropWorks Select
                                  </TextfieldLG>
                                </div>
                              </div>
                            </td>
                            <td className="quantity">
                              <span className="table-data">
                                {walletDropStocks?.dws?.balance}
                              </span>
                              <span className="table-subdata">-</span>
                            </td>
                            <td className="price">
                              <span>-</span>
                              <span className="table-subdata">-</span>
                            </td>
                            <td>
                              {" "}
                              {walletDropStocks?.dws?.royality?.symbol ||
                                ""}{" "}
                              {walletDropStocks?.dws?.royality?.balance}
                            </td>
                          </tr> 
                          <tr className="d-flex align-items-center justify-content-between table-row-dropstock">
                            <td className="date-time-2">
                              <div className="d-flex gap-3 align-items-center bottom-table">
                                <span className="select-box wallet-select-box bg-white"></span>
                                <div className="d-flex">
                                  <TextfieldLG className="my-0  p-0 select-title">
                                    USD
                                  </TextfieldLG>
                                </div>
                              </div>
                            </td>
                            <td className="quantity">
                              <span className="table-data">
                                {walletDropStocks?.usdc?.symbol || ""}{" "}
                                {walletDropStocks?.usdc?.balance || ""}
                              </span>
                              <span className="table-subdata">-</span>
                            </td>
                            <td className="price">
                              <span>-</span>
                            </td>
                            <td>-</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> */}

                <DataTable
                  value={LiquidAssets}
                  className="prime-custom-table"
                  emptyMessage="No dropStocks available."
                  dataKey="id"
                  tableStyle={{ minWidth: "40rem" }}
                  scrollHeight="352px"
                  scrollable
                >
                  <Column
                    field="currency"
                    header="Asset"
                    body={(row) => (
                      <div className="d-flex gap-3 align-items-center bottom-table">
                        <span className="select-box wallet-select-box bg-white">
                          <img
                            className="select-box bg-custom-trans"
                            src="https://cryptologos.cc/logos/usd-coin-usdc-logo.svg?v=040"
                            alt=""
                          />
                        </span>
                        <div className="d-flex">
                          <TextfieldLG className="my-0  p-0 select-title">
                            USD
                          </TextfieldLG>
                        </div>
                      </div>
                    )}
                  />
                  <Column
                    field="balance"
                    header="Quantity/Value"
                    body={(row) => (
                      <div className="quantity">
                        <span className="table-data">
                          {walletDropStocks?.usdc?.symbol || ""}{" "}
                          {walletDropStocks?.usdc?.balance || ""}
                          <span className="table-subdata">-</span>
                        </span>
                      </div>
                    )}
                  />
                  <Column
                    field="price"
                    header="Price/Avg. Cost"
                    body={(row) => (
                      <span className="table-data">
                        <span>-</span>
                      </span>
                    )}
                  />
                  <Column
                    field="royalties"
                    header="Royalties"
                    body={(row) => (
                      <span className="table-data">
                        <span>-</span>
                      </span>
                    )}
                  />
                </DataTable>
              </div>

              <Heading5 className="liquid-asstes-head uni-mb-32">
                DropStocks
              </Heading5>
              <div className="dropstocks-assets uni-mb-56">
                {/* <div className="table-view">
                  <div className="table-bg-parent">
                    <div className="table-wrapper">
                      <table className="table custom-table-2  mb-0">
                        <thead className="text-dark d-flex align-items-center justify-content-between mobile-table-thead">
                          <tr className="d-flex align-items-center justify-content-between table-row-dropstock">
                            <th scope="col" className="space-table-head">
                              Asset
                            </th>
                            <th scope="col" className="space-table-head">
                              Quantity
                            </th>
                          </tr>
                        </thead>
                        <tbody className="uni-mt-32">
                          {walletDetails && walletDetails.length > 0 ? (
                            walletDetails.map((wallet: any, index: any) => (
                              <tr
                                key={index}
                                className="d-flex align-items-center justify-content-between table-row-dropstock"
                              >
                                <td className="date-time-2">
                                  <div className="d-flex gap-3 align-items-center bottom-table">
                                    <span
                                      className={`select-box wallet-select-box ${
                                        wallet?.art_work_image.length > 0
                                          ? "cursor-pointer"
                                          : "bg-white"
                                      }`}
                                    >
                                      {wallet?.art_work_image &&
                                      wallet?.art_work_image.length > 0 ? (
                                        <>
                                          <img
                                            className="select-box bg-custom-trans"
                                            src={`${
                                              process.env.REACT_APP_IMGURL
                                            }/${
                                              wallet?.art_work_image[0]
                                                ?.thumbnail ||
                                              wallet?.art_work_image[0]?.src
                                            }`}
                                            onClick={navigateTo(
                                              `/track-page/${wallet.slug_name}`
                                            )}
                                            alt=""
                                          />
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                    {wallet?.art_work_image.length > 0 ? (
                                      <div
                                        className={`d-flex ${
                                          wallet?.art_work_image.length > 0
                                            ? "cursor-pointer"
                                            : ""
                                        }`}
                                        onClick={navigateTo(
                                          `/track-page/${wallet.slug_name}`
                                        )}
                                      >
                                        <TextfieldLG className="my-0  p-0 select-title">
                                          {wallet.project_name}
                                        </TextfieldLG>
                                      </div>
                                    ) : (
                                      <div className="d-flex">
                                        <TextfieldLG className="my-0  p-0 select-title">
                                          {wallet.project_name}
                                        </TextfieldLG>
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td className="quantity">
                                  <span className="table-data">
                                    {formatNumberWithCommas(
                                      wallet.dropstockCount
                                    )}
                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="text-center">
                                No DropStocks available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> */}

                {/*  DropStock Data DataTable */}
                <DataTable
                  value={walletDetails}
                  className="prime-custom-table"
                  tableStyle={{ minWidth: "30rem" }}
                  emptyMessage="No dropStocks available."
                  dataKey="id"
                  scrollHeight="352px"
                  scrollable
                >
                  <Column
                    sortable
                    header="Asset"
                    body={(row) => (
                      <div className="d-flex gap-3 align-items-center bottom-table">
                        <span
                          className={`select-box wallet-select-box ${
                            row.art_work_image.length > 0
                              ? "cursor-pointer"
                              : "bg-white"
                          }`}
                        >
                          {row.art_work_image.length > 0 && (
                            <img
                              className="select-box bg-custom-trans"
                              src={`${process.env.REACT_APP_IMGURL}/${
                                row.art_work_image[0]?.thumbnail ||
                                row.art_work_image[0]?.src
                              }`}
                              onClick={navigateTo(`/track-page/${row.slug_name}`)}
                              alt=""
                            />
                          )}
                        </span>
                        <div
                          className={`d-flex ${
                            row.art_work_image.length > 0
                              ? "cursor-pointer"
                              : ""
                          }`}
                          onClick={navigateTo(`/track-page/${row.slug_name}`)}
                        >
                          <span className="my-0 p-0 select-title">
                            {row.track_name}
                          </span>
                        </div>
                      </div>
                    )}
                  />

                  <Column
                    sortable
                    header="Quantity"
                    body={(row) => (
                      <span className="table-data">
                        {formatNumberWithCommas(row.dropstockCount)}
                      </span>
                    )}
                  />
                </DataTable>
              </div>
            </div>
          </div>,

          <div className="uni-mb-56">
            <div className="d-flex gap-3 uni-mb-40 uni-mt-32 mt-mobile-0">
              <DropworkGhostButton
                className={`invite-acexp-btn table-btn-wallet ${
                  filterBy === "balance" ? "active" : ""
                }`}
                onClick={() => handleSourceTypeChange("balance")}
              >
                Balance
              </DropworkGhostButton>
              <DropworkGhostButton
                className={`invite-acexp-btn table-btn-wallet ${
                  filterBy === "funds" ? "active" : ""
                }`}
                onClick={() => handleSourceTypeChange("funds")}
              >
                Funds
              </DropworkGhostButton>
              <DropworkGhostButton
                className={`invite-acexp-btn table-btn-wallet ${
                  filterBy === "royalty" ? "active" : ""
                }`}
                onClick={() => handleSourceTypeChange("royalty")}
              >
                Royalties
              </DropworkGhostButton>
              <DropworkGhostButton
                className={`invite-acexp-btn table-btn-wallet ${
                  filterBy === "dropstock" ? "active" : ""
                }`}
                onClick={() => handleSourceTypeChange("dropstock")}
              >
                DropStocks
              </DropworkGhostButton>
            </div>

            {/*  Transactions Data DataTable */}
            <DataTable
              value={walletTransactions}
              className="prime-custom-table"
              tableStyle={{ minWidth: "50rem" }}
              emptyMessage="No transactions available."
              dataKey="id"
              sortField={sortField}
              sortOrder={sortOrder}
              onSort={handleSort}
              scrollHeight="50vh"
              scrollable
            >
              {filterBy === "dropstock" || filterBy === "royalty" ? (
                <Column
                  field="name"
                  sortable
                  header={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Source
                      <RiArrowDropDownLine
                        style={{
                          marginLeft: "0.5rem",
                          transform:
                            sortField === "name" && sortOrder === 1
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </div>
                  }
                  body={(transaction) => (
                    <>
                      <div className="d-flex align-items-center">
                        <a
                          href={`https://polygonscan.com/tx/${transaction.transaction_hash}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="transaction-link"
                        >
                          <img
                            src="https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg?v=25.1.3.0"
                            alt=""
                            className="table-polygonscan-icon"
                          />
                        </a>
                        <TrackLink
                          slugName={transaction?.slug_name}
                          name={transaction?.name}
                        />
                      </div>
                    </>
                  )}
                />
              ) : null}

              <Column
                field="date"
                sortable
                header={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Date
                    <RiArrowDropDownLine
                      style={{
                        marginLeft: "0.5rem",
                        transform:
                          sortField === "date" && sortOrder === 1
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    />
                  </div>
                }
                body={(transaction) => (
                  <div className="d-flex align-items-center">
                    {filterBy === "balance" || filterBy === "funds" ? (
                      <div>
                        <a
                          href={`https://polygonscan.com/tx/${transaction.transaction_hash}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="transaction-link"
                        >
                          <img
                            src="https://polygonscan.com/assets/poly/images/svg/logos/token-light.svg?v=25.1.3.0"
                            alt=""
                            className="table-polygonscan-icon"
                          />
                        </a>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="date-time">
                      <span className="date">
                        {new Date(transaction.date).toLocaleDateString()}
                      </span>
                      <span className="time">
                        {new Date(transaction.date).toLocaleTimeString()}
                      </span>
                    </div>
                  </div>
                )}
                sortField="date"
              />

              {(filterBy === "balance" ||
                filterBy === "dropstock" ||
                filterBy === "funds") && (
                <Column
                  field="source_type"
                  sortable
                  header={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Type
                      <RiArrowDropDownLine
                        style={{
                          marginLeft: "0.5rem",
                          transform:
                            sortField === "source_type" && sortOrder === 1
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </div>
                  }
                  body={statusBodyTemplate}
                />
              )}

              {(filterBy === "balance" ||
                filterBy === "royalty" ||
                filterBy === "funds") && (
                <Column
                  field="amount_symbol"
                  sortable
                  header={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Currency
                      <RiArrowDropDownLine
                        style={{
                          marginLeft: "0.5rem",
                          transform:
                            sortField === "amount_symbol" && sortOrder === 1
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </div>
                  }
                  body={(transaction) => (
                    <span>{transaction.amount_symbol}</span>
                  )}
                />
              )}

              {filterBy === "royalty" || filterBy === "funds" ? (
                <Column
                  field="value"
                  sortable
                  header={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Value
                      <RiArrowDropDownLine
                        style={{
                          marginLeft: "0.5rem",
                          transform:
                            sortField === "value" && sortOrder === 1
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </div>
                  }
                  body={(transaction) => `${transaction.amount}`}
                />
              ) : null}

              {filterBy === "balance" && (
                <Column
                  field="balance"
                  sortable
                  header={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Balance
                      <RiArrowDropDownLine
                        style={{
                          marginLeft: "0.5rem",
                          transform:
                            sortField === "gas_unit" && sortOrder === 1
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </div>
                  }
                  body={(transaction) => (
                    <div className="date-time position-relative">
                      {transaction.type === "Out" && (
                        <span className="out-icon">{"-"}</span>
                      )}

                      <span
                        className={`date ${
                          transaction.type === "In" ? "text-green" : ""
                        }`}
                      >
                        {transaction.type === "Out" ? (
                          <>
                            {transaction.amount} {transaction.amount_symbol}
                          </>
                        ) : (
                          <>
                            {transaction.amount} {transaction.amount_symbol}
                          </>
                        )}
                      </span>
                      <span className="time">
                        {transaction.balance} {transaction.amount_symbol}
                      </span>
                    </div>
                  )}
                />
              )}

              {filterBy === "dropstock" && (
                <Column
                  field="amount"
                  sortable
                  header={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Amount
                      <RiArrowDropDownLine
                        style={{
                          marginLeft: "0.5rem",
                          transform:
                            sortField === "amount" && sortOrder === 1
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </div>
                  }
                  body={(transaction) => (
                    <div>
                      <CustomTooltip title={transaction.amount} position="top">
                        <span
                          data-pr-tooltip={transaction.amount}
                          data-pr-position="top"
                          style={{ cursor: "pointer" }}
                        >
                          {transaction.amount}
                        </span>
                      </CustomTooltip>
                    </div>
                  )}
                />
              )}

              {(filterBy === "royalty" ||
                filterBy === "dropstock" ||
                filterBy === "funds") && (
                <Column
                  field="status"
                  sortable
                  header={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      Status
                      <RiArrowDropDownLine
                        style={{
                          marginLeft: "0.5rem",
                          transform:
                            sortField === "status" && sortOrder === 1
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </div>
                  }
                  body={(transaction) =>
                    transaction.status === "success"
                      ? "Completed"
                      : "Processing"
                  }
                />
              )}
            </DataTable>
          </div>,
        ]}
      />
    </div>
  );
};
