import { FC, useState } from "react";
import  view  from "./view";
import { reportSomethingElse } from "../../../utils/apiServices";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";



export const ImpersonatingReportModal:FC<any>=()=>{

  const UserId = useSelector((state: RootState) => state.report.userModalId);
  const UserReason = useSelector((state: RootState)=> state.report.somethingreportreason)
  const [imperSonatingReport, setImperSonatingReport] = useState();
  const [selectedReason, setSelectedReason] = useState<string>("");
 
  const reportData= {
    reason: UserReason,     
    report_type: "user",
    user_id: UserId,
    who_pretending_to: selectedReason
  };

    const somethingaboutreport = async () => {
        try {
          const result = await reportSomethingElse(UserId,reportData);
          if (result.success) {
            setImperSonatingReport(result.reportedSomething.data);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
    return (view(somethingaboutreport,selectedReason,setSelectedReason));
}
export default ImpersonatingReportModal