import { FC, useState } from "react";
import "./styles.scss";
import { TrackIcon } from "../../../../common/track-icon";
import { ProjectIcon } from "../../../../common/project-icon";
import { DropworkGhostButton, DropworksNormalButton } from "../../../../common/custom-field/dropworks-button";
import { BeatesIcon } from "../../../../common/beats-icon";
import { Heading2 } from "../../../../common/custom-field/dropstock-heading";
import Tabs from "../../../../common/tabs";
import TabsComponent from "../../../../common/tabs-component/view";
import { TextfieldLG } from "../../../../common/custom-field/dropstock-text";
import { useNavigate } from "react-router-dom";



export const view: FC = ({
  trackarr,
  projectarr,
  beatsarr,
  libraryRerelease,
  filterActive,
  filteredProject,
  filterReleased,
  status,
  loading
}: any) => {



  const [tabsValue, setTabValue] = useState(0);

  const checkTabState = (value: number) => {
    setTabValue(value);
  };
  const navigate=useNavigate()

  return (
    <div className="library container container-xl container-xxl min-height-cover">
      <Heading2 className="library-heading">Library</Heading2>
      <Tabs
        className="uni-mb-56"
        tabsTitle={["All", "Tracks", "Projects", "Beats", "Released Tracks"]}
        id="7"
        checkTabState={checkTabState}
      changeTabValue={tabsValue}
      />
      <TabsComponent
        tabsValue={tabsValue}
        components={[
          <>
          {trackarr?.length>0 &&<>
            <div className="library-subheading" ><span className="cursor-pointer library-subheading" onClick={()=>checkTabState(1)}>Your Tracks</span></div>
            <div className="library-usericon">
              <TrackIcon
                arr={trackarr?.slice(0, 6)}
                className="all-icon-mobile uni-pl-24 full-width-child"
                className2="track-icon-mobile-view"
              />
            </div>
            </> }

            {projectarr?.length>0 &&<>
            <div className="library-subheading"><span className="cursor-pointer library-subheading" onClick={()=>checkTabState(2)}>Your Projects</span></div>
            <div className="library-usericon">
              <ProjectIcon
                arr={projectarr?.slice(0, 6)}
                page="library"
                className="all-icon-mobile uni-pl-24 full-width-child"
                className2="project-icon-mobile-view"
              />
            </div>
            </> }
            {beatsarr?.length>0 &&<>
            <div className="library-subheading"><span className="cursor-pointer library-subheading" onClick={()=>checkTabState(3)}>Your Beats</span></div>
            <div className="library-usericon">
              <BeatesIcon
                arr={beatsarr?.slice(0, 6)}
                className="all-icon-mobile uni-pl-24 full-width-child"
                className2="beats-icon-mobile-view"
                isLibraryText={true}
              />
            </div>
            </> }
            {libraryRerelease?.length>0 &&<>
            <div className="library-subheading"><span className="cursor-pointer library-subheading" onClick={()=>checkTabState(4)}>Your Released Tracks</span></div>
            <div className="library-usericon">
              <ProjectIcon
                arr={libraryRerelease?.slice(0, 6)}
                page="library"
                className="all-icon-mobile uni-pl-24 full-width-child"
                className2="beats-icon-mobile-view"
                isLibraryText={true}
              />
            </div>
            </> }

            { !loading && trackarr?.length==0 && projectarr?.length==0 && beatsarr?.length==0 && libraryRerelease?.length==0 && <>
              <TextfieldLG className="uni-fw-700 uni-mb-32"><span >Looks like someone is yet to use the studio.</span></TextfieldLG>
               <DropworksNormalButton className="mb-0 library-btn" onClick={()=>navigate("/create")}>
                                        Create
                                      </DropworksNormalButton>
            </>}
          </>,
          <>
           {trackarr?.length>0 &&
            <div className="library-usericon">
              <TrackIcon arr={trackarr} />
            </div>
        }
          {  !loading && trackarr?.length==0 && <>
              <TextfieldLG className="uni-fw-700 uni-mb-32"><span >Woah, no tracks seem to have been dropped yet!</span></TextfieldLG>
               <DropworksNormalButton className="mb-0 library-btn" onClick={()=>navigate("/create")}>
                                        Create
                                      </DropworksNormalButton>
            </>}
          </>,
          <>
          {projectarr?.length>0 &&
            <div className="library-usericon">
              <ProjectIcon 
                arr={status?.length > 0 ? filteredProject : projectarr}
                page="library"
                
              />
            </div>}
            { !loading && projectarr?.length==0 && <>
              <TextfieldLG className="uni-fw-700 uni-mb-32"><span >Not in any project right now? You can find something that suits you, or you can create it yourself!</span></TextfieldLG>
               <DropworksNormalButton className="mb-0 library-btn" onClick={()=>navigate("/create")}>
                                        Create
                                      </DropworksNormalButton>
            </>}
          </>,
          <>
          {beatsarr?.length>0 &&
            <div className="library-usericon">
              <BeatesIcon arr={beatsarr}    isLibraryText={true}/>
            </div>
        }
          { !loading && beatsarr?.length==0 && <>
              <TextfieldLG className="uni-fw-700 uni-mb-32"><span >Not in any beat right now? You can find something that suits you, or you can create it yourself!</span></TextfieldLG>
               <DropworksNormalButton className="mb-0 library-btn" onClick={()=>navigate("/create")}>
                                        Create
                                      </DropworksNormalButton>
            </>}
          </>,
          <>
           {libraryRerelease?.length>0 &&
            <div className="library-usericon">
              <ProjectIcon arr={libraryRerelease} page="library" isLibraryText={true} />
            </div>}
            { !loading && libraryRerelease?.length==0 && <>
              <TextfieldLG className="uni-fw-700 uni-mb-32"><span >Not in any project right now? You can find something that suits you, or you can create it yourself!</span></TextfieldLG>
               <DropworksNormalButton className="mb-0 library-btn" onClick={()=>navigate("/create")}>
                                        Create
                                      </DropworksNormalButton>
            </>}
          </>,
        ]}
      />
    </div>
  );
};

// navigate(isRerelease?`/track-project-rerelease/${data}`:`/track-page/${data}`)
