import { FC} from "react";
import "./styles.scss";
import { FaExternalLinkAlt } from "react-icons/fa";
import fonticon from "../../../../assets/img/dropworkfonticon.png"
import globeImg from '../../../../assets/steps/global.svg';
import footermobile from '../../../../assets/steps/Dwfooterlogo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from "../../../../redux/reducer/languageSlice";
import { RootState } from "../../../../redux/store";
import Insta from "../../../../assets/steps/InstaImage.svg";
import Twitter from "../../../../assets/steps/twitter.svg";
import YotubeImage from "../../../../assets/steps/youtubeImage.png";
import { useNavigate } from "react-router-dom";


export const view: FC<any> = ({ hide, allHide }) => {
    const dispatch = useDispatch();
    const currentLanguage = useSelector((state: RootState) => state.language.language);
    const navigate=useNavigate()
    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedLanguage = e.target.value;
      dispatch(setLanguage(selectedLanguage));
    };
    const currentYear = new Date().getFullYear();
    return (
        <div className={allHide ? "d-none" : `footer  px-0 ${!hide && "mt-3rem"}`}>
            <div className={hide ? "d-none" : "footer_first px-0 container container-xxl container-xl  px-0"}>
                <div className="row footer_first_columns px-0">
                    <div className="col-lg-4 col-6 col-md-4 fs-6 footer_first_columns_first px-0">
                        <div className="footer_first_columns_first_heading">Company</div>
                        <div className="footer_first_columns_first_subheading footer-options footer-underline-opening">About</div>
                        {/* <div className="footer_first_columns_first_subheading  footer-thrid-coll footer-options footer-underline-opening">Resource</div> */}
                        <div className="footer_first_columns_third_heading footer_mobile_view footer-underline-opening">Support</div>
                        <div className="footer_first_columns_third_subheading footer_mobile_view footer-options footer-underline-opening">Help Center <span className="footer_first_columns_third_subheading_icons"><FaExternalLinkAlt /></span></div>
                    </div>
                    <div className="col-lg-4 col-6 col-md-4 fs-6 footer_first_columns_second px-0">
                        <div className="footer_first_columns_second_heading ">Legal</div>
                        <div className="footer_first_columns_second_subheading footer-options  footer-underline-opening" onClick={()=>navigate("/dropworks-terms")}>Terms of Use</div>
                        <div className="footer_first_columns_second_subheading footer-options footer-privacy footer-underline-opening" onClick={()=>navigate("/dropworks-privacy-policy")}>Privacy Policy</div>
                        <div className="footer_first_columns_second_subheading footer_mobile_view text-nowrap footer-options footer-underline-opening">Community Guidlines</div>
                        <div className="footer_first_columns_second_subheading  footer-thrid-coll footer-options footer-underline-opening" onClick={()=>navigate("/dropworks-risk")}>Risk Disclosure Statement</div>
                        {/* <div className="footer_first_columns_second_subheading footer-options footer-underline-opening">GDPR</div>
                        <div className="footer_first_columns_second_subheading  footer-thrid-coll footer-options footer-underline-opening">Security Policy</div>
                        <div className="footer_first_columns_second_subheading  footer-thrid-coll footer-options footer-underline-opening">Cookie Settings</div> */}
                    </div>
                    <div className="col-lg-4 col-6 col-md-4 fs-6 footer_first_columns_third px-0 footer-thrid-coll">
                        <div className="footer_first_columns_third_heading">Support</div>
                        <div className="footer_first_columns_third_subheading footer-options footer-underline-opening">Help Center <span className="footer_first_columns_third_subheading_icons"><FaExternalLinkAlt /></span></div>
                        <div className="footer_first_columns_third_subheading footer-options footer-underline-opening">Status <span className="footer_first_columns_third_subheading_icons"><FaExternalLinkAlt /></span></div>
                    </div>
                </div>
            </div>
            <hr className="full-width-child footer-hr" />
            <div className="footer_second">
                {/* <div className="d-flex footer_second_first container-new container"> */}
                <div className="d-flex footer_second_first w-100 ">
                    <div className="footer_second_first_texts">
                        <img src={fonticon} className="web-footerlogo" />
                        <img src={footermobile} className="mobile-footerlogo" />
                        <span className="footer_second_first_texts_copyright fs-6"><span className="footer_second_first_texts_copyright_icon">&copy;</span> Copyright {currentYear} DropWorks Inc. All rights reserved</span>
                    </div>
                    <div className="footer_second_first_icons d-flex">
                    <div className="footer_second_first_icons_icon">
                  <img src={globeImg} />
                  {/* <span className="uni-ml-8"> */}
                <select 
                 value={currentLanguage}
                 onChange={handleChange}
                 className="footer-select-language">
                 <option value="en">English</option>
                    {/* <option value="es">Spanish</option>
                    <option value="pt">Portuguese</option> */}
                </select>
                {/* </span> */}
                </div>
                        {/* <div className="footer_second_first_icons_english">English</div> */}
                        {/* <div className="footer_second_first_icons_icon"><FaTiktok /></div>
                        <div className="footer_second_first_icons_icon"><FaTwitter /></div> */}
                        <div className="footer_second_first_icons_icon">
                        <a
                        href="https://www.instagram.com/dropworksapp/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-anchor"
                           >
                          <img src={Insta} alt=""className="img-fluid mbl-footer-icon"/>
                        </a>
                        </div>
                        <div className="footer_second_first_icons_icon">
                        <a
                        href="https://www.youtube.com/@DropWorksApp" 
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-anchor"
                           >
                        <img src={YotubeImage} alt="" className="img-fluid mbl-footer-icon"/>
                        </a>
                        </div>
                        <div className="footer_second_first_icons_icon">
                        <a
                        href="https://x.com/DropWorksApp"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-anchor"
                           >
                          <img src={Twitter} alt=""className="img-fluid mbl-footer-icon"/>
                        </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
