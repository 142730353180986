import { FC, useState } from "react";
import "./styles.scss";
import { FcGoogle } from "react-icons/fc";
import { DropworksInput } from "../../../../common/custom-field/dropworks-input";
import { DropworksButton } from "../../../../common/custom-field/dropworks-button";
import { DropworksPassword } from "../../../../common/custom-field/dropworks-password";
import {
  TextfieldSM,
  TextfieldXSM,
} from "../../../../common/custom-field/dropstock-text";
import { Heading3 } from "../../../../common/custom-field/dropstock-heading";
import { viewType } from "../login/modal";
import Loader from "../../../shared/components/loader/view";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { loginApiGoogle } from "../../../shared/utils/apiServices";
import GoogleSignInButton from "../../google/GoogleSignInButton";

const view: FC<viewType> = ({
  navigateTo,
  handleSubmit,
  onSubmit,
  errors,
  register,
  loading = false,
  isSubmitted,
  navigate,
  onLoginSuccessfull,
}) => {
  // const googleLogin = useGoogleLogin({
  //   onSuccess: (credentialResponse) => {
  //     console.log("Login Success:", credentialResponse);
  //   },
  //   onError: () => {
  //     console.log("Login Failed");
  //   },
  // });
  const [googleError, setGoogleError] = useState(false);

  const GoogleLoginAuth = async (data: any) => {
    if (data.credential) {
      const response = await loginApiGoogle(data.credential);
      if (response?.success) {
        if (response.data.mandatory_field === 6) {
          navigate("/fill-details");
        }
        if (response.data.mandatory_field === 7) {
          navigate("/setting-up");
        }
        if (response.data.mandatory_field === 0) {
          localStorage.setItem("dropworksToken", response.data.token);
          onLoginSuccessfull && onLoginSuccessfull();
          navigate("/");
        }
        if (
          response.data.mandatory_field > 0 &&
          response.data.mandatory_field < 6
        ) {
          navigate(
            `/?token=${response?.data?.token}&openPopup=${response?.data?.mandatory_field}&display_name=${response?.data?.username}&profile_image=${response?.data?.image}`
          );
        }
      }
    } else {
      setGoogleError(true);
      setTimeout(() => {
        setGoogleError(false);
      }, 2000);
      console.log("not recieved token");
    }
  };
  return (
    <div className="signUp container-xl container-xxl row align-items-pages container pb-3 m-auto h-100 px-0  min-h-800">
      <div className="signUp_content col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
        <Heading3 className="signUp_heading my-0">
          Welcome to DropWorks
        </Heading3>
        <TextfieldSM className="signUp_normal uni-my-32 front-pages-second-heading">
          Use one of the options below to register
        </TextfieldSM>
        <form
          className="singup-form-mobile"
          onSubmit={onSubmit && handleSubmit(onSubmit)}
        >
          <div className="mbl-mb-8">
            <DropworksInput
              disabled={loading}
              className={`form-control login-specing login-input-first ${
                isSubmitted &&
                (errors.email
                  ? "is-invalid b-red-2"
                  : !errors.email
                  ? "is-valid  b-green-2"
                  : "")
              }`}
              {...register("email")}
              placeholder="Email"
            />
          </div>
          {errors.email && (
            <p className="invalid-feedback">{errors.email.message}</p>
          )}
          <DropworksPassword
            className="mb-0 relative login-specing "
            className3={isSubmitted}
            className2={`uni-mt-16 p-0 mbl-mt-8 signup-cstm-pass  form-control ${
              isSubmitted &&
              (errors.password
                ? "is-invalid b-red-2"
                : !errors.password
                ? "is-valid   b-green-2"
                : "")
            }`}
            disabled={loading}
            {...register("password")}
          />
          {errors.password && (
            <p className="invalid-feedback text-danger">
              {errors.password.message}
            </p>
          )}
          {!loading && (
            <DropworksButton
              className="mb-0 signUp_register uni-mt-32 font-weight-600"
              type="submit"
            >
              Register
            </DropworksButton>
          )}
        </form>
        {loading ? (
          <Loader className="uni-mt-40" />
        ) : (
          <>
            <TextfieldSM className="signUp_or text-center uni-my-16">
              OR
            </TextfieldSM>
            <div className="w-100 google-auth d-none">
              <GoogleOAuthProvider
                clientId={`${process.env.REACT_APP_Client_Id}`}
              >
                <GoogleLogin
                  onSuccess={(data: any) => {
                    console.log(data, "on success google");
                    GoogleLoginAuth(data);
                  }}
                  onError={() =>
                    setTimeout(() => {
                      setGoogleError(false);
                    }, 2000)
                  }
                  width={"100"}
                />
              </GoogleOAuthProvider>
            </div>
            <div className="uni-mb-32 mobile-mb-16">
              <GoogleSignInButton />
              {googleError && (
                <p className="invalid-feedback">Something went wrong</p>
              )}
            </div>

            <p className="signUp_already_registered  uni-mb-32 fs-6">
              Already registered?{" "}
              <span className="signUp_logins" onClick={navigateTo("/login")}>
                Login
              </span>
            </p>
            <TextfieldXSM className="signUp_privacy pb-0">
              By creating an account with DropWorks you agree with our{" "}
              <a className="signUp_privacy_link" onClick={navigateTo("/dropworks-terms")}>Terms of Use</a> and{" "}
              <a className="signUp_privacy_link" onClick={navigateTo("/dropworks-privacy-policy")}> Privacy Policy </a>
              {/* <a className="signUp_privacy_link" onClick={navigateTo("/login")}>Community Guidelines</a>. */}
            </TextfieldXSM>
          </>
        )}
      </div>
    </div>
  );
};
export default view;
