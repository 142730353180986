import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import "./styles.scss";
import {
  ButtonComponent,
  CloseModalButton,
  openPopup,
} from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import React, { FC, useEffect, useState } from "react";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import { WalletPin } from "./modal";
import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksRadioCheckbox } from "../../../../../common/custom-field/dropworks-radio-checkbox";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../redux/store";
import {
  getWalletDetails,
  getWalletDropStocks,
  setWalletDetails,
} from "../../../../../redux/reducer/walletSlice";

export const view: FC<WalletPin> = ({ closeModalRef, openModal }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { walletDetails } = useSelector((state: RootState) => state.wallet);
  const [selectedDropStocks, setSelectedDropStocks] = useState<any[]>([]);
  const cleanedWalletDetails = walletDetails.filter(
    (item) => item.art_work_image?.length > 0
  );
  const [remainingDropStocks, setRemainingDropStocks] =
    useState<any[]>(cleanedWalletDetails);

  const [allErrors, setAllErrors] = useState({
    amount: 1,
    submit: 1,
    amountMessage: "",
  });

  const DropStockRow: FC<{
    wallet: any;
    isChecked: boolean;
    onSelect: (wallet: any) => void;
  }> = React.memo(({ wallet, isChecked, onSelect }) => (
    <tr
      className="d-flex align-items-center justify-content-between table-row-dropstock p-0"
      onClick={() => onSelect(wallet)}
    >
      <td className="date-time-2">
        <div className="d-flex gap-3 align-items-center bottom-table">
          <span className="select-box wallet-select-box">
            {wallet?.art_work_image?.[0]?.src && (
              <img
                className="select-box bg-custom-trans"
                src={`${process.env.REACT_APP_IMGURL}/${wallet.art_work_image[0].src}`}
                alt=""
              />
            )}
          </span>
          <div className="d-flex cursor-pointer">
            <TextfieldLG className="my-0 p-0 select-title">
              {wallet.track_name}
            </TextfieldLG>
          </div>
        </div>
      </td>
      <td className="quantity d-flex p-0 align-items-end">
        <DropworksRadioCheckbox checked={false} />
      </td>
    </tr>
  ));

  const toggleDropStockSelection = (dropStock: any) => {
    setSelectedDropStocks((prevSelected) => {
      const isAlreadySelected = prevSelected.some(
        (item) => item.trackId === dropStock.trackId
      );

      if (isAlreadySelected) {
        // Move back to remainingDropStocks
        setRemainingDropStocks((prevRemaining) => {
          const isPresent = prevRemaining.some(
            (item) => item.trackId === dropStock.trackId
          );
          return isPresent ? prevRemaining : [...prevRemaining, dropStock];
        });
        return prevSelected.filter(
          (item) => item.trackId !== dropStock.trackId
        );
      } else {
        // Move to selectedDropStocks
        setRemainingDropStocks((prevRemaining) =>
          prevRemaining.filter((item) => item.trackId !== dropStock.trackId)
        );
        return [...prevSelected, { ...dropStock, amount: "" }]; // Initialize with empty amount
      }
    });
  };

  const validateAllAmounts = () => {
    const hasError = selectedDropStocks.some(
      (stock) =>
        !stock.amount ||
        parseFloat(stock.amount) <= 1 ||
        parseFloat(stock.amount) > parseFloat(stock.dropstockCount)
    );

    setAllErrors((prev) => ({
      ...prev,
      amount: hasError ? 2 : 3, // 2: Invalid, 3: Valid
      amountMessage: hasError ? "Ensure all amounts are valid." : "",
    }));
  };

  const handleCloseModalClick = () => {
    if (closeModalRef.current) {
      closeModalRef.current.click();
    }
  };

  const handleOnClick = () => {
    validateAllAmounts();
    const amounts = selectedDropStocks
      .filter((item) => item.amount)
      .map((item) => item.amount); // Extract `amount` values
    const trackNFTId = selectedDropStocks
      .filter((item) => item.nftId)
      .map((item) => item.nftId); // Extract `amount` values

    dispatch(
      setWalletDetails({
        amount: amounts || [],
        type: "dropstock",
        trackNFTId: trackNFTId || [],
        selectedDropStocks: selectedDropStocks || [],
      })
    );
    openPopup("WalletFeesDropStocksWithdrawModal", dispatch);
    handleCloseModalClick();
  };

  useEffect(() => {
    validateAllAmounts();
  }, [selectedDropStocks]);

  const openModal1 = () => {
    setAllErrors({
      amount: 0,
      submit: 0,
      amountMessage: "",
    });
  };

  useEffect(() => {
    dispatch(getWalletDetails());
    dispatch(getWalletDropStocks());
  }, []);

  return (
    <ModalWrapper
      modalId="WalletDropstockWithdrawModal"
      onBackdropClick={openModal1}
    >
      <div
        className="modal fade"
        id="WalletDropstockWithdrawModal"
        aria-hidden="true"
        aria-labelledby="WalletDropstockWithdrawModal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-width uni-md-popup">
          <div className="modal-content ">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header uni-mb-16">
              <Heading3 className="modal-title">DropStocks Withdraw</Heading3>
            </div>

            {/* <form> */}
            <div className="modal-body uni-mb-56">
              <TextfieldLG className="uni-mb-56 ctm-txt ctm-md-lh uni-mbt-16 uni-fw-700 gray3">
                Select the DropStocks you want to withdraw
              </TextfieldLG>

              <div className="dropstocks-assets dropstock-popup-container">
                <div className="table-view">
                  <div className="table-wrapper">
                    <table className="table custom-table-2  mb-0">
                      <thead className="text-dark d-flex flex-column border-0 mobile-table-thead">
                        {selectedDropStocks?.length > 0
                          ? selectedDropStocks.map((stock, index) => (
                              <div key={index}>
                                <tr className="d-flex align-items-center justify-content-between table-row-dropstock border-0 p-0 scroll-pr-20">
                                  <td className="date-time-2">
                                    <div className="d-flex gap-3 align-items-center bottom-table">
                                      <span className="select-box wallet-select-box">
                                        {stock.art_work_image[0]?.src && (
                                          <img
                                            className="select-box bg-custom-trans"
                                            src={`${process.env.REACT_APP_IMGURL}/${stock.art_work_image[0].src}`}
                                            alt=""
                                          />
                                        )}
                                      </span>
                                      <div className="d-flex cursor-pointer">
                                        <TextfieldLG className="my-0 p-0 select-title">
                                          {stock.track_name || ""}
                                        </TextfieldLG>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="quantity">
                                    <span className="table-data">
                                      <DropworksRadioCheckbox
                                        checked={true}
                                        onClick={() =>
                                          toggleDropStockSelection(stock)
                                        }
                                      />
                                    </span>
                                  </td>
                                </tr>

                                <div className="custom_pass_container_marketplace deposit-amount-input border-0 scroll-pr-20 p-0">
                                  <DropworksInput
                                    placeholder="Amount"
                                    value={stock.amount || ""}
                                    onChange={(e) => {
                                      const updatedAmount = e.target.value;
                                      setSelectedDropStocks((prev) =>
                                        prev.map((item, i) =>
                                          i === index
                                            ? { ...item, amount: updatedAmount }
                                            : item
                                        )
                                      );
                                      validateAllAmounts(); // Trigger validation after state update
                                    }}
                                    className={`uni-mt-16  form-control login-cstm-pass scroll-pl-20 ${
                                      stock?.amount > 1
                                        ? "is-valid b-green-2"
                                        : ""
                                    }${
                                      allErrors.amount == 2 && stock?.amount < 0
                                        ? "is-invalid b-red-2 mb-0"
                                        : ""
                                    }`}
                                    autoComplete="off"
                                    onInput={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      const maxy = stock.dropstockCount
                                        ? parseFloat(stock.dropstockCount)
                                        : -1;
                                      let value = e.target.value;

                                      // Remove non-numeric characters except dots
                                      value = value.replace(/[^0-9]/g, "");

                                      // Prevent more than one decimal point
                                      const parts = value.split(".");
                                      if (parts?.length > 2) {
                                        value = parts[0] + "." + parts[1];
                                      }

                                      // Parse the value as a float for comparison
                                      const numericValue = parseFloat(value);

                                      // Check if the value is greater than maxy or less than or equal to 0
                                      if (
                                        (value && numericValue > maxy) ||
                                        numericValue <= 0
                                      ) {
                                        e.target.value = value.slice(0, -1);
                                        return;
                                      }

                                      // Set the cleaned value
                                      e.target.value = value;
                                    }}
                                  />
                                </div>

                                <div
                                  className={`ds-ctm-txt gray4 m-0 d-flex scroll-pr-20 ${
                                    1 ? "uni-mb-16" : "uni-mb-16"
                                  }`}
                                >
                                  <p
                                    className={`invalid-feedback error-red p-0 `}
                                  >
                                    {stock.amount < 0 && stock.amountMessage}
                                  </p>

                                  <TextfieldXSM className="ds-ctm-txt gray4 r-text m-0 white-space-n p-0">
                                    Available: {stock.dropstockCount || "0.00"}{" "}
                                    DropStocks
                                  </TextfieldXSM>
                                </div>
                              </div>
                            ))
                          : ""}
                      </thead>
                      <tbody
                        className="uni-mt-8 p-0"
                        style={{ maxHeight: "336px" }}
                      >
                        {remainingDropStocks?.length > 0 ? (
                          remainingDropStocks.map((wallet: any) => (
                            <DropStockRow
                              key={wallet.trackId}
                              wallet={wallet}
                              isChecked={false}
                              onSelect={toggleDropStockSelection}
                            />
                          ))
                        ) : (
                          <>
                            {" "}
                            <tr className="m-0">
                              <td className="text-center">
                                {selectedDropStocks?.length ===
                                cleanedWalletDetails?.length
                                  ? ""
                                  : "No DropStocks available"}
                              </td>
                            </tr>
                            <tr className="m-0">
                              {walletDetails?.length === 0 && (
                                <td className="text-center">
                                  No DropStocks available
                                </td>
                              )}
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer social-mobile-modal-footer uni-mr-16">
              {selectedDropStocks?.length > 0 ? (
                <DropworksSmallButton
                  className="btn-wid btn-bg-gray3 my-0 ml-0 social-link-modal-btns"
                  disabled={allErrors.amount !== 3}
                  onClick={handleOnClick}
                >
                  Next
                </DropworksSmallButton>
              ) : (
                <DropworksSmallButton
                  className="btn-wid btn-bg-gray3 my-0 ml-0 social-link-modal-btns"
                  disabled={true}
                >
                  Next
                </DropworksSmallButton>
              )}
            </div>
            {/* </form> */}
            <CloseModalButton ref={closeModalRef} className="d-none">
              close
            </CloseModalButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
