import { initializeApp } from "firebase/app";
import { getMessaging ,getToken} from "firebase/messaging";
import { sendFirebaseToken } from "../app/shared/utils/apiServices";
 
const firebaseConfig =  {
  apiKey: "AIzaSyDXVtHGPnu8BxqFNsnsA3f9J11i627dILk",
  authDomain: "fir-dw-95194.firebaseapp.com",
  projectId: "fir-dw-95194",
  storageBucket: "fir-dw-95194.firebasestorage.app",
  messagingSenderId: "293169705293",
  appId: "1:293169705293:web:777b9f4db43b90956d4704",
  measurementId: "G-0N8PRV7Y57",
  // VapidKey: "BFran4cZ6xlsxUxnMjb0c53lr93DK1RTGAc9qisGaOWXz-2-M2XP79nNnWi0OTIOiogSEGcqDp8zdswplHirm08",
};
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
 
export const generateToken = async() =>{
 
    if (!window.Notification) {
        console.log("Browser does not support notifications.");
      } else {
        if (Notification.permission === "granted") {
            AfterTokenGranted();
        } else {
          Notification.requestPermission()
            .then(async (p) => {
              if (p === "granted") {
                AfterTokenGranted();
              } else {
                console.log("User blocked notifications.");
              }
            })
            .catch(function (err) {
              console.error(err);
            });
        }
      }
   
}
 
const AfterTokenGranted = async () => {
   
    try {
        const token = await getToken(messaging,{
            vapidKey:process.env.REACT_APP_Notification_VapidKey,
          });
          localStorage.setItem("dw_fb_token",token);
          let auth_token=localStorage.getItem("dropworksToken");
      try {
        const res:any = await sendFirebaseToken({auth_token: auth_token,device_token: token})
        if (res?.success) {
          console.log("token is registered in backend");
        } else {
          console.log(res.message,"cusses false in insert token of fire base");
        }
      } catch (error) {
        console.error(`error in api to insert token `,error);
      }
    } catch (error) {
      console.error("error create token API",error);
    }
  };