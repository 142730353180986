import { Heading2 } from "../../../../common/custom-field/dropstock-heading";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

const VerificationComplete = () => {
  const mail = useSelector((state: RootState) => state.mail);

  return (
    <div className="d-flex flex-column h-80vh">
      <Heading2 className=" text-center my-auto" role="alert">
        Verification link is sent to your email address:
        <Heading2 className=" text-center uni-mt-8" role="alert">
          {mail.Data}
        </Heading2>
      </Heading2>
    </div>
  );
};

export default VerificationComplete;
