import { FC, useEffect, useState, useCallback } from "react";
import { view } from "./view";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  getSearchValue?: (value: string) => void;
  clearInputExternal?: number;
}

export const DropworksSearchInput: FC<InputProps> = ({
  getSearchValue,
  clearInputExternal = 0,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");

  // Debounce function
  const debounce = (func: (...args: any[]) => void, wait: number) => {
    let timeout: ReturnType<typeof setTimeout>;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  // Debounced callback for getSearchValue
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      getSearchValue && getSearchValue(value);
    }, 1000), // Debounce delay in milliseconds
    [getSearchValue]
  );

  useEffect(() => {
    if (clearInputExternal > 0) {
      clearInput();
    }
  }, [clearInputExternal]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  const clearInput = () => {
    setInputValue("");
    getSearchValue && getSearchValue("");
  };

  return view({ handleInputChange, inputValue, clearInput, ...props });
};
