import { TextfieldLG} from '../../../../../common/custom-field/dropstock-text'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { CloseModalButton } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import Loader from '../../loader/view';


const view = ({callFinishApi,message}:{callFinishApi:()=>void,message?:string}) => {
  return (
    <div
    className="modal fade"
    id="acc-secured"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel"
    tabIndex={-1}
    data-bs-backdrop="static" 
    data-bs-keyboard="false"   
     data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered uni-md-popup">
      <div className="modal-content ">
    <div className='modal-cross-icon'>
      {/* <CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton> */}
        </div>
        <div className="modal-header ps-0 pt-0">
          <Heading3 className="modal-title">Account protected</Heading3>
        </div>
        
        <div className="modal-body ps-0 pt-0 pb-0">
          <TextfieldLG className="uni-mb-56 uni-mt-16 mbl-mt-8 ctm-txt mbl-mb-16 ">Enhance your account protection in Settings</TextfieldLG>
        </div>  
        <div className="modal-footer mbl-mt-8 ps-0 pt-0 mbl-modal-footer pb-0">
          {message === "Loading" ? <Loader/> : <CloseModalButton className="mbl-anchor-full-btn">
          <DropworksSmallButton  className="btn-wid  mbl-full-width-btn  m-0" onClick={callFinishApi}>Finish</DropworksSmallButton>
          </CloseModalButton>}
        </div>
        {message && message !=="Loading" && <p  className='text-danger w-100 text-center uni-mt-16'>{message}</p>}
      </div>
    </div>
  </div>
  )
}

export default view;


export const openButton=()=>{
  document.getElementById('okk')?.click();
}