import { useRef, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { view } from "./view";
import { verifyPin } from "../../../utils/apiServices";
import { useNavigate } from "react-router-dom";
import { openPopup } from "../../logo-header/view";
import { setValidateValue } from "../../../../../redux/reducer/auth";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../redux/store";
import {clearWalletDetails, getWalletDetails, getWalletDropStocks, getWalletTransactions } from "../../../../../redux/reducer/walletSlice";

export const WalletTransaction = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const closeModalRef = useRef<HTMLAnchorElement>(null);
  const navigate = useNavigate();
  const navigateTo = (value: string) => () => {
    navigate(value);
  };



  const handleCloseModalClick = () => {
    if (closeModalRef.current) {
      closeModalRef.current.click();
    }
  };

  const openModal = () => {
    window.location.reload();
    //reset()
    // dispatch(getWalletDetails());
    // dispatch(getWalletDropStocks());
    // dispatch(getWalletTransactions());
    dispatch(clearWalletDetails());
    setLoading(false);
  }
  return view({loading, navigateTo, closeModalRef, openModal });
}
export default WalletTransaction;