import { FC, useEffect, useState } from "react";
import "./styles.scss";
import PlaybackBtn from "../../../assets/img/play-btn/Beat-Playback-Btn.png";
import { UserLable } from "../../../common/user-labels";
import {
  DropworksDisableButton,
  DropworksSmallButton,
} from "../../../common/custom-field/dropworks-button";
import { MeatballsMenuDropdown } from "../../../common/icons";
import { viewType } from "./modal";
import { BlueTik } from "../../../common/all-icons";
import { Heading1 } from "../../../common/custom-field/dropstock-heading";
import {
  ButtonComponent,
  openPopup,
} from "../../shared/components/logo-header/view";
import { HeartIcon } from "../../../common/heart-icon";
import { MoreMobile } from "../../../common/moreMobile";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  setData,
  setDeleteModalData,
  setMusicPageDataLike,
  setMusicPlayerData,
  setMusicPlayerDataLike,
  setMusicTrack,
  setMusicTrackData,
} from "../../../redux/reducer/musicSlice";
import {
  setbeatModalId,
  setbeatPage,
  setOpenPopup,
  setReportedUserId,
} from "../../../redux/reducer/reportSlice";
import Loader from "../../shared/components/loader/view";
import { updateFevourite } from "../../shared/utils/apiServices";
import { TextfieldMD } from "../../../common/custom-field/dropstock-text";

export const view: FC<viewType> = ({ navigateTo, loading }) => {
  const dispatch = useDispatch();

  const beat = useSelector((state: RootState) => state.upload.beatData);
  const user = useSelector((state: RootState) => state.user);
  const {musicPageData , musicPlayer}=useSelector((state:RootState)=>state.music)
  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState(["Report"]);

  const [beatId, setBeatId] = useState({
    beat_id: "",
  });
  useEffect(() => {
    setBeatId({ beat_id: beat?.[0]?._id });
  }, [beat]);
 

  useEffect(() => {
    setMeatballsMenuOptions(["Report"]);
  }, [beat, user]);

  const userId = useSelector((state: RootState) => state.report);

  const OnClickMeatBalls = async (value: any) => {
    if (value === "Report") {
      dispatch(setReportedUserId(userId.userModalId));
      dispatch(setbeatPage(true));
      openPopup("ReportModal1", dispatch);
    }
  };

  const clickMeat = async (value: any) => {
    if (value === "Report") {
      dispatch(setbeatPage(true));
      dispatch(setbeatModalId(beat?.[0]?._id))
      openPopup("BeatReportModal", dispatch);
    } else if (value === "Delete") {
      dispatch(setDeleteModalData({
        deleteID:beat?.[0]?._id,
        deleteName:beat?.[0]?.beat_name,
        deleteImage:beat?.[0]?.art_work_image?.[0]?.src,
        deleteSlugName:beat?.[0]?.description,
        deleteType:"beat"
      }))
      openPopup("DeleteModal", dispatch);
    }
  };

const handleLike=async ()=>{
  dispatch(setMusicPageDataLike(!musicPageData.musicPageLiked))
  if(musicPageData.musicPageId === musicPlayer.musicId)
  {
    dispatch(setMusicPlayerDataLike(!musicPlayer.musicLiked))
  }
const obj = { beat_id: musicPageData.musicPageId }; // or { track_id: 456 } or { project_id: 789 }
const userID = user.id
try{

  const response = await updateFevourite(obj ,userID);
  if(response)
    {
      
  }
  else{
  }
}

catch{
}
}

const clickMeatMobile = async (value: any) => {
  if (value === "Report") {
    dispatch(setbeatPage(true));
    dispatch(setbeatModalId(beat?.[0]?._id))
     openPopup("BeatReportModal", dispatch);
  }
  else if (value === "Delete") {
    dispatch(setDeleteModalData({
      deleteID:beat?.[0]?._id,
      deleteName:beat?.[0]?.beat_name,
      deleteImage:beat?.[0]?.art_work_image?.[0]?.src,
      deleteSlugName:beat?.[0]?.description,
      deleteType:"beat"
    }))
    openPopup("DeleteModal", dispatch);
  }
 
};

  return (
    <>

      <div className="beats-page container container-xxl min-height-cover beats-page-minheight">
        <div className="beats-page-body">
          <div>
            <img
              src={`${process.env.REACT_APP_IMGURL}/${beat?.[0]?.art_work_image?.[0]?.src}`}
              className="beats-page-image"
              alt="user-image"
            />
          </div>
          <div className="beats-page-content">
            <div className="w-100">
              <div className="beats-page-details">
                <Heading1 className="beats-page-name font-weight-600 mbl-mb-0">
                  {beat?.[0]?.beat_name}
                  <img
                  src={PlaybackBtn}
                  className="beats-page-playback-Btn uni-ml-24 cursor-pointer"
                  alt="PlaybackBtn"
                  onClick={() => {
                    dispatch(setData(true));
                    dispatch(setMusicTrack(beat?.[0]?.audio_file?.[0]?.src));
                    dispatch(setMusicTrackData(beat));
                    dispatch(setOpenPopup("beat"));
                    dispatch(setMusicPlayerData({
                      musicName:beat?.[0]?.beat_name,
                      musicAudioSrc:beat?.[0]?.audio_file?.[0]?.src,
                      musicImgSrc:beat?.[0]?.art_work_image?.[0]?.src,
                      showOptions:true,
                      musicLiked:Boolean(beat?.[0]?.favoriteData?._id),
                      showMusicBar:false,
                      musicOwnedByMe:beat?.[0]?.user_id?._id ===user.id ,
                      musicDescription:beat?.[0]?.description,
                      musicId:beat?.[0]?._id,
                      musicPath:`beats-page/${beat?.[0]?.beat_slug_name}`,
                      musicType:"beat",
                      musicCreator:[{receiver_name:beat?.[0]?.user_id?.display_name, slug_name:beat?.[0]?.user_id?.slug_name,receiver_user_id:beat?.[0]?.user_id?._id}]
                      }))
                  }}
                />
                </Heading1>
                <img
                  src={PlaybackBtn}
                  className="beats-page-playback-Btn uni-ml-24 cursor-pointer d-none"
                  alt="PlaybackBtn"
                  onClick={() => {
                    dispatch(setData(true));
                    dispatch(setMusicTrack(beat?.[0]?.audio_file?.[0]?.src));
                    dispatch(setMusicTrackData(beat));
                    dispatch(setOpenPopup("beat"));
                    dispatch(setMusicPlayerData({
                      musicName:beat?.[0]?.beat_name,
                      musicAudioSrc:beat?.[0]?.audio_file?.[0]?.src,
                      musicImgSrc:beat?.[0]?.art_work_image?.[0]?.src,
                      showOptions:true,
                      musicLiked:Boolean(beat?.[0]?.favoriteData?._id),
                      showMusicBar:false,
                      musicOwnedByMe:beat?.[0]?.user_id?._id ===user.id ,
                      musicDescription:beat?.[0]?.description,
                      musicId:beat?.[0]?._id,
                      musicPath:`beats-page/${beat?.[0]?.beat_slug_name}`,
                      musicType:"beat",
                      musicCreator:[{receiver_name:beat?.[0]?.user_id?.display_name, slug_name:beat?.[0]?.user_id?.slug_name, receiver_user_id:beat?.[0]?.user_id?._id}]

                      }))
                  }}
                />
                 <span className="beats-icon-track hide-pc-icon-beat">
              <HeartIcon
              liked={musicPageData.musicPageLiked}
              onClickHeart={handleLike}
              />
          </span>
              </div>
              <div className="beats-page-song-title link-btm">
                <TextfieldMD
                  className="underline-opening mb-0 mt-0 m-0"
                  onClick={navigateTo(
                    `/user-profile/${beat?.[0]?.user_id?.slug_name}`
                  )}
                >
                  {beat?.[0]?.user_id?.display_name}
                </TextfieldMD>
                                  
              </div>
              {/* <div className="beats-page-song-title link-btm">
                  {beat?.collaborators &&
                    beat.collaborators.map((data: any, index: number) => (
                      <React.Fragment key={data.slugName}>
                        <TextfieldMD
                          className="underline-opening mb-0 mt-0 m-0"
                          onClick={navigateTo(
                            `/user-profile/${data.slug_name}`
                          )}
                        >
                          {data.receiver_name}
                        </TextfieldMD>
                        {index < beat.collaborators.length - 1 && ", "}
                      </React.Fragment>
                    ))}
                </div> */}
            </div>
            <div className="d-flex gap-3 mt-2 w-100">
              <UserLable>Beat</UserLable>
            </div>
          </div>
        </div>

        <div className="d-flex gap-3 uni-mt-mb-64 beats-page-bottons">
          <DropworksDisableButton
            onClick={navigateTo("/message-chat")}
            className="message-btn font-weight-600"
          >
            Message
          </DropworksDisableButton>

          <MoreMobile
            className="px-0 mobile-p-0"
           pagename="call-function"
            arr={
              beat?.[0]?.user_id?._id === user.id
                ? ["Delete"]
                : ["Report"]
            }
            onClickOptions={(value: any) => clickMeatMobile(value)}
          />

          {beat?.[0]?.user_id?._id === user.id ? (
            ""
          ) : (
            <ButtonComponent
              id="InviteModal"
              className="invite-disabled-btn-mbl"
            >
              <DropworksSmallButton className="width-178 message-btn invite-btn-disable-mbl">
                Invite
              </DropworksSmallButton>
            </ButtonComponent>
          )}
          <span className="beats-icon-track beat-heart-mbl-hide">
            <HeartIcon
              liked={musicPageData.musicPageLiked}
              onClickHeart={handleLike}
            />
          </span>

          <span className="convert-more d-flex align-items-center">
            <MeatballsMenuDropdown
              pagename="call-funtion"
              className="p-0"
              arr={
                beat?.[0]?.user_id?._id === user.id ? ["Delete"] : ["Report"]
              }
              height={"9px"}
              onClickOptions={(value: any) => clickMeat(value)}
            ></MeatballsMenuDropdown>
          </span>
        </div>
        <h4 className="beats-page-subheading uni-mb-16">Creator</h4>
        <div className="beats-page-sub-content mb-4 ">
          <img
            src={`${process.env.REACT_APP_IMGURL}/${beat?.[0]?.user_id?.profile_image?.[0]?.thumbnail || beat?.[0]?.user_id?.profile_image?.[0]?.src}`}
            className="beats-page-sub-content-img cursor-pointer"
            alt=""
            onClick={
              beat?.[0]?.user_id?._id === user.id
                ? navigateTo(`/profile`)
                : navigateTo(`/user-profile/${beat?.[0]?.user_id?.slug_name}`)
            }
          />
          <div
            className="beats-page-sub-details cursor-pointer"
            onClick={
              beat?.[0]?.user_id?._id === user.id
                ? navigateTo(`/profile`)
                : navigateTo(`/user-profile/${beat?.[0]?.user_id?.slug_name}`)
            }
          >
            <h5 className="beats-page-sub-song-name">
              {beat?.[0]?.user_id?.display_name}
            </h5>
            <div className="uni-ml-8">
            {Boolean(beat?.[0]?.user_id?.is_bluetick) && <BlueTik position="top" />}
            </div>
            {beat?.[0]?.user_id?._id === user.id ? (
              ""
            ) : (
              <span className="drop-beats">
                <MeatballsMenuDropdown
                  arr={["Report"]}
                  pagename="call-funtion"
                  onClickOptions={(value: any) => OnClickMeatBalls(value)}
                  height={"5px"}
                  className="track-metaballsmenudropdown"
                ></MeatballsMenuDropdown>
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
