import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import "./styles.scss";
import { DropworksTextArea } from '../../../../../common/custom-field/dropworks-textArea';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import ModalWrapper from '../../../../../common/open-modal/ModalWrapper';

const view = ({bioGraphy ,onChange,submitData,openModal}:any) => {

  return (
    <ModalWrapper modalId="BiographyModal" onBackdropClick={openModal}>
    <div
    className="modal fade"
    id="BiographyModal"
    aria-hidden="true"
    aria-labelledby="BiographyModal"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered uni-md-popup">
      <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
        <div className="modal-header ps-0 pt-0">
          <Heading3 className="modal-title">Biography</Heading3>
        </div>
        
        <div className="modal-body ps-0 pt-0 pb-0">
          <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt ctm-md-lh  modal-second-head-font">To be displayed in your profile</TextfieldLG>
          <DropworksTextArea className="uni-mb-56 ctm-textarea biography-textarea-mbl w-100" placeholder="Biography" value={bioGraphy} onChange={onChange} maxLength={250}/>

        </div>  
        <div className="modal-footer mobile-modal-footer ps-0 pt-0 pb-0">  
          <CloseModalButton disabled={!bioGraphy}>
          <DropworksSmallButton   disabled={!bioGraphy} className={ !bioGraphy ? "btn-wid  modal-btns m-0 custom-inactive-btn text-secondary disable-interactions":"btn-wid  modal-btns m-0"}  onClick={()=>submitData({ biography: bioGraphy })}>Save</DropworksSmallButton>
          </CloseModalButton>
        </div>
      </div>
    </div>
  </div>
  </ModalWrapper>
  )
}

export default view;


