import { TextfieldLG, TextfieldMD, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { DropworksSwitch } from '../../../../../common/custom-field/dropworks-switch';
import iconImg from "../../../../../assets/img/upload-icon.png";
import { DropworksSearchInput } from '../../../../../common/custom-field/dropworks-search-input';
import { closeModal, CloseModalButton } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useEffect, useState } from 'react';
import { deletAuploadFile, spotifyRerelease, updateInvitedCollaboratorsTrackProject } from '../../../utils/apiServices';
import { fetchRereleaseTrackDetails, fetchTrackDetails, getALlInvitedUserbyRereleaseTrackProject, getALlInvitedUserbyTrackProject } from '../../../../../redux/reducer/uploadSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../redux/store';
import Loader from '../../loader/view';
import ModalWrapper from '../../../../../common/open-modal/ModalWrapper';

const view = () => {
  const {selectedCollaborator,trackId,trackSlugname}=useSelector((state:RootState)=>state.upload);
  const [changeName,setChangeName]=useState("");
  const [is_spotify_link,setIs_spotify_link]=useState<boolean>(false);
  const [message,setMessage]=useState<string>("")
  const dispatch =useDispatch<AppDispatch>(); 
  const [loading,setLoading]=useState<boolean>(false);
  const path =window.location.pathname

  useEffect(()=>{
    if(selectedCollaborator){
      setChangeName(selectedCollaborator?.spotify_link||"")
      }
  },[selectedCollaborator])


  const validateCustomUrl = (url: string) => {
    if (url) {
      const urlRegex = new RegExp(
        `^https?:\/\/(www|open)\\.spotify\\.com\/.*$`
      );
      if (!urlRegex.test(url)) {
        setMessage("The URL is not valid. Enter a valid Spotify profile link.");
        return false;
      }
    }
    setMessage("");
    return true;
  };
  

  const handleChange = (e: any) => {
    const value = e.target.value;
    setChangeName(value);
    validateCustomUrl(value); 
  };



  const click = async () => {
    setLoading(true);
    const data={
       "spotify_link":changeName,
       "is_spotify_link":true,
       "track_id":trackId,
       ...(selectedCollaborator && {  "collaborator_id": selectedCollaborator.collaborator_id }),
   }
 
       const response = await spotifyRerelease(data);
       if (response.success) {
      
          dispatch(getALlInvitedUserbyRereleaseTrackProject(selectedCollaborator.track_id));
        
         closeModal();
         setLoading(false);
       } else {
        setLoading(false);
         setMessage(response.message)
       }
     
   };

   


  const openModal=()=>{
    setChangeName(changeName)
    setMessage("")
}
  return (
    <ModalWrapper modalId="SpotifyVerifyRerelease" onBackdropClick={openModal}>
    <div
      className="modal fade"
      id="SpotifyVerifyRerelease"
      aria-hidden="true"
      aria-labelledby="SpotifyVerifyRerelease"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width ">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className="modal-title">Spotify Profile Link</Heading3>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
            <TextfieldLG className={`uni-mt-16 ctm-txt ctm-md-lh uni-mb-56`}>Enter the spotify profile link of {selectedCollaborator.display_name}</TextfieldLG>
              <DropworksInput value={changeName} className={`form-control   ${changeName?message && message?"is-invalid b-red-2":"is-valid b-green-2":""} `} onChange={handleChange} placeholder={`https://open.spotify.com/`} ></DropworksInput>
              {message && <p className=' text-danger invalid-feedback'>{message}</p>}
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-mt-40 uni-pr-72">
            {loading?<Loader className="uni-mt-16"/>:<DropworksSmallButton className={ changeName && message=="" ?"btn-wid  m-0":"btn-wid m-0 custom-inactive-btn text-secondary disable-interactions"} onClick={click}>Confirm</DropworksSmallButton>}
          </div>
        </div>
      </div>
    </div>
    </ModalWrapper>
  )
}

export default view;

