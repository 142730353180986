import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { boolean } from "yup";

export interface trackDetails {
 trackDetailsReleaseDate:string,
 trackDeatilsCreatorList:string[],
 trackDetailsUniqueId:string
}
interface BeatSlice {
   Data:any,
   isLiked:boolean,
   trackDetailsReleaseDate:string,
   trackDeatilsCreatorList:string[],
   trackDetailsUniqueId:string
  }

const initialState : BeatSlice = {
  Data:"", 
  isLiked:false,
  trackDetailsReleaseDate:"",
  trackDeatilsCreatorList:[],
  trackDetailsUniqueId:""
};


const beatSlice = createSlice({
  name: "invite",
  initialState,
  reducers: {
    setBeat: (state, action: PayloadAction<any>) => {
      state.Data = action.payload; 
    },
    setIsLiked: (state, action: PayloadAction<boolean>) => {
      state.isLiked = action.payload; 
    },
    clearIsLike: (state) => {
      state.isLiked = false; 
    },
    setTrackDetailsPage:(state,action:PayloadAction<trackDetails>)=>{
      state.trackDetailsReleaseDate=action.payload.trackDetailsReleaseDate;
      state.trackDeatilsCreatorList=action.payload.trackDeatilsCreatorList;
      state.trackDetailsUniqueId=action.payload.trackDetailsUniqueId
    }
  },
});


export const { setBeat,setIsLiked ,clearIsLike,setTrackDetailsPage } = beatSlice.actions;


export const beatReducer = beatSlice.reducer;
