import React, { FC, useEffect, useState } from "react";
import "./styles.scss";
import trackImage from "../../../assets/img/temporary-images/track-image.svg";
import { UserLable } from "../../../common/user-labels";
import { ArrowDropdownGray4, MeatballsMenuDropdown } from "../../../common/icons";
import { BlueTik, TrackPlayBackBtn, YelloTik } from "../../../common/all-icons";
import { viewType } from "./modal";
import { setData, setMusicPageData, setMusicPageDataLike, setMusicPlayerData, setMusicPlayerDataLike, setMusicTrack, setMusicTrackData } from "../../../redux/reducer/musicSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Heading1 } from "../../../common/custom-field/dropstock-heading";
import { TextfieldMD, TextfieldXSM } from "../../../common/custom-field/dropstock-text";
import { HeartIcon } from "../../../common/heart-icon";
import { MoreMobile } from "../../../common/moreMobile";
import { openPopup } from "../../shared/components/logo-header/view";
import {
  setbeatPage,
  setOpenPopup,
  setTrackModalId,
  setUserModalId,
} from "../../../redux/reducer/reportSlice";
import { apionMusicPlay, updateFevourite } from "../../shared/utils/apiServices";
import { ErrorImg, filteredArtist } from "../../shared/utils/localStorage";
import { setTrackDetailsPage } from "../../../redux/reducer/beatSlice";
import { DropworksSmallButton } from "../../../common/custom-field/dropworks-button";
import { Console } from "console";
import { useNavigate } from "react-router-dom";

export const view: FC<viewType> = ({ navigateTo, trackData }) => {
  const user = useSelector((state: RootState) => state.user);
  const navigate=useNavigate()
  const {musicPageData ,musicPlayer} =useSelector((state:RootState)=>state.music)
   const userData = useSelector((state: any) => state.user);
  const [songTeam, setSongTeam] = useState([
    {
      img: ``,
      fullName: "",
      title: "",
      goldTik: false,
      blueTik: false,
      id: "",
      collaborator_id: "",
    },
  ]);

  useEffect(() => {
    if (Array.isArray(trackData?.collaborators)) {
      setSongTeam(
        trackData?.collaborators?.map((item: any) => ({
          fullName: item.receiver_name,
          title: item.role,
          img: item.profile_image?.[0]?.thumbnail || item.profile_image?.[0]?.src,
          slugName: item.slug_name,
          goldTik: item.isyellow_tick === 0 ? false : true,
          blueTik: Boolean(item.is_bluetick),
          id: item?.receiver_user_id,
          collaborator_id: item._id,
        }))
      );
    }
  }, [trackData?.collaborators]);



  const [linksArray, setLinkAyyay] = useState<any>([]);
  useEffect(() => {
    if (Boolean(trackData?.streaming?.length)) {
      setLinkAyyay(
        trackData?.streaming?.map((data: any) => ({
          name: data.streaming_name,
          link: data.streaming_link,
        }))
      );
    }
  }, [trackData]);

  const OnClickMeatBalls = async (value: any, item: any) => {
    if (value == "Report") {
      openPopup("ReportModal1", dispatch);
      dispatch(setUserModalId(item));
    }
    else if(value === "Details"){
      openPopup("TrackDetailsPopUp", dispatch);
    }
    dispatch(setbeatPage(false));
  };


  const dispatch = useDispatch();

  const trackImg = trackData?.art_work_image?.[0]?.src;



  const clickMeat = async (value: any) => {
    if (value === "Report") {
        dispatch(setTrackModalId(trackData._id));
      dispatch(setbeatPage(true));
      openPopup("TrackReportModal", dispatch);
    }
    else if(value === "Details"){
    dispatch(setTrackDetailsPage({...trackData.trackPopupdetail}));
     
      openPopup("TrackDetailsPopUp", dispatch);
    }
  };

  const playMusic=()=>{
  
    let newMusicData=trackData.upload_file.filter((ele:any)=>ele.file_type==="Final mix and master");
    if( trackData && newMusicData && Boolean(newMusicData.length))
    {
      dispatch(setData(true));
      apionMusicPlay({ name: "track_id", value: trackData?._id });
      dispatch(setOpenPopup("track"));
      dispatch(setMusicTrackData([{...trackData,beat_name:trackData.track_name}]));   
      dispatch(setMusicTrack(newMusicData?.[0]?.src));
      dispatch(setMusicPlayerData({
      musicName:trackData?.track_name,
      musicAudioSrc:newMusicData?.[0]?.src,
      musicImgSrc:trackData?.art_work_image?.[0]?.thumbnail || trackData?.art_work_image?.[0]?.src,
      showOptions:true,
      musicLiked:Boolean(trackData?.favoriteData?._id),
      showMusicBar:true,
      musicCreator:trackData?.collaborators,
      musicOwnedByMe:trackData?.user_id?._id === user.id,
      musicDescription:trackData?.description,
      musicId:trackData?._id,
      musicPath:`/track-page/${trackData?.slug_name}`,
      musicType:"track",
      musicPageTrackData:trackData?.trackPopupdetail 
    }))
    }
  }

const handleLike=async ()=>{
  dispatch(setMusicPageDataLike(!musicPageData.musicPageLiked))
  if(musicPageData.musicPageId === musicPlayer.musicId)
  {
    dispatch(setMusicPlayerDataLike(!musicPlayer.musicLiked))
  }
const obj = { track_id: musicPageData.musicPageId }; // or { track_id: 456 } or { project_id: 789 }
const userID = user.id
try{

  const response = await updateFevourite(obj ,userID);
  if(response)
    {
      
  }
  else{
  }
}

catch{
}
}

const clickMeatMobile = async (value: any) => {
  if (value === "Report") {
    dispatch(setTrackModalId(trackData._id));
  dispatch(setbeatPage(true));
  openPopup("TrackReportModal", dispatch);
}
   else if(value === "Details"){
  dispatch(setTrackDetailsPage({...trackData.trackPopupdetail}));
   
    openPopup("TrackDetailsPopUp", dispatch);
  }
   
  };

  return (
    <>
      <div className="track-page container container-xxl min-height-cover">
        <div className="track-page-body uni-mb-56">
          <div className="image-track">
            <img
              src={`${process.env.REACT_APP_IMGURL}/${trackImg}` || trackImage}
              className="track-page-image"
              alt="user-image"
              onError={(e) => (e.currentTarget.src = ErrorImg)}
            />
          </div>
          <div className="track-page-content">
            <div className="w-100 d-flex align-items-center uni-mb-8 track-page-head-content track-mbl-view">
              <div className="track-page-details">
                <Heading1 className="mb-0 head">
                  {trackData?.track_name} 
                  <span className="track-pc-hide-icon" onClick={()=>{playMusic();}}>
                <TrackPlayBackBtn className="play-btn-track uni-ml-24" />
                    </span>   
                </Heading1>
                <div className="track-page-song-title link-btm">
                  {/* {trackData?.collaborators &&
                    trackData.collaborators.map((data: any, index: number) => (
                      <React.Fragment key={data.slugName}>
                        <TextfieldMD
                          className="underline-opening mb-0 mt-0 m-0"
                          onClick={navigateTo(
                            `/user-profile/${data.slug_name}`
                          )}
                        >
                          {data.receiver_name}
                        </TextfieldMD>
                        {index < trackData.collaborators.length - 1 && ", "}
                      </React.Fragment>
                    ))} */}
                     {filteredArtist(trackData?.collaborators)?.map((data: any, index: number, arr: any) => (
                        <React.Fragment key={data.slugName}>
                          <TextfieldMD
                            className="underline-opening mb-0 mt-0 m-0"
                            onClick={() =>
                              userData.id == data?.receiver_user_id
                                ? navigate(`/profile`)
                                : navigate(`/user-profile/${data?.slug_name}`)
                            }
                          >
                            {data.receiver_name}
                          </TextfieldMD>
                          {index < arr?.length - 1 && ", "}
                        </React.Fragment>
                      ))}
                </div>
              </div>
              <div
                className="track-page-playback-Btn"
                onClick={() => {
                  playMusic();
                }}
              >
                <TrackPlayBackBtn className="play-btn-track uni-ml-24" />
              </div>
              <div className="mbl-heart-icon-track ">
                 <HeartIcon
                  liked={musicPageData.musicPageLiked}
                  onClickHeart={handleLike}
                />
                </div>
            </div>
            <div className="d-flex gap-3 mt-2 w-100 mobile-viewtrackbutton">
              <UserLable className="track-lable-btm">Track</UserLable>
              {/* <UserLable
                className={`user-lables-gradient cursor-pointer`}
                onClick={navigateTo("/dropworks-composite")}
              >
                DropWorks Composite
              </UserLable>
              <UserLable
                className={`user-lables-gradient2 cursor-pointer uni-mb-8`}
                onClick={navigateTo("/dropworks-select")}
              >
                DropWorks Select
              </UserLable> */}
                 {linksArray.filter((ele:any)=>ele.link).length>0 ? (
                              <DropworksSmallButton
                                className="user-profiles-select-buttons uni-bg-disable position-relative m-0 mbl-mb-8"
                                data-bs-toggle="dropdown"
                              >
                                <TextfieldXSM className="m-0 ">Streaming Links</TextfieldXSM>
                                <ArrowDropdownGray4
                                  pagename="user-profile"
                                  className="px-0 "
                                  arr={linksArray.filter((ele:any)=>ele.link)}
                                />
                              </DropworksSmallButton>
                            ) : (
                              ""
                            )}
              <div className="uni-py-8 heart-icon-track ">
                <HeartIcon
                  liked={musicPageData.musicPageLiked}
                  onClickHeart={handleLike}
                />
              </div>

              <MoreMobile
                className="px-0 mobile-p-0 "
                arr={["Report","Details"]}
                pagename="call-function"
                onClickOptions={(value: any) => clickMeatMobile(value)}
              />

              <span className="convert-more d-flex align-items-center">
                {trackData?.user_id?._id === user?.id ? (
                   <MeatballsMenuDropdown
                   pagename="call-funtion"
                   className="p-0"
                   arr={["Details"]}
                   height={"6px"}
                   onClickOptions={(value: any) => clickMeat(value)}
                 ></MeatballsMenuDropdown>
                ) : (
                  <MeatballsMenuDropdown
                    pagename="call-funtion"
                    className="p-0"
                    arr={["Report","Details"]}
                    height={"6px"}
                    onClickOptions={(value: any) => clickMeat(value)}
                  ></MeatballsMenuDropdown>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between flex-wrap track-page-main">
          {songTeam.map((item: any, index: number) => (
            <div
              key={index}
              className="col-xxl-5 col-xl-5 col-md-5  col-12  uni-mb-16 track-page-main-sub"
            >
              <div className="track-page-col">
                <div>
                  <h4 className="track-page-subheading d-flex">
                    {/* {item.title} */}
                    {index==0?"Creator":item.title}
                    {item.goldTik ? (
                      <div className="uni-ml-8">
                        {/* <YelloTik position="top" /> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </h4>
                </div>
                <div
                  className="track-page-sub-content mb-4"
                >
                  <img
                    src={`${process.env.REACT_APP_IMGURL}/${item.img}`}
                    className="track-page-sub-content-img cursor-pointer"
                    alt=""
                    onClick={
                      item?.id === user?.id
                        ? navigateTo(`/profile`)
                        : navigateTo(`/user-profile/${item.slugName}`)
                    }
                  />
                  <div className="track-page-sub-details">
                    <h5 className="track-page-sub-song-name cursor-pointer"
                    onClick={
                      item?.id === user?.id
                        ? navigateTo(`/profile`)
                        : navigateTo(`/user-profile/${item.slugName}`)
                    }>
                      {item.fullName}
                    </h5>

                    {item.blueTik ? (
                      <div className="uni-ml-8">
                        <BlueTik position="top" />
                      </div>
                    ) : (
                      ""
                    )}
                    <span className="drop-track">
                      {item?.id === user?.id ? (
                        ""
                      ) : (
                        <MeatballsMenuDropdown
                          arr={["Report"]}
                          height={"5px"}
                          className="track-metaballsmenudropdown"
                          pagename="call-funtion"
                          onClickOptions={(value: any) =>
                            OnClickMeatBalls(value, item.id)
                          }
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
