import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import "./styles.scss"
import { BlueTik } from '../../../../../common/all-icons';
import kingImage from "../../../../../assets/img/king.png"
import { CloseModalButton } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import {  deletAuploadFile } from '../../../utils/apiServices';
import { fetchRereleaseTrackDetails, fetchTrackDetails } from '../../../../../redux/reducer/uploadSlice';
import { useParams } from 'react-router-dom';

const view = () => {
  const { selectedFile,trackId,selectedCollaborator,trackSlugname} = useSelector((state: RootState) => state.upload);
  const dispatch=useDispatch()
  const click = async () => {
   const data={
      "verification_status":"delete",
      "upload_file_id":selectedFile?._id,
      "track_id":trackId,
      ...(selectedCollaborator && {  "collaborator_id": selectedCollaborator.collaborator_id}),
  }

      const response = await deletAuploadFile(data);
      if (response.success) {
        dispatch(fetchTrackDetails(trackSlugname) as any);
        dispatch(fetchRereleaseTrackDetails(trackSlugname) as any)
      } else {
      }
    
  };
 
  
  return (
    <div
      className="modal fade" 
      id="DeleteFile"
      aria-hidden="true"
      aria-labelledby="DeleteFile"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width ">
        <div className="modal-content approve-modal-width m-auto">
        <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72 uni-mb-32">
            <Heading3 className='approve-modal-title mx-0 my-0'>Delete file?</Heading3>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            <TextfieldLG className='approve-modal-subtitle uni-mb-32 mt-0'>Doing so will permanently remove this file from the project</TextfieldLG>
            <TextfieldSM className='text-g3 mx-0 my-0'>Type:<span className='text-g5'>{selectedFile?.file_type}</span></TextfieldSM>
            <TextfieldSM className='text-g3 mx-0 my-0'>Description:  <span className='text-g5'>{selectedFile?.short_description}</span></TextfieldSM>
            <TextfieldSM className='text-g3 mx-0 my-0'>Date:<span className='text-g5'> {new Date(selectedFile?.upload_date).toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, 
    })}</span></TextfieldSM>
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton>
            <DropworksSmallButton className="btn-wid btn-bg-gray3-approve my-0 ml-0">Cancel</DropworksSmallButton></CloseModalButton>
            <CloseModalButton>
            <DropworksSmallButton className="btn-wid m-0" onClick={click}>Delete</DropworksSmallButton></CloseModalButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default view;


