import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import "./styles.scss"
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
const view = () => {
  return (
    <div
      className="modal fade"
      id="ProfilePictureModal2"
      aria-hidden="true"
      aria-labelledby="ProfilePictureModal2"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
      <div className="modal-content mx-auto  w-auto ">
      <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className="modal-title">Upload a Banner</Heading3>
          </div>

          <div className="modal-body  ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            <TextfieldLG className=" uni-mt-16 uni-mb-16 ctm-txt"> Use a 16:9 aspect ratio with dimensions of 1500px * 850 or higher</TextfieldLG>
            <TextfieldXSM className="ctm-txt2 uni-mb-56 mb-16-mbl">(Banner will be displayed in your profile)</TextfieldXSM>
            <div className='profile-pic-white-space-rectangle'>
            </div>
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72 social-mobile-modal-footer">
            <CloseModalButton className='buttoncomponent-mobile-modle'>
              <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 social-link-modal-btns">Cancel</DropworksSmallButton></CloseModalButton>
            <CloseModalButton className='buttoncomponent-mobile-modle'>
              <DropworksSmallButton className="btn-wid m-0 social-link-modal-btns">Save</DropworksSmallButton></CloseModalButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default view;


