import { FC, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { viewType } from "./modal";
import { UserIcon } from "../../../common/user-icon";
import { TrackIcon } from "../../../common/track-icon";
import { BeatesIcon } from "../../../common/beats-icon";
import { DropworksSearchInput } from "../../../common/custom-field/dropworks-search-input";
import { ProjectIcon } from "../../../common/project-icon";
import Tabs from "../../../common/tabs";
import TabsComponent from "../../../common/tabs-component/view";
import {
  Heading2,
  Heading5,
} from "../../../common/custom-field/dropstock-heading";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoCloseCircleOutline } from "react-icons/io5";
import { closeModal, CloseModalButton } from "../../shared/components/logo-header/view";
import { ref } from "yup";

export const view: FC<viewType> = ({
  navigateTo,
  allUserData,
  allBeatsData,
  allProjectData,
  allTrackData,
  userarr,
  filteredUsers,
  selectedRoles,
  handleRoleChange,
  beatsarr,
  projectarr,
  trackarr,
  getSearchValue,
  searchResult,
  selectedRoles1,
  handleRoleChange1,
  filteredTrackarr,
  loading
}) => {
  const dropdownarrRoles = {
    heading: "Roles",
    option: [
      "Music Preneur",
      "Producer",
      "Vocalist",
      "Lyricist",
      "Beat Maker",
      "Curator",
      "Artwork Designer",
    ],
  };
  useEffect(() => {
    return () => {
      document.querySelectorAll('.modal-backdrop').forEach((backdrop) => backdrop.remove());
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };
  }, []);

  const dropdownarrTracks = {
    heading: "Genre",
    option: [
      "R&B",
      "Hip Hop",
      "Rock",
      "Pop",
      "Electropop",
      "Dance - pop",
      "J - pop",
      "K - pop",
      "EDM",
      "House",
      "Deep House",
      "Tech House",
      "Progressive House",
      "Electro House",
      "Techno",
      "Peak Time Techno",
      "Melodic House Techno",
      "Progressive Techno",
      "Trance",
      "Future Bass",
      "Latina",
      "Alterative",
      "LoFi",
      "Ballad",
      "Others",
    ],
  };

  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
  };



  return (
    <div className="search container container-xxl min-height-cover">
      <Heading2 className="search-heading mx-0 my-0" tabIndex={2}>
        Search {searchResult && " Result"}
      </Heading2>
      <DropworksSearchInput
        className="uni-my-40 search-custm-input"
        getSearchValue={getSearchValue}
      />
      <Tabs
        tabsTitle={["All", "Users", "Tracks", "Projects", "Beats"]}
        id="5"
        className="uni-mb-40"
        checkTabState={checkTabState}
      />
      <TabsComponent
        tabsValue={tabsValue}
        components={[
          <>
            {allUserData ? (
              <Heading5 className="search-subheading">Users</Heading5>
            ) : (
              ""
            )}
            <div className="search-usericon">
         {!loading &&   <UserIcon
                arr={userarr?.slice(0, 6)}
                className="all-icon-mobile uni-pl-24 full-width-child"
                className2="user-icon-mobile-view"
              />}
            </div>
            {allTrackData ? (
              <Heading5 className="search-subheading">Tracks</Heading5>
            ) : (
              ""
            )}
            <div className="search-usericon">
            {!loading &&   <TrackIcon
                arr={trackarr?.slice(0, 6)}
                className="all-icon-mobile uni-pl-24 full-width-child"
                className2="track-icon-mobile-view"
              />}
            </div>
            {allProjectData ? (
              <Heading5 className="search-subheading">Projects</Heading5>
            ) : (
              ""
            )}
            <div className="search-usericon">
            {!loading &&   <ProjectIcon
                arr={projectarr?.slice(0, 6)}
                className="all-icon-mobile uni-pl-24 full-width-child"
                className2="project-icon-mobile-view"
              />}
            </div>
            {allBeatsData ? (
              <Heading5 className="search-subheading">Beats</Heading5>
            ) : (
              ""
            )}
            <div className="search-usericon">
            {!loading &&    <BeatesIcon
                arr={beatsarr?.slice(0, 6)}
                className="all-icon-mobile uni-pl-24 full-width-child"
                className2="beats-icon-mobile-view"
              />}
            </div>
          </>,
          <>
            <div className="uni-mb-16">
              <div className="dropworks-dropdown">
                <button
                  className="dropworks-dropdown-button dropdown-toggle  uni-fw-700"
                  type="button"
                  id="dropdownMenuClickableInside"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  {dropdownarrRoles.heading}{" "}
                  <RiArrowDropDownLine className="fs-4" />
                </button>

                <ul
                  className="dropdown-menu dropworks-dropdown-menu"
                  aria-labelledby="dropdownMenuClickableInside"
                >
                  {dropdownarrRoles.option.map((data) => (
                    <li key={data}>
                      <label className="dropdown-item d-flex align-items-center justify-content-between dropworks-dropdown-menu-option ">
                        <span className="text-wrap filter-option">{data}</span>
                        <input
                          type="checkbox"
                          name="role"
                          value={data}
                          checked={selectedRoles.includes(data)}
                          onChange={() => handleRoleChange(data)}
                          className="custom-checkbox me-2"
                        />
                        <span className="custom-radio"></span>
                      </label>
                    </li>
                  ))}
                </ul>

                {selectedRoles.map((role) => (
                  <button key={role} className="dropworks-dropdown-button">
                    {role}{" "}
                    <IoCloseCircleOutline
                      onClick={() => handleRoleChange(role)}
                      className="dropworks-dropdown-button-icon"
                    />
                  </button>
                ))}
              </div>
            </div>
            <div className="search-usericon">
              <UserIcon
                arr={selectedRoles?.length > 0 ? filteredUsers : userarr}
              />
            </div>
          </>,
          <>
            <div className="uni-mb-16">
              <div className="dropworks-dropdown">
                <button
                  className="dropworks-dropdown-button dropdown-toggle  uni-fw-700"
                  type="button"
                  id="dropdownMenuClickableInside"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  {dropdownarrTracks.heading}{" "}
                  <RiArrowDropDownLine className="fs-4" />
                </button>

                <ul
                  className="dropdown-menu dropworks-dropdown-menu"
                  aria-labelledby="dropdownMenuClickableInside"
                >
                  {dropdownarrTracks.option.map((data) => (
                    <li key={data}>
                      <label className="dropdown-item d-flex align-items-center justify-content-between dropworks-dropdown-menu-option ">
                        <span className="text-wrap filter-option">{data}</span>
                        <input
                          type="checkbox"
                          name="role"
                          value={data}
                          checked={selectedRoles1.includes(data)}
                          onChange={() => handleRoleChange1(data)}
                          className="custom-checkbox me-2"
                        />
                        <span className="custom-radio"></span>
                      </label>
                    </li>
                  ))}
                </ul>

                {selectedRoles1.map((role) => (
                  <button key={role} className="dropworks-dropdown-button">
                    {role}{" "}
                    <IoCloseCircleOutline
                      onClick={() => handleRoleChange1(role)}
                      className="dropworks-dropdown-button-icon"
                    />
                  </button>
                ))}
              </div>

              {/* <DropworksDropdown arr={dropdownarrTracks} className="search-dropdownbutton"  tabsValue={tabsValue}  updatedData={allData2} allData={allSeletedValues1}/> */}
            </div>
            <div className="search-usericon">
            {!loading &&   <TrackIcon
                arr={selectedRoles1?.length > 0 ? filteredTrackarr : trackarr}
              />}
            </div>
          </>,
          <>
            <div className="mb-4"></div>
            <div className="search-usericon">
            {!loading &&   <ProjectIcon arr={projectarr} />}
            </div>
          </>,
          <>
            <div className="mb-4"></div>
            <div className="search-usericon">
            {!loading &&    <BeatesIcon arr={beatsarr} />}
            </div>
          </>,
        ]}
      />
    </div>
  );
};
