import "./styles.scss";
import { Heading1 } from "../../../common/custom-field/dropstock-heading";
import { TextfieldSM } from "../../../common/custom-field/dropstock-text";
import { DropworksPrimaryButton } from "../../../common/custom-field/dropworks-button";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { getformatDate, getformatTime } from "../../shared/utils/localStorage";

const view = () => {
  const navigate = useNavigate();
  const {
    isMaintenance,
    title,
    description,
    maintenance_type,
    maintenance_from_date,
    maintenance_to_date,
  } = useSelector((state: RootState) => state.maintenance);

  return (
    <div className="marketplaceProduct-main-thirdrow mx-auto uni-pt-32 container container-xl container-xxl">
      <div className="scheduled-maintenance-content d-flex justify-content-between mt-0">
        <div className="scheduled-maintenance-main">
          <Heading1 className="text-white">Scheduled Maintenance</Heading1>
          <Heading1 className="uni-mb-56 text-white">
            We're Enhancing Your Experience
          </Heading1>
          <TextfieldSM className="uni-mb-56 scheduled-maintenance-text">
            Our website is currently undergoing scheduled maintenance to bring
            you an improved experience. <br></br>We apologize for the
            inconvenience and appreciate your patience.
          </TextfieldSM>
          <div className="uni-mb-56">
            <TextfieldSM className="scheduled-maintenance-text">
              <strong>Start Time:</strong>{" "}
              {getformatDate(maintenance_from_date) || "N/A"}{" "}{getformatTime(maintenance_from_date) || "N/A"}
            </TextfieldSM>
            <TextfieldSM className="scheduled-maintenance-text">
              <strong>End Time:</strong>{" "}
              {getformatDate(maintenance_to_date) || "N/A"}{" "}{getformatTime(maintenance_to_date) || "N/A"}
            </TextfieldSM>
          </div>
          {/* <DropworksPrimaryButton
            className="scheduled-maintenance-return-home"
            onClick={() => navigate("/")}
          >
            Return to Homepage
          </DropworksPrimaryButton> */}
        </div>
      </div>
    </div>
  );
};

export default view;
