import { useNavigate } from "react-router-dom"
import view from "./view";

export const MarketPlaceDropworksComposite = () => {
    const navigate = useNavigate();
    const navigateTo = (value: string) => {
        navigate(value)
    }

    return (view({ navigateTo }))
}