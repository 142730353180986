import { useNavigate } from "react-router-dom"
import { view } from "./view"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CheckAuthentication, setLocalStorageObject } from "../../utils/localStorage";
import { setAuthentication, setHeaderValue, setWalletAddress } from "../../../../redux/reducer/auth";
import { RootState } from "../../../../redux/store";
import { getUserData, logoutApi } from "../../utils/apiServices";
import { setLoginHistory, setUserData } from "../../../../redux/reducer/userSlice";
import { openPopup } from "../logo-header/view";
export const NewStudioheader = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { authentication: isLoggedin } = useSelector((state: RootState) => state.auth) || false;

    useEffect(() => {
        let value = CheckAuthentication();
        dispatch(setAuthentication(value));
        dispatch(setHeaderValue(2));

    }, []);


    const navigateTo = (value: string) => () => {
        navigate(value)
    }


    useEffect(() => {
        const fetchUserData = async () => {
            const result = await getUserData(); // Call your API function
            if (result.success && result.userData) { // Adjust based on your API response
                if (result.userData.data.userdata.address) {
                    dispatch(setWalletAddress(result.userData.data.userdata?.address))
                }
                if (result.userData.data.userdata.address === null) {
                    openPopup("OneTimePinModal", dispatch);
                }
                else if (result.userData.data.userdata.is_downloaded_pin === 0) {
                    openPopup("downloadPinOneTime", dispatch);
                }
                dispatch(setUserData(result.userData.data.userdata));
                dispatch(setLoginHistory(result.userData.data.loginDeviceData)) // Dispatch the action to set user data
            }
        };

        fetchUserData();
    }, []);

    const userData = useSelector((state: any) => state.user);
    const { displayName } = userData;

    return (view({ navigateTo, isLoggedin, displayName, profileImg: userData.profileImg }))
}