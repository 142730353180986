import { view } from "./view"
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setInvite, setSentInvite } from "../../../redux/reducer/inviteSlice";
import { fetchReceviedInvite, fetchSentInvite } from "../../../redux/reducer/uploadSlice";
import { AppDispatch } from "../../../redux/store";

export const Invite = () => {
    const dispatch = useDispatch<AppDispatch>()
    useEffect(() => {
        // const fetchReceviedInvite = async () => {
        //     const result = await getReceivedInvite(); // Call your API function
        //     if (result && result) { // Adjust based on your API response
        //         console.log(result.data.data,"sssssaaaaaaaaaaaaaaaaaaaa")
        //        dispatch(setInvite(result.data.data))
        //     }
        // };


        // fetchReceviedInvite();
        dispatch(fetchReceviedInvite()).then((res) => dispatch(setInvite(res.payload)))
    }, []);

    useEffect(() => {
        // const fetchSentInvite = async () => {
        //     const result = await getSentInvite(); // Call your API function
        //     if (result && result) { // Adjust based on your API response
        //        dispatch(setSentInvite(result.data.data))
        //     }
        // };

        // fetchSentInvite();
        dispatch(fetchSentInvite()).then((res) => dispatch(setSentInvite(res.payload)))
    }, []);

    return (view())
}