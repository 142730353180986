import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import apiClient from "../../services/FetchClient";
import axios from "axios";
import { setInvite } from "./inviteSlice";
import { useDispatch } from "react-redux";

interface UploadSlice {
   imgData:string;
   settingUpImg:string;
   settingUpImgAPI:any;
   imgDataAPI:any;
   tabValueChange:number;
   beatImg:any;
   beatImgAPI:any;
   uploadnewbeatImg:any;
   uploadnewbeatImgAPI:any;
   inviteModalUserId:string;
   trackId:string;
   invitedUserTrackProject:any;
   trackDetails:any,
   selectedCollaborator:any,
   selectedFile:any,
   selectedFileCollaborator:any,
   trackSlugname:any,
    userRole:string,
    userAllRole:any,
    receviedInvite:any,
    sentInvite:any,
    reReleaseTrackDetails:any,
    userBannerImg:any,
    trackBannerImg:any,
    projectBannerImg:any,
    invitedUserRereleaseTrackProject:any,
    beatData:any,
    notificationData:any,
    inviteTab:any,
    socketNotification:any,
    portfolioDetails:any,
    selectedPortfolio:any,
    selectedNotification:any,
    isNotificationPage:boolean,
    spotifyUser:any
  }

const initialState : UploadSlice = {
  imgData: "", 
  settingUpImg:"",
  settingUpImgAPI:"",
  imgDataAPI:"",
  beatImg:"",
  tabValueChange:0,
  beatImgAPI:"",
  uploadnewbeatImg:"",
  uploadnewbeatImgAPI:"",
  inviteModalUserId:"",
  trackId:"",
  selectedCollaborator:"",
  trackDetails:"",
  reReleaseTrackDetails:"",
  selectedFile:"",
  selectedFileCollaborator:"",
  trackSlugname:"",
  userRole:"",
  userAllRole:[],
  receviedInvite:[],
  sentInvite:[],
  notificationData:[],
  userBannerImg:null,
  trackBannerImg:null,
  projectBannerImg:null,
  beatData:"",
  inviteTab:"",
  socketNotification:"",
  portfolioDetails:[],
  spotifyUser:"",
  invitedUserRereleaseTrackProject:[],
  selectedPortfolio:"",
  selectedNotification:"",
  isNotificationPage:false,
  invitedUserTrackProject:[
    {
        "_id": "6730792187d49fdc23732510",
        "sent_user_id": "671758625da7cdc12459e85b",
        "receiver_user_id": "672b3bec76d584506bbbc173",
        "receiver_name": "Subodh Patil",
        "track_id": "6730785187d49fdc237324ed",
        "beat_id": null,
        "invite_token": null,
        "role": "Producer",
        "percentage_offered": "33",
        "message": "message",
        "upload_audio": [
            {
                "fileType": "audio/mpeg",
                "src": "uploads/1731229981593.mpeg",
                "fileName": "ringtone_sino_natal.mp3",
                "_id": "6730792187d49fdc23732511"
            }
        ],
        "status": "active",
        "acceept_status": "pending",
        "expired_invite_at": "2024-11-10T09:18:05.024Z",
        "updated_at": null,
        "created_by": null,
        "updated_by": null,
        "created_at": "2024-11-10T09:13:05.025Z",
        "__v": 0
    },
    {
      "_id": "6730792187d49fdc23732510",
      "sent_user_id": "671758625da7cdc12459e85b",
      "receiver_user_id": "672b3bec76d584506bbbc173",
      "receiver_name": "Sunil Dhangar",
      "track_id": "6730785187d49fdc237324ed",
      "beat_id": null,
      "invite_token": null,
      "role": "Producer",
      "percentage_offered": "33",
      "message": "message",
      "upload_audio": [
          {
              "fileType": "audio/mpeg",
              "src": "uploads/1731229981593.mpeg",
              "fileName": "ringtone_sino_natal.mp3",
              "_id": "6730792187d49fdc23732511"
          }
      ],
      "status": "active",
      "acceept_status": "pending",
      "expired_invite_at": "2024-11-10T09:18:05.024Z",
      "updated_at": null,
      "created_by": null,
      "updated_by": null,
      "created_at": "2024-11-10T09:13:05.025Z",
      "__v": 0
  },
  {
    "_id": "6730792187d49fdc23732510",
    "sent_user_id": "671758625da7cdc12459e85b",
    "receiver_user_id": "672b3bec76d584506bbbc173",
    "receiver_name": "Sagar Rana",
    "track_id": "6730785187d49fdc237324ed",
    "beat_id": null,
    "invite_token": null,
    "role": "Producer",
    "percentage_offered": "33",
    "message": "message",
    "upload_audio": [
        {
            "fileType": "audio/mpeg",
            "src": "uploads/1731229981593.mpeg",
            "fileName": "ringtone_sino_natal.mp3",
            "_id": "6730792187d49fdc23732511"
        }
    ],
    "status": "active",
    "acceept_status": "pending",
    "expired_invite_at": "2024-11-10T09:18:05.024Z",
    "updated_at": null,
    "created_by": null,
    "updated_by": null,
    "created_at": "2024-11-10T09:13:05.025Z",
    "__v": 0
}
]
};

export const getALlInvitedUserbyTrackProject = createAsyncThunk(
  "track/get-all-invited-collabotor-by", // Action type
  async(data: string, { rejectWithValue })=>{
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track/get-all-invited-collabotor-by/${data}`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      });
      if (response.data.success) {
        return response.data || [];
    } else {
   
        return []

    }
    } catch (err: any) {
      return []
    }
  }
);

export const getALlInvitedUserbyRereleaseTrackProject = createAsyncThunk(
  "rerelease/get-all-invited-collabotor-by", // Action type
  async(data: string, { rejectWithValue })=>{
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track/get-all-invited-collabotor-by-released/${data}`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      });
      if (response.data.success) {
        return response.data || [];
    } else {
   
        return []

    }
    } catch (err: any) {
      return []
    }
  }
);

export const fetchTrackDetails = createAsyncThunk(
  "track/get-all-track", // Action type
  async(data: string, { rejectWithValue })=>{
    
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track/get-track-by/${data}`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      });
      if (response.data.success) {
        return response.data.data || [];
    } else {
   
        return []

    }
    } catch (err: any) {
      return []
    }
  }
);

export const fetchRereleaseTrackDetails = createAsyncThunk(
  "track/get-rerelease-all-track", // Action type
  async(data: string, { rejectWithValue })=>{
    
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track/get-track-by-released/${data}`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      });
      if (response.data.success) {
        return response.data.data || [];
    } else {
   
        return []

    }
    } catch (err: any) {
      return []
    }
  }
);









export const fetchReceviedInvite = createAsyncThunk(
  "track/recevied-invite",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/invite/get-received-invitation`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dropworksToken")}`,
          },
        }
      );
      if (response.data.success) {
 
        return response.data.data || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching invitations:", error);
      return [];
    }
  }
);


export const fetchSentInvite = createAsyncThunk(
  "track/sent-invite",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/invite/get-sent-invitation`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dropworksToken")}`,
          },
        }
      );
      if (response.data.success) {
 
        return response.data.data || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching invitations:", error);
      return [];
    }
  }
);




export const fetchBeatDataThunk = createAsyncThunk(
  "beat/get-beat",
  async(data: any, { rejectWithValue })=> {
    try {
      const response = await axios.get(
        (`${process.env.REACT_APP_BASE_URL}/api/beats/get-beat-by/${data}`),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dropworksToken")}`,
          },
        }
      );
      if (response.data.success) {
 
        return response.data.data || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching invitations:", error);
      return [];
    }
  }
);




export const fetchNotification = createAsyncThunk(
  "notification",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/notification/get-all-notification`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dropworksToken")}`,
          },
        }
      );
      if (response.data.success) {
 
        return response.data.data || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching invitations:", error);
      return [];
    }
  }
);


export const fetchPersonalPortfolio = createAsyncThunk(
  "PersonalPortfolio",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/users/user-personal-portfolio`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("dropworksToken")}`,
          },
        }
      );
      if (response.data.success) {
        return response.data.data || [];
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching invitations:", error);
      return [];
    }
  }
);



export const SpotifyLinkUser = createAsyncThunk(
  "spotifyLinks", // Action type
  async(data: string, { rejectWithValue })=>{
    
    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/track/get-spotify-link-of-collaborator/${data}`,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
        },
      });
      if (response.data.success) {
        return response.data.data || [];
    } else {
   
        return []

    }
    } catch (err: any) {
      return []
    }
  }
);



const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    setBeatData:(state,action:PayloadAction<any>)=>{
      state.beatData=action.payload;
    },
    setUploadImg: (state, action: PayloadAction<any>) => {
      state.imgData = action.payload; 
    },
    setUploadImgAPI: (state, action: PayloadAction<any>) => {
      state.imgDataAPI = action.payload; 
    },
    setUploadSettingImg: (state, action: PayloadAction<any>) => {
      state.settingUpImg = action.payload; 
    },
    clearUploadSettingImg: (state) => {
      state.settingUpImg = ""; 
    },
    setUploadSettingImgAPI:(state, action: PayloadAction<any>) => {
      state.settingUpImgAPI = action.payload; 
    },
    setUploadSettingImgBanner: (state, action: PayloadAction<any>) => {
      state.settingUpImg = action.payload; 
    },
    setUploadSettingImgBannerAPI:(state, action: PayloadAction<any>) => {
      state.settingUpImgAPI = action.payload; 
    },
    setSelectedNotification:(state, action: PayloadAction<any>) => {
      state.selectedNotification = action.payload; 
    },
    setIsnotificationPage:(state, action: PayloadAction<any>) => {
      state.isNotificationPage = action.payload; 
    },
    setUploadSettingImgProfile: (state, action: PayloadAction<any>) => {
      state.settingUpImg = action.payload; 
    },
    setUploadSettingImgProfileAPI:(state, action: PayloadAction<any>) => {
      state.settingUpImgAPI = action.payload; 
    },
    setUploadBeatImg: (state, action: PayloadAction<any>) => {
      state.beatImg = action.payload; 
    },
    setUploadBeatImgAPI: (state, action: PayloadAction<any>) => {
      state.beatImgAPI = action.payload; 
    },
    setUploadNewBeatImg: (state, action: PayloadAction<any>) => {
      state.uploadnewbeatImg = action.payload; 
    },
    setUploadNewBeatImgAPI: (state, action: PayloadAction<any>) => { 
      state.uploadnewbeatImgAPI = action.payload; 
    },
    setInviteModalUserID: (state, action: PayloadAction<any>) => {
      state.inviteModalUserId = action.payload; 
    },
    setTrackID:(state,action:PayloadAction<string>)=>{
      state.trackId=action.payload;
    },
    setSelectedPortfolio:(state,action:PayloadAction<string>)=>{
      state.selectedPortfolio=action.payload;
    },
    setSetelectedCollaborator:(state,action:PayloadAction<any>)=>{
      state.selectedCollaborator=action.payload
    },
    setTrackdetails:(state,action:PayloadAction<any>)=>{
      state.trackDetails=action.payload
    },
    setRereleaseTrackdetails:(state,action:PayloadAction<any>)=>{
      state.reReleaseTrackDetails=action.payload
    },
    setSelectedFile:(state,action:PayloadAction<any>)=>{
      
      state.selectedFile=action.payload
    },
    setSelectedFileCollaborator:(state,action:PayloadAction<any>)=>{
      
      state.selectedCollaborator=action.payload
    },
    setSocketNotification:(state,action:PayloadAction<any>)=>{
      state.socketNotification=action.payload
    },
    setUserRole:(state,action:PayloadAction<any>)=>{
      
      state.userRole=action.payload
    },
    setTrackSlugName:(state,action:PayloadAction<any>)=>{
      
      state.trackSlugname=action.payload
    },
    setAllRoleUser:(state,action:PayloadAction<any>)=>{

      state.userAllRole=action.payload
    },
    setInvitTab:(state,action:PayloadAction<any>)=>{

      state.inviteTab=action.payload
    },
    setUserBannerImg:(state,action:PayloadAction<any>)=>{
      
      state.userBannerImg=action.payload?.[0]?.src?`${process.env.REACT_APP_IMGURL}/${action.payload[0].src}`:null
    },
    setTrackBannerImg:(state,action:PayloadAction<any>)=>{
      state.trackBannerImg=action.payload?.[0]?.src?`${process.env.REACT_APP_IMGURL}/${action.payload[0].src}`:null
    },
    setProjectBannerImg:(state,action:PayloadAction<any>)=>{
      state.projectBannerImg=action.payload?`${process.env.REACT_APP_IMGURL}/${action.payload}`:null

      
      state.userAllRole=action.payload
    },
    setTabValueChange:(state,action:PayloadAction<any>)=>{
      state.tabValueChange=action.payload

    },
    
    setNotificationAfterClick:(state,action:PayloadAction<any>)=>{
      state.notificationData=action.payload

    },
    clearNotificationByType:(state,action:PayloadAction<any>)=>{
      state.notificationData=action.payload
    },
    
    
    setLikedBeat:(state,action:PayloadAction<boolean>)=>{

    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(getALlInvitedUserbyTrackProject.pending, (state) => {
        state.invitedUserTrackProject = state.invitedUserTrackProject||[];
      })
      .addCase(getALlInvitedUserbyTrackProject.fulfilled, (state, action) => {

        state.invitedUserTrackProject = action.payload;
      })
      .addCase(getALlInvitedUserbyTrackProject.rejected, (state, action) => {
        state.invitedUserTrackProject = []; 
      });

      builder
      .addCase(getALlInvitedUserbyRereleaseTrackProject.pending, (state) => {
        state.invitedUserRereleaseTrackProject = state.invitedUserRereleaseTrackProject||[];
      })
      .addCase(getALlInvitedUserbyRereleaseTrackProject.fulfilled, (state, action) => {

        state.invitedUserRereleaseTrackProject = action.payload;
      })
      .addCase(getALlInvitedUserbyRereleaseTrackProject.rejected, (state, action) => {
        state.invitedUserRereleaseTrackProject = []; 
      });




      builder
      .addCase(fetchTrackDetails.pending, (state) => {
        state.trackDetails = state.trackDetails||[];
      })
      .addCase(fetchTrackDetails.fulfilled, (state, action) => {
     
        state.trackDetails = action.payload;
      })
      .addCase(fetchTrackDetails.rejected, (state, action) => {
        state.trackDetails = []; 
      });



      builder
      .addCase(fetchReceviedInvite.pending, (state) => {
        state.receviedInvite = state.receviedInvite||[];
      })
      .addCase(fetchReceviedInvite.fulfilled, (state, action) => {
     
        state.receviedInvite = action.payload;
      })
      .addCase(fetchReceviedInvite.rejected, (state, action) => {
        state.receviedInvite = []; 
      });


      builder
      .addCase(fetchSentInvite.pending, (state) => {
        state.sentInvite =state.sentInvite|| [];
      })
      .addCase(fetchSentInvite.fulfilled, (state, action) => {
     
        state.sentInvite = action.payload;
      })
      .addCase(fetchSentInvite.rejected, (state, action) => {
        state.sentInvite = []; 
      });

      builder
      .addCase(fetchRereleaseTrackDetails.pending, (state) => {
        state.reReleaseTrackDetails = state.reReleaseTrackDetails ||[];
      })
      .addCase(fetchRereleaseTrackDetails.fulfilled, (state, action) => {
     
        state.reReleaseTrackDetails = action.payload;
      })
      .addCase(fetchRereleaseTrackDetails.rejected, (state, action) => {
        state.reReleaseTrackDetails = []; 
      });


      builder
      .addCase(fetchBeatDataThunk.pending, (state) => {
        state.beatData =state.beatData|| [];
      })
      .addCase(fetchBeatDataThunk.fulfilled, (state, action) => {
        state.beatData = action.payload;
      })
      .addCase(fetchBeatDataThunk.rejected, (state, action) => {
        state.beatData = []; 
      });



      builder
      .addCase(fetchNotification.pending, (state) => {
        state.notificationData = state.notificationData||[];
      })
      .addCase(fetchNotification.fulfilled, (state, action) => {
        state.notificationData = action.payload;
      })
      .addCase(fetchNotification.rejected, (state, action) => {
        state.notificationData = []; 
      });



      builder
      .addCase(fetchPersonalPortfolio.pending, (state) => {
        state.portfolioDetails =state.portfolioDetails|| [];
      })
      .addCase(fetchPersonalPortfolio.fulfilled, (state, action) => {
        state.portfolioDetails = action.payload;
      })
      .addCase(fetchPersonalPortfolio.rejected, (state, action) => {
        state.portfolioDetails = []; 
      });


      builder
      .addCase(SpotifyLinkUser.pending, (state) => {
        state.spotifyUser = state.spotifyUser||[];
      })
      .addCase(SpotifyLinkUser.fulfilled, (state, action) => {
        state.spotifyUser = action.payload;
      })
      .addCase(SpotifyLinkUser.rejected, (state, action) => {
        state.spotifyUser = []; 
      });

    // Add similar handling for remaining actions...


  },
});



export const {clearUploadSettingImg,setIsnotificationPage,setBeatData,setSelectedNotification,setSelectedPortfolio,setSocketNotification,setLikedBeat,setInvitTab,setTabValueChange,setRereleaseTrackdetails,setAllRoleUser,setTrackBannerImg,setProjectBannerImg,setUserBannerImg,setUserRole,setTrackSlugName,setSelectedFileCollaborator,setTrackID,setSelectedFile,setSetelectedCollaborator, setUploadImg,setUploadSettingImg,setUploadSettingImgAPI,setUploadSettingImgBanner,setUploadSettingImgBannerAPI,setUploadSettingImgProfileAPI,setUploadSettingImgProfile,setUploadImgAPI,setUploadBeatImg,setUploadBeatImgAPI,setUploadNewBeatImg ,setInviteModalUserID, setUploadNewBeatImgAPI,setTrackdetails,setNotificationAfterClick} = uploadSlice.actions;



export const uploadReducer = uploadSlice.reducer;