import { useNavigate } from "react-router-dom";
import view from "./view";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchNotification, fetchReceviedInvite, fetchSentInvite } from "../../../redux/reducer/uploadSlice";
import { setInvite, setSentInvite } from "../../../redux/reducer/inviteSlice";

export const MainNotification = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const navigateTo = (value: string) => () => {
        navigate(value)
    }

    useEffect(() => {
        const fetchnotification = async () => {

            // const response: any = await getAllNotification();
            // if (response) {

            //   setFavNotification(response?.allProjectData?.data?.favourites)
            //   setinviteFavNotification(response?.allProjectData?.data?.invites?.items)

            // } else {
            //   console.log("Failed to fetch data");
            // }

            dispatch(fetchNotification() as any)


        };
        dispatch(fetchReceviedInvite() as any).then((res: any) => dispatch(setInvite(res.payload)))
        dispatch(fetchSentInvite() as any).then((res: any) => dispatch(setSentInvite(res.payload)))
        fetchnotification();
    }, []);


    return (view({ navigateTo }))
}