import { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { view } from "./view";
import { changePassword, verifyPin } from "../../../utils/apiServices";
import { useNavigate } from "react-router-dom";
import { openPopup } from "../../logo-header/view";
import { setValidateValue } from "../../../../../redux/reducer/auth";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

export const VerifyPinPrivateKey = () => {
  return view();
}
export default VerifyPinPrivateKey