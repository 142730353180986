import { FC, useState } from "react";
import  view  from "./view";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { reportSomethingElse } from "../../../utils/apiServices";



export const UserReportReasonModal:FC<any>=()=>{

    const UserId = useSelector((state: RootState) => state.report.userModalId);
    const UserReason = useSelector((state: RootState)=> state.report.somethingreportreason)
    const [imperSonatingReport, setImperSonatingReport] = useState();
    const [selectedValue,setSelectedValue]=useState<any>();
    const [customReason, setCustomReason] = useState<string>(''); 
    const [popupId, setPopupId] = useState<string>(''); 

    const reportData= {
        reason: selectedValue === "Something else" ? customReason : selectedValue,      
        report_type: "user",
        user_id: UserId,
        who_pretending_to: selectedValue === "Something else" ? customReason : selectedValue, 
      };

      const userreportreasonsubmit = async () => {
        try {
          const result = await reportSomethingElse(UserId,reportData);
          if (result.success) {
            setImperSonatingReport(result.reportedSomething.data);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

    return view(userreportreasonsubmit,selectedValue,setSelectedValue,customReason,setCustomReason,popupId,setPopupId);
}
export default UserReportReasonModal