import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import "./styles.scss"
import { AiOutlineRight } from "react-icons/ai";
import { closeModal, CloseModalButton } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClick, setProjectname } from '../../../../../redux/reducer/projectnameSlice';
import { createRereleaseTrackProject, createTrackProject } from '../../../utils/apiServices';
import { setTrackID, setTrackSlugName } from '../../../../../redux/reducer/uploadSlice';
import ModalWrapper from '../../../../../common/open-modal/ModalWrapper';
import { useNavigate } from 'react-router-dom';
const view = () => {
  const [projectName, setProjectName] = useState("");
  const userData = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const {displayName} =userData;
const closeModalRef = useRef<HTMLAnchorElement>(null);
  const dispatch=useDispatch()
  const path=window.location.pathname

  const click = async (
    name: string,
    value: string | null | boolean
  ) => {
    
    if(path.includes("track-project-rerelease")){
      const response = await createRereleaseTrackProject({ [name]: value});
      if (response.success) {
        dispatch(setTrackID(response.data._id));
        dispatch(setTrackSlugName(response.data.slug_name))
        dispatch(setProjectname(response.data.project_name))
        navigate(`/track-project-rerelease/${response.data.slug_name}`)
        if (closeModalRef.current) {
          closeModalRef.current.click();
        }
      } else {
      }
    }
  };

  // useEffect(() => {
  //   const handleBackdropClick = async (event: MouseEvent) => {
  //     const modalElement = document.getElementById("ProjectName2");
  

  //     if (event.target === modalElement) {
  //       const name = "project_name"; 
  //       const value = `${displayName}'s Project`; 
  //       await click(name, "");
  //     }
  //   };
  
  //   const modalElement = document.getElementById("ProjectName2");
  //   modalElement?.addEventListener("click", handleBackdropClick);
  

  //   return () => {
  //     modalElement?.removeEventListener("click", handleBackdropClick);
  //   };
  // }, [displayName]);

  const openModal = () => {
    setProjectName("")
  };
  

  return (
    <ModalWrapper modalId='ProjectName2'  onBackdropClick={openModal}>
    <div
      className="modal fade"
      id="ProjectName2"
      aria-hidden="true"
      aria-labelledby="ProjectName2"
      tabIndex={-1}
      data-bs-theme="dark"
      data-bs-backdrop="static" 
      data-bs-keyboard="false"  
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
        <CloseModalButton
          ref={closeModalRef}
          className="d-none"
        ></CloseModalButton>
    
          <div className="modal-header ps-0 pt-0 uni-pr-72 uni-mb-40">
            <Heading3 className="modal-title-fs-32">Project Name</Heading3>
          </div>
       
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 ">
            {/* <p className='d-flex justify-content-between uni-p-16 popup-space-mobile uni-mb-56'>
                <TextfieldSM className="ctm-txt2 font-col-wh mx-0 my-0">Learn more about our <span className="change-color">community guidelines</span></TextfieldSM>
            
            </p> */}
            <DropworksInput
                  placeholder=" Name"
                  className="uni-mb-32"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                  maxLength={30}
                />

          </div>
          <div className="modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
          <DropworksSmallButton className={` btn-wid-success btn-bg-gray3 social-link-modal-btns  uni-mr-8 mt-0 mb-0`}  onClick={()=>navigate("/create")}>Back</DropworksSmallButton>
          
         <DropworksSmallButton className={`btn-wid btn-wid-success social-link-modal-btns m-0 `} onClick={()=>click("project_name",projectName)} disabled={!projectName}>Confirm</DropworksSmallButton>
        
        </div>
      
        </div>
      </div>
    </div>
    </ModalWrapper>
    
  )
}

export default view;


