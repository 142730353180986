import { TextfieldLG } from "../../../../../common/custom-field/dropstock-text";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { BlueTik } from "../../../../../common/all-icons";
import hankeyman from "../../../../../assets/img/hankeyman.png";
import { closeModal, CloseModalButton } from "../../logo-header/view";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useState } from "react";
import { updateInvitedCollaboratorsTrackProject } from "../../../utils/apiServices";
import { AppDispatch } from "../../../../../redux/store";
import { getALlInvitedUserbyRereleaseTrackProject, getALlInvitedUserbyTrackProject } from "../../../../../redux/reducer/uploadSlice";
import Loader from "../../loader/view";
import { useNavigate } from "react-router-dom";
const view = () => {
  const {invitedUserTrackProject,trackDetails}=useSelector((state:RootState)=>state.upload);
  const userData = useSelector((state: any) => state.user);
  const ErrorImg =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxqCQMRexiN9Iu6yVJQt3py5bPb13DRIqCXA&s";
  const selectedCollaborator=invitedUserTrackProject?.data?.find((ele:any)=>ele.receiver_user_id==userData.id)
  const navigate=useNavigate()
  const [loading,setLoading]=useState<boolean>(false);
  const dispatch =useDispatch<AppDispatch>(); 
  const [message,setMessage]=useState<string>("")
  const removeCollaborator=async()=>{
    setLoading(true);
    const data={track_id:selectedCollaborator.track_id,remove:"remove",invited_id:selectedCollaborator._id};
    const response= await updateInvitedCollaboratorsTrackProject(data);
if(response.success)
{
  navigate("/library")
closeModal();
setLoading(false);
}
else{
  setLoading(false);
  setMessage(response.message)
}
  }
  return (
    <div
      className="modal fade"
      id="LeaveProject"
      aria-hidden="true"
      aria-labelledby="LeaveProject"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
        <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header uni-mb-40 p-0">
            <h1 className="modal-title">Leave project {trackDetails?.trackData?.project_name} </h1>
          </div>
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            <div className="d-flex align-items-center uni-mb-32 invite_firstcolumn">
              <div>
                <img className="removeCollaborator-img" src={`${process.env.REACT_APP_IMGURL}/${trackDetails?.trackData?.art_work_image?.[0]?.thumbnail || trackDetails?.trackData?.art_work_image?.[0]?.src || trackDetails?.trackData?.default_artwork}`}  alt="" onError={(e) => (e.currentTarget.src = ErrorImg)}/>
              </div>
              <div>
                <h2 className="fw-700 displayname text-capitalize">
                {trackDetails?.trackData?.project_name}
                </h2>
              </div>
            </div>

            <TextfieldLG className="remove-content">
            Are you sure you want to leave the project '<span className="text-capitalize">{trackDetails?.trackData?.project_name}</span>'? Do you want to continue?
            </TextfieldLG>
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton>
              <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">
                Cancel
              </DropworksSmallButton>
            </CloseModalButton>
            <CloseModalButton>
            {loading?<Loader className="uni-mt-16"/>:
              <DropworksSmallButton className="btn-wid m-0" onClick={removeCollaborator}>
                Confirm
              </DropworksSmallButton>}
            </CloseModalButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
