import { FC, useEffect, useState } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import iconImg from "../../../assets/img/upload-icon.png";
import { DropworksInput } from "../../../common/custom-field/dropworks-input";
import { DropworksTextArea } from "../../../common/custom-field/dropworks-textArea";
import {
  DropworksButton,
  DropworksMediumButton,
  DropworksNormalButton,
  GradientActiveBtn,
} from "../../../common/custom-field/dropworks-button";
import { DropworksInputIcon } from "../../../common/custom-field/dropworks-input-icon";
import { RiQuestionFill } from "react-icons/ri";
import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
  TextfieldXSM,
} from "../../../common/custom-field/dropstock-text";
import {
  Heading0,
  Heading3,
  Heading4,
} from "../../../common/custom-field/dropstock-heading";
import Slider from "react-slick";
import image from "../../../assets/img/roland.png";
import TabsComponent from "../../../common/tabs-component/view";
import Tabs from "../../../common/tabs";
import scarollImage from "../../../assets/img/Maintenance.jpg";
import ProjectPageImg from "../../../assets/img/project-page-img.png";
import BeatpageImg from "../../../assets/img/beat-page-img.png";
import TrackPageImg from "../../../assets/img/track-page-img.png";
import InfiniteImageScroll from "../../../common/infinite-image-scroll";
import HomeTabs from "../../../common/home-tabs";
import { useDispatch } from "react-redux";
import { setDropworksSelectoptin } from "../../../redux/reducer/dropworksSelect";
const view: FC<viewType> = ({ navigateTo }) => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 2, // Adjust to 2, since you are using 2 rows and want to fit the grid
    speed: 500,
    rows: 3, // 2 rows of slides
    slidesPerRow: 1, // Display 2 slides per row
    autoplay: false, // Enable autoplay
    autoplaySpeed: 1000, // Time between slides in ms
    arrows: false,
  };
  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
  };
  const dispatch = useDispatch();
  const [showComponent, setShowComponent] = useState(tabsValue);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowComponent(tabsValue);
    }, 120);

    return () => clearTimeout(timeout);
  }, [tabsValue]);
  return (
    <div className=" marketplaceProduct-main mt-0">
      <div className="d-flex marketplace-dw-composite-main-firstrow">
        <div className="marketplaceProduct-main-firstrow-col1 px-0 uni-px-24">
          <div className="marketplaceProduct-main-firstrow-col1-content">
            <Heading0 className="text-wrap uni-mb-40  marketplace-mobile-view-head mb-32-mbl">
              {/* Easy.
              <br />
              Simple.
              <br /> */}
              Experience new privileges​
            </Heading0>

            <TextfieldMD className="uni-mb-40 products-page-second-heading mb-32-mbl">
              Add{" "}
              <span className="gradient-text uni-fw-700">
                DropWorks Select{" "}
              </span>
              and{" "}
              <span className="gradient-text-2  uni-fw-700">
                DropWorks Composite
              </span>{" "}
              to your collections to access to exclusive fans’ and musiconics’
              benefits ​
            </TextfieldMD>

            <div className="d-flex">
              <DropworksNormalButton
                className="w-auto uni-px-72 home-mobileview-btn mb-0"
                onClick={() => {
                  const element = document.getElementById("market-below");
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                Explore the collections​
              </DropworksNormalButton>
            </div>
          </div>
        </div>

        <div className="  marketplace-dw-composite-main-firstrow-col2 pe-0">
          <InfiniteImageScroll />
        </div>
      </div>

      <div className="marketplaceProduct-main-secondrow mx-auto uni-mb-120 container container-xl container-xxl">
        <Heading3
          className="uni-mb-56 marketplace-content-mbl font-weight-600"
          id="market-below"
          data-aos="fade-right"
        >
          Own now to enrich your collection portfolio​
        </Heading3>
        <TextfieldMD
          className="my-0 marketplaceProduct-main-secondrow-subtitle"
          data-aos="fade-right"
        >
          Join the marketplace,
          <br />
          and own DropWorks Select and DropWorks Composite now. As a owner, you
          become a part of the ongoing music journey to enjoy exclusive access
          to sync licensing rights, reperformance rights and periodic
          <br />
          royalties revenues.{" "}
        </TextfieldMD>
      </div>

      <div className="marketplaceProduct-main-thirdrow mx-auto uni-pt-32  container container-xl container-xxl">
        <HomeTabs
          id="18"
          tabsTitle={["DropWorks Select", "DropWorks Composite"]}
          className="uni-mb-96 mx-auto marketplaceProduct-main-thirdrow-tab"
          checkTabState={checkTabState}
          home={true}
        />

        <div className={`home-tabs-content overflow-hidden fade ${tabsValue === showComponent ? "fade-in" : "fade-out"}`}>
        
              <div className={tabsValue !== 0 ? "d-none" :"d-flex home-tab-mobile-view"}>
                <div>
                  <div
                    className="home-tabs-content-box"
                    data-aos="fade-left"
                  ></div>
                </div>
                <div
                  className="uni-p-72 pe-0 marketplace-tab-mobileview "
                  data-aos="fade-right"
                >
                  <div className="w-mx-701">
                    <Heading4 className="uni-mb-56 marketplace-heading4 font-weight-600">
                      Make your favorite music tracks accessible​
                    </Heading4>
                    <TextfieldMD className="uni-mb-56 mt-0 ">
                      DropWorks Select features a portfolio of music tracks that
                      have already been released, and now being available in
                      DropWorks for ownership and collections.
                    </TextfieldMD>
                    <p className="d-flex justify-content-start learn-trade-mobileview">
                      <DropworksNormalButton
                        className="w-auto home-mobileview-btn  fs-18 "
                        onClick={() =>
                          navigateTo("/marketplace-dropworks-select")
                        }
                      >
                        Learn more!
                      </DropworksNormalButton>
                      {/* <DropworksNormalButton
                        className="w-auto  home-tabs-content-btn-bg uni-ml-56  fs-18  home-mobileview-btn  trade-btn"
                        onClick={() => {
                          navigateTo("/dropworks-select");
                        }}
                      >
                        Trade now!
                      </DropworksNormalButton> */}
                    </p>
                  </div>
                </div>
              </div>
              <div className={tabsValue !== 1 ? "d-none" :"d-flex home-tab-mobile-view"}>
                <div>
                  <div
                    className="home-tabs-content-box "
                    data-aos="fade-left"
                  ></div>
                </div>
                <div
                  className="uni-p-72 pe-0 marketplace-tab-mobileview"
                  data-aos="fade-right"
                >
                  <div className="w-mx-701">
                    <Heading4 className="uni-mb-56 marketplace-heading4">
                      Own the upcoming hits tracks​
                    </Heading4>
                    <TextfieldMD className="uni-mb-56 mt-0 ">
                      DropWorks Composite features a portfolio of music tracks
                      that are created through DropWorks’ community of Musicons,
                      and exclusive opportunity to pre own these future hits in
                      your portfolio of collections. ​
                    </TextfieldMD>
                    <p className="d-flex justify-content-start learn-trade-mobileview">
                      <DropworksNormalButton
                        className="w-auto home-mobileview-btn fs-18 font-weight-600"
                        onClick={() =>
                          navigateTo("/marketplace-dropworks-composite")
                        }
                      >
                        Learn more!
                      </DropworksNormalButton>
                      {/* <DropworksNormalButton
                        className="w-auto  home-tabs-content-btn-b gradient-btn-2  fs-18  home-mobileview-btn  trade-btn uni-ml-56 font-weight-600 "
                        onClick={() => {
                          navigateTo("/dropworks-composite");
                        }}
                      >
                        Trade now!
                      </DropworksNormalButton> */}
                    </p>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </div>
  );
};

export default view;
