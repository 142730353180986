const CountriesArray = [
    { value: "Australia", label: "Australia", image: "https://flagpedia.net/data/flags/h80/au.png" },
    { value: "Brazil", label: "Brazil", image: "https://flagpedia.net/data/flags/h80/br.png" },
    { value: "Canada", label: "Canada", image: "https://flagpedia.net/data/flags/h80/ca.png" },
    { value: "Germany", label: "Germany", image: "https://flagpedia.net/data/flags/h80/de.png" },
    { value: "Japan", label: "Japan", image: "https://flagpedia.net/data/flags/h80/jp.png" },
    { value: "Korea", label: "Korea", image: "https://flagpedia.net/data/flags/h80/kr.png" },
    { value: "Mexico", label: "Mexico", image: "https://flagpedia.net/data/flags/h80/mx.png" },
    { value: "Portugal", label: "Portugal", image: "https://flagpedia.net/data/flags/h80/pt.png" },
    { value: "Singapore", label: "Singapore", image: "https://flagpedia.net/data/flags/h80/sg.png" },
    { value: "Spain", label: "Spain", image: "https://flagpedia.net/data/flags/h80/es.png" },
    { value: "Sweden", label: "Sweden", image: "https://flagpedia.net/data/flags/h80/se.png" },
    { value: "Taiwan", label: "Taiwan", image: "https://flagpedia.net/data/flags/h80/tw.png" },
    { value: "UK", label: "UK", image: "https://flagpedia.net/data/flags/h80/gb.png" },
    { value: "US", label: "US", image: "https://flagpedia.net/data/flags/h80/us.png" }
];

export default CountriesArray;