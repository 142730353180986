import {
  TextfieldLG,
  TextfieldSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import { CloseModalButton } from "../../logo-header/view";
import "./styles.scss";
import { ButtonComponent } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { setTrackID } from "../../../../../redux/reducer/uploadSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import { DropworksRadioCheckbox } from "../../../../../common/custom-field/dropworks-radio-checkbox";
const view = (project: any) => {
  const dispatch = useDispatch();

  const [projectId, setProjectId] = useState<string>("");

  const sendData = (data: any) => {
    setProjectId(data);
    dispatch(setTrackID(data));
  };

  const openModal = () => {
    setProjectId("");
  };

  return (
    <ModalWrapper modalId="InviteModal" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="InviteModal"
        aria-hidden="true"
        aria-labelledby="InviteModal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content m-auto">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header ps-0 pt-0 ">
              <Heading3 className="modal-title">Invitation</Heading3>
            </div>
            {project?.length > 0 ? (
              <div className="modal-body ps-0 pt-0 pb-0">
                <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt modal-second-head-font">
                  Select the project that you want to invite this user to
                </TextfieldLG>
                <div className="content-height">
                  {project?.map((item: any, index: number) => {
                    return (
                      <p
                        key={index}
                        onClick={() => sendData(item._id)}
                        className="d-flex uni-p-16 content align-items-center popup-space-mobile content invite-flex"
                      >
                        <span className="box">
                          <img
                            className="project-img"
                            src={`${process.env.REACT_APP_IMGURL}/${
                              item?.art_work_image?.[0]
                                ? (item?.art_work_image?.[0]?.thumbnail || item?.art_work_image?.[0]?.src)
                                : item?.default_artwork
                            }`}
                            alt=""
                          />
                        </span>
                        <TextfieldSM className="uni-mx-16 my-0 text-field">
                          {item.project_name}
                        </TextfieldSM>
                        <DropworksRadioCheckbox
                          checked={item._id === projectId}
                        />
                      </p>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="modal-body ps-0 pt-0 pb-0 ">
                <div className="content-height d-flex justify-content-center align-items-center">
                  <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt modal-second-head-font">
                    No projects are available for invitation.
                  </TextfieldLG>
                </div>
              </div>
            )}
            <div className="modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0  uni-mt-56">
              <ButtonComponent disabled={!projectId} id="InviteModal2">
                <DropworksSmallButton
                  disabled={!projectId}
                  className="btn-wid social-link-modal-btns m-0 my-0"
                >
                  Next
                </DropworksSmallButton>
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default view;
