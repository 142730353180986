import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import * as Sentry from '@sentry/react';

export interface MaintenanceState {
  isMaintenance: boolean
  title: string,
  description: string,
  maintenance_type: string,
  maintenance_from_date: string,
  maintenance_to_date: string,

}
const initialState: MaintenanceState = {
  isMaintenance: false,
  title: "",
  description: "",
  maintenance_type: "",
  maintenance_from_date: "",
  maintenance_to_date: "",
};


// Get Maintenance Data
export const getMaintenanceDetails = createAsyncThunk(
  "maintenance/getMaintenanceDetails", // Action type
  async (body, { rejectWithValue }) => {

    try {
      const response: any = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/notification/get-system-maintenance-landing-page`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('dropworksToken')}`
          },
        });
      if (response.data) {
        return response.data.data || null;

      } else {

        return []

      }
    } catch (err: any) {
      Sentry.captureException(err);
      return [];
    }
  }
);


const maintenanceSlice = createSlice({
  name: "maintenance",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMaintenanceDetails.pending, (state, action) => {
      })
      .addCase(getMaintenanceDetails.fulfilled, (state, action) => {
        const maintenance = action.payload;
        if (maintenance?._id) {
          state.isMaintenance = true;
          state.title = maintenance.message;
          state.description = maintenance.description;
          state.maintenance_type = maintenance.maintenance_type;
          state.maintenance_from_date = maintenance.maintenance_from_date;
          state.maintenance_to_date = maintenance.maintenance_to_date;
        }else{
          state.isMaintenance = false;
        } 
      })
      .addCase(getMaintenanceDetails.rejected, (state, action) => {
      });
  }

});




export const maintenanceReducer = maintenanceSlice.reducer;
