import React from "react";
import view from "./view";
interface CustomDivProps extends React.HTMLAttributes<HTMLDivElement> {
  }

  export  const HrLineLG:React.FC<CustomDivProps> = ({  ...props }) => {
    return (
        view({...props,className:`${props.className} hr-line-lg`})
    );
  };

  export  const HrLineMD:React.FC<CustomDivProps> = ({  ...props }) => {
    return (
        view({...props,className:`${props.className} hr-line-md`})
    );
  };

  export  const HrLineSM:React.FC<CustomDivProps> = ({  ...props }) => {
    return (
        view({...props,className:`${props.className} hr-line-sm`})
    );
  };  

  export  const HrLineXSM:React.FC<CustomDivProps> = ({  ...props }) => {
    return (
        view({...props,className:`${props.className} hr-line-xsm`})
    );
  };

  