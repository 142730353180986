import { useRef, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { view } from "./view";
import { downloadPdfConfirmation, downloadPinPdf, setWalletPin } from "../../../utils/apiServices";
import { useNavigate } from "react-router-dom";
import { openPopup } from "../../logo-header/view";
import { useDispatch } from "react-redux";

export const DownloadPdfConfirmation = () => {
  const [loading, setLoading] = useState(false);
  const closeModalRef = useRef<HTMLAnchorElement>(null);
  const dispatch =useDispatch()
  const handleCloseModalClick = () => {
    if (closeModalRef.current) {
      closeModalRef.current.click();
    }
  };

  const nextBtnClick = async () => {
    setLoading(true);
    try {
      const response: any = await downloadPdfConfirmation();
      if (response) {
        setLoading(false);
        handleCloseModalClick();
      } else {
        setLoading(false);
        openPopup("downloadPdfConfirmation", dispatch);
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setLoading(false);
      openPopup("downloadPdfConfirmation", dispatch);
    }
  };

  return view({ loading, nextBtnClick });
}
export default DownloadPdfConfirmation