import React, { FC } from 'react'
import "./styles.scss"
import { ErrorImg } from '../../app/shared/utils/localStorage'
import { infiniteScrollModal } from '../invite-friends/modal'
const view:FC<infiniteScrollModal> = ({arr}) => {
  return (
    <div className="image-grid-wrapper  full-width-child ">
    <div className="scroll">
      <div>
      <img src={ arr?.[0] ? `${process.env.REACT_APP_IMGURL}/${arr?.[0]}` : ErrorImg} />
      <img src={ arr?.[1] ? `${process.env.REACT_APP_IMGURL}/${arr?.[1]}` : ErrorImg} />
        <img src={ arr?.[2] ? `${process.env.REACT_APP_IMGURL}/${arr?.[2]}` : ErrorImg} />
        <img src={ arr?.[3] ? `${process.env.REACT_APP_IMGURL}/${arr?.[3]}` : ErrorImg} />
        <img src={ arr?.[4] ? `${process.env.REACT_APP_IMGURL}/${arr?.[4]}` : ErrorImg} />
        <img src={ arr?.[5] ? `${process.env.REACT_APP_IMGURL}/${arr?.[5]}` : ErrorImg} />
        <img src={ arr?.[6] ? `${process.env.REACT_APP_IMGURL}/${arr?.[6]}` : ErrorImg} />
      </div>
      <div>
     <img src={ arr?.[0] ? `${process.env.REACT_APP_IMGURL}/${arr?.[0]}` :  ErrorImg} />
      <img src={ arr?.[1] ? `${process.env.REACT_APP_IMGURL}/${arr?.[1]}` : ErrorImg} />
        <img src={ arr?.[2] ? `${process.env.REACT_APP_IMGURL}/${arr?.[2]}` : ErrorImg} />
        <img src={ arr?.[3] ? `${process.env.REACT_APP_IMGURL}/${arr?.[3]}` : ErrorImg} />
        <img src={ arr?.[4] ? `${process.env.REACT_APP_IMGURL}/${arr?.[4]}` : ErrorImg} />
        <img src={ arr?.[5] ? `${process.env.REACT_APP_IMGURL}/${arr?.[5]}` : ErrorImg} />
        <img src={ arr?.[6] ? `${process.env.REACT_APP_IMGURL}/${arr?.[6]}` : ErrorImg} />
      </div>
    </div>
    <div className="scroll">
    <div>
      <img src={ arr?.[7] ? `${process.env.REACT_APP_IMGURL}/${arr?.[7]}` : ErrorImg} />
      <img src={ arr?.[8] ? `${process.env.REACT_APP_IMGURL}/${arr?.[8]}` : ErrorImg} />
      <img src={ arr?.[9] ? `${process.env.REACT_APP_IMGURL}/${arr?.[9]}` : ErrorImg} />
      <img src={ arr?.[10] ? `${process.env.REACT_APP_IMGURL}/${arr?.[10]}` : ErrorImg} />
      <img src={ arr?.[11] ? `${process.env.REACT_APP_IMGURL}/${arr?.[11]}` : ErrorImg} />
      <img src={ arr?.[12] ? `${process.env.REACT_APP_IMGURL}/${arr?.[12]}` : ErrorImg} />
      <img src={ arr?.[13] ? `${process.env.REACT_APP_IMGURL}/${arr?.[13]}` : ErrorImg} />
      </div>
      <div>
      <img src={ arr?.[7] ? `${process.env.REACT_APP_IMGURL}/${arr?.[7]}` : ErrorImg} />
      <img src={ arr?.[8] ? `${process.env.REACT_APP_IMGURL}/${arr?.[8]}` : ErrorImg} />
      <img src={ arr?.[9] ? `${process.env.REACT_APP_IMGURL}/${arr?.[9]}` : ErrorImg} />
      <img src={ arr?.[10] ? `${process.env.REACT_APP_IMGURL}/${arr?.[10]}` : ErrorImg} />
      <img src={ arr?.[11] ? `${process.env.REACT_APP_IMGURL}/${arr?.[11]}` : ErrorImg} />
      <img src={ arr?.[12] ? `${process.env.REACT_APP_IMGURL}/${arr?.[12]}` : ErrorImg} />
      <img src={ arr?.[13] ? `${process.env.REACT_APP_IMGURL}/${arr?.[13]}` : ErrorImg} />
      </div>
    </div>{" "}
    <div className="scroll">
      <div>
      <img src={ arr?.[14] ? `${process.env.REACT_APP_IMGURL}/${arr?.[14]}` :  ErrorImg} />
        <img src={ arr?.[15] ? `${process.env.REACT_APP_IMGURL}/${arr?.[15]}` : ErrorImg} />
        <img src={ arr?.[16] ? `${process.env.REACT_APP_IMGURL}/${arr?.[16]}` : ErrorImg} />
        <img src={ arr?.[17] ? `${process.env.REACT_APP_IMGURL}/${arr?.[17]}` : ErrorImg} />
        <img src={ arr?.[18] ? `${process.env.REACT_APP_IMGURL}/${arr?.[18]}` : ErrorImg} />
        <img src={ arr?.[19] ? `${process.env.REACT_APP_IMGURL}/${arr?.[19]}` : ErrorImg} />
        <img src={ arr?.[20] ? `${process.env.REACT_APP_IMGURL}/${arr?.[20]}` : ErrorImg} />
      </div>
      <div>
      <img src={ arr?.[14] ? `${process.env.REACT_APP_IMGURL}/${arr?.[14]}` :  ErrorImg} />
        <img src={ arr?.[15] ? `${process.env.REACT_APP_IMGURL}/${arr?.[15]}` : ErrorImg} />
        <img src={ arr?.[16] ? `${process.env.REACT_APP_IMGURL}/${arr?.[16]}` : ErrorImg} />
        <img src={ arr?.[17] ? `${process.env.REACT_APP_IMGURL}/${arr?.[17]}` : ErrorImg} />
        <img src={ arr?.[18] ? `${process.env.REACT_APP_IMGURL}/${arr?.[18]}` : ErrorImg} />
        <img src={ arr?.[19] ? `${process.env.REACT_APP_IMGURL}/${arr?.[19]}` : ErrorImg} />
        <img src={ arr?.[20] ? `${process.env.REACT_APP_IMGURL}/${arr?.[20]}` : ErrorImg} />
      </div>
    </div>
  </div>
  )
}

export default view