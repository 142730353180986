import React, { FC, useState, useEffect, useRef } from "react";
import { Heading1, Heading2, Heading3, Heading4, Heading5, Heading6 } from "../../../common/custom-field/dropstock-heading";
import "./styles.scss";
import { TextfieldLG } from "../../../common/custom-field/dropstock-text";


const DropworksMarketplacePrivacy: FC<any> = () => {
 
  return (
    <>
    <div className="container container-xxl min-height-cover">
     <Heading1 className="text-center">DropWorks Markets Inc.</Heading1>
    <Heading2 className="text-center">Terms of Use</Heading2>
    <Heading2 className="text-center"><strong>Last Updated:</strong> January 12, 2025</Heading2>

    <Heading3 className="uni-mt-56 ">IMPORTANT: THESE TERMS OF USE CONTAIN A BINDING ARBITRATION CLAUSE AND CLASS ACTION WAIVER IN SECTION 18. PLEASE READ CAREFULLY.</Heading3>
    <Heading4  className="uni-mt-32 uni-mb-32">1. Introduction and Acceptance of Terms</Heading4>
    <TextfieldLG className="uni-mb-16 privacy-line-height uni-fw-400 ">Welcome to DropWorks Markets Inc. ("we," "us," "our"). These Terms of Use ("Terms") govern your access to and use of our website located at DropWorks.app ("Site") and our mobile applications, related technologies, and services (collectively, the "Service"). By accessing or using the Service, you agree to be bound by these Terms.</TextfieldLG>

    <Heading5 className="text-decoration-underline uni-mb-16">IMPORTANT: THESE TERMS OF USE CONTAIN A BINDING ARBITRATION CLAUSE AND CLASS ACTION WAIVER IN SECTION 18. PLEASE READ CAREFULLY.</Heading5>

     <Heading4 className="uni-mb-32 uni-mt-32">2. Glossary</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400" >2.1. Artist: Anyone can be a User or non-User, who contributes to the music industry but not a Collaborator, e.g. DJ performer or DJ producer.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">2.2. Artwork: A genuine digital form of a picture, which will become the picture of the Track with streaming Platforms and the DropStock.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">2.3. Artwork Designer: User, who designs the Artwork.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">2.4. Beat Maker: User, who writes and uploads a Beat.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">2.5. Collaborators: Users, who participate in a Project, including Beat Maker, Producer, Lyricist, Vocalist, Artwork Designer and Curator.</TextfieldLG>
<TextfieldLG  className="privacy-line-height uni-fw-400">2.6. Curator: User, who helps to promote the Track, including labels and publishers.</TextfieldLG>
<TextfieldLG  className="privacy-line-height uni-fw-400">2.7. Distributed Track: A completed song that has been distributed to music streaming platforms.</TextfieldLG>
<TextfieldLG  className="privacy-line-height uni-fw-400">2.8. Drop: The process of turning the ownership of a Track into DropStocks.</TextfieldLG>
<TextfieldLG  className="privacy-line-height uni-fw-400">2.9. DropStock: The sellable unit of a Drop that represents the respective ownership of the Drop and the proportion of the royalty income the Drop generates in the future. Each Drop is divided into 6,000 DropStocks.</TextfieldLG>
<TextfieldLG  className="privacy-line-height uni-fw-400">2.10.File Utility: The purpose or usage of an uploaded file in the process of creating a Project or releasing a Distributed Track.</TextfieldLG>
<TextfieldLG  className="privacy-line-height uni-fw-400">2.11. Lyricist: User, who provides lyrics of a Track.</TextfieldLG>
<TextfieldLG  className="privacy-line-height uni-fw-400">2.12. Musiconics: Economics activities connected to music in web 3 environment, including collaborations, visibilities, monetization and values creations. Values creations refer to appreciation in the value of the music and its related royalties.</TextfieldLG>
<TextfieldLG  className="privacy-line-height uni-fw-400">2.13. Musicons: Anyone who contributes to the music industry, including Collaborators and Artist.</TextfieldLG>
<TextfieldLG  className="privacy-line-height uni-fw-400">2.14. Music Prenuers: Anyone, who invests in music or music royalties as a music fans.</TextfieldLG>
<TextfieldLG  className="privacy-line-height uni-fw-400">2.15. Platform: DropWorks.app</TextfieldLG>
<TextfieldLG  className="privacy-line-height uni-fw-400">2.16. Producer: User, who setups, designs and owns a Project, produces, and manages the Project.</TextfieldLG>
<TextfieldLG  className="privacy-line-height uni-fw-400">2.17. Track: A song that is produced through a Project, no matter if it is before or after Drop.</TextfieldLG>
<TextfieldLG  className="privacy-line-height uni-fw-400">2.18. User: Anyone who registers and has an account with the Platform.</TextfieldLG>
<TextfieldLG  className="privacy-line-height uni-fw-400">2.19.Vocalist: User, who performs the vocal of a Track.</TextfieldLG>


     <Heading4 className="uni-mb-32 uni-mt-32">3. Service Description</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">We provide a workspace for collaborators to create a Track and then Drop it. The project encompasses the entire lifecycle of a track before it turns into a Drop. The workspace includes the platform to drop a released track, provide credit collaborator’s ownerships, upload track files, release a track by producers, redistribute the track, create DropStocks of the track and credit DropStocks to collaborators wallet. </TextfieldLG>

     <Heading4 className="uni-mb-32 uni-mt-32">4. Changes to Terms</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">We reserve the right to modify or amend these Terms in our sole discretion from time to time. The “Last updated” date specified on these Terms indicates the date on which the Terms were last updated. Please check these Terms periodically to ensure that you are aware of and in compliance with the most current version. Any revisions to the Terms shall take effect immediately upon such revised Terms being made accessible via the Interface. Such revised Terms shall be binding on the User, and by the User’s continued access and/or use of the Interface, the User shall be deemed to agree to and accept the Terms as revised.</TextfieldLG>

     <Heading4 className="uni-mb-32 uni-mt-32">5. Eligibility and Account Registration</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">5.1.You must be at least 18 years old and have the legal capacity to enter into these Terms.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">5.2. You are required to register an account. You agree to provide accurate, current, and complete information during the registration process.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">5.3. You must not be located in, or be a national or resident of, any restricted or embargoed countries prohibited from using our services.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">5.4. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">5.5. We may require additional verification, including Know Your Customer (KYC) and Anti-Money Laundering (AML) procedures, before allowing full access to our Services.</TextfieldLG>


     <Heading4 className="uni-mb-32 uni-mt-32">6. User Responsibilities</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">6.1. You agree to use the Service only for lawful purposes and in a manner that does not infringe the rights of any third party.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">6.2. The Service is provided "as is," without any guarantees of performance, accuracy, or reliability. You use them at your own risk. We make no warranties, expressed or implied, regarding the operation of the Protocol and the Interface or the outcomes of any actions conducted through it.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">6.3. Assumption of Risk: Trading digital assets involves a high risk of loss, including the potential to lose the entire value of your investment. The value of cryptocurrencies and digital assets can fluctuate widely, and users may experience losses. DropWorks Markets, Inc., is not liable for any financial losses incurred as a result of using the Service. Users should make operations with money they can afford to lose and are solely responsible for their trading decisions.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">6.4. Technology-Related Risks: Using the Service involves various technology-related risks, including but not limited to hardware failures, software bugs, network interruptions, and cyberattacks. </TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">6.5. Cybersecurity Threats: Chateau Our services can be subjected to cybersecurity threats, including hacking, phishing attacks, malware, and other unauthorized access attempts. While we implement security measures to protect the Platform, complete security cannot be guaranteed. Users acknowledge these inherent risks.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">6.6. You are responsible for complying with all applicable local laws and regulations.</TextfieldLG>


     <Heading4 className="uni-mb-32 uni-mt-32">7. Investment Process and Risks</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">7.1. The Service may offer various investment opportunities. Each opportunity will have its own terms, conditions, and risk factors.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">7.2. You acknowledge that investing involves risks, including the potential loss of principal.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">7.3. We do not guarantee any returns on investments made through our Service.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">7.4. You are solely responsible for evaluating the suitability of any investment opportunity for your individual circumstances.</TextfieldLG>


     <Heading4 className="uni-mb-32 uni-mt-32">8. Fees and Charges</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">8.1. Fees may be associated with the use of our Service or specific investment opportunities.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">8.2. All applicable fees will be clearly disclosed to you before you make any investment or transaction.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">8.3. You agree to pay all fees and charges associated with your use of the Service.</TextfieldLG>


     <Heading4 className="uni-mb-32 uni-mt-32">9. Intellectual Property</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">9.1. The content, features, and functionality of the Service are owned by DropWorks Markets, Inc.  and are protected by copyright, trademark, and other intellectual property laws.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">9.2. You may not copy, modify, distribute, sell, or lease any part of our Service without our explicit permission.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">9.3. Any reproduction, distribution, adaptation, modification, or commercial exploitation of any part of the Service is strictly prohibited without explicit written consent from us. You agree not to sell, license, reverse engineer, modify, publish, participate in the transfer or sale of, create derivative works from, or in any other way exploit any of the Content, in whole or in part.</TextfieldLG>


     <Heading4 className="uni-mb-32 uni-mt-32">10. User Content</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">By posting content on our Service, you grant us a non-exclusive, worldwide, royalty-free license to use, modify, publicly perform, publicly display, reproduce, and distribute such content on and through the Service.</TextfieldLG>

     <Heading4 className="uni-mb-32 uni-mt-32">11. Privacy and Data Protection</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">Your use of the Service is also governed by our Privacy Policy, which is incorporated into these Terms by reference.</TextfieldLG>

     <Heading4 className="uni-mb-32 uni-mt-32">12. Third-Party Links and Services</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">Our Service may contain links to third-party websites or services. We are not responsible for the content or practices of these third-party sites or services.</TextfieldLG>

     <Heading4 className="uni-mb-32 uni-mt-32">13. Disclaimer of Warranties</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. WE DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</TextfieldLG>

     <Heading4 className="uni-mb-32 uni-mt-32">14. Limitation of Liability</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">TO THE MAXIMUM EXTENT PERMITTED BY LAW, [YOUR COMPANY NAME] SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES.</TextfieldLG>

     <Heading4 className="uni-mb-32 uni-mt-32">15. Prohibited Activities</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">You are strictly prohibited from using the Platform and its services for any of the following activities</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">15.1. Engaging in any activities that violate local, national, or international laws, regulations, or legal orders, including but not limited to money laundering, terrorist financing, and fraudulent activities.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">15.2. Attempting to manipulate the market, including but not limited to wash trading, spoofing, layering, and other forms of market manipulation.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">15.3. Attempting to gain unauthorized access to our systems, networks, or data, or attempting to interfere with the proper functioning of the Protocol and the Interface.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">15.4. Uploading or distributing any software, programs, or files that are harmful, disruptive, or invasive, including viruses, malware, or any other malicious code.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">15.5. Using the services if you are located in, or a national or resident of, any restricted location or countries embargoed or prohibited from using in ways that infringe on any intellectual property rights.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">15.6. Engaging in any activities that violate these Terms of Use, or any other policies or guidelines provided by us. </TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">15.7. Altering, combining, or integrating the Protocol and the Interface with other software without prior written consent.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">15.8.	Attempting to exploit any vulnerabilities in the Service for personal gain or to harm us or the users.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">15.9. Attempting to obtain the Service’s source code through reverse engineering, decompilation, disassembly, or any other method. Engaging in any of the above activities may result in immediate termination of your access to the Service and may subject you to legal action and other consequences as deemed appropriate by us.</TextfieldLG>


     <Heading4 className="uni-mb-32 uni-mt-32">16. Indemnification</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">You agree to indemnify and hold DropWorks Markets, Inc. and its affiliates harmless from any claims arising out of your use of the Service or violation of these Terms.</TextfieldLG>

     <Heading4 className="uni-mb-32 uni-mt-32">17. Termination</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">We reserve the right to terminate, suspend, or limit your use of DropWorks Markets, Inc. at any time, without prior notice, and at our sole discretion. Such actions may be taken if we reasonably believe that any provision of these Terms of Use has been, or may have been, violated or for any other legitimate reason.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">In exercising these rights, we shall not be liable for any damages, compensation, or payments resulting from the suspension, termination, or limitation of your access to the Platform. You shall not be entitled to any payment, damages, or compensation for any such actions taken by us.</TextfieldLG>
    <TextfieldLG  className="privacy-line-height uni-fw-400">The suspension, termination, or limitation of your use of the Service does not absolve you of any accrued liabilities or responsibilities under these Terms of Use. Our rights to suspend or terminate your use are in addition to, and separate from, any other legal rights or remedies available to us.</TextfieldLG>

     <Heading4 className="uni-mb-32 uni-mt-32">18. Governing Law and Jurisdiction</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">These Terms shall be governed by and construed in accordance with the laws of Nevada without regard to its conflict of law provisions and users consent and agree to exclusive jurisdiction by the competent courts of Nevada.</TextfieldLG>

     <Heading4 className="uni-mb-32 uni-mt-32">19. International Use</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">The Service is controlled and operated from Nevada. If you access the Service from outside Nevada, you are responsible for compliance with local laws.</TextfieldLG>

     <Heading4 className="uni-mb-32 uni-mt-32">20. Severability</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the Terms will otherwise remain in full force and effect.</TextfieldLG>

     <Heading4 className="uni-mb-32 uni-mt-32">21. Entire Agreement</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">These Terms constitute the entire agreement between you and Chateau Capital regarding the use of the Service.</TextfieldLG>

    <Heading4 className="uni-mb-32 uni-mt-32">22. Contact Information</Heading4>
    <TextfieldLG  className="privacy-line-height uni-fw-400">If you have any questions about these Terms, please contact us at:
DropWorks Markets, Inc, Supports@dropworks.app
By using our Service, you acknowledge that you have read and understood these Terms of Use and agree to be bound by them.
</TextfieldLG>
    </div>
    </>
  );
};

export default DropworksMarketplacePrivacy;
