import { FC, useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import './styles.scss';
 
 
interface Crop {
  x: number;
  y: number;
}
 
interface ImageSize {
  width: number;
  height: number;
}
 
interface CroppedArea {
  width: number;
  height: number;
  x: number;
  y: number;
}
interface CropperToolProps {
  onImageCrop: (croppedImage: string) => void;
  onClose: () => void;
  iconImg: string;
  uploadText?: string;
  id?:string,
  is3000?:boolean
}
 
export const CropperTool: FC<CropperToolProps> = ({ onImageCrop, onClose, iconImg, uploadText,id="",is3000=false}) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CroppedArea | null>(null);
  const [cropSize, setCropSize] = useState({ width: 300, height: 300 })
  const [imageSize, setImageSize] = useState<ImageSize | null>(null);
  const [error, setError] = useState<string | null>(null);
 
 
  useEffect(() => {
    setTimeout(() => {
      setError(null); 
    }, 3000); 
  }, [error]);
 
 
  useEffect(() => {
    const updateCropSize = () => {
      if (window.innerWidth < 768) {
        setCropSize({ width: 150, height: 150 }); // Mobile crop size
      } else {
        setCropSize({ width: 300, height: 300 }); // Default desktop crop size
      }
    };
 
    updateCropSize(); // Initial check
    window.addEventListener("resize", updateCropSize); // Listen for window resize
 
    return () => window.removeEventListener("resize", updateCropSize);
  }, []);
 
 
  // Function to get cropped image
  const getCroppedImg = (imageSrc: string, pixelCrop: CroppedArea): Promise<string> => {
    const canvas = document.createElement('canvas');
    const image = new Image();
 
    return new Promise((resolve, reject) => {
      image.src = imageSrc;
      image.onload = () => {
        const ctx = canvas.getContext('2d');
        if (!ctx) return reject('Could not get context from canvas');
 
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
 
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );
 
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(URL.createObjectURL(blob));
          } else {
            reject('Could not create blob from canvas');
          }
        }, 'image/jpeg');
      };
    });
  };
 
 
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
 
    if (file) {
      const reader = new FileReader();
      const img = new Image();
 
     
      reader.onload = () => {
        img.src = reader.result as string;
        img.onload = () => {
          if (is3000) {
            if (img.width < 3000 || img.height < 3000) {
              setError(
                "The uploaded image must be at least 3000x3000 pixels."
              );
             
            } else {
              setError(null); 
              setImageSrc(reader.result as string);
              setImageSize({ width: img.width, height: img.height });
              
              const initialZoom = Math.min(img.width / 4760, img.height / 4760);
              setZoom(initialZoom);
            }
          } else {
            if (img.width < 1500 || img.height < 1500) {
              setError(
                "The uploaded image must be at least 1500x1500 pixels."
              );
            } else {
              setError(null); 
              setImageSrc(reader.result as string);
              setImageSize({ width: img.width, height: img.height });
            
              const initialZoom = Math.min(img.width / 2360, img.height / 2360);
              setZoom(initialZoom);
            }
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };
 
 
  // useEffect(() => {
  //   if (imageSrc) {
  //     const img = new Image();
  //     img.src = imageSrc;
  //     img.onload = () => {
  //       const { width, height } = img;
  //       setImageSize({ width, height });
 
  //       const cropperWidth = 300;
  //       const cropperHeight = 300;
 
  //       const zoomWidth = cropperWidth / width;
  //       const zoomHeight = cropperHeight / height;
  //       const newZoom = Math.max(zoomWidth, zoomHeight);
 
  //       setZoom(newZoom > 1 ? newZoom : 1);
  //     };
  //   }
  // }, [imageSrc]);
 
 
 
 
 
  const handleCrop = async () => {
    if (imageSrc && croppedAreaPixels) {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      onImageCrop(croppedImage);
      onClose();
    }
  };
 
  const handleZoomIn = () => {
    if (is3000) {
      if (imageSize) {
        const maxZoom = Math.min(3,Math.min(imageSize.width / 4760, imageSize.height / 4760));
        setZoom((prevZoom) => Math.min(prevZoom + 0.001, maxZoom));
      }
    } else {
      if (imageSize) { const maxZoom = Math.min( 3,Math.min(imageSize.width / 2360, imageSize.height / 2360));
        setZoom((prevZoom) => Math.min(prevZoom + 0.001, maxZoom));
      }
    }
  };
 
  const handleZoomOut = () => {
    if (is3000) {
      setZoom((prevZoom) => Math.max(prevZoom - 0.001, 0.6302));
    } else {
      setZoom((prevZoom) => Math.max(prevZoom - 0.001, 0.6355));
    }
  };
 
  const onCropComplete = useCallback((croppedArea: CroppedArea, croppedAreaPixels: CroppedArea) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
 
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      // event.preventDefault();
      if (event.deltaY < 0) {
        handleZoomIn();
      } else {
        handleZoomOut();
      }
    };
 
    window.addEventListener("wheel", handleWheel, { passive: false });
 
    return () => {
      setError(null)
      window.removeEventListener("wheel", handleWheel);
    };
  }, [imageSize]);
 
  const minZoom = is3000 ? 0.6302 : 0.6355; // Define the minimum zoom level
const maxZoom = is3000 ? (imageSize ? Math.min(3, Math.min(imageSize.width / 4760, imageSize.height / 4760)) : 3) : (imageSize ? Math.min(3, Math.min(imageSize.width / 2360, imageSize.height / 2360)) : 3);
 
 
 
const isImageZoomable = () => {
  if (imageSize) {
    return is3000 ? (imageSize.width > 3000 && imageSize.height > 3000) : (imageSize.width > 1500 && imageSize.height > 1500);
  }
  return false;
};
 
  return (
    <div className="cropper-container mt-5">
      {!imageSrc && (
        <>
          <input
            type="file"
            accept="image/*"
            onChange={(e)=>{handleImageChange(e);e.target.value = "";}}
            id={id || "image-upload"}
            className="d-none"
          />
          <label htmlFor={id || "image-upload"} className="upload-label d-flex flex-column align-items-center ">
            <img src={iconImg} className="uploadBanner-uploadicon cursor-pointer" alt="Upload Icon" />
            <span className="text-content uploadBanner-upload-text mx-0 my-0">{uploadText}</span>
          </label>
        </>
      )}
           {error && <p className="error-message text-danger">{error}</p>}
      {imageSrc && (<>
        <div className="cropper mt-5">
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={1}
            cropSize={cropSize}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            objectFit="cover"
            onWheelRequest={(e) => {
              return false;
            }}
          />
        </div>
 
        <div className="zoom-controls crooper-zoom d-flex justify-content-between m-cropper-none  position-absolute end-0  flex-row gap-2 ">
        {isImageZoomable() && <>
          <button onClick={handleZoomOut} className="btn btn-light cropper-btn cropper-tranform">-</button>
 
         
            <input
            type="range"
            color="red"
            min={is3000 ? 0.6302 : 0.6355}
            step={0.001}
            max={is3000? imageSize ? Math.min(3, Math.min(imageSize.width / 4760, imageSize.height / 4760)) : 3: imageSize ? Math.min(3, Math.min(imageSize.width / 2360, imageSize.height / 2360)) : 3}
            value={zoom || 1 }
            onChange={(e) => setZoom(parseFloat(e.target.value))}
            className="cursor-pointer"
            style={{ height: "3px" }}
          />
         
       
          <button onClick={handleZoomIn} className="btn btn-light cropper-btn cropper-tranform">+</button>
         
          <span className='percentage-cropper' >{Math.round(((zoom - minZoom) / (maxZoom - minZoom)) * 100)}%</span> </>}
        </div>
        <div className="d-flex justify-content-between  position-absolute start-0 bottom-0  gap-2 uni-ml-8 uni-mb-8">
          <button onClick={handleCrop} className=' btn btn-light cropper-btn' >Ok</button>
          <button onClick={()=>setImageSrc(null)} className=' btn btn-light cropper-btn'>Cancel</button>
        </div>
      </>
      )}
 
    </div>
  );
};