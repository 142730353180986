import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import "./styles.scss"
import { BlueTik } from '../../../../../common/all-icons';
import kingImage from "../../../../../assets/img/king.png"
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

const view = (doDeleteUser:any) => {

  const DeletedUserId = useSelector((state: RootState) => state.messageChat);

  return (
    <div
      className="modal fade"
      id="DeleteChat"
      aria-hidden="true"
      aria-labelledby="DeleteChat"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered uni-md-popup">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-mb-16">
            <Heading3 className='approve-modal-title mx-0 my-0'>Delete Chat?</Heading3>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0  uni-mb-56">
            <TextfieldLG className='approve-modal-subtitle mt-0'>This action will permanently delete all chats with {DeletedUserId.Data.name}</TextfieldLG>
          </div>
          <div className="modal-footer  mbl-modal-footer ps-0 pt-0 pb-0 ">
            <CloseModalButton className="mbl-anchor-full-btn">
            <DropworksSmallButton className="btn-wid mbl-full-width-btn btn-bg-gray3-approve my-0 ml-0">Cancel</DropworksSmallButton></CloseModalButton>
            <CloseModalButton className="mbl-anchor-full-btn">
            <DropworksSmallButton onClick={doDeleteUser} className="btn-wid mbl-full-width-btn m-0">Delete</DropworksSmallButton></CloseModalButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default view;


