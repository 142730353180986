import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import { CloseModalButton } from "../../logo-header/view";
import "./styles.scss";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";

const view = () => {
  return (
    <div
      className="modal fade"
      id="DeleteAccount3"
      aria-hidden="true"
      aria-labelledby="DeleteAccount3"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered uni-md-popup">
        <div className="modal-content ">
          <div className="modal-cross-icon">
            <CloseModalButton>
              <img src={crossIcon} className="fs-1 uni-mr-16" />
            </CloseModalButton>
          </div>
          <div className="modal-header ps-0 pt-0">
            <Heading3 className="modal-title">Delete Account</Heading3>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
            <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt ctm-md-lh">
              To confirm your identity finish the process through the email that
              we just sent you.
            </TextfieldLG>
          </div>
          <div className="modal-footer social-mobile-modal-footer ps-0 pt-0 pb-0 ">
            <DropworksSmallButton className="btn-wid  social-link-modal-btns m-0">
              Confirm{" "}
            </DropworksSmallButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
