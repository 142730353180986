import { useNavigate, useParams } from "react-router-dom"
import { view } from "./view"
import { getTrackProjectBeatsDetailsById, updateFevourite } from "../../shared/utils/apiServices";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProjectModalId } from "../../../redux/reducer/reportSlice";
import { setProjectBannerImg } from "../../../redux/reducer/uploadSlice";
import { clearIsLike, setIsLiked } from "../../../redux/reducer/beatSlice";
import { RootState } from "../../../redux/store";

export const ProjectPage = () => {
    const slugName = useParams();
    const projectSlugName = slugName.slugName;
    const [projectData, setProjectData] = useState<any>()
    const {id} =useSelector((state:RootState)=>state.user)
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchUserData = async () => {
            const result = await getTrackProjectBeatsDetailsById(projectSlugName,0);
            dispatch(clearIsLike());
            if (result.success) {
                setProjectData({...result?.featuredBeatsData?.data?.track,isLiked:Boolean(result.featuredBeatsData?.data?.track?.favoriteData?._id)});
                dispatch(setIsLiked(result?.featuredBeatsData?.data?.track.favoriteData ? true : false));
                dispatch(setProjectModalId(result?.featuredBeatsData?.data?.track?._id));
                dispatch(setProjectBannerImg(result?.featuredBeatsData?.data?.track?.art_work_image?.[0]?.src
                    ? result?.featuredBeatsData?.data?.track?.art_work_image?.[0]?.src
                    : result?.featuredBeatsData?.data?.track?.default_artwork))
            }
            else{
                navigate("/PageNotFound")
            }
        };

        fetchUserData();
    }, [projectSlugName])


    const navigateTo = (value: string) => () => {
        navigate(value)
    }
    const callOnLikeUnlike=async()=>{
    setProjectData({...projectData,isLiked:!projectData?.isLiked});
    const obj = { project_id: projectData._id }; // or { track_id: 456 } or { project_id: 789 }
    const userID = id
    try{
    
      const response = await updateFevourite(obj ,userID);
      if(response)
        {
          
      }
      else{
        setProjectData({...projectData,isLiked:!projectData?.isLiked});
      }
    }
    
    catch{
        setProjectData({...projectData,isLiked:!projectData?.isLiked});
    }
    }
    return (view({ navigateTo, projectData,callOnLikeUnlike }))
}