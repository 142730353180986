import { FC, useEffect, useState } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import "../../marketplace-dropworks-composite/styles.scss";
import { DropworksNormalButton } from "../../../../common/custom-field/dropworks-button";
import { TextfieldMD } from "../../../../common/custom-field/dropstock-text";
import { Heading0 } from "../../../../common/custom-field/dropstock-heading";

import InfiniteImageScroll from "../../../../common/infinite-image-scroll";
import {
  closeModal,
  openPopup,
} from "../../../shared/components/logo-header/view";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const view: FC<viewType> = ({ navigateTo,infiniteScrollData }) => {
  const navigate = useNavigate();
  const dispatch =useDispatch()
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1000);

  useEffect(() => {
    closeModal();
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1000);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className=" marketplace-dw-composite-main overflow-hidden ">
      <div className="d-flex marketplace-dw-composite-main-firstrow">
        <div className="marketplace-dw-composite-main-firstrow-col1 px-0 uni-px-24">
          <div className="marketplace-dw-composite-main-firstrow-col1-content w-54-create">
            <Heading0 className="text-wrap uni-mb-40 marketplace-select-head">
              <div data-aos="fade-right">Add a Beat </div>
            </Heading0>
            <TextfieldMD className="uni-mb-40 marketplace-dw-composite-main-firstrow-col1-content-sm px-0 mb-32-mbl mx-0">
              Share the Beat from you to others for Tracks creation
            </TextfieldMD>
            <div className="d-flex marketplace-dw-btns ">
              <DropworksNormalButton
                className="w-auto uni-px-72 mb-0 fs-18 home-mobileview-btn"
                onClick={() =>
                  isWideScreen
                    ? navigate("/beat-empty")
                    : openPopup("CreateMobile", dispatch)
                }
              >
                Start
              </DropworksNormalButton>
            </div>
          </div>
        </div>

        <div className=" marketplace-dw-composite-main-firstrow-col2 pe-0 ">
          <InfiniteImageScroll arr={infiniteScrollData.beatData}/>
        </div>
      </div>

      <div className="d-flex marketplace-dw-composite-main-firstrow ">
        <div className=" marketplace-dw-composite-main-firstrow-col2 pe-0  mobile-order-1">
          <InfiniteImageScroll arr={infiniteScrollData.projectData}/>
        </div>

        <div className="marketplace-dw-composite-main-firstrow-col11 px-0 uni-px-24">
          <div className="marketplace-dw-composite-main-firstrow-col1-content w-54-create">
            <Heading0 className="text-wrap uni-mb-40 marketplace-select-head">
              <div data-aos="fade-right">Create a Project</div>
            </Heading0>
            <TextfieldMD className="uni-mb-40 marketplace-dw-composite-main-firstrow-col1-content-sm px-0 mb-32-mbl mx-0">
              Collaborate with others to create a Track as a Producer
            </TextfieldMD>
            <div className="d-flex marketplace-dw-btns ">
              <DropworksNormalButton
                className="w-auto uni-px-72 mb-0 fs-18 home-mobileview-btn"
                onClick={() =>
                  isWideScreen
                    ? navigate("/track-project-overview")
                    : openPopup("CreateMobile", dispatch)
                }
              >
                Start
              </DropworksNormalButton>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex marketplace-dw-composite-main-firstrow">
        <div className="marketplace-dw-composite-main-firstrow-col1 px-0 uni-px-24">
          <div className="marketplace-dw-composite-main-firstrow-col1-content w-54-create">
            <Heading0 className="text-wrap uni-mb-40 marketplace-select-head">
              <div data-aos="fade-right"> Drop a Released Track</div>
            </Heading0>
            <TextfieldMD className="uni-mb-40 marketplace-dw-composite-main-firstrow-col1-content-sm px-0 mb-32-mbl mx-0">
              Offer your Released Track to DropWorks' community
            </TextfieldMD>
            <div className="d-flex marketplace-dw-btns ">
              <DropworksNormalButton
                className="w-auto uni-px-72 mb-0 fs-18 home-mobileview-btn"
                onClick={() =>
                  isWideScreen
                    ? navigate("/track-project-rerelease")
                    : openPopup("CreateMobile", dispatch)
                }
              >
                Start
              </DropworksNormalButton>
            </div>
          </div>
        </div>

        <div className=" marketplace-dw-composite-main-firstrow-col2 pe-0 ">
          <InfiniteImageScroll arr={infiniteScrollData.reReleaseData}/>
        </div>
      </div>
    </div>
  );
};

export default view;
