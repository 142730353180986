import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import {
  DropworksDisableButton,
  DropworksSmallButton,
} from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import UserIcon from "../../../../../assets/steps/Group 18414.svg";
import { DropworksRadioButton } from "../../../../../common/custom-field/dropworks-radio-button";
import "./styles.scss";
import { DropworksSearchInput } from "../../../../../common/custom-field/dropworks-search-input";
import king from "../../../../../assets/img/king.png";
import { BlueTik } from "../../../../../common/all-icons";
import { SelectArrowDropdown } from "../../../../../common/selection-dropdown";
import {
  ButtonComponent,
  closeModal,
  CloseModalButton,
} from "../../logo-header/view";
import React, { useEffect, useState } from "react";
import { getAllUsers } from "../../../utils/apiServices";
import Loader from "../../loader/view";
import { DropworksRadioCheckbox } from "../../../../../common/custom-field/dropworks-radio-checkbox";
import {
  setAllRoleUser,
  setInviteModalUserID,
} from "../../../../../redux/reducer/uploadSlice";
import { useDispatch, useSelector } from "react-redux";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import { RootState } from "../../../../../redux/store";
import { useNavigate } from "react-router-dom";
const view = () => {
  const { spotifyUser } = useSelector((state: RootState) => state.upload);
  const navigate = useNavigate();

  const openModal = () => {};

  const userData = useSelector((state: any) => state.user);

  return (
    <ModalWrapper modalId="LinkConfirmation" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="LinkConfirmation"
        aria-hidden="true"
        aria-labelledby="LinkConfirmation"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered custom-modal-width">
          <div className="modal-content modal-width-inviteModal">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header ps-0 pt-0 uni-pr-72">
              <h1 className="modal-title">Spotify Links</h1>
            </div>

            <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72">
              <TextfieldLG className=" uni-mt-16 ctm-txt">
                These users do not have their own Spotify links.
              </TextfieldLG>
              {/* <TextfieldSM className='uni-mb-24 modal-8-subcontent'>You may search for his display name or by his username</TextfieldSM> */}
              <div className="content-height content-height-invite uni-p-16">
                {spotifyUser &&
                  spotifyUser?.map((data: any, index: number, arr:any) => {
                    return (
                      <p
                        className="d-flex uni-mb-8  content align-items-center cursor-pointer link-btm"
                        onClick={() => {
                          userData.id == data?.receiver_user_id
                            ? navigate(`/profile`)
                            : navigate(`/user-profile/${data?.slug_name}`);
                          closeModal();
                        }}
                      >
                        <img
                          className="modal-8-box"
                          src={`${process.env.REACT_APP_IMGURL}/${data?.profile_image?.[0]?.src}`}
                          onError={(e) => {
                            e.currentTarget.src = UserIcon;
                          }}
                        />
                        <TextfieldSM className="uni-mx-16 my-0 text-field">
                          {" "}
                          <TextfieldXSM className=" m-0">
                            {data?.display_name || data?.username} <br />
                          </TextfieldXSM>
                          <React.Fragment key={data.slugName}>
                            <TextfieldXSM
                              className="underline-opening m-0"
                              onClick={() =>
                                userData.id == data?.receiver_user_id
                                  ? navigate(`/profile`)
                                  : navigate(`/user-profile/${data?.slug_name}`)
                              }
                            >
                              @{data?.username}{" "}
                            </TextfieldXSM>

                            {/* {(index < arr?.length - 1) && ", "} */}
                          </React.Fragment>
                          {}
                        </TextfieldSM>
                      </p>
                    );
                  })}
              </div>
              <TextfieldLG className=" uni-mt-16 ctm-txt">
                Continue releasing the project
              </TextfieldLG>
            </div>
            <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72 uni-mt-32">
              <CloseModalButton>
                <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">
                  Cancel
                </DropworksSmallButton>
              </CloseModalButton>
              <ButtonComponent id="ReleaseModal">
                <DropworksSmallButton className="btn-wid m-0 my-0">
                  Acknowledged
                </DropworksSmallButton>
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default view;
