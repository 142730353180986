import { useNavigate } from "react-router-dom"
import { view } from "./view"
import { useEffect, useState } from "react";
import { closeModal } from "../logo-header/view";
import { trace } from "console";

export const Footer=()=>{

    const navigate=useNavigate();
    
    const navigateTo=(value:string)=>()=>{
        navigate(value)
    }
const [hide,setHide]=useState(false);
const [allHide,setAllHide]=useState(false);

const handlePagesHover = () => {

    setTimeout(()=>{
        const galleryLink = document.querySelector(".pj-1 a") as HTMLAnchorElement ;
        if (galleryLink) {
          galleryLink?.blur(); // Remove focus from Gallery + when hovering over Pages +
        }
        const galleryLink2 = document.querySelector(".pj-2 a") as HTMLAnchorElement ;
        if (galleryLink2) {
          galleryLink2?.blur(); // Remove focus from Gallery + when hovering over Pages +
        }
        const galleryLink3 = document.querySelector(".home-nav-ul-studio") as HTMLAnchorElement ;
        if (galleryLink3) {
          galleryLink3?.blur(); // Remove focus from Gallery + when hovering over Pages +
        }
        const galleryLink4 = document.querySelector(".home-nav-ul-marketplace") as HTMLAnchorElement ;
        if (galleryLink4) {
          galleryLink4?.blur(); // Remove focus from Gallery + when hovering over Pages +
        }
    },500)
  };

useEffect(()=>{
    closeModal();
    if ( window.location.pathname === "/PageNotFound"|| window.location.pathname === "/beat-empty" || window.location.pathname.includes("track-project-rerelease") || window.location.pathname.includes("track-project-overview") || window.location.pathname === "/message-chat" || window.location.pathname === "/profile-setting"|| window.location.pathname === "/dropworks-select" || window.location.pathname === "/dropworks-composite")
     {
      setAllHide(true); // hide all footer
     }
   else if( window.location.pathname.includes("project") || window.location.pathname.includes("track-page") || window.location.pathname.includes("beats-page") || window.location.pathname.includes("profile") || window.location.pathname.includes("favorites") || window.location.pathname === "/personal-portfolio" || window.location.pathname === "/search" || window.location.pathname === "/library"|| window.location.pathname === "/invite" ||  window.location.pathname === "/create" || window.location.pathname === "/wallet" ||  window.location.pathname === "/notification"  ||  window.location.pathname === '/select-portfolio'  ||  window.location.pathname === '/composite-portfolio'       )
    {
        // hide small footer 
            setHide(true);
            setAllHide(false);
    }

    else{
        setHide(false);
        setAllHide(false);
        }
        handlePagesHover()

        },[window.location.pathname])
    return(view({navigateTo,hide,allHide}))
}