import {TextfieldMD,TextfieldSM} from "../../../../../common/custom-field/dropstock-text";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { FC } from "react";
import "./styles.scss";
import { ButtonComponent, closeModal, CloseModalButton } from "../../logo-header/view";
import {useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { deleteBeat, deleteTrackProject } from "../../../utils/apiServices";
import { useNavigate } from "react-router-dom";
import { setData } from "../../../../../redux/reducer/musicSlice";
import { ErrorImg } from "../../../utils/localStorage";
import { Heading3, Heading4 } from "../../../../../common/custom-field/dropstock-heading";

interface viewType {
  navigateTo:(path:string)=>()=>void
} 

   

// const navigate =useNavigate()

const view  :FC<viewType> =({ navigateTo }) => {

  const path = window.location.pathname;
  const dispatch=useDispatch()
  const {deleteModal,musicPageData,musicPlayer}=useSelector((state:RootState)=>state.music)
  console.log(deleteModal,'deleteModal')
const navigate=useNavigate()
  const handleDelete = async()=>{
    const response:any = deleteModal.deleteType==="beat"? await deleteBeat(deleteModal.deleteID): await deleteTrackProject({  id: deleteModal.deleteID });
    if (response?.success) {
      if(deleteModal.deleteType==="beat"){
        if(musicPageData.musicPageId==musicPlayer.musicId){
          dispatch(setData(false))
          closeModal()
          navigate("/library")
        }
        else{
          closeModal()
          navigate("/library")
        }
      }
      else{
        closeModal()
        navigate("/library")
      }
     
    }
}
  return (
    <div
      className="modal fade"
      id="DeleteModal"
      aria-hidden="true"
      aria-labelledby="DeleteModal"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered uni-md-popup">
        <div className="modal-content">
        <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header uni-mb-32 p-0">
            <Heading3 className="modal-title">{deleteModal.deleteType==="beat"?"Delete Beat":deleteModal.deleteType==="track"?"Delete Track":""}</Heading3>
          </div>
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-32">
             <TextfieldSM className="release-subheading uni-mb-32">You are about to delete the {deleteModal.deleteType==="beat"?"beat":deleteModal.deleteType==="track"?"final track":""}. This action is final and non-reversible.</TextfieldSM>
              
              <div className="d-flex align-items-center gap-3 uni-mb-32">
              {/* `${process.env.REACT_APP_IMGURL}/${ele.profile_image?.[0]?.src}` */}
                <img src={`${process.env.REACT_APP_IMGURL}/${deleteModal.deleteImage}`} 
                  onError={(e) => (e.currentTarget.src = ErrorImg)} alt="" className="releaseImage"/>
                <div>
                  <h2 className="hot-summer"> {deleteModal.deleteName} 
                    </h2>
                 <TextfieldMD className="hot-summer-under">{deleteModal.deleteSlugName}</TextfieldMD>
                </div>
              </div>
              
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 mbl-modal-footer">
            <CloseModalButton className="mbl-anchor-full-btn">
            <DropworksSmallButton className="btn-wid mbl-full-width-btn btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">
              Cancel
            </DropworksSmallButton>
            </CloseModalButton>
            <CloseModalButton className="mbl-anchor-full-btn">
         
            {/* <ButtonComponent id="DeleteModal"> */}
            <DropworksSmallButton className="btn-wid m-0 mbl-full-width-btn" onClick={()=>handleDelete()}>
              Delete
            </DropworksSmallButton>
            {/* </ButtonComponent> */}
  
          </CloseModalButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
