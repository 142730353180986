import { useLocation, useNavigate } from "react-router-dom"
import view from "./view";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSecondFormApi } from "../../../shared/utils/apiServices";
import { useEffect, useState } from "react";
import { NavigationOnLogin } from "../login";
import { useDispatch, useSelector } from "react-redux";
import { clearUploadSettingImg } from "../../../../redux/reducer/uploadSlice";

export const Setting_Up_Page = () => {
  const uploadedImage = useSelector((state: any) => state.upload.settingUpImg);
  const setUploadImgAPI = useSelector((state: any) => state.upload.settingUpImgAPI);
  const location = useLocation();
  const dispatch =useDispatch()
  NavigationOnLogin();
  const [loading, setLoading] = useState(false);
  const [showUploadLoading , setShowUploadLoading]=useState(false)
  const [showText, setShowText] = useState(false);
  const schema3 = yup
    .object({
      username: yup.string().trim().required("Username is required").min(3, "Username should  have atleast 3 characters"),
      display_name: yup.string().trim().required("Display Name is required").min(3, "Display name should  have atleast 3 characters").max(30, "Display name should not be greater than 30 characters"),
      biography: yup.string().trim().max(80, "Biography should not be greater than 80 characters"),
      profile_image: yup.array().required("Profile Image is required"),
    })

  useEffect(() => {
    if(!setUploadImgAPI && uploadedImage)
      {
        setShowUploadLoading(true);
        setValue("profile_image", "", { shouldValidate: false }) 
    }
    if (setUploadImgAPI?.[0]?.src && uploadedImage) {

       setValue("profile_image", setUploadImgAPI, { shouldValidate: true }) 
       setShowUploadLoading(false)
      
      }
  }, [setUploadImgAPI,uploadedImage])

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setError,
    clearErrors,
    reset,
    watch,
    setValue
  } = useForm<any>({
    resolver: yupResolver(schema3),
    mode: "onSubmit",
  });
  const usernameValue = watch("username", "");

  const onSubmit = async (data: any) => {
    // setLoading(true);
    let newData = { ...data };
    newData.username = newData.username.slice(1);
    let response: any = await registerSecondFormApi(newData);
    if (response?.success) {
      setLoading(false);
      if(response?.data?.token && response?.data?.mandatory_field)
      {
        navigate(`/?token=${response?.data?.token}&openPopup=${response?.data?.mandatory_field}&display_name=${response?.data?.updatedUser?.username}&profile_image=${response?.data?.updatedUser?.profile_image?.[0]?.thumbnail || response?.data?.updatedUser?.profile_image?.[0]?.src}`);
      }
      else{
        navigate("/verification-complete");
      }

    } else {
      setLoading(false);
      setShowText(false);
      setError(response?.type, { type: "custom", message: response?.message });
    }
  };

  const navigate = useNavigate();
  const navigateTo = (value: string) => () => {
    navigate(value)
  }

  useEffect(() => {
    dispatch(clearUploadSettingImg())
    reset()
  }, [location.pathname]);
  return (view({ navigateTo, handleSubmit, onSubmit, errors,showUploadLoading, register, setValue,usernameValue, loading, showText, isSubmitted, uploadedImage,setError, clearErrors}))
}