import { FC, useState } from "react";
import view from "./view";

import { useDispatch } from "react-redux";
import { setWalletDetails } from "../../../../../redux/reducer/walletSlice";

export const WalletDepositTypeModal = () => {
    const dispatch = useDispatch();
    const [depositTypeName, setDepositTypeName] = useState("USDC")
    const setDepositType = (name: string) => {
        setDepositTypeName(name);
        dispatch(setWalletDetails({ deposit: name }))
    }

    return view({ setDepositType, depositTypeName });
}
export default WalletDepositTypeModal;