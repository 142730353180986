import { useRef, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { view } from "./view";
import { changePin } from "../../../utils/apiServices";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

export const ChangePinModal = () => {

  const { validateValue } = useSelector((state: RootState) => state.auth)
  const [loading, setLoading] = useState(false);
  const closeModalRef = useRef<HTMLAnchorElement>(null);
  const navigate = useNavigate();
  const navigateTo = (value: string) => () => {
    navigate(value);
  };
  const schema4 = yup.object({
    pin: yup.string().min(4, "The PIN must contain exactly 4 or 6 digits. Letters and special characters are not allowed.").max(6,"The PIN must contain exactly 4 or 6 digits. Letters and special characters are not allowed.").required("New Pin is required").matches(/^\d{4}$|^\d{6}$/, "Pin should be 4 or 6 digits."),
    newPin: yup
      .string()
      .oneOf([yup.ref("pin")], "Pin must match")
      .required("Confirm Pin is required"),
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm<any>({
    resolver: yupResolver(schema4),
    mode: "onSubmit",
  });

  const handleCloseModalClick = () => {
    if (closeModalRef.current) {
      closeModalRef.current.click();
    }
  };

  const onSubmit = async (data: any) => {
    // Clone validateValue to avoid direct mutation
    const clonedValidateValue = { ...validateValue };

    data.pin = clonedValidateValue?.pin;
    data.phrase = clonedValidateValue?.phrase;
    data.privateKey = clonedValidateValue?.privateKey;

    setLoading(true)
    const response: any = await changePin({ data });
    if (response?.success) {
      setLoading(false);
      handleCloseModalClick()
    } else {
      setLoading(false);
      setError(response.type || "pin", { type: "custom", message: response.message });
    }
  };

  const openModal = () => {
    setValue("pin", "");
    setValue("newPin", "");
    setError("pin", { type: "custom", message: "" });
    setError("newPin", { type: "custom", message: "" });
    setLoading(false);
  }
  return view({ errors, isSubmitted, loading, register, onSubmit, handleSubmit, navigateTo, closeModalRef, openModal });
}
export default ChangePinModal