import { useEffect, useRef, useState } from "react";
import { viewType } from "./modal";
import "./styles.scss";

import {
  Heading1,
  Heading3,
  Heading4,
} from "../../../../common/custom-field/dropstock-heading";
import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
} from "../../../../common/custom-field/dropstock-text";
import {
  DropworksNormalButton,
  DropworksSmallButton,
} from "../../../../common/custom-field/dropworks-button";
import { HomeCheckIcon } from "../../../../common/all-icons";
import { FC } from "react";
import HomeTabs from "../../../../common/home-tabs";
import { useTranslation } from "react-i18next";
import { getHomeSliderPhoto } from "../../../shared/utils/apiServices";
import { preloadImages } from "../../../shared/utils/localStorage";
import { ErrorImg } from "../../../shared/utils/localStorage";

// CSS for your component

const view: FC<viewType> = ({ navigateTo }) => {
  const [tabsValue, setTabValue] = useState(0);
  const [showComponent, setShowComponent] = useState(tabsValue);
  const checkTabState = (value: number) => {
    setTabValue(value);
  };
  const { t } = useTranslation();

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    callSliderPhoto()
    const videoElement = videoRef.current;

    if (!videoElement) return;
    videoElement.play().catch((error) => {
      // Handle any errors with autoplay restrictions on iOS or other browsers
      console.log("Autoplay failed: ", error);
    });

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          videoElement.play();
        } else {
          videoElement.pause();
        }
      },
      { threshold: 0.1 } // Play video when at least 50% of it is visible
    );

    observer.observe(videoElement);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    return () => {
      document.querySelectorAll('.modal-backdrop').forEach((backdrop) => backdrop.remove());
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    };
  }, []);




  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowComponent(tabsValue);
    }, 120);

    return () => clearTimeout(timeout);
  }, [tabsValue]);







const [homeImagesList,setHomeImagesList]=useState({
  ArtistGallery: [{
    src:"",
    thumbnail: ""
  }],
DropWorksCompositeGallery: [{
  src:"",
  thumbnail: ""
}],
DropWorksSelectGallery: [{
  src:"",
  thumbnail: ""
}],
MusicGallery: [{
  src:"",
  thumbnail: ""
}],
TemplateGallery:[{
  src:"",
  thumbnail: ""
},
{
  src:"",
  thumbnail: ""
},{
  src:"",
  thumbnail: ""
},{
  src:"",
  thumbnail: ""
},{
  src:"",
  thumbnail: ""
},{
  src:"",
  thumbnail: ""
},{
  src:"",
  thumbnail: ""
},{
  src:"",
  thumbnail: ""
},{
  src:"",
  thumbnail: ""
},{
  src:"",
  thumbnail: ""
},],
});

  const callSliderPhoto=async()=>{
    const data =await getHomeSliderPhoto();
    if(data?.success)
    {
      if(data?.data?.length>0){
        setHomeImagesList(data.data);
      }
      // preloadImages([data.data.MusicGallery?.[0]?.src])
    }
    else{
      console.log("error in home")
    }
  }

  return (
    <div className="home mt-0">
      
      <div className="home-container full-width-child">
        <div className="bubbles">
          <video
            className="dw-maintenance-videod"
            autoPlay
            muted
            loop
            playsInline
            webkit-playsinline="true"
            controls={false}
          >
            <source src="/HomeBackVideo.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="girlImg"></div>
      </div>

      <div className="container container-xxl" data-aos="fade-up">
        <Heading1 className="uni-mb-40  text-center">
          {t("TRANSACTIONS")}
        </Heading1>
        <TextfieldLG className="uni-mb-40 home-w-782 txt-col-204 text-center">
          DropWorks orb music Creators, Artists, and Musicpreneurs into a
          collaborative and value creation community
        </TextfieldLG>
        <DropworksSmallButton
          className="w-auto mx-auto home-mobileview-btn"
          onClick={() => navigateTo("/featured")}
        >
          Start Your Journey
        </DropworksSmallButton>
      </div>
      <div
        className=" w-100 d-flex full-width-child uni-my-208 mx-auto home-box-slide"
        data-aos="fade-up"
      >
        { homeImagesList?.TemplateGallery?.map((ele :any, i) => {
          return <div className="home-box" style={{backgroundImage:`url("${process.env.REACT_APP_IMGURL}/${(ele?.thumbnail || ele?.src)}")`}}></div>;
        })}
      </div>
      <div
        className="container container-xxl overflow-hidden"
        data-aos="fade-up"
      >
        <div className="uni-mb-120 home-page-mobilespace-bottom">
          <Heading1 className="text-center uni-mb-40" data-aos="fade-right">
            Musicons, Musicpreneurs and Musiconics
          </Heading1>
          <TextfieldLG className="text-center txt-col-204" data-aos="fade-left">
            DropWorks believes in music contributors (Musicons) and music lovers
            in actions (Musicpreneurs) are the driving force for music economy
            (Musiconics) of the future to achieve collaboration, visibility,
            monetization and value creation
          </TextfieldLG>
          <HomeTabs
            id="17"
            className="mx-auto home-tabs uni-mb-96 home-page-mobilespace-bottom"
            tabsTitle={["DropWorks Select", "DropWorks Composite"]}
            checkTabState={checkTabState}
            home={true}
          />
          <div
            className={`home-tabs-content fade ${
              tabsValue === showComponent ? "fade-in" : "fade-out"
            }`}
          >
            <div
              className={
                tabsValue !== 0 ? "d-none" : "d-flex home-tab-mobile-view"
              }
            >
              <div>
                <div
                  className="home-tabs-content-box"
                  data-aos="fade-left"
                  style={{backgroundImage:`url("${process.env.REACT_APP_IMGURL}/${homeImagesList.DropWorksSelectGallery?.[0]?.thumbnail || homeImagesList.DropWorksSelectGallery?.[0]?.src}")`}}
                ></div>
              </div>
              <div
                className="uni-p-72 home-page-mobile-pd"
                data-aos="fade-right"
              >
                <Heading4 className="uni-mb-56 home-heading4 home-font-weight">
                  For Music preneurs
                </Heading4>
                <TextfieldMD className="uni-mb-56 home-textMd">
                  DropWorks Select and DropWorks Composite empower music fans to
                  create wealth through their passions.
                </TextfieldMD>
                <p className="d-flex justify-content-start learn-trade-mobileview">
                  <DropworksNormalButton
                    className="w-auto f-18 home-mobileview-btn"
                    onClick={() => navigateTo("/marketplace-dropworks-select")}
                  >
                    Learn More!
                  </DropworksNormalButton>
                  {/* <DropworksNormalButton
                    className="w-auto f-18  home-tabs-content-btn-bg uni-ml-56  home-mobileview-btn trade-btn"
                    onClick={() => {
                      navigateTo("/dropworks-select");
                    }}
                  >
                    Participate now
                  </DropworksNormalButton> */}
                </p>
              </div>
            </div>
            <div
              className={
                tabsValue !== 1 ? "d-none" : "d-flex home-tab-mobile-view"
              }
            >
              <div>
                <div
                  className="home-tabs-content-box"
                  data-aos="fade-left"
                  style={{backgroundImage:`url("${process.env.REACT_APP_IMGURL}/${(homeImagesList.DropWorksCompositeGallery?.[0]?.thumbnail || homeImagesList.DropWorksCompositeGallery?.[0]?.src)}")`}}
                ></div>
              </div>
              <div className="uni-p-72 home-second-tab" data-aos="fade-right">
                <Heading4 className="uni-mb-56 home-heading4">
                 Exclusive Music From DropWorks' Community
                </Heading4>
                <TextfieldMD className="uni-mb-56 home-textMd">
                  DropWorks Composite offers a curated selection of songs from
                  established artists, now available on DropWorks. own and enjoy
                  music that’s already making waves.
                </TextfieldMD>
                <p className=" learn-trade-mobileview d-flex justify-content-start ">
                  <DropworksNormalButton
                    className="w-auto f-18  home-mobileview-btn"
                    onClick={() =>
                      navigateTo("/marketplace-dropworks-composite")
                    }
                  >
                    Learn More!
                  </DropworksNormalButton>
                  {/* <DropworksNormalButton
                    className="w-auto  home-tabs-content-btn-b f-18 gradient-btn-2 uni-ml-56  home-mobileview-btn trade-btn"
                    onClick={() => {
                      navigateTo("/dropworks-composite");
                    }}
                  >
                    Participate now
                  </DropworksNormalButton> */}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="uni-mt-120 home-page-mobilespace-top">
          <Heading1 className="text-center uni-mb-40" data-aos="fade-left">
            Join DropWorks for The Pitch of Tomorrow
          </Heading1>
        
          {/* <TextfieldLG className=" text-center home-new-text">Never be bored.</TextfieldLG>
          <TextfieldLG className=" text-center home-new-text">Explore our wide range of unique features</TextfieldLG> */}
         
        </div>
        <div className="home-tabs-content home-tab-mobile-view home-box-section-spacing uni-mb-120 uni-mt-120">
          <div
            className="home-tabs-content-box2 uni-mr-8"
            data-aos="fade-left"
            style={{backgroundImage:`url("${process.env.REACT_APP_IMGURL}/${homeImagesList.MusicGallery?.[0]?.src}")`}}
          ></div>
          <div className="w-670 d-flex align-items-center justify-content-center uni-ml-56 content-boxes">
            <div
              className="max-w-403 home-page-max-width"
              data-aos="fade-right"
            >
              <Heading3 className="home-tabs-content-box2-heading uni-mb-24">
                For Music Creators – New Music Track Creations
              </Heading3>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile" />{" "}
                Access to all major streaming platforms
              </TextfieldSM>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile" />{" "}
                Automate ownership structure and royalties
              </TextfieldSM>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile" />{" "}
                Access to DropWorks Composites
              </TextfieldSM>
              <DropworksSmallButton
                className="w-auto  uni-mt-32 home-mobileview-btn home-btn-mobileui-mt"
                onClick={() => navigateTo("/create")}
              >
                Create a Track
              </DropworksSmallButton>
            </div>
          </div>
        </div>

        <div className="home-tabs-content home-tab-mobile-view reverse-col uni-mb-120">
          <div
            className="w-670 d-flex align-items-center justify-content-center uni-mr-56 content-boxes"
            data-aos="fade-left"
     

          >
            <div className="max-w-403 home-page-max-width">
              <Heading3 className="home-tabs-content-box2-heading uni-mb-24">
                For Artists – Release Your Track for Visibility and
                Monetization.
              </Heading3>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile" />
                Keep your track in your profile
              </TextfieldSM>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile" />
                Access to all major streaming platforms
              </TextfieldSM>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile" />
                Maintain your share of royalties
              </TextfieldSM>
              <TextfieldSM className="uni-fw-400 f-col-theme5 home-mobile-sm">
                <HomeCheckIcon className="uni-mr-8 homecheckIcon-mobile" />
                Access to DropWorks Select
              </TextfieldSM>
              <DropworksSmallButton
                className="w-auto  uni-mt-32 home-mobileview-btn home-btn-mobileui-mt"
                onClick={() => navigateTo("/create")}
              >
                Drop a Released Track
              </DropworksSmallButton>
            </div>
          </div>
          <div
            className="home-tabs-content-box2 uni-ml-8 home-box-right"
            data-aos="fade-right"
            style={{backgroundImage:`url("${process.env.REACT_APP_IMGURL}/${homeImagesList.ArtistGallery?.[0]?.src}")`}}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default view;
