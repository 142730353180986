import { TextfieldLG } from "../../../../../common/custom-field/dropstock-text";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import "./styles.scss";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { FC } from "react";
import { WalletDepositType } from "./modal";
import { DropworksRadioCheckbox } from "../../../../../common/custom-field/dropworks-radio-checkbox";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";

const view: FC<WalletDepositType> = ({ setDepositType, depositTypeName }) => {
  const deposit = useSelector(
    (state: RootState) => state.wallet.walletData.deposit
  );
  return (
    <ModalWrapper
      modalId="WalletDepositTypeModal"
      onBackdropClick={() => setDepositType("USDC")}
    >
      <div
        className="modal fade"
        id="WalletDepositTypeModal"
        aria-hidden="true"
        aria-labelledby="WalletDepositTypeModal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-sm-popup">
          <div className="modal-content custom-mobile-email-width">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header uni-mb-16">
              <Heading3 className="modal-title ">Deposit</Heading3>
            </div>

            <div className="modal-body uni-mb-56">
              <TextfieldLG className=" modal-second-head-font ctm-txt mt-0 uni-mb-56">
                Select what you deposit
              </TextfieldLG>
              <div className="d-flex investor-box-parent-deposit">
                <div
                  className="investor-boxs-deposit"
                  onClick={() => setDepositType("USDC")}
                >
                  <p className="investor-boxs-under-modal2">USDC</p>
                  <p className="investor-boxs-radiobutton">
                    <DropworksRadioCheckbox
                      checked={depositTypeName === "USDC"}
                    />
                  </p>
                </div>
                <div
                  className="investor-boxs-deposit"
                  onClick={() => setDepositType("DropStocks")}
                >
                  <p className="investor-boxs-under-modal2">DropStocks</p>
                  <p className="investor-boxs-radiobutton">
                    <DropworksRadioCheckbox
                      checked={depositTypeName === "DropStocks"}
                    />
                  </p>
                </div>
              </div>
            </div>

            <div className="modal-footer mbl-modal-footer  ps-0 pt-0 pb-0">
              {deposit === "USDC" ? (
                <ButtonComponent id="WalletUsdcDepositModal"  className="mbl-anchor-full-btn">
                  <DropworksSmallButton className="btn-wid m-0 my-0 mbl-full-width-btn">
                    Next
                  </DropworksSmallButton>
                </ButtonComponent>
              ) : (
                <ButtonComponent id="WalletDropstocksDepositModal"  className="mbl-anchor-full-btn">
                  <DropworksSmallButton className="btn-wid m-0 my-0 mbl-full-width-btn">
                    Next
                  </DropworksSmallButton>
                </ButtonComponent>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default view;
