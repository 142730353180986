import { useRef, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { view } from "./view";
import { verifyPin } from "../../../utils/apiServices";
import { useNavigate } from "react-router-dom";
import { openPopup } from "../../logo-header/view";
import { setValidateValue } from "../../../../../redux/reducer/auth";
import { useDispatch, useSelector } from "react-redux";
import { isAddressValidate } from "../../../../../redux/reducer/walletSlice";
import { AppDispatch } from "../../../../../redux/store";

export const WalletUsdcDeposit = () => {
  const closeModalRef = useRef<HTMLAnchorElement>(null);
  const openModal = () => {

  }

  return view({  closeModalRef, openModal });
}
export default WalletUsdcDeposit;