import React from "react";
import "./styles.scss";
interface CustomSpanProps extends React.HTMLAttributes<HTMLSpanElement> {
  headingtype: number;
}
const view: React.FC<CustomSpanProps> = ({  headingtype,className,...props }) => {
  if (headingtype === 0) {
    return <h1 {...props} className={`${className} heading0`}>{props.children}</h1>;
  }

  if (headingtype === 1) {
    return <h1 {...props} className={`${className} heading1`}>{props.children}</h1>;
  }

  if (headingtype === 2) {
    return <h2 {...props} className={`${className} heading2`}>{props.children}</h2>;
  }

  if (headingtype === 3) {
    return <h3 {...props} className={`${className} heading3`}>{props.children}</h3>;
  }
  if (headingtype === 4) {
    return <h4 {...props} className={`${className} heading4`}>{props.children}</h4>;
  }
  if (headingtype === 5) {
    return <h5 {...props} className={`${className} heading5`}>{props.children}</h5>;
  }
  if (headingtype === 6) {
    return <h6 {...props} className={`${className} heading6`}>{props.children}</h6>;
  }
  else{
    return<>Select appropriate Heading</>
  }
};

export default view;
