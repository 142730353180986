import "./styles.scss";
import React, { FC, useEffect, useState, useRef } from "react";
import Plyr from "plyr"; // Ensure you have installed plyr library
import "plyr/dist/plyr.css"; // Import Plyr's CSS
import {
  AudioPauseBtn,
  LeftNextBtn,
  MuteIcon,
  PlayerPauseBtn,
  RightNextBtn,
  VolumeUpIcon,
} from "../../../../common/all-icons";
import { MeatballsMenuDropdown } from "../../../../common/icons";
import trackImage from "../../../../assets/img/temporary-images/track-image.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNextBeats,
  fetchNextTracks,
  setData,
  setDeleteModalData,
  setMusicPageDataLike,
  setMusicPlayerData,
  setMusicPlayerDataLike,
} from "../../../../redux/reducer/musicSlice";
import { HeartIcon } from "../../../../common/heart-icon";
import { TextfieldMD, TextfieldXSM } from "../../../../common/custom-field/dropstock-text";
import crossIcon from "../../../../assets/steps/modal-cross-icon.svg";
import { RootState } from "../../../../redux/store";
import { openPopup } from "../logo-header/view";
import {
  setbeatModalId,
  setbeatPage,
  setTrackModalId,
} from "../../../../redux/reducer/reportSlice";
import { setTrackDetailsPage } from "../../../../redux/reducer/beatSlice";
import { updateFevourite } from "../../utils/apiServices";

import { useLocation, useNavigate } from "react-router-dom";

import { AppDispatch } from "../../../../redux/store";
import { filteredArtist } from "../../utils/localStorage";
// Define Track type
interface Track {
  track: number;
  name: string;
  duration: string;
  file: string;
  img: any;
}

// Track list
const tracks: Track[] = [
  {
    track: 1,
    name: "King Roland, Mabapupi Ocachino,Kahino, Lala Baby",
    duration: "3:20",
    file: "/new.mp3",
    img: trackImage,
  },
];

export const view: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { musicPlayer, musicPageData, nextTracks, nextBeats } = useSelector(
    (state: RootState) => state.music
  );
  const [playing, setPlaying] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [npAction, setNpAction] = useState("Paused...");
  const audioRef = useRef<HTMLAudioElement>(null);
  const plyrRef = useRef<Plyr | null>(null);
  const [volume, setVolume] = useState<any>("75");
  const [mute, setMute] = useState(false);
  const [currentTime, setCurrentTime] = useState(0); // Track current time
  const userData = useSelector((state: any) => state.user);
  const [duration, setDuration] = useState(0); // Track total duration
  const dispatch = useDispatch<AppDispatch>();
  const [previousVolume, setPreviousVolume] = React.useState(volume);

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const newVolume: any = Number(e.target.value); // Convert to number
    setVolume(newVolume);
    setMute(newVolume === 0); // If volume is 0, mute is true
    if (audioRef.current) {
      audioRef.current.volume = newVolume / 100; // Update audio volume (normalize to 0-1 scale)
    }
  };

  const handleMute = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (mute) {
      // Unmute: set volume to previous value (75 in this case)
      setVolume(previousVolume);
      if (audioRef.current) {
        audioRef.current.volume = previousVolume / 100;
      }
    } else {
      setPreviousVolume(volume)
      // Mute: set volume to 0
      setVolume(0);
      if (audioRef.current) {
        audioRef.current.volume = 0;
      }
    }
    setMute(!mute); // Toggle mute state
  };

  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(fetchNextBeats());
    dispatch(fetchNextTracks());
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      plyrRef.current = new Plyr(audioRef.current, {
        controls: [
          "restart",
          "play",
          "progress",
          "current-time",
          "duration",
          "mute",
          "volume",
          "download",
        ],
      });

      const audioElement = audioRef.current;

      audioElement.addEventListener("play", () => {
        setPlaying(true);
        setNpAction("Now Playing...");
      });

      audioElement.addEventListener("pause", () => {
        setPlaying(false);
        setNpAction("Paused...");
      });

      audioElement.addEventListener("ended", () => {
        setNpAction("Paused...");
        if (currentTrackIndex + 1 < tracks.length) {
          setCurrentTrackIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            loadTrack(newIndex);
            audioElement.play();
            return newIndex;
          });
        } else {
          setCurrentTrackIndex(0);
          loadTrack(0);
          audioElement.pause();
        }
      });

      audioElement.addEventListener("timeupdate", () => {
        if (audioRef.current) {
          setCurrentTime(audioRef.current.currentTime);
          setDuration(audioRef.current.duration);
        }
      });
    }
  }, [currentTrackIndex]);

  // const [primaryArtist, setPrimaryArtist] = useState<any>([]);
  // const [featuredArtist, setFeaturedArtist] = useState<any>([]);
  // const [hiddenArtist, setHiddenArtist] = useState<any>([]);
  // const [nonCredits, setNonCredits] = useState<any>([]);

  // useEffect(() => {
  //   if (musicPlayer?.musicType == "track") {
  //     if (Array.isArray(musicPlayer.musicCreator)) {
  //       const filteredPrimaryArtists = musicPlayer.musicCreator
  //         .slice(1)
  //         .filter((data: any) => data.credit_to === "Primary Artist")
  //         .sort((a: any, b: any) => {
  //           if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1;
  //           if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1;

  //           return (
  //             new Date(a.updated_at).getTime() -
  //             new Date(b.updated_at).getTime()
  //           );
  //         });
  //       setPrimaryArtist(filteredPrimaryArtists);

  //       const filteredFeaturedArtist = musicPlayer.musicCreator
  //         .slice(1)
  //         .filter((data: any) => data.credit_to === "Featured Artist")
  //         .sort((a: any, b: any) => {
  //           if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1;
  //           if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1;
  //           return (
  //             new Date(a.updated_at).getTime() -
  //             new Date(b.updated_at).getTime()
  //           );
  //         });
  //       setFeaturedArtist(filteredFeaturedArtist);

  //       const filteredHiddenArtist = musicPlayer.musicCreator
  //         .slice(1)
  //         .filter((data: any) => data.credit_to === "Hidden")
  //         .sort((a: any, b: any) => {
  //           if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1;
  //           if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1;
  //           return (
  //             new Date(a.updated_at).getTime() -
  //             new Date(b.updated_at).getTime()
  //           );
  //         });
  //       setHiddenArtist(filteredHiddenArtist);
  //       const filteredNonCreditArtist = musicPlayer.musicCreator
  //         .slice(1)
  //         .filter(
  //           (data: any) =>
  //             data.credit_to == null && data.role.toLowerCase() != "curator"
  //         )
  //         .sort((a: any, b: any) => {
  //           if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1;
  //           if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1;
  //           return (
  //             new Date(a.updated_at).getTime() -
  //             new Date(b.updated_at).getTime()
  //           );
  //         });
  //       setNonCredits(filteredNonCreditArtist);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    loadTrack(currentTrackIndex);
  }, [musicPlayer?.musicAudioSrc]);

  const loadTrack = (index: number) => {
    if (audioRef.current) {
      const audioElement = audioRef.current;
      const track = tracks[index];
      audioElement.src = `${process.env.REACT_APP_IMGURL}/${musicPlayer.musicAudioSrc}`;
      setNpAction("Now Playing...");

      audioElement.addEventListener(
        "loadeddata",
        () => {
          setDuration(audioElement.duration || 0);
          if (playing) audioElement.play();
        },
        { once: true }
      );
      const handleLoadedData = () => {
        setDuration(audioElement.duration || 0);
        audioElement.play().catch((error) => {
          console.error("Autoplay failed:", error);
        });
      };

      audioElement.addEventListener("loadeddata", handleLoadedData, {
        once: true,
      });
    }
  };

  const handleLikeUnlike = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(setMusicPlayerDataLike(!musicPlayer.musicLiked));
    if (musicPageData.musicPageId === musicPlayer.musicId) {
      dispatch(setMusicPageDataLike(!musicPageData.musicPageLiked));
    }
    const obj =
      musicPlayer.musicType === "track"
        ? { track_id: musicPlayer.musicId }
        : { beat_id: musicPlayer.musicId }; // or { track_id: 456 } or { project_id: 789 }
    const userID = user.id;
    try {
      const response = await updateFevourite(obj, userID);
    } catch {}
  };

  const handleProgressBarClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (duration && !isNaN(duration)) {
      // Check if duration is valid
      const progressBar = e.currentTarget;
      const clickPosition =
        e.clientX - progressBar.getBoundingClientRect().left;
      const newTime = (clickPosition / progressBar.clientWidth) * duration;

      if (!isNaN(newTime) && audioRef.current) {
        // Ensure newTime is a valid number
        audioRef.current.currentTime = newTime;
        setCurrentTime(newTime);
      }
    }
  };

  const [nextIndexTrack, setNextIndexTrack] = useState(0);
  const [nextIndexBeat, setNextIndexBeat] = useState(0);

  // const handlePrev = (e: React.MouseEvent<HTMLDivElement>) => {
  //   e.stopPropagation();

  //   if (musicPlayer.musicType == "track") {
  //     if (currentTime < 3 && nextIndexTrack != 0) {
  //       let newIndex = nextIndexTrack - 1;

  //       dispatch(setMusicPlayerData(nextTracks[newIndex]));
  //       setNextIndexTrack(newIndex);
  //       setNextIndexBeat(0);
  //     } else {
  //       loadTrack(currentTrackIndex);
  //     }
  //   } else if (musicPlayer.musicType == "beat") {
  //     if (currentTime < 3 && nextIndexBeat != 0) {
  //       let newIndex = nextIndexBeat - 1;
  //       dispatch(setMusicPlayerData(nextBeats[newIndex]));
  //       setNextIndexTrack(0);
  //       setNextIndexBeat(newIndex);
  //     } else {
  //       loadTrack(currentTrackIndex);
  //     }
  //   }
  // };

  // const handleNext = (e: React.MouseEvent<HTMLDivElement>) => {
  //   e.stopPropagation();
  //   if (musicPlayer.musicType == "track") {
  //     if (nextTracks.length - 1 == nextIndexTrack) {
  //       loadTrack(currentTrackIndex);
  //     } else {
  //       let newIndex = nextIndexTrack + 1;
  //       dispatch(setMusicPlayerData(nextTracks[newIndex]));
  //       setNextIndexBeat(0);
  //       setNextIndexTrack(newIndex);
  //     }
  //   } else if (musicPlayer.musicType == "beat") {
  //     if (nextBeats.length - 1 == nextIndexBeat) {
  //       loadTrack(currentTrackIndex);
  //     } else {
  //       let newIndex = nextIndexBeat + 1;
  //       dispatch(setMusicPlayerData(nextBeats[newIndex]));
  //       setNextIndexTrack(0);
  //       setNextIndexBeat(newIndex);
  //     }
  //   }
  // };
 
  /*Music previous song*/
  const handlePrev = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  
    if (musicPlayer.musicType == "track") {
     
      let newIndex = (nextIndexTrack - 1 + nextTracks.length) % nextTracks.length;
  
      dispatch(setMusicPlayerData(nextTracks[newIndex]));
      setNextIndexTrack(newIndex);
      setNextIndexBeat(0);
    } else if (musicPlayer.musicType == "beat") {
     
      let newIndex = (nextIndexBeat - 1 + nextBeats.length) % nextBeats.length;
  
      dispatch(setMusicPlayerData(nextBeats[newIndex]));
      setNextIndexTrack(0);
      setNextIndexBeat(newIndex);
    }
  };
  /*Music Next song*/
  const handleNext = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  
    if (musicPlayer.musicType == "track") {
     
      let newIndex = (nextIndexTrack + 1) % nextTracks.length;
  
      dispatch(setMusicPlayerData(nextTracks[newIndex]));
      setNextIndexBeat(0);
      setNextIndexTrack(newIndex);
    } else if (musicPlayer.musicType == "beat") {
      
      let newIndex = (nextIndexBeat + 1) % nextBeats.length;
  
      dispatch(setMusicPlayerData(nextBeats[newIndex]));
      setNextIndexTrack(0);
      setNextIndexBeat(newIndex);
    }
  };
  
  const OnClickMeatBalls = async (value: any) => {
    if (value === "Delete") {
      dispatch(
        setDeleteModalData({
          deleteID: musicPlayer.musicId,
          deleteName: musicPlayer.musicName,
          deleteImage: musicPlayer.musicImgSrc,
          deleteSlugName: musicPlayer.musicDescription,
          deleteType: musicPlayer.musicType,
        })
      );
      openPopup("DeleteModal", dispatch);
    } else if (value === "Report") {
      if (musicPlayer.musicType === "beat") {
        musicPlayer.musicCreator?.[0]?.receiver_user_id &&   dispatch(setbeatModalId(musicPlayer.musicCreator?.[0]?.receiver_user_id))
        dispatch(setTrackModalId(musicPlayer.musicId));
        dispatch(setbeatPage(true));
        openPopup("BeatReportModal", dispatch);
      } else if (musicPlayer.musicType === "track") {
        dispatch(setTrackModalId(musicPlayer.musicId));
        openPopup("TrackReportModal", dispatch);
        dispatch(setbeatPage(true));
      }
    } else if (value === "Details" && musicPlayer.musicPageTrackData) {
      dispatch(
        setTrackDetailsPage({
          trackDetailsReleaseDate:
            musicPlayer.musicPageTrackData?.trackDetailsReleaseDate,
          trackDeatilsCreatorList:
            musicPlayer.musicPageTrackData?.trackDeatilsCreatorList,
          trackDetailsUniqueId:
            musicPlayer.musicPageTrackData?.trackDetailsUniqueId,
        })
      );

      openPopup("TrackDetailsPopUp", dispatch);
    }
  };

  const formatTime = (time: number) => {
    if (!time || isNaN(time)) return "0:00"; // Return "--:--" if time is 0 or not a valid number
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  useEffect(() => {
    loadTrack(0); // Load the first track on component mount
  }, []);

  const navigateToTrackBeatpage = () => {
    if (
      location.pathname === musicPlayer.musicPath ||
      location.pathname === `/${musicPlayer.musicPath}`
    ) {
    } else {
      // navigate("/");
      navigate(`${musicPlayer.musicPath}`);
    }
  };

  return (
    <div
      className="palyer container-fluid "
      // onClick={navigateToTrackBeatpage}
    >
      <div className="d-none">
        <audio ref={audioRef} />
      </div>
      <div className="palyer-nowPlay col-lg-4 col-8">
        <div className="d-flex align-items-center gap-4 audio-player-spacing">
          <div>
            <img
              src={`${process.env.REACT_APP_IMGURL}/${musicPlayer?.musicImgSrc}`}
              className="palyer-song-image cursor-pointer"
              alt="user-image"
              onClick={navigateToTrackBeatpage}
            />
          </div>
          <div className="player-content">
            <div className="w-100 link-btm">
              <div className="player-details">
                <h6 className="player-name cursor-pointer"onClick={navigateToTrackBeatpage}>{musicPlayer?.musicName}<HeartIcon
              isAudioBar={true}
              liked={musicPlayer?.musicLiked}
              onClickHeart={handleLikeUnlike}
              className="custom-audio-heart-icon uni-ml-8 d-none"
            /></h6>
              </div>
              <div className="player-song-title cursor-pointer" onClick={navigateToTrackBeatpage}>
                {/* {musicPlayer.musicCreator} */}
                {musicPlayer?.musicType == "beat" ? (
              <span
              className="underline-opening"
              onClick={(e) => {e.stopPropagation();
                userData.id ==
                musicPlayer?.musicCreator?.[0]?.receiver_user_id
                  ? navigate(`/profile`)
                  : navigate(
                      `/user-profile/${musicPlayer?.musicCreator?.[0]?.slug_name}`
                    )
              }}
            >
              {musicPlayer?.musicCreator?.[0]?.receiver_name}
            </span>
                ) : (
                  <>
                    {/* <span
                      className="underline-opening"
                      onClick={() =>
                        userData.id ==
                        musicPlayer?.musicCreator?.[0]?.receiver_user_id
                          ? navigate(`/profile`)
                          : navigate(
                              `/user-profile/${musicPlayer?.musicCreator?.[0]?.slug_name}`
                            )
                      }
                    >
                      {musicPlayer?.musicCreator?.[0]?.receiver_name}{(primaryArtist.length > 0 ||
                        featuredArtist.length > 0 ||
                        nonCredits.length > 0 ||
                        hiddenArtist.length > 0) &&
                        ", "}
                    </span>
               
                 
                    {primaryArtist &&
                      primaryArtist.map((data: any, index: number) => (
                        <React.Fragment key={data.slugName}>
                          <TextfieldMD
                            className="underline-opening mb-0 mt-0 m-0"
                            onClick={() =>
                              userData.id == data?.receiver_user_id
                                ? navigate(`/profile`)
                                : navigate(`/user-profile/${data?.slug_name}`)
                            }
                          >
                            {data.receiver_name}
                          </TextfieldMD>
                          {index < primaryArtist.length - 1 && ", "}
                        </React.Fragment>
                      ))}

                    
                    {featuredArtist &&
                      featuredArtist.map((data: any, index: number) => (
                        <React.Fragment key={data.slugName}>
                          <TextfieldMD
                            className="underline-opening mb-0 mt-0 m-0"
                            onClick={() =>
                              userData.id == data?.receiver_user_id
                                ? navigate(`/profile`)
                                : navigate(`/user-profile/${data?.slug_name}`)
                            }
                          >
                            {data.receiver_name}
                          </TextfieldMD>
                          {index < featuredArtist.length - 1 && ", "}
                        </React.Fragment>
                      ))}

                   
                    {hiddenArtist &&
                      hiddenArtist.map((data: any, index: number) => (
                        <React.Fragment key={data.slugName}>
                          <TextfieldMD
                            className="underline-opening mb-0 mt-0 m-0"
                            onClick={() =>
                              userData.id == data?.receiver_user_id
                                ? navigate(`/profile`)
                                : navigate(`/user-profile/${data?.slug_name}`)
                            }
                          >
                            {data.receiver_name}
                          </TextfieldMD>
                          {index < hiddenArtist.length - 1 && ", "}
                        </React.Fragment>
                      ))}

                 
                    {nonCredits &&
                      nonCredits.map((data: any, index: number) => (
                        <React.Fragment key={data.slugName}>
                          <TextfieldMD
                            className="underline-opening mb-0 mt-0 m-0"
                            onClick={() =>
                              userData.id == data?.receiver_user_id
                                ? navigate(`/profile`)
                                : navigate(`/user-profile/${data?.slug_name}`)
                            }
                          >
                            {data.receiver_name}
                          </TextfieldMD>
                          {index < nonCredits.length - 1 && ", "}
                        </React.Fragment>
                      ))} */}

{filteredArtist(musicPlayer?.musicCreator)?.map((data: any, index: number, arr: any) => (
                        <React.Fragment key={data.slugName}>
                          <TextfieldMD
                            className="underline-opening mb-0 mt-0 m-0"
                            onClick={() =>
                              userData.id == data?.receiver_user_id
                                ? navigate(`/profile`)
                                : navigate(`/user-profile/${data?.slug_name}`)
                            }
                          >
                            {data?.receiver_name}
                          </TextfieldMD>
                          {index < arr?.length - 1 && ", "}
                        </React.Fragment>
                      ))}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex gap-2  heart-image">
            <HeartIcon
              isAudioBar={true}
              liked={musicPlayer?.musicLiked}
              onClickHeart={handleLikeUnlike}
              className="custom-audio-heart-icon"
            />
            {musicPlayer?.showOptions && (
              <MeatballsMenuDropdown
                arr={
                  musicPlayer.musicType === "beat" &&
                  musicPlayer?.musicOwnedByMe
                    ? ["Delete"]
                    : musicPlayer.musicType === "beat"
                    ? ["Report"]
                    : musicPlayer.musicType === "track" &&
                      musicPlayer.musicOwnedByMe
                    ? ["Details"]
                    : musicPlayer.musicType === "track"
                    ? ["Report", "Details"]
                    : []
                }
                pagename="call-funtion"
                onClickOptions={(value: any) => OnClickMeatBalls(value)}
                height={"6px"}
                className="meatballsplayer p-0"
              ></MeatballsMenuDropdown>
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-4">
        <div className="d-flex justify-content-center align-items-center gap-2 all-music-btn">
          <div className="cursor-pointer" onClick={handlePrev}>
            <LeftNextBtn />
          </div>

          <div
            className="cursor-pointer play-pause"
            onClick={(e) => {
              e.stopPropagation();
              if (playing) {
                audioRef.current?.pause();
              } else {
                audioRef.current?.play();
              }
            }}
          >
            {playing ? <AudioPauseBtn /> : <PlayerPauseBtn />}
          </div>
          <div className="cursor-pointer" onClick={handleNext}>
            <RightNextBtn />
          </div>
        </div>
        <div className="player-controls gap-3">
          <TextfieldXSM className="m-0 play-time">
            {formatTime(currentTime)}
          </TextfieldXSM>

          <div
            className="progress cursor-pointer music-bar"
            style={{ height: "4px", width: "100%" }}
            onClick={handleProgressBarClick}
          >
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${(currentTime / duration) * 100}%` }}
            ></div>
          </div>

          <TextfieldXSM className="m-0 play-time">
            {formatTime(duration)}
            {/* {tracks[currentTrackIndex].duration} */}
          </TextfieldXSM>
        </div>
      </div>

      <div className="col-lg-4 col-4 d-flex justify-content-end h-100 cross-icon">
        <div className="d-flex align-items-center gap-3">
          <div className="cursor-pointer sound-image">
            {mute && !(volume === "0") ? (
              <div onClick={handleMute}>
                <MuteIcon />
              </div>
            ) : (
              <div onClick={handleMute}>
                <VolumeUpIcon />
              </div>
            )}
          </div>
          <input
            type="range"
            color="red"
            min="0"
            max="100"
            onClick={(e) => e.stopPropagation()}
            value={mute ? 0 : volume}
            onChange={handleVolumeChange}
            className="cursor-pointer uni-mr-32"
            style={{ height: "3px", width: "81px", marginTop: "4px" }}
          />
          <div
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setData(false));
            }}
          >
            <img src={crossIcon} className="cursor-pointer cross-icon-audio" />
          </div>
        </div>
      </div>
    </div>
  );
};
