import { FC } from "react";
import { TextfieldLG } from "../../../../../common/custom-field/dropstock-text";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { DropworksRadioCheckbox } from "../../../../../common/custom-field/dropworks-radio-checkbox";
import { WalletWithdrawType } from "./modal";
import "./styles.scss";
import "../popup.scss";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";

const view: FC<WalletWithdrawType> = ({ setWalleType, walleTypeName }) => {
  return (
    <>
    <ModalWrapper modalId="WalletWithdrawTypeModal" >
      <div
        className="modal fade"
        id="WalletWithdrawTypeModal"
        aria-hidden="true"
        aria-labelledby="WalletWithdrawTypeModal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-sm-popup">
          <div className="modal-content custom-mobile-email-width ">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header ps-0 pt-0">
              <Heading3 className="modal-title">Withdraw</Heading3>
            </div>

            <div className="modal-body uni-mb-40 ps-0 pt-0 pb-0 ">
              <TextfieldLG className=" modal-second-head-font uni-mt-16 uni-mb-56 ctm-txt">
                Select what you withdraw
              </TextfieldLG>
              <div className="d-flex investor-box-parent-deposit   uni-mt-8 ">
                <div
                  className="investor-boxs-deposit"
                  onClick={() => setWalleType("USDC")}
                >
                  <p className="investor-boxs-under-modal2">USDC</p>
                  <p className="investor-boxs-radiobutton">
                    <DropworksRadioCheckbox
                      checked={walleTypeName === "USDC"}
                    />
                  </p>
                </div>
                <div
                  className="investor-boxs-deposit"
                  onClick={() => setWalleType("DropStocks")}
                >
                  <p className="investor-boxs-under-modal2">DropStocks</p>
                  <p className="investor-boxs-radiobutton">
                    <DropworksRadioCheckbox
                      checked={walleTypeName === "DropStocks"}
                    />
                  </p>
                </div>
              </div>
            </div>

            <div className="modal-footer mbl-modal-footer ps-0 pt-0 pb-0 ">
                <ButtonComponent id="WalletPinModal" className="mbl-anchor-full-btn">
                  <DropworksSmallButton className="btn-wid m-0 my-0 mbl-full-width-btn">
                    Next
                  </DropworksSmallButton>
                </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
      </ModalWrapper>
    </>
  );
};

export default view;
