import { useNavigate } from "react-router-dom"
import { view } from "./view"
import { useEffect, useState } from "react";
import { getAllSearch } from "../../shared/utils/apiServices";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { getformatDate, mixMasterAudio } from "../../shared/utils/localStorage";

export const Search = () => {
  const [allUserData, setAllUserData] = useState<string[]>([])
  const [allBeatsData, setAllBeatsData] = useState<string[]>([])
  const [allTrackData, setAllTrackData] = useState<string[]>([])
  const [allProjectData, setAllProjectData] = useState<string[]>([])
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedRoles1, setSelectedRoles1] = useState<string[]>([]); 
   const [loading ,setLoading] =useState(true);
const user =useSelector((state:RootState)=>state.user)
  const [searchResult, setSearchResult] = useState<any>("");
  const {musicPlayer} =useSelector((state:RootState)=>state.music)


  const [userarr, setUserarr] = useState([
    { heading: "", subheading: "", image: "", slugName: "" },

  ]);
  const [filteredUsers, setFilteredUsers] = useState([
    { heading: "", subheading: "", image: "", slugName: "" },

  ]);

  const [beatsarr, setBeatsarr] = useState<any>([
    { heading: "", subheading: "", image: "", beastName: "", fullBeatData: "",id:"" },
  ]);

  const [projectarr, setProjectarr] = useState<any>([
    {
      heading: "", subheading: "", image: "", slugName: ""
    }
  ]);

  const navigate = useNavigate();

  const getAllSearchData = async (value: any) => {
    const result = await getAllSearch(value);
    if (result?.success) {
      setAllUserData(result?.allSearchData?.data?.userDatData)
      setAllBeatsData(result?.allSearchData?.data?.beatData)
      setAllTrackData(result?.allSearchData?.data?.trackData)
      setAllProjectData(result?.allSearchData?.data?.projectData)
      setLoading(false)
    }
    else{
      setLoading(false)
    }
  };

  useEffect(() => {
    getAllSearchData("")
  }, [])

  useEffect(() => {
    if (Array.isArray(allUserData)) {
      setUserarr(allUserData.map((item: any) => (
        {
          heading: item.display_name,
          subheading: item.username,
          image: item.profile_image[0]?.thumbnail || item.profile_image[0]?.src,
          slugName: item.username,
          id: item._id
        })))

    }
  }, [allUserData])

  useEffect(() => {
    if (Array.isArray(allBeatsData)) {
      setBeatsarr(allBeatsData.map((item: any) => (
        {
          heading: item.beat_name,
          subheading: item?.user_id?.display_name,
          image: item.art_work_image[0].thumbnail || item.art_work_image[0].src,
          artistSlug: item?.user_id?.slug_name,
          beatName: item.beat_slug_name,
          fullBeatData: [item],
          id: item._id
        }
      )))
    }
  }, [allBeatsData])

  useEffect(() => {
    if (Array.isArray(allProjectData)) {
      setProjectarr(allProjectData.map((item: any) => (
        {
          heading: item.project_name,
          subheading: item.description,
          image: (item.art_work_image[0]?.thumbnail || item.art_work_image[0]?.src) || item.default_artwork,
          slugName: item.slug_name,
          collaborators:item?.collaborators,
          id: item._id
        }
      )))
    }
  }, [allProjectData])


  const handleRoleChange = (role: string) => {
    setSelectedRoles((prev) => prev.includes(role) ? prev.filter((r) => r !== role) : [...prev, role]);
  };


  useEffect(() => {
    if (selectedRoles.length > 0) {
      const filteredUsers = allUserData.filter((user: any) =>
        user.role.some((role: any) => selectedRoles.includes(role))
      );
      setFilteredUsers(filteredUsers.map((item: any) => (
        {
          heading: item.display_name,
          subheading: item.username,
          image: item.profile_image[0]?.thumbnail || item.profile_image[0]?.src,
          slugName: item.username,
          id: item._id
        })))
    }
    else {
      setFilteredUsers(allUserData.map((item: any) => (
        {
          heading: item.display_name,
          subheading: item.username,
          image: item.profile_image[0]?.thumbnail || item.profile_image[0]?.src,
          slugName: item.username,
          id: item._id
        })))
    }
  }, [selectedRoles])

  const [trackarr, setTrackarr] = useState([
    {
      heading: "",
      subheading: "",
      slugName: "",
      image: "",
      id:""
    }
  ]);
  const [filteredTrackarr, setFilteredTrackarr] = useState([
    { heading: "", subheading: "", image: "", slugName: "" },

  ]);
 
  useEffect(() => {
    
    if (Array.isArray(allTrackData)) {
           setTrackarr(allTrackData.map((item: any) => (
        {
          heading: item.track_name,
          subheading: item.description,
          image: (item?.art_work_image[0]?.thumbnail || item?.art_work_image[0]?.src) || item.default_artwork,
          slugName: item.slug_name,
          id: item._id,
            createrName:item?.collaborators,
            audioUrl:mixMasterAudio(item.upload_file),
            audioLiked:Boolean(item?.favoriteData?._id),
            audioPath:`track-page/${item?.slug_name}`,
            audioName:item?.track_name,
            fullTrackData:[item],
            audioByMe:item?.user_id?._id === user.id,
            trackDetailsReleaseDate:getformatDate(item?.created_at),
            trackDeatilsCreatorList:[item?.creator_user_name],
            trackDetailsUniqueId:item?.unique_id
        }
      )))
    }
  }, [allTrackData])

  const getSearchValue = (value: string) => {
    setSearchResult(value)
    getAllSearchData(value)
  }

  const handleRoleChange1 = (role: string) => {
    setSelectedRoles1((prev) => prev.includes(role) ? prev.filter((r) => r !== role) : [...prev, role]);
  };

//   useEffect(()=>{
// console.log("in useeffect",Boolean(allTrackData.length) , musicPlayer.musicLiked, musicPlayer.musicType)
//     if(Boolean(allTrackData.length)  && musicPlayer.musicType ==="track")
//     {
//       console.log(allTrackData,"all track dataaaaaaaaaaaaaaaa")
//   const updatedData= allTrackData.map((ele:any)=>{
//         if(ele?._id!==musicPlayer.musicId)
//         {
//           return ele ;
//         }
//         else if(ele?._id===musicPlayer.musicId && musicPlayer.musicLiked)
//         {
//          return {...ele,favoriteData:{id:"addedfromtrack"}};
//         }
//         else  if(ele?._id===musicPlayer.musicId && !musicPlayer.musicLiked) {
//           return {...ele,favoriteData:""};
//         }
//       })
//       console.log(updatedData,"all updated track data")
//      setAllTrackData(updatedData)
//     }
//   },[musicPlayer.musicLiked,musicPlayer.musicId])

  useEffect(() => {
    if (selectedRoles1.length > 0) {
      const filteredTrack = allTrackData.filter((track: any) =>
        selectedRoles1.some((role: any) => track.genre.includes(role))
      );
      setFilteredTrackarr(filteredTrack.map((item: any) => ({
        heading: item.track_name,
        subheading: item.description,
        image: (item?.art_work_image[0]?.thumbnail || item?.art_work_image[0]?.src),
        slugName: item.slug_name,
        id: item._id
      })));
    }
    else {
      setFilteredTrackarr(allTrackData.map((item: any) => (
        {
          heading: item.track_name,
          subheading: item.description,
          image: (item?.art_work_image[0]?.thumbnail || item?.art_work_image[0]?.src),
          slugName: item.slug_name,
          id: item._id
        })))
    }
  }, [selectedRoles1])


  useEffect(()=>{
    if(musicPlayer.musicType ==="track" && Boolean(trackarr.length))
    {
      let newUpdatedData=trackarr.map((ele)=>{
        if(ele.id && ele?.id === musicPlayer.musicId)
        {
         let newData = musicPlayer.musicLiked?{_id:"abc"}:"";
          return {...ele,favoriteData:newData,audioLiked:musicPlayer.musicLiked}
        }
        else{
          return ele ;
        }
      })
     setTrackarr(newUpdatedData);    
    }
    else if(musicPlayer.musicType ==="beat" && Boolean(beatsarr.length)) {
      let newUpdatedData=beatsarr.map((ele:any)=>{
        if(ele.id && ele?.id === musicPlayer.musicId)
        {
          let newdata= [{...ele.fullBeatData?.[0],favoriteData:musicPlayer.musicLiked?{_id:"abcd"}:""}];
          return {...ele,fullBeatData:newdata}
        }
        else{
          return ele ;
        }
      })
     setBeatsarr(newUpdatedData);    
    }
      },[musicPlayer.musicLiked , musicPlayer.musicId])

  const navigateTo = (value: any) => () => {
    navigate(value)
  }

  return (view({
    navigateTo, allUserData, allBeatsData, allTrackData, allProjectData, userarr, filteredUsers, selectedRoles,
    beatsarr, projectarr, trackarr, handleRoleChange, getSearchValue, searchResult, selectedRoles1, handleRoleChange1,
    filteredTrackarr,loading
  }))
}