import React from 'react'
import "./styles.scss"
interface CustomDivProps extends React.HTMLAttributes<HTMLDivElement> {}
const view:React.FC<CustomDivProps>  = ({...props}) => {
  return (
         <hr {...props}>
        {props.children}
      </hr>
  )
}

export default view;