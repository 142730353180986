import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import { CloseModalButton, openPopup } from "../../logo-header/view";
import { ButtonComponent } from "../../logo-header/view";
import { useEffect, useState } from "react";
import "./styles.scss";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { updateEmail, updateEmailOtp } from "../../../utils/apiServices";
import Loader from "../../loader/view";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";

const view = () => {
  const [cooldown, setCooldown] = useState(false);
  const [timer, setTimer] = useState(0);
  const { startTimer } = useSelector((state: RootState) => state.modal);
  const [otp, setOtp] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (cooldown && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      if (timer === 1) {
        setCooldown(false);
      }

      return () => clearInterval(countdown);
    }
  }, [cooldown, timer]);

  const handleResendCode = async () => {
    if (!cooldown) {
      setCooldown(true);
      setTimer(10);
      if (!startTimer) {
        const response = await updateEmail({ type: "email" });
        if (response.success) {
        } else {
          setMessage(response.message);
          setTimeout(() => {
            setMessage("");
          }, 3000);
        }
      }
    }
  };
  const OpenPopup = async () => {
    setLoading(true);
    const response: any = await updateEmailOtp({ otp: otp });
    if (response.success) {
      setLoading(false);
      openPopup("EmailModal2", dispatch);
    } else {
      setLoading(false);
      setMessage(response.message);
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  };
  const openModal = () => {
    setOtp("");
    setLoading(false);
    setMessage("");
    setCooldown(false);
    setTimer(0);
  };
  return (
    <ModalWrapper modalId="EmailModal" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="EmailModal1"
        aria-hidden="true"
        aria-labelledby="EmailModal1"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content ">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header ps-0 pt-0">
              <Heading3 className="modal-title">Confirm Your Identity</Heading3>
            </div>
            <div className="modal-body ps-0 pt-0 pb-0">
              <TextfieldLG className="uni-mb-56 uni-mt-16 ctm-txt mbl-mt-8 mbl-mb-8">
                Confirm the code we’ve sent you
              </TextfieldLG>
              <DropworksInput
                className="mb-0 mt-0 mbl-mt-0"
                placeholder="Code"
                value={otp}
                maxLength={6}
                onInput={(e: any) => {
                  let check = e.target.value.replace(/[^0-9]/g, "");
                  setOtp(check);
                }}
              />
              <TextfieldXSM
                className={`ctm-txt2  mb-0 ${cooldown ? "disabled" : ""}`}
              >
                <button
                  onClick={handleResendCode}
                  className="resend-btn "
                  disabled={cooldown}
                >
                  {" "}
                  {cooldown ? `Resend in ${timer}s` : "Resend Code"}
                </button>
              </TextfieldXSM>
              {message && <p className="text-danger">{message}</p>}
            </div>
            <div className="modal-footer mbl-modal-footer uni-mt-56">
              {loading ? (
                <Loader />
              ) : (
                <>
                  <ButtonComponent
                    id="EmailModal"
                    className="buttoncomponent-mobile-modle mbl-anchor-full-btn"
                  >
                    <DropworksSmallButton className="btn-wid social-link-modal-btns btn-bg-gray3 my-0 ml-0 uni-mr-16">
                      Back
                    </DropworksSmallButton>
                  </ButtonComponent>
                  <DropworksSmallButton
                    className={
                      Boolean(otp)
                        ? "btn-wid  modal-btns m-0"
                        : "btn-wid  modal-btns m-0 custom-inactive-btn text-secondary social-link-modal-btns disable-interactions"
                    }
                    onClick={OpenPopup}
                  >
                    Next
                  </DropworksSmallButton>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default view;
