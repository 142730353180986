import { useEffect, useRef, useState } from "react";
import { view } from "./view";
import { downloadPinPdf, getPrivateAndPhrase, getUserData } from "../../../utils/apiServices";
import { openPopup } from "../../logo-header/view";
import { setUserData } from "../../../../../redux/reducer/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { todaysDate } from "../../../utils/localStorage";

export const DownloadPinOneTime = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isPDFDownloaded, setIsPDFDownloaded] = useState(false);
  const [phrase, setPhrase] = useState("");
  const [privateKey, setPrivateKey] = useState("");
  const closeModalRef = useRef<HTMLAnchorElement>(null);

  const handleCloseModalClick = () => {
    if (closeModalRef.current) {
      closeModalRef.current.click();
    }
  };

  const downloadBtnClick = async () => {
    setLoading(true);
    setIsFilled(true);
    try {
      const response: any = await downloadPinPdf();
      if (response) {
        // Convert the buffer to a Blob
        const blob = new Blob([response], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        const formattedDate = todaysDate();
        link.download = `DropWorks-Private-key_${formattedDate}.pdf`
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
        sessionStorage.setItem('isDownloadPDFClicked','1');
        const fetchUserData = async () => {
          const result = await getUserData();
          if (result.success && result.userData) {
            dispatch(setUserData(result.userData.data.userdata));
          }
        };

        fetchUserData();
      } else {
        setLoading(false);
         handleCloseModalClick();
        openPopup("downloadPinOneTime", dispatch);
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setLoading(false);
      handleCloseModalClick();
      openPopup("downloadPinOneTime", dispatch);
    }
  };
  const nextBtnClick = () => {
    setTimeout(() => {
      setIsFilled(false)
    }, 1000);
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await getPrivateAndPhrase();
      if (response.secureInfo) {
        setPrivateKey(response.secureInfo.privateKey);
        setPhrase(response.secureInfo.phrase);
      } else {
      }
    }
      fetchData();

      const isDownloadPDFClicked:any = sessionStorage.getItem('isDownloadPDFClicked');
      
      if(isDownloadPDFClicked == '1'){
        setIsPDFDownloaded(true)
      }
  }, [])

  return view({ loading, downloadBtnClick, nextBtnClick, isFilled,isPDFDownloaded, phrase, privateKey,closeModalRef });
}
export default DownloadPinOneTime