import { FC } from "react";
import { view } from "./view";
import ArrowDown from "../../assets/steps/arrow-down.svg"
import ArrowDownGray4 from "../../assets/steps/arrow-down-gray-4.svg"
import meatballsIcon from "../../assets/img/icons/meatballs-menu-icon.svg"

interface DropdownProps extends React.InputHTMLAttributes<HTMLInputElement> {
    arr: string[];
    icon?:any;
    className?:string;
    className2?:string;
  height?:string;
  onClickOptions?:(data:any)=>void
  pagename?:string;
  isOpen?: boolean; // New prop to manage dropdown state
  onToggle?: () => void; // Function to toggle dropdown state
  onItemClick?: (value: string) => void;
  showArrowDropdown?:boolean
  id?:string
}

export const ArrowDropdown:FC<DropdownProps>=({ arr, pagename, isOpen, onToggle,showArrowDropdown=true, ...props })=>{
    return view({ arr, pagename, icon:showArrowDropdown?ArrowDown:meatballsIcon, isOpen, onToggle, ...props });
}

export const MeatballsMenuDropdown:FC<DropdownProps>=({ arr,height,pagename,...props})=>{
    return view({ arr,height,pagename,...props});
}

export const ArrowDropdownGray4:FC<DropdownProps>=({ arr,pagename,...props})=>{
    return view({ arr,pagename,icon:ArrowDownGray4,...props});
}