import { TextfieldLG, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { CloseModalButton } from '../../logo-header/view';
import './styles.scss';
import { ButtonComponent } from '../../logo-header/view';
import { DropworksRadioCheckbox } from '../../../../../common/custom-field/dropworks-radio-checkbox';
import { FC } from 'react';
import { modal2Type } from '../popup-modal';
import Loader from '../../loader/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';

const view:FC<modal2Type>  = ({genres,UpdateRole,modal2=false,OpenPopup3,loading=false,message="",closeModalRef}) => {
  return (
    <div
    className="modal fade"
    id="Modal2"
    aria-hidden="true"
    aria-labelledby="Modal2"
    tabIndex={-1}
    data-bs-backdrop="static" 
    data-bs-keyboard="false"   
     data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered uni-md-popup">
      <div className="modal-content ">
    <div className='modal-cross-icon'>
     <CloseModalButton ref={closeModalRef} className='d-none'></CloseModalButton>
     </div>
        <div className="modal-header  ps-0 pt-0">
          <Heading3 className="modal-title">Create your profile</Heading3>
        </div>
       
        <div className="modal-body ps-0 pt-0 pb-0">
          <TextfieldLG className=" uni-mt-16 uni-mb-16 ctm-txt">Pick your favorite genres </TextfieldLG>
          <TextfieldXSM className='ctm-daws-text uni-mb-56'>(Select at least three)</TextfieldXSM>
          <div className='d-flex flex-wrap uni-mb-24 investor-box-parent-modal2 uni-mt-8 '>
               <div className='investor-boxs-favorite-geners  cursor-pointer' onClick={()=>UpdateRole("House")} >
                    <p className='investor-boxs-under-modal2'>House</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("House")} /></p>
               </div>
               <div className='investor-boxs-favorite-geners  cursor-pointer' onClick={()=>UpdateRole("Techno")}>
                    <p className='investor-boxs-under-modal2'>Techno</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Techno")}/></p>
               </div>
               <div className='investor-boxs-favorite-geners cursor-pointer' onClick={()=>UpdateRole("Trance")}>

                    <p className='investor-boxs-under-modal2'>Trance</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Trance")}/></p>
               </div>
       
          {/* <div className='d-flex uni-mb-24 investor-box-parent uni-mt-8 '> */}
               <div className='investor-boxs-favorite-geners  cursor-pointer' onClick={()=>UpdateRole("Future Bass")}>
                    <p className='investor-boxs-under-modal2'>Future Bass</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Future Bass")}/></p>
               </div>
               <div className='investor-boxs-favorite-geners  cursor-pointer' onClick={()=>UpdateRole("Pop")}>
                    <p className='investor-boxs-under-modal2'>Pop</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Pop")}/></p>
               </div>
               <div className='investor-boxs-favorite-geners  cursor-pointer' onClick={()=>UpdateRole("K-Pop")}>
                    <p className='investor-boxs-under-modal2'>K-Pop</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("K-Pop")}/></p>
               </div>
          {/* </div> */}
          {/* <div className='d-flex uni-mb-24 investor-box-parent uni-mt-8 '> */}
               <div className='investor-boxs-favorite-geners  cursor-pointer' onClick={()=>UpdateRole("J-Pop")}>
                    <p className='investor-boxs-under-modal2'>J-Pop</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("J-Pop")}/></p>
               </div>
               <div className='investor-boxs-favorite-geners  cursor-pointer' onClick={()=>UpdateRole("Hip Hop")}>
                    <p className='investor-boxs-under-modal2'>Hip Hop</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Hip Hop")}/></p>
               </div>
               <div className='investor-boxs-favorite-geners  cursor-pointer' onClick={()=>UpdateRole("R&B")}>
                    <p className='investor-boxs-under-modal2'>R&B</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("R&B")}/></p>
               </div>
          {/* </div> */}
          {/* <div className='d-flex uni-mb-24 investor-box-parent'> */}
               <div className='investor-boxs-favorite-geners  cursor-pointer' onClick={()=>UpdateRole("Latina")}>
                    <p className='investor-boxs-under-modal2'>Latina</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Latina")}/></p>
               </div>
               <div className='investor-boxs-favorite-geners cursor-pointer' onClick={()=>UpdateRole("Alternative")}>
                    <p className='investor-boxs-under-modal2'>Alternative</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Alternative")}/></p>
               </div>
               <div className='investor-boxs-favorite-geners  cursor-pointer' onClick={()=>UpdateRole("Rock")}>
                    <p className='investor-boxs-under-modal2'>Rock</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Rock")}/></p>
               </div>

               
          {/* </div> */}
          {/* <div className='d-flex uni-mb-24 investor-box-parent'> */}
               <div className='investor-boxs-favorite-geners  cursor-pointer' onClick={()=>UpdateRole("LoFi")}>
                    <p className='investor-boxs-under-modal2'>LoFi</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("LoFi")}/></p>
               </div>
               <div className='investor-boxs-favorite-geners cursor-pointer' onClick={()=>UpdateRole("Ballad")}>
                    <p className='investor-boxs-under-modal2'>Ballad</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Ballad")}/></p>
               </div>
               <div className='investor-boxs-favorite-geners  cursor-pointer' onClick={()=>UpdateRole("Others")}>
                    <p className='investor-boxs-under-modal2'>Others</p>
                    <p className='investor-boxs-radiobutton'><DropworksRadioCheckbox checked={genres.includes("Others")}/></p>
               </div>
          {/* </div> */}
          </div>
        </div>  
        
        <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
        {/* <DropworksSmallButton  className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16">Back</DropworksSmallButton> */}
        
        <ButtonComponent id="SecureYourAccount" className='d-none'></ButtonComponent>
        {loading?<Loader />:<DropworksSmallButton  disabled={modal2} className={modal2?"btn-wid  modal-btns m-0 custom-inactive-btn text-secondary disable-interactions":"btn-wid  modal-btns m-0"} onClick={OpenPopup3}>Next</DropworksSmallButton>}

        {/* <DropworksSmallButton  className="btn-wid m-0">Next</DropworksSmallButton> */}
        </div>
        {message && <p className='text-danger w-100 text-center uni-mt-16'>{message}</p>}

      </div>
    </div>
  </div>
  )
}

export default view;

