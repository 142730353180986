import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import "./styles.scss";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { FC } from "react";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import { WalletPin } from "./modal";
import { TextfieldSM } from "../../../../../common/custom-field/dropstock-text";
import warningIconGray from "../../../../../assets/steps/warning-Icon-gray.svg";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";

export const view: FC<WalletPin> = ({
  errors,
  loading,
  closeModalRef,
  openModal,
}) => {
  const { deposit, withdraw } = useSelector(
    (state: RootState) => state.wallet.walletData
  );
  return (
    <ModalWrapper
      modalId="WalletNetworkConfirmation"
      onBackdropClick={openModal}
    >
      <div
        className="modal fade"
        id="WalletNetworkConfirmation"
        aria-hidden="true"
        aria-labelledby="WalletNetworkConfirmation"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-sm-popup">
          <div className="modal-content ">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header d-flex justify-content-center align-items-center">
              <Heading3 className="modal-title">Network Confirmation</Heading3>
            </div>

            <div className="modal-body  uni-mb-56">
              <div className="uni-mb-56 uni-mt-56 d-flex justify-content-center align-items-center">
                <img src={warningIconGray} />
              </div>
              <div>
                <TextfieldSM className="uni-mb-56 uni-fw-400 gray3 j-text m-0 ">
                  The selected network is Polygon POS, make sure that your
                  withdrawal address supports Polygon POS network. In case it
                  does not and you proceed with the withdraw your assets may be
                  permanently lost. Confirm that the withdraw address supports
                  Polygon POS network, or else go back and change the address to
                  one that supports it.
                </TextfieldSM>
              </div>
            </div>
            <div className="modal-footer social-mobile-modal-footer   ps-0 pt-0 pb-0 uni-pr-72">
              {loading ? (
                <Loader className="uni-mt-16" />
              ) : (
                <>
                  {withdraw === "USDC" ? (
                    <ButtonComponent
                      id="WalletUsdcWithdrawModal"
                      className="buttoncomponent-mobile-modle"
                    >
                      <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 social-link-modal-btns">
                        Back
                      </DropworksSmallButton>
                    </ButtonComponent>
                  ) : (
                    <ButtonComponent
                      id="WalletFeesDropStocksWithdrawModal"
                      className="buttoncomponent-mobile-modle"
                    >
                      <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 social-link-modal-btns">
                        Back
                      </DropworksSmallButton>
                    </ButtonComponent>
                  )}{" "}
                  <ButtonComponent id="WalletConfirmWithdrawal">
                    <DropworksSmallButton
                      className="btn-wid m-0 my-0 social-link-modal-btns"
                      type="submit"
                    >
                      Confirm
                    </DropworksSmallButton>
                  </ButtonComponent>
                </>
              )}
            </div>

            <CloseModalButton ref={closeModalRef} className="d-none">
              close
            </CloseModalButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
