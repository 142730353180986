import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { FaLess } from 'react-icons/fa';

interface ReportState {
    data: any;
    loading: boolean;
    error: string | null;
    beatModalId: any;
     trackModalId : any;
     projectModalId: any;
     userModalId: any;
     somethingreportreason: any;
     ModalOpen: boolean;
     reportedUser: any;
     userProfileData:any;
     openPopups: any;
     beatPage:boolean;
  }
  
  const initialState: ReportState = {
    data: null,
    loading: false,
    error: null,
    beatModalId:null,
    trackModalId:null,
    projectModalId:null,
    userModalId:null,
    somethingreportreason: null,
    ModalOpen: false,
    reportedUser:"",
    userProfileData:"",
    openPopups:"",
    beatPage:false,
  };

export const getTrackBeatProjectUserId = createAsyncThunk(
  'report/getTrackBeatProjectUserId',
  async ({ report_type, user_id }: { report_type: string; user_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/report/get-track-beat-project-user-id/${report_type}/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
          },
        }
      );
      if (response.data.success) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message || 'Failed to fetch data');
      }
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.message || err?.message);
    }
  }
);

export const getReportCount = createAsyncThunk(
  'report/reported_user_id',
  async ({ reported_user_id }: { reported_user_id: string }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/report/get-chat-count/${reported_user_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
          },
        }
      );
      if (response.data.success) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message || 'Failed to fetch data');
      }
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.message || err?.message);
    }
  }
);

const reportSlice = createSlice({
    name: "setbeattrackresponse",
    initialState,
    reducers: {
      setbeattrackresponse: (state, action: PayloadAction<any>) => {
        state.data = action.payload; 
      },
        setbeatModalId: (state, action: PayloadAction<any>) => {
          state.beatModalId = action.payload; 
        },
        setTrackModalId: (state, action: PayloadAction<any>) => {
          state.trackModalId = action.payload; 
        },
        setProjectModalId: (state, action: PayloadAction<any>) => {
          state.projectModalId = action.payload; 
        },
        setUserModalId: (state, action: PayloadAction<any>) => {
          state.userModalId = action.payload; 
        },
        setSomethingReport: (state, action: PayloadAction<any>) => {
          state.somethingreportreason = action.payload; 
        },
        setModalOpen: (state, action: PayloadAction<any>) =>{
          state.ModalOpen = action.payload;
        },
        setReportedUserId: (state, action: PayloadAction<any>) =>{
          state.reportedUser = action.payload;
        },
        setUserProfileData: (state, action: PayloadAction<any>) =>{
          state.userProfileData = action.payload;
        },
        setOpenPopup: (state, action: PayloadAction<any>) =>{
          state.openPopups = action.payload;
        },
        setbeatPage: (state, action: PayloadAction<any>) =>{
          state.beatPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getTrackBeatProjectUserId.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(getTrackBeatProjectUserId.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(getTrackBeatProjectUserId.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload as string;
        });
        builder
        .addCase(getReportCount.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(getReportCount.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(getReportCount.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload as string;
        });
    },
  });
  
  export const {setbeattrackresponse,setbeatModalId,setModalOpen,setOpenPopup,setReportedUserId,setTrackModalId,
    setProjectModalId,setUserModalId,setSomethingReport,setUserProfileData,setbeatPage} = reportSlice.actions;
  export const reportReducer = reportSlice.reducer;
