import React from "react";
import { FaDownload } from "react-icons/fa";

interface CircularProgressBarProps {
  progress: number; // Progress percentage (0-100)
  size?: number; // Size of the circular progress bar
  strokeWidth?: number; // Thickness of the progress bar
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  progress,
  size = 25,
  strokeWidth = 1,
}) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <div
      style={{
        position: "relative",
        width: size,
        height: size,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg
        width={size}
        height={size}
        style={{
          transform: "rotate(-90deg)", // Rotate to start progress from top
        }}
      >
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke="#e6e6e6" // Background color of the circle
          strokeWidth={strokeWidth}
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke="#A91079" // Progress bar color
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
        />
      </svg>
      <FaDownload
        style={{
          position: "absolute",
          fontSize: size / 2,
          color: "#A91079",
        }}
      />
    </div>
  );
};

export default CircularProgressBar;
