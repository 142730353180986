import { useRef, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { view } from "./view";
import { verifyPin } from "../../../utils/apiServices";
import { useNavigate } from "react-router-dom";
import { openPopup } from "../../logo-header/view";
import { setValidateValue } from "../../../../../redux/reducer/auth";
import { useDispatch, useSelector } from "react-redux";
import { gasFeesCalculate, setWalletDetails } from "../../../../../redux/reducer/walletSlice";
import { RootState } from "../../../../../redux/store";
import { AppDispatch } from "../../../../../redux/store";

export const WalletDropStocksWithdraw = () => {
  const closeModalRef = useRef<HTMLAnchorElement>(null);
  const navigate = useNavigate();
  const navigateTo = (value: string) => () => {
    navigate(value);
  };

  const handleCloseModalClick = () => {
    if (closeModalRef.current) {
      closeModalRef.current.click();
    }
  };


  const openModal = () => {

  }
  
    return view({ navigateTo, closeModalRef, openModal});
}
export default WalletDropStocksWithdraw;