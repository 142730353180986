import {
  TextfieldLG,
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { DropworksRadioButton } from "../../../../../common/custom-field/dropworks-radio-button";
import "./styles.scss";
import { BlueTik } from "../../../../../common/all-icons";
import kingImage from "../../../../../assets/img/king.png";
import { Heading4 } from "../../../../../common/custom-field/dropstock-heading";
import { CloseModalButton } from "../../logo-header/view";

const view = () => {
  return (
    <div
      className="modal fade"
      id="SubMissionThankyou"
      aria-hidden="true"
      aria-labelledby="SubMissionThankyou"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72 uni-mb-40">
            <Heading4 className="approve-modal-title mx-0 my-0">
            Submission received
            </Heading4>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            {/* <TextfieldLG className='approve-modal-subtitle uni-mb-32 mt-0'>Doing so will permanently remove this file from the project</TextfieldLG> */}
            <TextfieldSM className="text-g3 mx-0 my-0 Notify-submission">
            You will be notified of the decision
            </TextfieldSM>
          </div>
          <div className="modal-footer social-mobile-modal-footer  ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton className="buttoncomponent-mobile-modle">
            <DropworksSmallButton className="btn-wid  social-link-modal-btns m-0">
            Confirm
            </DropworksSmallButton>
            </CloseModalButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
