import { useNavigate } from "react-router-dom";
import view from "./view";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerFirstFormApi } from "../../../shared/utils/apiServices";
import { useRef, useState } from "react";
import { NavigationOnLogin } from "../login";

export const Sign_Up_Fill_Details = () => {
  NavigationOnLogin();
  const [dobError,setDOBError]=useState("");
  const [loading, setLoading] = useState(false);
  const dateInputRef = useRef<HTMLInputElement>(null);
  const schema2 = yup
    .object({
      firstname: yup.string().trim().required("Name is required").matches(/^[A-Za-z]+$/, "Name should only contain letters."),
      lastname: yup.string().trim().required("Surname is required").matches(/^[A-Za-z]+$/, "Surname should only contain letters."),
      DOB: yup
        .string()
      // .required('Date of birth is required')
      // .test(
      //   'is-valid-date',
      //   'Invalid date format. Enter a valid date.',
      //   (value) => {
      //     return !value || !isNaN(Date.parse(value));
      //   }
      // )
      // .test(
      //   'is-real-date',
      //   'Date of birth must be after January 1st, 1950 and not in the future.',
      //   (value) => {
      //     // if (!value) return true;
      //     const date = new Date(value);
      //     const minDate = new Date(1950, 0, 1);
      //     const maxDate = new Date();
      //     return date >= minDate && date <= maxDate;
      //   }
      // )
      ,
      gender: yup.string().trim().required("Gender is required"),
      country: yup.string().trim().required("Country is required"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
    setError, watch,
    setValue,
    reset,
    clearErrors,
    trigger
  } = useForm<any>({
    resolver: yupResolver(schema2),
    mode: "onSubmit",
  });


  const formatDateToDDMMYYYY = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  
  const onSubmit = async (data: any) => {
    if(!dobError)
    {
      setLoading(true);
      let response: any = await registerFirstFormApi(data);
      if (response?.success) {
          navigate("/setting-up");
          setLoading(false);
        } else {
      setError(response?.type, { type: "custom", message: response?.message });
      setLoading(false);
    
    }
  }
  };
  const navigate = useNavigate();

  const navigateTo = (value: string) => () => {
    navigate(value);
  };

  return view({ navigateTo, handleSubmit, clearErrors, onSubmit, errors, register, setValue, loading, isSubmitted, dateInputRef, trigger, setError, watch ,dobError,setDOBError});
};
