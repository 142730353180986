import { FC, useEffect, useState } from "react";
import view from "./view";
import { changePhoneNumber, getUserData, updatePhoneNumber } from "../../../utils/apiServices";
import { setUserData } from "../../../../../redux/reducer/userSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openPopup } from "../../logo-header/view";
import { setPhoneNumberAdded, setPhoneNumberAddedCountryCode } from "../../../../../redux/reducer/modal";
import Countries from "../../../../../i18n/country";
import CountriesArray from "../../../../../i18n/countryArrayForSelection";

export const MobilePhoneModalB: FC<any> = () => {

    const dispatch = useDispatch();
    const countryData = Countries;
    const [saveValue, setSaveValue] = useState<any>({})
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [shownext, setShowNext] = useState<boolean>(false);
    const userData = useSelector((state: any) => state.user);
    const [loading, setLoading] = useState<boolean>(false)
    const { PhoneNubmer ,country } = userData;
    const navigate = useNavigate();
    const [message, setMessage] = useState("")
    useEffect(() => {
        setPhoneNumber(PhoneNubmer);
        setShowNext(true);
    }, [PhoneNubmer]);

    const handleChange = (e: string) => {
        setPhoneNumber(e);
    };

    const [numberValidation, setNumberValidation] = useState({ name: "Afghanistan", phonePrefix: "+93", minPhoneLength: 9, maxPhoneLength: 9, flag: "🇦🇫", flagUrl: "https://flagpedia.net/data/flags/h80/af.png" })
    const changeCountry = (data: any) => {
        let countryDeails = countryData.filter((ele: any) => ele.name == data);
        setNumberValidation(countryDeails[0]);
    }
    useEffect(()=>{
        if(country){
            let countryDeailsSave = CountriesArray.filter((ele:any)=>ele.value == country);
             setSaveValue(countryDeailsSave[0])
        }
    },[country])


    const submitData = async () => {
        setLoading(true);
        const response: any = await changePhoneNumber({ phone: phoneNumber, countryCode: numberValidation.phonePrefix });
        if (response.success) {
            setLoading(false)
            setPhoneNumber("")
            openPopup('MobilePhoneModal3', dispatch)
            dispatch(setPhoneNumberAdded(phoneNumber));
            dispatch(setPhoneNumberAddedCountryCode(numberValidation.phonePrefix));
            const result = await getUserData();
            if (result.success && result.userData) {
                dispatch(setUserData(result.userData.data.userdata));
            }
        } else {
            setLoading(false)
            setMessage(response.message);
            setTimeout(() => {
                setMessage("")
            }, 4000)
            console.error("Failed to update phone number.");
        }
    };
    return view({
        shownext,
        message,
        numberValidation,
        submitData,
        phoneNumber,
        handleChange,
        loading,
        saveValue,
        changeCountry
    });

}
export default MobilePhoneModalB;