import { useNavigate } from "react-router-dom"
import view from "./view";
import { useEffect, useState } from "react";
import { getCreateSliderPhoto } from "../../../shared/utils/apiServices";
 
export const NewCreate = () => {
    const navigate = useNavigate();
    const navigateTo = (value: string) => {
        navigate(value);
    };
 
    function repeatArrayToLength21(inputArray: string[]): string[] {
        const targetLength = 21;
        const result: string[] = [];
     
        if (inputArray.length === 0) {
        //   throw new Error("Input array must contain at least one string.");
        return result;
        }
     
        while (result.length < targetLength) {
          result.push(...inputArray.slice(0, targetLength - result.length));
        }
     
        return result;
      }
     
     
 
const [infiniteScrollData,setInfinitScrollData]=useState({
  beatData:[""],
  projectData:[""],
  reReleaseData:[""]
})
 
    const getData=async()=>{
const data = await getCreateSliderPhoto();
if(data?.success)
{
let beatData=data?.data?.BeatGallery.map((ele: any) => ele.src);
let projectData=data?.data?.ProjectGallery.map((ele: any) => ele.src);
let releaseTrackData=data?.data?.ReleaseTrackGallery.map((ele: any) => ele.src);
setInfinitScrollData({
  beatData:repeatArrayToLength21(beatData) || [],
  projectData:repeatArrayToLength21(projectData) || [],
  reReleaseData:repeatArrayToLength21(releaseTrackData) || []
})
}
    }
 
   
    useEffect(()=>{
        getData();      
    },[])
    return view({ navigateTo,infiniteScrollData });
}