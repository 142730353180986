import { FC, useRef, useState } from "react";
import { filldetailsviewType, LoadingInterface } from "../login/modal";
import "./styles.scss";
import { DropworksInput } from "../../../../common/custom-field/dropworks-input";
import "react-datepicker/dist/react-datepicker.css";
import {
  DropworksButton,
  DropworksDisableButton,
} from "../../../../common/custom-field/dropworks-button";
import { TextfieldSM } from "../../../../common/custom-field/dropstock-text";
import { Heading3 } from "../../../../common/custom-field/dropstock-heading";
import { SelectArrowDropdown } from "../../../../common/selection-dropdown";
import Loader from "../../../shared/components/loader/view";
import Countries from "../../../../i18n/country";
import DatePicker from "react-datepicker";
import { IMaskInput } from "react-imask";
import IMask from "imask";

const view: FC<filldetailsviewType & LoadingInterface> = ({
  handleSubmit,
  onSubmit,
  clearErrors,
  errors,
  setError,
  register,
  setValue,
  loading = false,
  isSubmitted,
  dobError,
  setDOBError
}) => {
  const options = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
    { value: "I prefer not to say", label: "I prefer not to say" },
  ];

  const CountryList = Countries.map((ele: any) => {
    return { value: ele.name, label: ele.name };
  });

  const currentYear = new Date().getFullYear();

  const onChangeCountry = (data: any) => {
    setValue("country", data, { shouldValidate: true });
  };
  const onChangeGender = (data: any) => {
    setValue("gender", data, { shouldValidate: true });
  };
  const [isFocused, setIsFocused] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const datePickerRef = useRef<DatePicker | null>(null);
  const calendarIconRef = useRef<HTMLSpanElement | null>(null);

  // const handleFocus = () => {
  //   setIsFocused(true);
  // };
  const handleChange = (date: Date | null) => {
    if (date) {
      const minDate = new Date(1950, 0, 1);
      const maxDate = new Date();
      if (date >= minDate && date <= maxDate) {
        const formattedDate = `${String(date.getDate()).padStart(
          2,
          "0"
        )}/${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}/${date.getFullYear()}`;
        setStartDate(date);
        setValue("DOB", formattedDate, { shouldValidate: true });
      } else {
        setError("DOB", {
          type: "custom",
          message:
            "Date of birth must be between January 1st, 1950, and today.",
        });
        setDOBError("Date of birth must be between January 1st, 1950, and today.");
      }
    }
  };
  // const formatInputValue = (value: string) => {
  //   const numericValue = value?.replace(/\D/g, "");
  //   if (numericValue?.length <= 2) {
  //     return numericValue;
  //   } else if (numericValue?.length <= 4) {
  //     return `${numericValue?.slice(0, 2)}/${numericValue?.slice(2)}`;
  //   } else {
  //     return `${numericValue?.slice(0, 2)}/${numericValue?.slice(2, 4)}/${numericValue?.slice(4, 8)}`;
  //   }
  // };
  const formatDateToMMDDYYYY = (date: Date | string): string => {
    const parsedDate = new Date(date); // Parse the input into a Date object

    // Extract month, day, and year
    const month = parsedDate.getMonth() + 1; // Months are zero-based
    const day = parsedDate.getDate();
    const year = parsedDate.getFullYear();

    // Return in MM/dd/yyyy format
    return `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year}`;
  };

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (
  //       datePickerRef.current &&
  //       calendarIconRef.current &&
  //       !(ReactDOM.findDOMNode(datePickerRef.current) as HTMLElement)?.contains(
  //         event.target as Node
  //       ) &&
  //       !calendarIconRef.current.contains(event.target as Node)
  //     ) {
  //       datePickerRef.current.setOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   document.addEventListener("touchstart", handleClickOutside as EventListener, { passive: true });

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //     document.removeEventListener("touchstart", handleClickOutside as EventListener);
  //   };
  // }, []);
  const [dob, setDob] = useState("");
  const handleDateChange = (value: string) => {
    setDob(value);
    if (value === "MM/DD/YYYY") {
      setDob("");
      clearErrors("DOB");
      setDOBError("")
    } else {
      validateDate(value);
    }
  };

  const validateDate = (value: string) => {
    if (value) {
      const [month, day, year] = value.split("/").map(Number);

      if (!day || !month || !year || day > 31 || month > 12) {
        setError("DOB", {
          type: "custom",
          message: "Invalid date (MM/DD/YYYY). Enter a valid date.",
        });
        setDOBError("Invalid date (MM/DD/YYYY). Enter a valid date.")
        return;
      }
      if (year < 1900) {
        setError("DOB", {
          type: "custom",
          message: "Date of birth should be after 1899.",
        });
        setDOBError("Date of birth should be after 1899.")

        return;
      }
      const enteredDate = new Date(year, month - 1, day);
      const today = new Date();
      const eighteenYearsAgo = new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate()
      );
      if (
        enteredDate.getDate() !== day ||
        enteredDate.getMonth() + 1 !== month
      ) {
        setError("DOB", {
          type: "custom",
          message: "Invalid date (MM/DD/YYYY). Enter a valid date.",
        });
        setDOBError("Invalid date (MM/DD/YYYY). Enter a valid date.")

      } else if (enteredDate >= today) {
        setError("DOB", {
          type: "custom",
          message: "Date of birth must be in the past.",
        });
        setDOBError("Date of birth must be in the past.")

      } 
      else if (enteredDate > eighteenYearsAgo) {
        setError("DOB", {
          type: "custom",
          message: "You must be at least 18 years old.",
        });
        setDOBError("You must be at least 18 years old.");
      }
      
      else {
        enteredDate &&
          setValue("DOB", formatDateToMMDDYYYY(enteredDate), {
            shouldValidate: true,
          });
        setDOBError("")

      }
    }
  };
  const [mask, setMask] = useState("");
  const handleFocus = () => {
    setMask("MM/DD/YYYY");
  };

  const handleBlur = () => {
    setMask("");
  };
  return (
    <div className=" page-content row align-items-pages container px-0 m-auto h-100  min-h-800">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6  col-xl-6 col-xxl-5 px-0">
        <Heading3 className="signUp_heading my-0">
          Welcome to DropWorks
        </Heading3>
        <TextfieldSM className="uni-my-32 signUp-fill-normal">
          Fill out all the fields
        </TextfieldSM>

        <div className="row fill-details-mobile-ui">
          <div className="col-lg-6 col-12  pe-1 ps-0 mobile-p-0-filldetails">
            <DropworksInput
              {...register("firstname")}
              placeholder="Name"
              className={`form-control ${
                isSubmitted &&
                (errors.firstname
                  ? "is-invalid b-red-2"
                  : !errors.firstname
                  ? "is-valid  b-green-2"
                  : "")
              }`}
              disabled={loading}
            />
            {errors.firstname && (
              <p className="invalid-feedback">{errors.firstname.message}</p>
            )}
          </div>
          <div className="col-lg-6 col-12 pe-0 ps-1 mobile-p-0-filldetails ps-fill-details">
            <DropworksInput
              placeholder="Surname"
              {...register("lastname")}
              className={`form-control ${
                isSubmitted &&
                (errors.lastname
                  ? "is-invalid b-red-2"
                  : !errors.lastname
                  ? "is-valid  b-green-2"
                  : "")
              }`}
              disabled={loading}
            />
            {errors.lastname && (
              <p className="invalid-feedback">{errors.lastname.message}</p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-12 fill-details-space uni-mt-16 pe-1 ps-0 mobile-p-0-filldetails">
            <div className="input-group d-flex">
              {/* <DropworksInput placeholder="Birth Date"  {...register('DOB')}   className=   disabled={loading}  /> */}

              <IMaskInput
                id="dob"
                mask={mask}
                placeholder="Birth Date (MM/DD/YYYY)"
                className={`custom_inp form-control ${
                isSubmitted && dob && dob !== "MM/DD/YYYY" && !dobError? "is-valid  b-green-2": ""} ${dobError && isSubmitted && dob !== "MM/DD/YYYY"? "is-invalid b-red-2": ""}`}
                value={dob}
                onAccept={(value: any) => handleDateChange(value as string)}
                lazy={false}
                definitions={{
                  M:{
                    mask: /(.*\d)/,
                    placeholderChar: "M"
                  },
                  D:{
                    mask: /(.*\d)/,
                    placeholderChar: "D"
                  },
                  Y:{
                    mask: /(.*\d)/,
                    placeholderChar: "Y"
                  }
                }}
                onFocus={handleFocus} 
                onBlur={handleBlur}
              />
              {/* <DatePicker
          ref={datePickerRef}
          selected={startDate}
          onChange={handleChange}
           onFocus={handleFocus}
           placeholderText="Birth Date"
           dateFormat="MM/dd/yyyy"
           calendarClassName="dark-theme-datepicker"
           className={`custom_inp form-control ${DobValue && !errors.DOB ?"is-valid  b-green-2":""} ${isSubmitted && (errors.DOB ? "is-invalid b-red-2" : "")}`}
           id="date"
           showYearDropdown
           showMonthDropdown
          dropdownMode="select"
          minDate={new Date(1950, 0, 1)}
         maxDate={new Date(currentYear, 11, 31)}
         onChangeRaw={(event) => {
          const inputElement = event?.target as HTMLInputElement | null;
         if (inputElement) {
          inputElement.value = formatInputValue(inputElement.value);
       }
      }}
      />
      <span
        className="calendar-icon cursor-pointer"
        ref={calendarIconRef}
        
        onClick={() => datePickerRef.current?.setOpen(true)}
      >
        <SlCalender />
      </span> */}
            </div>
            {dobError && isSubmitted &&  (
              <p className="invalid-feedback">{dobError}</p>
            )}
          </div>
          <div className="col-lg-6 ps-fill-details col-12 pe-0 ps-1 uni-mt-16 mobile-p-0-filldetails mobile-p-0-filldetails mobile-m-0-filldetails">
            <DropworksDisableButton
              disabled={loading}
              className={`custom-select-dropdown track-overview-dropdown cursor-pointer bg-trans mobile-mb-0-filldetails form-control cursor-pointer ${
                isSubmitted &&
                (errors.gender
                  ? "is-invalid b-red-2"
                  : !errors.gender
                  ? "is-valid  b-green-2"
                  : "")
              }`}
            >
              <SelectArrowDropdown
                options={options}
                placeHolderValue="Gender"
                onChangeValue={onChangeGender}
              ></SelectArrowDropdown>
            </DropworksDisableButton>
            {errors.gender && (
              <p className="invalid-feedback">{errors.gender.message}</p>
            )}
          </div>
        </div>

        <div className="row uni-mb-32  uni-mt-16  mobile-m-0-filldetails">
          <DropworksDisableButton
            disabled={loading}
            className={`form-control custom-select-dropdown   track-overview-dropdown bg-trans cursor-pointer ${
              isSubmitted &&
              (errors.country
                ? "is-invalid b-red-2"
                : !errors.country
                ? "is-valid  b-green-2"
                : "")
            }`}
          >
            <SelectArrowDropdown
              options={CountryList}
              placeHolderValue="Country / Region"
              onChangeValue={onChangeCountry}
              isSearch={true}
            ></SelectArrowDropdown>
          </DropworksDisableButton>
          {errors.country && (
            <p className="invalid-feedback ps-0">{errors.country.message}</p>
          )}
        </div>
        {loading ? (
          <Loader className="uni-mt-40" />
        ) : (
          <DropworksButton
            type="submit"
            className="btn-submit-filldetails font-weight-600"
            onClick={handleSubmit(onSubmit)}
            //  onClick={navigateTo("/setting-up")}
          >
            Next
          </DropworksButton>
        )}
      </div>
    </div>
  );
};
export default view;
