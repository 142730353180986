import { FC, useEffect, useState } from "react";
import  view  from "./view";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { blockedUser, getUserData } from "../../../utils/apiServices";
import { AppDispatch } from "../../../../../redux/store";
import { getAllUserStatus } from "../../../../../redux/reducer/messageChatSlice";
import { useNavigate } from "react-router-dom";
import { setUserData } from "../../../../../redux/reducer/userSlice";



export const BlockedModal:FC<any>=()=>{
    const BlockedUserId = useSelector((state: RootState) => state.messageChat);
    const userData = useSelector((state: any) => state.report.userProfileData);
     const dispatch=useDispatch<AppDispatch>();
     const navigate = useNavigate()

     const[userBlock,setUserBlock] = useState();

        const doBlockUser = async () =>{
             try{
                const result = await blockedUser(BlockedUserId.Data.id);
                if(result){
                    dispatch(getAllUserStatus())
                    setUserBlock(result.blockUser.data);
                }
             }
             catch(error){
                console.error('Error fetching unread message', error);
             }  
        }

        const fetchUserData = async () => {
            const result = await getUserData(); // Call your API function
            if (result.success && result.userData) { // Adjust based on your API response
                dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
            }
        };

        const blockUser = async () =>{
             try{
                const result = await blockedUser(userData._id);
                if(result){
                    setUserBlock(result.blockUser.data);
                    fetchUserData();
                }
             }
             catch(error){
                console.error('Error fetching unread message', error);
             }  
        }


    return (view(doBlockUser,userData,blockUser));
    
     
}
export default BlockedModal