import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';

import "./styles.scss";
import { DropworksSwitch } from '../../../../../common/custom-field/dropworks-switch';
import checkCircle from "../../../../../assets/steps/check-circle.svg"
import { ButtonComponent, CloseModalButton } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { socialLinks } from '../../../../../redux/reducer/userSlice';
import { setsocialModal } from '../../../../../redux/reducer/modal';

const view = () => {
  const {social_links} =useSelector((state:RootState)=>state.user);
  const doesNameExist = (nameToCheck: string): boolean => {
    return social_links.some(service => service.name.toLowerCase() === nameToCheck.toLowerCase());
  };
const dispatch =useDispatch();
  return (
    <div
      className="modal fade"
      id="SocialLinksMain"
      aria-hidden="true"
      aria-labelledby="SocialLinksMain"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered uni-md-popup">
        <div className="modal-content modal-width-notification">
        <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0">
            <Heading3 className="modal-title ">Social Links</Heading3>
          </div>
          <div className="modal-body ps-0 pt-0 pb-0 mbl-mb-0 uni-mb-56">
            <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh modal-second-head-font social-link-main-title mbl-mb-8">Add your social medias</TextfieldLG>
            <TextfieldXSM className='uni-mb-56 mbl-mb-16 sn-ctm-txt modal-second-head-font social-links-main-xsm'>Social medias will be displayed in your profile</TextfieldXSM>

            <ButtonComponent id="SocialLink8">
              <div className='uni-p-16 social-links-main-hover popup-space-mobile  d-flex justify-content-between ' onClick={()=>dispatch(setsocialModal('Spotify'))}>
                <TextfieldSM className='mx-0 my-0 text-field-notification '>Spotify</TextfieldSM>
                {doesNameExist('Spotify') && <img src={checkCircle} className='checkcircle-mobile' />}
              </div>
            </ButtonComponent>

            <ButtonComponent id="SocialLink8">
            <div className='uni-p-16 social-links-main-hover popup-space-mobile  d-flex justify-content-between '  onClick={()=>dispatch(setsocialModal('music.apple'))}>
              <TextfieldSM className='mx-0 my-0 text-field-notification'>Apple Music</TextfieldSM>
              {doesNameExist('music.apple') && <img src={checkCircle} className='checkcircle-mobile' />}
            </div>
            </ButtonComponent>

            <ButtonComponent id="SocialLink8">
            <div className='uni-p-16 social-links-main-hover d-flex justify-content-between popup-space-mobile '  onClick={()=>dispatch(setsocialModal('Tidal'))}>
              <TextfieldSM className='mx-0 my-0 text-field-notification'>Tidal</TextfieldSM>
              {doesNameExist('Tidal') && <img src={checkCircle} className='checkcircle-mobile' />}
            </div>
            </ButtonComponent>

            <ButtonComponent id="SocialLink8">
            <div className='uni-p-16 social-links-main-hover popup-space-mobile  d-flex justify-content-between '  onClick={()=>dispatch(setsocialModal('Deezer'))}>
              <TextfieldSM className='mx-0 my-0 text-field-notification'>Deezer</TextfieldSM>
              {doesNameExist('Deezer') && <img src={checkCircle} className='checkcircle-mobile' />}
            </div>
            </ButtonComponent>
            <ButtonComponent id="SocialLink8">
            <div className='uni-p-16 social-links-main-hover popup-space-mobile  d-flex justify-content-between '  onClick={()=>dispatch(setsocialModal('Instagram'))}>
              <TextfieldSM className='mx-0 my-0 text-field-notification'>Instagram</TextfieldSM>
              {doesNameExist('Instagram') && <img src={checkCircle} className='checkcircle-mobile' />}
            </div>
            </ButtonComponent>

            <ButtonComponent id="SocialLink8">
            <div className='uni-p-16 social-links-main-hover popup-space-mobile  d-flex justify-content-between '  onClick={()=>dispatch(setsocialModal('X'))}>
              <TextfieldSM className='mx-0 my-0 text-field-notification'>X</TextfieldSM>
              {doesNameExist('X') && <img src={checkCircle} className='checkcircle-mobile' />}
            </div>
            </ButtonComponent>
            <ButtonComponent id="SocialLink8">
            <div className='uni-p-16 social-links-main-hover popup-space-mobile  d-flex justify-content-between'  onClick={()=>dispatch(setsocialModal('Youtube'))}>
              <TextfieldSM className='mx-0 my-0 text-field-notification'>Youtube</TextfieldSM>
              {doesNameExist('Youtube') && <img src={checkCircle} className='checkcircle-mobile' />}
            </div>
            </ButtonComponent>
          </div>
          <div className="modal-footer mobile-modal-footer ps-0 pt-0 pb-0">
            <CloseModalButton>
            <DropworksSmallButton className="btn-wid m-0 modal-btns">Save</DropworksSmallButton>
            </CloseModalButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default view;

