import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import "./styles.scss";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { FC } from "react";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import { WalletPin } from "./modal";
import {
  TextfieldSM,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import warningIconGray from "../../../../../assets/steps/warning-Icon-gray.svg";
import { HrLineMD } from "../../../../../common/hr-line";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { formatNumberWithCommas } from "../../../utils/localStorage";

export const view: FC<WalletPin> = ({
  errors,
  loading,
  closeModalRef,
  openModal,
  walletConfirm,
}) => {
  const {
    amount,
    receiverAddress,
    pin,
    type,
    trackNFTId,
    fiatPrice,
    selectedDropStocks,
    trackTitle,
    fiatSymbol,
  } = useSelector((state: RootState) => state.wallet.walletData);

  const handleOnClick = () => {
    if (type==="usdc") {
      const payload = {
        amount: amount || "",
        pin: pin || "",
        receiverAddress: receiverAddress || "",
        type: type || "",
      };
      walletConfirm(payload);
    } else {
      const payload1 = {
        amount: amount || [],
        pin: pin || "",
        receiverAddress: receiverAddress || "",
        type: type || "",
        trackNFTId: trackNFTId || [],
      };
      walletConfirm(payload1);
    }
  };

  return (
    <ModalWrapper modalId="WalletConfirmWithdrawal" onBackdropClick={openModal}>
      <div
        className="modal fade"
        id="WalletConfirmWithdrawal"
        aria-hidden="true"
        aria-labelledby="WalletConfirmWithdrawal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content uni-md-popup">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header uni-mb-16">
              <Heading3 className="modal-title">Confirm Withdrawal</Heading3>
            </div>

            <div className="modal-body uni-mb-56">
              <TextfieldXSM className="gray2 uni-mb-56 mt-0">
                Confirm all the details before proceeding. Withdrawal cannot be
                cancelled once confirmed.
              </TextfieldXSM>
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-between w-100 uni-mb-16">
                  <TextfieldSM className="gray3 m-0">Address</TextfieldSM>
                  <TextfieldSM className="m-0 text-white">{receiverAddress}</TextfieldSM>
                </div>

                <div className="d-flex justify-content-between w-100 uni-mt-16 uni-mb-16">
                  <TextfieldSM className="gray3  m-0">Network</TextfieldSM>
                  <TextfieldSM className="m-0 text-white">Polygon POS</TextfieldSM>
                </div>

                <div className="d-flex justify-content-between w-100 uni-mt-16 uni-mb-32">
                  <TextfieldSM className="gray3  m-0">Network Fee</TextfieldSM>
                  <TextfieldSM className="m-0 text-white">
                    {" "}
                    {fiatPrice} {fiatSymbol}
                  </TextfieldSM>
                </div>
                <HrLineMD className="gray2" />
              </div>
              {selectedDropStocks?.length && trackNFTId?.length ? (
                selectedDropStocks.map(
                  (stock:any, index:any) =>
                    stock.amount && (
                      <div key={index} className="d-flex flex-column">
                        <div className="d-flex justify-content-between w-100 uni-mt-32 uni-mb-16">
                          <TextfieldSM className="gray3 m-0">
                            DropStock
                          </TextfieldSM>
                          <div>
                            <TextfieldSM className="m-0 text-white">
                              {stock.track_name}
                            </TextfieldSM>
                          </div>
                        </div> <div className="d-flex justify-content-between w-100 uni-mt-16 uni-mb-32">
                          <TextfieldSM className="gray3  m-0">
                            Amount
                          </TextfieldSM>
                          <TextfieldSM className="m-0 text-white">
                            {formatNumberWithCommas(Number(stock.amount))}{" "}
                            DropStocks
                          </TextfieldSM>
                        </div>
                        <HrLineMD className="gray2" />
                      </div>
                    )
                )
              ) : (
                <></>
              )}
            </div>
            <div className="modal-footer social-mobile-modal-footer   ps-0 pt-0 pb-0 uni-pr-72">
              {loading ? (
                <Loader className="uni-mt-16" />
              ) : (
                <>
                  <ButtonComponent
                    id="WalletNetworkConfirmation"
                    className="buttoncomponent-mobile-modle"
                  >
                    <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 social-link-modal-btns">
                      Back
                    </DropworksSmallButton>
                  </ButtonComponent>{" "}
                  <DropworksSmallButton
                    className="btn-wid m-0 my-0 social-link-modal-btns"
                    onClick={() => {
                      handleOnClick();
                    }}
                  >
                    Confirm
                  </DropworksSmallButton>
                </>
              )}
            </div>

            <CloseModalButton ref={closeModalRef} className="d-none">
              close
            </CloseModalButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
