
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { DropworksPassword } from "../../../../../common/custom-field/dropworks-password";
import { CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { changePasswordForm } from "../../../../content/guest-pages/login/modal";
import { FC, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";

export const view :FC<changePasswordForm> = ({errors,isSubmitted,loading,register,onSubmit,handleSubmit,closeModalRef,openModal}) => {

  const { watch } = useForm();

  // Watch form fields for changes
  const oldPassword = watch("old_password");
  const newPassword = watch("new_password");
  const confirmPassword = watch("confirm_password");

  // Determine if all fields are filled
  const allFieldsFilled = oldPassword && newPassword && confirmPassword;
  

 

  return (
    <ModalWrapper modalId="ChangePassword" onBackdropClick={openModal}>
    <div
      className="modal fade"
      id="ChangePassword"
      aria-hidden="true"
      aria-labelledby="ChangePassword"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered uni-md-popup">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className="modal-title uni-mb-56 mbl-mb-16">Change Password</Heading3>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            <div   className="custom-pass-space">
            <DropworksPassword
            {...register("old_password")}
              placeholder="Current Password"
              className="mb-0 relative login-specing "
              className3={isSubmitted}
              className2={`uni-mt-16 mbl-mt-0 form-control login-cstm-pass p-0  ${
                isSubmitted &&
                (errors.old_password
                  ? "is-invalid b-red-2"
                  : !errors.old_password
                  ? "is-valid   b-green-2"
                  : "")
              }`}
            />
              {errors.old_password && <p className="invalid-feedback error-red">{errors.old_password.message}</p>}
            </div>
            <div   className="custom-confirm-pass-space">
            <DropworksPassword
              {...register("new_password")}
              placeholder="New Password"
              className="mb-0 relative login-specing "
              className3={isSubmitted}
              className2={`uni-mt-16 mbl-mt-0 form-control login-cstm-pass p-0  ${
                isSubmitted &&
                (errors.new_password
                  ? "is-invalid b-red-2"
                  : !errors.new_password
                  ? "is-valid   b-green-2"
                  : "")
              }`}
            />
            {errors.new_password && <p className="invalid-feedback error-red">{errors.new_password.message}</p>}
            </div>
            <div   className="uni-mb-56 custom-confirm-pass-space">
            <DropworksPassword
              {...register("confirm_password")}
              placeholder="Confirm New Password"
              className="mb-0 relative login-specing "
              className3={isSubmitted}
              className2={`uni-mt-16  mbl-mt-0 form-control login-cstm-pass p-0  ${
                isSubmitted &&
                (errors.confirm_password
                  ? "is-invalid b-red-2"
                  : !errors.confirm_password
                  ? "is-valid   b-green-2"
                  : "")
              }`}
            />
             {errors.confirm_password && <p className="invalid-feedback error-red">{errors.confirm_password.message}</p>}
            </div>
          </div>
          <div className="modal-footer mbl-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
          {loading?<Loader className="uni-mt-16"/>:<>
            <CloseModalButton className="buttoncomponent-mobile-modle" >
            <DropworksSmallButton className="btn-wid  social-link-modal-btns  btn-gray3 my-0 ml-0 uni-mr-16 btn-bg-gray3">
              Cancel
            </DropworksSmallButton>
            </CloseModalButton>{" "}
            
            <DropworksSmallButton className={ allFieldsFilled ? "btn-wid  modal-btns m-0 custom-inactive-btn social-link-modal-btns text-secondary disable-interactions":"btn-wid  modal-btns m-0"}
            type="submit">
              Finish
            </DropworksSmallButton>
            </>}
          </div>
          </form>
          <CloseModalButton ref={closeModalRef} className='d-none'>close</CloseModalButton>
        </div>
      </div>
    </div>
    </ModalWrapper>
  );
};



