import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import i18next from "i18next";
import "./i18n/init";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import * as Sentry from "@sentry/react";
import ErrorBoundary from "./app/shared/utils/errorBoundary";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PrimeReactProvider } from "primereact/api";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

Sentry.init({
  dsn: "https://a361fc4d631ae410b9cda374eadd7ee0@o4508442833518592.ingest.us.sentry.io/4508442835484672",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/uat\.dropworks\.app\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
  <React.StrictMode>
   {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_Client_Id || ""}> */}
      <PrimeReactProvider>
        <ErrorBoundary>
          <Provider store={store}>
            <App />
          </Provider>
        </ErrorBoundary>
      </PrimeReactProvider>
    {/* </GoogleOAuthProvider> */}
  </React.StrictMode>
);

reportWebVitals();
