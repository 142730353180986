import React, { FC, useEffect, useState } from "react";
import "./styles.scss";
import king from '../../assets/img/inviteimg.png';
import verfiedIcon from '../../assets/steps/verified-badge.svg';
import { ButtonComponent, openPopup } from "../../app/shared/components/logo-header/view";
import { BlueTik } from "../all-icons";
import { useDispatch, useSelector } from "react-redux";
import { setInvite, setSelectInde, setSentInvite } from "../../redux/reducer/inviteSlice";
import UserIcon from "../../assets/steps/Group 18414.svg";
import CustomTooltip from "../custom-tooltip/view";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";
import { fetchReceviedInvite, fetchSentInvite, setIsnotificationPage } from "../../redux/reducer/uploadSlice";
export const view: FC<any> = ({
    image,
    arr,
    page,
    showCondition
}) => {
    const dispatch=useDispatch();
    const navigate=useNavigate()
    const {selectedNotification,isNotificationPage,trackDetails,reReleaseTrackDetails}= useSelector((state: RootState) => state.upload)
    const invite = useSelector((state: RootState) => state.upload.receviedInvite)
    const sentInvite = useSelector((state: RootState) => state.upload.sentInvite)
    const userData = useSelector((state: any) => state.user);
    const [openSentInvite,setOpenSentInvite]=useState(false)

    const path=window.location.pathname

    useEffect(()=>{
        if(path.includes("track-project-overview") && userData.id == trackDetails?.trackData?.user_id._id ){
            setOpenSentInvite(true)
        }
        else if(path.includes("track-project-rerelease")&& userData.id == reReleaseTrackDetails?.trackData?.user_id._id ){
            setOpenSentInvite(true)
        }
    })

    useEffect(() => {
        if (isNotificationPage) {
            let findObj;
            let findIndex;
    
            if (selectedNotification?.is_own_item === 0 && invite.length> 0 ) {
                findIndex = invite.findIndex((item:any) => item._id === selectedNotification.invited_id);
                findObj = invite[findIndex]; // Access the object using the index
               const id= findObj?.acceept_status === "rejected" ? "DeclinedInviteReceived" : findObj?.acceept_status === "pending"? "ActiveInviteReceived": findObj?.acceept_status === "accepted"?"AcceptedInviteReceived":"ExpiredInviteReceived"
                openPopup(id,dispatch)
                dispatch(setSelectInde(findIndex))
            } 
            else if (selectedNotification?.is_own_item === 1 && sentInvite.length>0) {
                findIndex = sentInvite.findIndex((item:any) => item._id === selectedNotification.invited_id);
                findObj = sentInvite[findIndex]; // Access the object using the index
               const id= findObj?.acceept_status === "rejected" ? "DeclinedInviteSent" : findObj?.acceept_status === "pending"? "ActiveInviteSent": findObj?.acceept_status === "accepted"?"AcceptedInviteSent":"ExpiredInviteSent"
               openPopup(id,dispatch)
               dispatch(setSelectInde(findIndex))
            }
    
        }
    }, []);


    const trackClick=(id:number)=>{
        let findIndex;
        findIndex = sentInvite.findIndex((item:any) => item._id === id);
        dispatch(setSelectInde(findIndex))
    }

    useEffect(() => {
        dispatch(fetchReceviedInvite() as any).then((res:any) => dispatch(setInvite(res.payload)))
        dispatch(fetchSentInvite() as any).then((res:any) => dispatch(setSentInvite(res.payload)))



        return () => {
            dispatch(setIsnotificationPage(false));
            setOpenSentInvite(false)
        };
    }, []);
    

    return (<>
    
        <div className="row justify-content-between">
            {arr?.map((data: any, index: any) => (
                <>
                {page=="track"?<>
                {data.active === "Active" ?
                
               openSentInvite?
                <div className="invite-main col-6  uni-p-16 pe-0 uni-mb-32">
                    <ButtonComponent id={data.id} className="w-100">
                    <div className="d-flex align-items-center  invite_firstcolumn" onClick={()=>trackClick(data.userId)}>
                            <img src={`${process.env.REACT_APP_IMGURL}/${data.img}`}   onError={(e) => (e.currentTarget.src = UserIcon)} className="invite_firstcolumn_imagebox cursor-pointer" alt=""  />
                            <div>
                                <span className="d-flex align-items-center">
                            <CustomTooltip title={data?.name} className="w-auto" position={"top"}>
                                <h2 className="fw-700 invite-name text-capitalize" >{data.name} </h2>
                                </CustomTooltip>
                                {data.bluetik==1?<BlueTik position="top" className="uni-ml-8" />:""}
                                </span>
                                <p className="invite-subname mt-1 mx-1">{data.subname}</p>
                                <div className="d-flex gap-3  mt-2 invite-mobile-view-btns">
                                    <div className=" invite_firstcolumn_vocalist">{data?.vocalist.toLowerCase()=="cover designer"?"Artwork Designer":data.vocalist}</div>
                                    {showCondition?<></>: <button className="invite_firstcolumn_percentage">  {data?.percentage.includes('%') ? data?.percentage : `${data?.percentage}%`}</button>}
                                   
                                </div>
                            {/* <button className={data.active === "Active" ? "invite_firstcolumn_activebtn-yel" : data.active === "Declined" ? "invite_firstcolumn_activebtn-red" : data.active === "Accepted" ? "invite_firstcolumn_activebtn-grn" : "invite_firstcolumn_activebtn-gry"}>{data.active}</button> */}
                            </div>
                        </div>
                        </ButtonComponent>
                        </div>: <div className="invite-main col-6  uni-p-16 pe-0 uni-mb-32">
                  
                    <div className="d-flex align-items-center  invite_firstcolumn" onClick={()=>trackClick(data.userId)}>
                            <img src={`${process.env.REACT_APP_IMGURL}/${data.img}`} onClick={()=>navigate(`/user-profile/${data?.userSlug}`)}  onError={(e) => (e.currentTarget.src = UserIcon)} className="invite_firstcolumn_imagebox cursor-pointer" alt=""  />
                            <div>
                                <span className="d-flex align-items-center" onClick={()=>navigate(`/user-profile/${data?.userSlug}`)}>
                            <CustomTooltip title={data?.name} className="w-auto" position={"top"}>
                                <h2 className="fw-700 invite-name text-capitalize" >{data.name} </h2>
                                </CustomTooltip>
                                {data.bluetik==1?<BlueTik position="top" className="uni-ml-8" />:""}
                                </span>
                                <p className="invite-subname mt-1 mx-1">{data.subname}</p>
                                <div className="d-flex gap-3  mt-2 invite-mobile-view-btns">
                                    <div className=" invite_firstcolumn_vocalist">{data?.vocalist.toLowerCase()=="cover designer"?"Artwork Designer":data.vocalist}</div>
                                    {showCondition?<></>: <button className="invite_firstcolumn_percentage">  {data?.percentage.includes('%') ? data?.percentage : `${data?.percentage}%`}</button>}
                                   
                                </div>
                            {/* <button className={data.active === "Active" ? "invite_firstcolumn_activebtn-yel" : data.active === "Declined" ? "invite_firstcolumn_activebtn-red" : data.active === "Accepted" ? "invite_firstcolumn_activebtn-grn" : "invite_firstcolumn_activebtn-gry"}>{data.active}</button> */}
                            </div>
                        </div>
                        
                        </div>:""}
                    
                </>:<>

                <div className="invite-main col-6  uni-p-16 pe-0 uni-mb-32">
                <ButtonComponent id={data.id} className="w-100">
               
                        <div className="d-flex align-items-center  invite_firstcolumn" onClick={()=>dispatch(setSelectInde(index))}>
                            <img src={`${process.env.REACT_APP_IMGURL}/${data.img}`}  onError={(e) => (e.currentTarget.src = UserIcon)} className="invite_firstcolumn_imagebox" alt="" />
                            <div>
                            <span className="d-flex align-items-center">
                            <CustomTooltip title={data?.name} className="w-auto" position={"top"}>
                                <h2 className="fw-700 invite-name text-capitalize">{data.name}</h2>
                                </CustomTooltip>
                                {data.bluetik==1?<BlueTik position="top" className="uni-ml-8" />:""}
                                </span><p className="invite-subname  mt-1 mx-1">@{data.subname}</p>  
                                <div className="d-flex gap-3 mt-2 invite-mobile-view-btns">
                                    <div className=" invite_firstcolumn_vocalist">{data?.vocalist.toLowerCase()=="cover designer"?"Artwork Designer":data.vocalist}</div>
                                    <button className="invite_firstcolumn_percentage">{data?.percentage.includes('%') ? data?.percentage : `${data?.percentage}%`}</button>
                                    <button className={data.active === "Active" ? "invite_firstcolumn_activebtn-yel" : data.active === "Declined" ? "invite_firstcolumn_activebtn-red" : data.active === "Accepted" ? "invite_firstcolumn_activebtn-grn" : "invite_firstcolumn_activebtn-gry"}>{data.active}</button>
                                </div>
                            </div>
                        </div>
                        
                    </ButtonComponent>
                    </div>
                </>}
                   
                </>

            ))}
        </div>
        </>);
};
