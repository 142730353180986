import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import "./styles.scss";
import { Heading3, Heading4 } from "../../../../../common/custom-field/dropstock-heading";
import { FC } from "react";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import {TextfieldSM,} from "../../../../../common/custom-field/dropstock-text";
import { downloadPdfConfirmation } from "../../../../content/guest-pages/login/modal";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";

export const view: FC<downloadPdfConfirmation> = ({
  loading,
  nextBtnClick,
  closeModalRef
}) => {
  return (
    <ModalWrapper modalId="downloadPdfConfirmation">
      <div
        className="modal fade"
        id="downloadPdfConfirmation"
        aria-hidden="true"
        aria-labelledby="downloadPdfConfirmation"
        tabIndex={-1}
        data-bs-backdrop="static"
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content ">
          <CloseModalButton  ref={closeModalRef} className="d-none"  ></CloseModalButton>
            <div className="modal-cross-icon"></div>
            <div className="modal-header ps-0 pt-0">
              <Heading4 className="modal-title">
                Private key is downloaded
              </Heading4>
            </div>
            <div className="modal-body ps-0 pt-0 pb-0  uni-mb-56">
              <TextfieldSM className="uni-mb-56 ds-ctm-txt-dw-pdf mbl-mt-8 mbl-mb-0">
                By clicking confirm, you acknowledge and accept full
                responsibility for safeguarding this information.
              </TextfieldSM>
            </div>
            <div className="modal-footer mbl-modal-footer ps-0 pt-0 pb-0 ">
              {loading ? (
                <Loader className="uni-mt-16" />
              ) : (
                <>
                  <CloseModalButton className="mbl-anchor-full-btn">
                    <ButtonComponent id="downloadPinOneTime" className="mbl-anchor-full-btn" >
                      <DropworksSmallButton className=" btn-bg-gray3 mb-0 mbl-full-width-btn uni-mr-16 w-autoo">
                        Back
                      </DropworksSmallButton>
                    </ButtonComponent>
                  </CloseModalButton>
                  <CloseModalButton className="mbl-anchor-full-btn">
                    <DropworksSmallButton
                      className="btn-wid m-0 my-0 mbl-full-width-btn"
                      onClick={nextBtnClick}
                    >
                      Confirm
                    </DropworksSmallButton>
                  </CloseModalButton>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
