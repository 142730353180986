import { useNavigate } from "react-router-dom"
import { view } from "./view"
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassword } from "../../../shared/utils/apiServices";
import { useEffect, useState } from "react";
import * as yup from "yup";

export const RecoverAccount = () => {


  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [passwordToken, setPasswordToken] = useState("")
  const schema4 = yup.object({
    new_password: yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirm_password: yup.string()
      .oneOf([yup.ref('new_password')], "Passwords must match")
      .required("Confirm Password is required"),
  });

  useEffect(() => {
    const url = new URL(window.location.href);
    const tokenParam = url.searchParams.get("token");
    if (tokenParam) {
      setPasswordToken(tokenParam);
    }
    else {
      navigate("/login")
    }
  }, []);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitted },
  } = useForm<any>({
    resolver: yupResolver(schema4),
    mode: "onSubmit",
  });

  const onSubmit = async (data: any) => {
    setLoading(true)
    const response: any = await resetPassword({ data, passwordToken });
    if (response.success) {
      setLoading(false);
      navigate("/login");
    } else {
      setLoading(false);
      setError(response.type || "new_password", { type: "custom", message: response.message });
    }
  };


  const navigateTo = (value: string) => () => {
    navigate(value);
  };

  return (view({ navigateTo, onSubmit, handleSubmit, register, isSubmitted, errors }))
}