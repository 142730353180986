import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserSlice {
  id: string;
  firstname: string,
  lastname: string,
  username: string,
  displayName: string,
  bioGraphy: string,
  role: string[],
  displayReleases: boolean,
  displayBeats:boolean,
  DWPreferences:string[],
  blueTick:number,
  receiveMessagesFromAnyone:boolean,
  publicProfile:boolean,
  receiveOfferFromAnyone:boolean
  profileImg:string
  country:string,
  phone?:string,
  loginHistory?:any,
  language:string,
  bannerImg?:string,
  countryCode:string,
  notifications?: 
    {
        "announcements": boolean,
        "wallet": boolean,
        "invites": boolean,
        "marketplace": boolean,
        "favorites": boolean,
        "_id": string
    },
  social_links:socialLinks[],
  imgUrl:string,
  projects:string[],
  blockedUser:string[],
  is_downloaded_pin:number,
  is_downloaded_next:number,
  slug_name:string,
  banner_image:any
}

export interface socialLinks{
  "name": string,
  "link": string,
  "_id": string
}
const initialState: UserSlice = {
  id: '',
  countryCode:"",
  firstname: '',
  lastname: '',
  username: '',
  displayName: '',
  bioGraphy: '',
  role: [],
  displayReleases: false,
  displayBeats:false,
  DWPreferences:[],
  blueTick:0,
  receiveMessagesFromAnyone:false,
  publicProfile:false,
  receiveOfferFromAnyone:false,
  profileImg:"",
  country:"",
  language:"",
  phone:"",
  loginHistory:"",
  bannerImg:"",
  banner_image:[],
  notifications:{
        "announcements": false,
        "wallet": false,
        "invites": false,
        "marketplace": false,
        "favorites": false,
        "_id": ""
    },
social_links:[],
imgUrl:"",
projects:[],
blockedUser:[],
is_downloaded_next:0,
is_downloaded_pin:0,
slug_name:""
};


const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action: any) => {
      const { _id, firstname, lastname, username, display_name, biography, role, display_releases,display_beats,DW_preferences,banner_image,
        is_bluetick,receive_messages_from_anyone, public_profile,receive_offer_from_anyone,country,language,notifications,social_links,phone,countryCode,slug_name
       } = action.payload;
        state.id = _id,
        state.firstname = firstname,
        state.lastname = lastname,
        state.username = username,
        state.displayName = display_name,
        state.bioGraphy = biography,
        state.role = role,
        state.displayReleases = display_releases,
        state.displayBeats = display_beats,
        state.DWPreferences = DW_preferences,
        state.blueTick = is_bluetick,
        state.receiveMessagesFromAnyone = receive_messages_from_anyone,
        state.publicProfile = public_profile,
        state.receiveOfferFromAnyone = receive_offer_from_anyone;
        state.profileImg= action.payload?.profile_image?.[0]?.src ?`${process.env.REACT_APP_IMGURL}/${action.payload?.profile_image?.[0]?.src}` : "",
        state.country = country,
        state.language = language,
        state.bannerImg=action.payload?.banner_image?.[0]?.src?`${process.env.REACT_APP_IMGURL}/${action.payload?.banner_image?.[0]?.src}` : "",
        state.notifications=notifications?.[0]
        state.social_links=social_links || [],
        state.phone=phone,
        state.banner_image=banner_image||[""],
        state.slug_name=slug_name,
        state.countryCode=countryCode,
        state.imgUrl=action.payload?.profile_image?.[0]?.src,
        state.is_downloaded_pin = action.payload?.is_downloaded_pin,
        state.is_downloaded_next=action.payload?.is_downloaded_next
    },
    setLoginHistory: (state, action: any) => {
      state.loginHistory=action.payload
    },
    setProjects: (state, action: any) => {
      state.projects=action.payload
    },
    setBlokedUser: (state, action: { payload: string[] }) => {
      state.blockedUser=action.payload
    }
    
  },
});


export const { setUserData , setLoginHistory,setProjects,setBlokedUser} = userSlice.actions;


export const userReducer = userSlice.reducer;
