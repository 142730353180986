import React, { useEffect } from "react";
import { DropworksButton } from "../../../common/custom-field/dropworks-button";
import { FcGoogle } from "react-icons/fc";
import { GoogleSignInButtonProps } from "../guest-pages/login/modal";

// Extend the global window object to include Google Identity Services
declare global {
  interface Window {
    google: {
      accounts: {
        id: {
          initialize: (config: {
            client_id: string;
            callback: (response: { credential: string }) => void;
          }) => void;
          prompt: () => void;
        };
      };
    };
  }
}

const GoogleSignInButton: React.FC<GoogleSignInButtonProps> = ({isLogin=false}) => {
  useEffect(() => {
    // Dynamically load the Google Identity Services script
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.google && window.google.accounts) {
        // Initialize Google Sign-In once the script loads
        window.google.accounts.id.initialize({
          client_id:'386544391519-ui5ioms22te2a9a62ajjodgv8hnhavpm.apps.googleusercontent.com', 
          callback: handleCredentialResponse,
        });
      }
    };

    return () => {
      // Clean up the script
      document.body.removeChild(script);
    };
  }, []);

  const handleCredentialResponse = (response: { credential: string }) => {
    console.log("Encoded JWT ID token: ", response.credential);
    // Process the token as needed
  };

  const handleButtonClick = () => {
    if (window.google && window.google.accounts) {
      window.google.accounts.id.prompt(); // Triggers the Google Sign-In popup
    }
  };

  return (
    <DropworksButton
      onClick={handleButtonClick}
      className="login_register_google  font-weight-600 mb-0 "
    >
      {" "}
      <FcGoogle className="login_icon" /> Sign {isLogin ?'in':"up"} with Google
    </DropworksButton>
  );
};

export default GoogleSignInButton;
