import { ChangeEvent, FC, useState } from "react";
import { view } from "./view";
import axios from "axios";
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  message?:(value:string)=>void;
  onFileUploaded:(data:any)=>any
  userData:{name:string,value:string};
  onFileSizeError:(data:boolean)=>void
}

export const DropworksMessageInput:FC<InputProps>=({ message,userData,onFileUploaded, ...props })=>{
    const [inputValue, setInputValue] = useState("");
    const handleInputChange = (
      e?: ChangeEvent<HTMLTextAreaElement> | string,
    ) => {
      if (typeof e === "string") {
        setInputValue((pre:string)=>pre+e); // Directly set the string value
      } else {
        setInputValue(e?.target?.value ? e.target.value : "");
        const lines = e?.target?.value ? e.target.value.split("\n").length : "";
      }
    };
  
  
    const clearInput = () => {
      setInputValue("");
    };

    // const sendMessage = () => {
    //   props.message && props.message(inputValue);
    //   setInputValue("");
    // };

    const handleSendMessage = () => {
      if (message) {
        message(inputValue);
      }
      setInputValue("");
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault(); // Prevent line break
        handleSendMessage();
      }
    };
    let uploadLink=userData ?`${process.env.REACT_APP_URL}/upload-file-chat?${userData.name}=${userData.value}`:"hello";
  
    const callApiforfileupload=async(uploadedFile:any)=>{
      onFileUploaded("")
      const formData = new FormData();
      formData.append('uploadingFile',uploadedFile);
      try{
        const uploadResponse = await axios.post(uploadLink,formData,
          {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem('dropworksToken')}`,
              },
          }); 

        if(uploadResponse.data.success)
        {
          onFileUploaded(uploadResponse.data.file_data)
        }
        else{
          console.error("Else Error in response of uploading image", uploadResponse.data.message);
        }

    }
    catch (err){
        console.log(err,"iun uploading file image")
    }
        }


    return view({handleInputChange,handleKeyDown,inputValue,onFileUploaded,clearInput,userData,callApiforfileupload,sendMessage: handleSendMessage,...props});
}