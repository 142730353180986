import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import "./styles.scss";
import { DropworksPassword } from "../../../../../common/custom-field/dropworks-password";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { FC } from "react";
import { useForm } from "react-hook-form";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import { WalletPin } from "./modal";
import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { HrLineMD } from "../../../../../common/hr-line";
import { CopyBox } from "../../../../../common/copy-box";
import { DropworksInput } from "../../../../../common/custom-field/dropworks-input";
import QRCode from "react-qr-code";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";
export const view: FC<WalletPin> = ({
  errors,
  isSubmitted,
  loading,
  register,
  onSubmit,
  handleSubmit,
  closeModalRef,
  openModal,
}) => {
  const { watch } = useForm({
    defaultValues: {
      pin: "",
    },
  });
  const { receiverAddress } = useSelector((state: RootState) => state.auth);
  return (
    <ModalWrapper
      modalId="WalletDropstocksDepositModal"
      onBackdropClick={openModal}
    >
      <div
        className="modal fade"
        id="WalletDropstocksDepositModal"
        aria-hidden="true"
        aria-labelledby="WalletDropstocksDepositModal"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content ">
            <div className="modal-cross-icon">
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header">
              <Heading3 className="modal-title uni-mb-16">
                DropStocks Deposit
              </Heading3>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body uni-mb-56">
                <TextfieldLG className=" ctm-txt ctm-md-lh uni-fw-700 m-0">
                  Account deposit of DropStocks
                </TextfieldLG>
                <TextfieldXSM className="ds-ctm-txt uni-mb-56">
                  Use the details below to perform the deposit safely. Make sure
                  the address and the network are the same, otherwise you may
                  not receive the DropStocks.
                </TextfieldXSM>
                <div className="align-items-center parent-qr">
                  <div className="bg-light uni-p-24 border-radius-10">
                    <QRCode
                      value={receiverAddress || "not found"}
                      size={152}
                      level="H"
                    />
                  </div>
                  <div className=" w-100 ">
                    <TextfieldLG className="gray4 uni-fw-700 uni-mb-16 mt-0">
                      Account Address
                    </TextfieldLG>
                    <CopyBox
                      showButton={false}
                      text={receiverAddress}
                      className="uni-mb-16 w-100"
                    />
                  </div>
                </div>
                <div className="uni-mt-32">
                  <TextfieldLG className="gray4 uni-fw-700">
                    Network
                  </TextfieldLG>
                  <DropworksInput
                    disabled={true}
                    placeholder="Polygon POS"
                    className={`uni-mt-16 form-control login-cstm-pass `}
                  />
                </div>
              </div>
              <div className="modal-footer mbl-modal-footer ps-0 pt-0 pb-0 uni-pr-72">
                {loading ? (
                  <Loader className="uni-mt-16" isWhiteLoader={true} />
                ) : (
                  <>
                    <CloseModalButton className="mbl-anchor-full-btn">
                      <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 mbl-full-width-btn">
                        Cancel
                      </DropworksSmallButton>
                    </CloseModalButton>
                    <CloseModalButton className="mbl-anchor-full-btn">
                      <DropworksSmallButton className="btn-wid m-0 my-0 mbl-full-width-btn">
                        Confirm
                      </DropworksSmallButton>
                    </CloseModalButton>
                  </>
                )}
              </div>
            </form>
            <CloseModalButton ref={closeModalRef} className="d-none">
              close
            </CloseModalButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
