import React, { FC } from 'react'

const ProgressBar:FC<any> = ({className,className2,uploadProgress}) => {
  return (
    <div className="progress" style={{  marginTop: '10px' }}>
    <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${uploadProgress}%` }}
        aria-valuenow={uploadProgress}
        aria-valuemin={0} // Changed to number
        aria-valuemax={100} // Changed to number
    >
        {uploadProgress}%
    </div>
</div>
  )
}

export default ProgressBar;