import { TextfieldLG, TextfieldMD, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksDisableButton, DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import "./styles.scss";
import iconImg from "../../../../../assets/img/upload-icon.png";
import { useEffect, useState } from 'react';
import { CropperTool } from '../../../../../common/cropper-tool/view';
import { useDispatch } from 'react-redux';
import { setUploadSettingImg, setUploadSettingImgAPI } from '../../../../../redux/reducer/uploadSlice';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import axios from 'axios';

const view = () => {
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [isCropperOpen, setCropperOpen] = useState(false);

const dispatch =useDispatch();



  const handleImageCrop = (croppedImage: any) => {
    setCroppedImage(croppedImage);


  };

  const saveCall= async ()=>{
    dispatch(setUploadSettingImgAPI(""));
    dispatch(setUploadSettingImg(croppedImage));
    try {
      const response = await fetch(croppedImage);
      const blob = await response.blob();
 
      const fileName = `${Date.now()}.jpg`;
 
      const file = new File([blob], fileName, { type: blob.type });
 
      const formData = new FormData();
 
      formData.append('uploadingFile',file);
 
  try{
    const uploadResponse = await axios.post(`${process.env.REACT_APP_URL}/upload-file`,formData);  
    if(uploadResponse.data.success)
    {
      dispatch(setUploadSettingImgAPI(uploadResponse.data.file_data));
    }
    else{
      console.error("Else Error in response of uploading image", uploadResponse.data.message);
    }
  }   
  catch(error){
    console.error("Catch Error in response of uploading image", error);
  }
 
 
    } catch (error) {
      console.error("Error processing the cropped image:", error);
    }      
    setCroppedImage(null)
  }

  return (
    <div
      className="modal fade"
      id="settinguppopup"
      aria-hidden="true"
      aria-labelledby="settinguppopup"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width m-auto ">
        <div className="modal-content modal-width-upload-banner">
          <div className='modal-cross-icon' onClick={()=>{setCroppedImage(null); setCropperOpen(false)}}><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16' /></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className="modal-title">Upload your Profile Picture</Heading3>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-mb-56">
            <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh ">Use a 1:1 high resolution picture</TextfieldLG>
            <TextfieldXSM className='uni-mb-56 ds-ctm-txt  modal-second-head-font'>(Note that the image must be at least 1500px diemension.)</TextfieldXSM>


            <div className="uploadBanner_uploadbox mx-0 my-0 position-relative overflow-hidden">
              {croppedImage == null ? (
                <CropperTool
                  onClose={() => setCropperOpen(false)}
                  onImageCrop={handleImageCrop}
                  iconImg={iconImg}
                  uploadText="Upload"
                  id="abc"
                />
              ) : (
                <img src={croppedImage} className="cursor-pointer w-100 h-100" style={{borderRadius:"10px"}} />
              )}
            </div>
          </div>
          <div className="modal-footer mbl-modal-footer ps-0 pt-0 pb-0 ">
            <CloseModalButton className="mbl-anchor-full-btn">
              <DropworksSmallButton className="btn-wid mbl-full-width-btn btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16" onClick={()=>setCroppedImage(null)}>Cancel</DropworksSmallButton>
            </CloseModalButton>

            {croppedImage == null ? <DropworksSmallButton className="btn-wid m-0 disabled-btn social-link-modal-btns" disabled>Save</DropworksSmallButton> : <>
              <CloseModalButton className="mbl-anchor-full-btn">
                <DropworksSmallButton className="btn-wid m-0 mbl-full-width-btn" onClick={saveCall}>Save</DropworksSmallButton>
              </CloseModalButton>
            </>}



          </div>
        </div>
      </div>
    </div>
  )
}

export default view;

