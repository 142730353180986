import { TextfieldLG } from "../../../../../common/custom-field/dropstock-text";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { BlueTik } from "../../../../../common/all-icons";
import hankeyman from "../../../../../assets/img/hankeyman.png";
import { closeModal, CloseModalButton } from "../../logo-header/view";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useState } from "react";
import { updateInvitedCollaboratorsTrackProject } from "../../../utils/apiServices";
import { AppDispatch } from "../../../../../redux/store";
import { fetchRereleaseTrackDetails, fetchTrackDetails, getALlInvitedUserbyRereleaseTrackProject, getALlInvitedUserbyTrackProject } from "../../../../../redux/reducer/uploadSlice";
import Loader from "../../loader/view";
import { useNavigate } from "react-router-dom";
const view = () => {
  const {selectedCollaborator,trackSlugname}=useSelector((state:RootState)=>state.upload);
 const path=window.location.pathname
  const [loading,setLoading]=useState<boolean>(false);
  const navigate=useNavigate()
  const dispatch =useDispatch<AppDispatch>(); 
  const [message,setMessage]=useState<string>("")
  const removeCollaborator=async()=>{
    setLoading(true);
    const data={track_id:selectedCollaborator.track_id,remove:"remove",invited_id:selectedCollaborator._id};
    const response= await updateInvitedCollaboratorsTrackProject(data);
if(response.success)
{
if(path.includes("track-project-overview")){
  dispatch(getALlInvitedUserbyTrackProject(selectedCollaborator.track_id));
  dispatch(fetchTrackDetails(trackSlugname));
}
else if(path.includes("track-project-rerelease")){
  dispatch(getALlInvitedUserbyRereleaseTrackProject(selectedCollaborator.track_id));
    dispatch(fetchRereleaseTrackDetails(trackSlugname));
}

closeModal();
setLoading(false);
}
else{
  setLoading(false);
  setMessage(response.message)
}
  }
  return (
    <div
      className="modal fade"
      id="RemoveCollaborator"
      aria-hidden="true"
      aria-labelledby="RemoveCollaborator"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width">
        <div className="modal-content ">
        <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header uni-mb-40 p-0">
            <h1 className="modal-title">Remove {selectedCollaborator?.display_name} </h1>
          </div>
          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
          <CloseModalButton>
            <div className="d-flex align-items-center uni-mb-32 invite_firstcolumn cursor-pointer" onClick={()=>navigate(`/user-profile/${selectedCollaborator?.slug_name}`)}>
              <div>
                <img className="removeCollaborator-img" src={`${process.env.REACT_APP_IMGURL}/${selectedCollaborator?.profile_image?.[0]?.src}`}  alt="" />
              </div>
              <div>
                <h2 className="fw-700 displayname text-capitalize">
                {selectedCollaborator?.display_name}
                </h2>
              </div>
            </div>
            </CloseModalButton>

            <TextfieldLG className="remove-content">
              You are about to remove {selectedCollaborator?.receiver_name} from this project. Do you want to continue?
            </TextfieldLG>
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            <CloseModalButton>
              <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16">
                Cancel
              </DropworksSmallButton>
            </CloseModalButton>
            <CloseModalButton>
            {loading?<Loader className="uni-mt-16"/>:
              <DropworksSmallButton className="btn-wid m-0" onClick={removeCollaborator}>
                Remove
              </DropworksSmallButton>}
            </CloseModalButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
