import { useNavigate, useParams } from "react-router-dom"
import { view } from "./view"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearIsLike, setIsLiked } from "../../../redux/reducer/beatSlice";
import { setbeatModalId, setUserModalId } from "../../../redux/reducer/reportSlice";
import { fetchBeatDataThunk, setBeatData } from "../../../redux/reducer/uploadSlice";
import { setMusicPageData } from "../../../redux/reducer/musicSlice";
import { RootState } from "../../../redux/store";

export const BeatesPage = () => {
  const navigate = useNavigate();
  const slugName = useParams();
  const slugValue=slugName.slugName;
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(true)
  const user = useSelector((state: RootState) => state.user);
  const {musicPlayer,musicPageData} = useSelector((state: RootState) => state.music);
  const {beatData} = useSelector((state: RootState) => state.upload);


  useEffect(() => {
    const fetchBeatData = async () => {
      if (slugName) {
        // const response: any = await getBeat(slugName.slugName);
           dispatch(clearIsLike());
        try {
          dispatch(fetchBeatDataThunk(slugValue) as any)
          .then((res: any) => {
            if(Boolean(res?.payload?.length))
            {
              dispatch(setIsLiked(res?.payload?.[0]?.favoriteData?._id ? true : false))
              dispatch(setUserModalId(res?.payload?.[0]?.user_id?._id));
              dispatch(setMusicPageData({
                musicPageName:res?.payload?.[0]?.beat_name,
                musicPageAudioSrc:res?.payload?.[0]?.audio_file?.[0]?.src,
                musicPageImgSrc:res?.payload?.[0]?.art_work_image?.[0]?.thumbnail || res?.payload?.[0]?.art_work_image?.[0]?.src,
                showPageOptions:false,
                musicPageLiked:Boolean(res?.payload?.[0]?.favoriteData?._id),
                showPageMusicBar:false,
                musicPageCreator:res?.payload?.[0]?.user_id?.displayName,
                musicPageOwnedByMe:res?.payload?.[0]?.user_id?._id === user.id,
                musicPageDescription:res?.payload?.[0]?.description,
                musicPageId:res?.payload?.[0]?._id,
                musicPagePath:`beats-page/${res?.payload?.[0]?.beat_slug_name}`,
                musicPageType:"beat"
              }))
              setLoading(false);
            }
            else{
      navigate("/PageNotFound")
            }
          })
        } catch (error) {
          console.log("Error fetching beat data:", error);
          navigate("/PageNotFound")
          setLoading(false);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchBeatData();
  }, [slugValue]);
 
  useEffect(()=>{
    if(musicPageData.musicPageId ===musicPlayer.musicId){
  dispatch(setBeatData([{...beatData?.[0],favoriteData:musicPlayer.musicLiked?{_id:"abc"}:""}]))
    }
  },[musicPlayer.musicId,musicPlayer.musicLiked])

  const navigateTo = (value: string) => () => {
    navigate(value)
  }

  return (view({ navigateTo, loading }))
}