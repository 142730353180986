import { useRef, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { view } from "./view";
import { setWalletPin } from "../../../utils/apiServices";
import { useNavigate } from "react-router-dom";
import { openPopup } from "../../logo-header/view";
import { useDispatch } from "react-redux";
import { setWalletpinset } from "../../../../../redux/reducer/auth";

export const OneTimePinModal = () => {
  const [loading, setLoading] = useState(false);
  const closeModalRef = useRef<HTMLAnchorElement>(null);
  const navigate = useNavigate();
  const navigateTo = (value: string) => () => {
    navigate(value);
  };
  const schema4 = yup.object({
    pin: yup.string().min(4, "The PIN must contain exactly 4 or 6 digits. Letters and special characters are not allowed.").max(6,"The PIN must contain exactly 4 or 6 digits. Letters and special characters are not allowed.").required("New Pin is required").matches(/^\d{4}$|^\d{6}$/, "Pin should be 4 or 6 digits."),
    confirmPin: yup
      .string()
      .oneOf([yup.ref("pin")], "Confirm Pin must match")
      .required("Confirm Pin is required"),
  });

  const {
    register,
    handleSubmit,
    setError,
    reset,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm<any>({
    resolver: yupResolver(schema4),
    mode: "onSubmit",
  });
const dispatch=useDispatch();
  const onSubmit = async (data: any) => {
    setLoading(true)
    const response: any = await setWalletPin({ data });
    if (response.success) {
      if (closeModalRef.current) {
        closeModalRef.current.click();
      }
      dispatch(setWalletpinset(true))
      setLoading(false)
      openPopup("downloadPinOneTime",dispatch)
    }
    else {
      setLoading(false);
      setError(response.type || "pin" || "confirmPin", { type: "custom", message: response.message });
    }
  };

  const openModal = () => {
    reset()
    setValue("pin", "");
    setError("pin", { type: "custom", message: "" });
    setValue("confirmPin", "");
    setError("confirmPin", { type: "custom", message: "" });
    setLoading(false);
  }
  return view({ errors, isSubmitted, loading, register, onSubmit, handleSubmit, navigateTo, closeModalRef, openModal });
}
export default OneTimePinModal