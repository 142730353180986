import { TextfieldLG, TextfieldMD, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import "./styles.scss";
import { DropworksSwitch } from '../../../../../common/custom-field/dropworks-switch';
import iconImg from "../../../../../assets/img/upload-icon.png";
import { DropworksSearchInput } from '../../../../../common/custom-field/dropworks-search-input';
import { closeModal, CloseModalButton } from '../../logo-header/view';
import { Heading3 } from '../../../../../common/custom-field/dropstock-heading';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useState } from 'react';
import { updateInvitedCollaboratorsTrackProject } from '../../../utils/apiServices';
import { getALlInvitedUserbyRereleaseTrackProject, getALlInvitedUserbyTrackProject } from '../../../../../redux/reducer/uploadSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../../redux/store';
import Loader from '../../loader/view';
import ModalWrapper from '../../../../../common/open-modal/ModalWrapper';

const view = () => {
  const {selectedCollaborator}=useSelector((state:RootState)=>state.upload);
  const [changeName,setChangeName]=useState("");
  const path=window.location.pathname
  const [message,setMessage]=useState<string>("")
  const dispatch =useDispatch<AppDispatch>(); 
  const [loading,setLoading]=useState<boolean>(false);
  const changeAliasName=async()=>{
    setLoading(true);
    const data={track_id:selectedCollaborator.track_id,change_alias:changeName,invited_id:selectedCollaborator._id};
    const response= await updateInvitedCollaboratorsTrackProject(data);
if(response.success)
{
  if(path.includes("track-project-overview")){
    dispatch(getALlInvitedUserbyTrackProject(selectedCollaborator.track_id));
  }
  else if(path.includes("track-project-rerelease")){
    dispatch(getALlInvitedUserbyRereleaseTrackProject(selectedCollaborator.track_id));
  }



closeModal();
setLoading(false);
}
else{
  setLoading(false);
  setMessage(response.message)
}
  }
  const openModal=()=>{
    setChangeName("")
}
  return (
    <ModalWrapper modalId="ChangeAlias" onBackdropClick={openModal}>
    <div
      className="modal fade"
      id="ChangeAlias"
      aria-hidden="true"
      aria-labelledby="ChangeAlias"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered custom-modal-width ">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72">
            <Heading3 className="modal-title">Change Alias</Heading3>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-pr-72 uni-mb-56">
            <TextfieldLG className=" uni-mt-16 ctm-txt ctm-md-lh uni-mb-56">Enter the desired release name for {selectedCollaborator.receiver_name}</TextfieldLG>
              <DropworksInput value={changeName} onChange={(e:any)=>{setMessage("");setChangeName(e.target.value)}} placeholder={selectedCollaborator.display_name || '#ARTISTNAME'}></DropworksInput>
              {message && <p className=' text-danger invalid-feedback'>{message}</p>}
          </div>
          <div className="modal-footer ps-0 pt-0 pb-0 uni-pr-72">
            {loading?<Loader className="uni-mt-16"/>:<DropworksSmallButton className={changeName ?"btn-wid  m-0":"btn-wid m-0 custom-inactive-btn text-secondary disable-interactions"} onClick={changeAliasName}>Change</DropworksSmallButton>}
          </div>
        </div>
      </div>
    </div>
    </ModalWrapper>
  )
}

export default view;

