import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import "./styles.scss"
import { ButtonComponent } from '../../logo-header/view';
import { Heading3, Heading4 } from '../../../../../common/custom-field/dropstock-heading';
import { DropworksRadioCheckbox } from '../../../../../common/custom-field/dropworks-radio-checkbox';
import { useState } from 'react';
import ModalWrapper from '../../../../../common/open-modal/ModalWrapper';

const view = (somethingaboutreport:any,selectedReason:any,setSelectedReason:any) => {

  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const handleReasonSelect = (reason: string) => () => {
    setSelectedReason(reason); 
    setIsNextDisabled(false);
  };
  const openModal=()=>{
    setSelectedReason("")
  }
 
  return (
    <ModalWrapper modalId="ImpersonatingReportModal" onBackdropClick={openModal}>
    <div
      className="modal fade"
      id="ImpersonatingReportModal"
      aria-hidden="true"
      aria-labelledby="ImpersonatingReportModal"
      tabIndex={-1}
      data-bs-theme="dark"
    >
      <div className="modal-dialog modal-dialog-centered uni-md-popup">
        <div className="modal-content ">
    <div className='modal-cross-icon'><CloseModalButton><img src={crossIcon} className='fs-1 uni-mr-16'/></CloseModalButton></div>
          <div className="modal-header ps-0 pt-0 uni-pr-72 uni-mb-40">
            <Heading4 className="modal-title-fs-32">Who is this user pretending to be</Heading4>
          </div>

          <div className="modal-body ps-0 pt-0 pb-0 uni-mb-56">
            <p className='d-flex justify-content-between align-items-center uni-p-16 p-lh uni-mb-8 popup-space-mobile' onClick={handleReasonSelect("You")} style={{ cursor: 'pointer' }}>
              <TextfieldSM className=" font-col-wh my-0 mx-0">You</TextfieldSM>
              <DropworksRadioCheckbox checked={"You"===selectedReason} />
            </p>
            <p className='d-flex justify-content-between align-items-center uni-p-16 p-lh uni-mb-8 popup-space-mobile' onClick={handleReasonSelect("Someone else")} style={{ cursor: 'pointer' }}>
              <TextfieldSM className=" font-col-wh my-0 mx-0">Someone else</TextfieldSM>
              <DropworksRadioCheckbox checked={"Someone else"===selectedReason} />
            </p>
          </div>
          <div className="modal-footer  mbl-modal-footer ps-0 pt-0 pb-0">
          <ButtonComponent id="UserReportReasonModal" className="mbl-anchor-full-btn">
            <DropworksSmallButton className="btn-wid-impersonating btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 mbl-full-width-btn">Back</DropworksSmallButton>
            </ButtonComponent>
            <ButtonComponent id="ThankYouModal" className="mbl-anchor-full-btn">
            <DropworksSmallButton className="btn-wid-impersonating mbl-full-width-btn m-0" onClick={somethingaboutreport}>Next</DropworksSmallButton>
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
    </ModalWrapper>
  )
}

export default view;


