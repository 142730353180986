import { FC, useState } from "react";
import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import crossIcon from "../../../../../assets/steps/modal-cross-icon.svg";
import { DropworksPassword } from "../../../../../common/custom-field/dropworks-password";
import { ButtonComponent, CloseModalButton } from "../../logo-header/view";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import {
  TextfieldLG,
  TextfieldXSM,
} from "../../../../../common/custom-field/dropstock-text";
import { PhraseFromData } from "./modal";
import "./styles.scss";
import "../popup.scss";
import { useForm } from "react-hook-form";

export const view: FC<PhraseFromData> = ({
  errors,
  isSubmitted,
  loading,
  register,
  onSubmit,
  handleSubmit,
  closeModalRef,
  openModal,
  changePinBy,
}) => {
  const { setValue, reset } = useForm();
  const [phrase, setPhrase] = useState<string[]>(Array(12).fill(""));
  const isNotEmpty = phrase.some((item) => item.trim() == "");
  const handleInputChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedPhrase = [...phrase];
      updatedPhrase[index] = event.target.value;
      setPhrase(updatedPhrase);
    };

  // Handle pasting a phrase into the input fields
  const handlePaste = (
    e: React.ClipboardEvent<HTMLInputElement>,
    index: number
  ) => {
    const pastedText = e.clipboardData.getData("Text");
    const newPhrase = pastedText.split(" ");

    
    // Update the phrase state with the new words
    let updatedPhrase = [...phrase];
   if(newPhrase.length>1)
   {

     newPhrase.forEach((word, i) => {
      if (i < 12) {
        updatedPhrase[i] = word;
      }
    });
  }
  else{
    console
    updatedPhrase[index]=newPhrase[0];
  }
    // setPhrase(Array(12).fill(""));
    setTimeout(() => {
      setPhrase(updatedPhrase);
    }, 300);
   
  };

  const onNext = () => {
    const isValid = phrase.every(
      (item) => typeof item === "string" && item.trim() !== ""
    );
    // Concatenate all elements into a single string with a space between each
    const concatenatedString = phrase.filter((item) => item !== "").join(" ");
    if (isValid) {
      setValue("phrase", concatenatedString);
      const data = {
        phrase: concatenatedString,
      };
      onSubmit(data);
      reset();
    }
  };

  const callOnBackdrop = () => {
    openModal();
    setPhrase(Array(0).fill(""));
    setTimeout(() => {
      setPhrase(Array(12).fill(""));
    }, 300);
  };
console.log(phrase,"phrase")
  return (
    <ModalWrapper modalId="VerifyPhrase" onBackdropClick={callOnBackdrop}>
      <div
        className="modal fade"
        id="VerifyPhrase"
        aria-hidden="true"
        aria-labelledby="VerifyPhrase"
        tabIndex={-1}
        data-bs-theme="dark"
      >
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content ">
            <div
              className="modal-cross-icon"
              onClick={() => setPhrase(Array(12).fill(""))}
            >
              <CloseModalButton>
                <img src={crossIcon} className="fs-1 uni-mr-16" />
              </CloseModalButton>
            </div>
            <div className="modal-header ps-0 pt-0">
              <Heading3 className="modal-title">
                Secret Recovery Phrase
              </Heading3>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body ps-0 pt-0 pb-0 uni-mb-56">
                <TextfieldLG className="uni-mt-16 ctm-txt ctm-md-lh">
                  DropWorks will use your secret
                  recovery phrase to validate your ownership, restore your
                  wallet, and set up a new password. First, enter the secret
                  recovery phrase that you were given, when you created your
                  wallet.
                </TextfieldLG>
                <TextfieldLG className="uni-mt-16 ctm-txt ctm-md-lh">
                  You can paste your entire secret recovery phrase into any
                  field.
                </TextfieldLG>
                <TextfieldXSM className="uni-mb-56 ds-ctm-txt">
                  Remember, never share your PIN with anyone
                </TextfieldXSM>

                <div className="custom-confirm-pass-space1">
                  {phrase.map((word, index) => (
                    <>
                      <div key={index} className="input-group">
                        <span className="index-label">{`${index + 1}.`}</span>
                        <DropworksPassword
                          autoComplete={"off"}
                          value={word}
                          onChange={handleInputChange(index)}
                          onPaste={(e) => handlePaste(e, index)}
                          placeholder="Enter Phrase"
                          className="phrase-input "
                          type="text"
                        />
                      </div>
                    </>
                  ))}
                  {errors.phrase && (
                    <p className="invalid-feedback error-red">
                      {errors.phrase.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="modal-footer mbl-modal-footer ps-0 pt-0 pb-0 ">
                {loading ? (
                  <Loader className="uni-mt-16" />
                ) : (
                  <>
                    <ButtonComponent id="SelectPinTypeModal" className="mbl-anchor-full-btn">
                      <DropworksSmallButton className="btn-wid btn-bg-gray3 my-0 ml-0 uni-mr-16 uni-mr-16 mbl-full-width-btn">
                        Back
                      </DropworksSmallButton>
                    </ButtonComponent>{" "}
                    <DropworksSmallButton
                      className="btn-wid m-0 my-0 mbl-full-width-btn"
                      onClick={onNext}
                      disabled={isNotEmpty}
                    >
                      Next
                    </DropworksSmallButton>
                  </>
                )}
              </div>
            </form>
            <CloseModalButton ref={closeModalRef} className="d-none">
              close
            </CloseModalButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
