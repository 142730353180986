import { FC, useEffect, useState } from "react";
import  view  from "./view";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { getTrackProjectBeatsDetailsById, reportProject, reportTrack } from "../../../utils/apiServices";
import { useParams } from "react-router-dom";



export const ProjectReportModal:FC<any>=()=>{

    const projectDataid = useSelector((state: RootState) => state.report.projectModalId);
    const [projectData , setProjectData] = useState<any>()
    const [projectReport, setProjectReport] = useState();
    const [projectreportReason, setProjectReportReason] = useState<string>("");
    const projectId = projectData; 
    const projectPage = useSelector((state: RootState) => state.report.beatPage);
  
    
    const reportedProject = async(sendReason:string) => {
        try {
          const result = await reportProject(projectDataid, {
            report_type: "Recent messages this user sent you", 
            reason: sendReason, 
          });
          if (result.success) {
            setProjectReport(result.reportedUser.data);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

    return (view(reportedProject,projectreportReason,setProjectReportReason,projectPage));
}
export default ProjectReportModal