import  { FC, useState } from "react";
import { FaPlay, FaPause, } from "react-icons/fa";
import "./styles.scss";
import audioIcon from '../../../../assets/steps/Audio_file_in_chat.svg';
import { PiCheckBold, PiChecksBold } from "react-icons/pi";
import doubletick2 from '../../../../assets/steps/Double-tick2.svg';
import CircularProgressBar from "../../../content/messages-chat/downloadProgressbar";
export const view: FC<any> = ({
  src,
  togglePlay,
  handleTimeUpdate,
  handleLoadedMetadata,
  formatTime,
  audioRef,
  isPlaying,
  currentTime,
  duration,
  is_recieved=false,
  messageTime="",
  seen_unseen=1,
  setDuration,
  allAudioData
}) => {
 const [isDownloaded,setIsDownloaded]=useState(false);
  const [progressValue,setProgressValue]=useState<number>(0);
  const renderDeliveryStatus = () => {
    switch (seen_unseen) {
      case 3:
        return <img src={doubletick2} className="dobule-tick" alt="Delivered" />;
      case 2:
        return <PiChecksBold className="uni-ml-8 " />;
      case 1:
        return <PiCheckBold className="uni-ml-8" />;
      default:
        return <PiCheckBold className="uni-ml-8" />;
    }
  };
  const handleDownload = async ()=> {
    const fileUrl = `${process.env.REACT_APP_IMGURL}/${allAudioData?.is_attached_file?.[0]?.src}`;
    const fileName = src || "download";
    try {
      const response = await fetch(fileUrl);
 
      if (!response.ok) {
        throw new Error("Failed to fetch the file.");
      }
 
      const contentLength = response.headers.get("content-length");
      if (!contentLength) {
        throw new Error("Unable to determine file size.");
      }
 
      const total = parseInt(contentLength, 10); // Total file size in bytes
      let loaded = 0;
 
      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error("Unable to read file stream.");
      }
 
      const chunks: Uint8Array[] = [];
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
 
        if (value) {
          chunks.push(value);
          loaded += value.length;
          const progress = Math.round((loaded / total) * 100);
          if(progress===100)
          {
            setIsDownloaded(true);
          }
          setProgressValue(progress)
        }
      }
 
      const blob = new Blob(chunks);
      const blobUrl = URL.createObjectURL(blob);
 
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  
  return (
    <div className="bhhh">

  <div className="audio-player">
    <img src={audioIcon} className="img-fluid audio-image"/>
      <audio
        ref={audioRef}
        src={src}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
      />
      <button onClick={togglePlay} className="audio-btn">
        {isPlaying ? <FaPause /> : <FaPlay />}
      </button>
      <span className="audio-time-running">{formatTime(currentTime)}</span>

      {/* Progress Bar */}
      <input
        type="range"
        value={currentTime}
        max={duration || 0} 
        onChange={(e) => {
          const newTime = Number(e.target.value);
          if (audioRef.current) {
            audioRef.current.currentTime = newTime;
            handleTimeUpdate();
          }
        }}
        className="audio-progress"
        />

      {/* Timestamps */}
     
      <span className="audio-time">{formatTime(duration)}</span>
     
       <div className="download-icon uni-ml-8">
              <a onClick={()=> handleDownload()}>
                {<CircularProgressBar progress={progressValue} />}
                </a>
            </div>
    </div>
    <div className="message-time fss-16">{!is_recieved && messageTime}{!is_recieved && renderDeliveryStatus()}</div>
        </div>

  );
};
