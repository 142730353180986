import { FC, useEffect, useState } from "react";
import  view  from "./view";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, updateUser } from "../../../utils/apiServices";
import { setUserData } from "../../../../../redux/reducer/userSlice";



export const RegionModal:FC<any>=()=>{
    const dispatch = useDispatch()
    const [selectedCountry, setSelectedCountry] = useState<string>()
    const country = useSelector((state: any) => state.user.country)
    useEffect(() => {
        setSelectedCountry(country)
    }, [country])

    const handleSelectChange = (value: string) => {
        setSelectedCountry(value)
    };
    const openModal=()=>{
        setSelectedCountry(country)  
    }

    const submitData = async (data: { country: string }) => {
        let response: any = await updateUser(data);
        if (response) {
            const result = await getUserData(); // Call your API function
            if (result.success && result.userData) { // Adjust based on your API response
                dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
            }
        } else {
            //console.log("failLLLLLLLLL");
        }
    }

    return view({ country, selectedCountry, handleSelectChange, submitData,openModal });
}
export default RegionModal