import { useEffect, useState } from "react";
import "./styles.scss";
import { TextfieldMD } from "../../../../common/custom-field/dropstock-text";
import { DropworksSwitch } from "../../../../common/custom-field/dropworks-switch";
import Tabs from "../../../../common/tabs";
import {
  ButtonComponent,
  openPopup,
} from "../../../shared/components/logo-header/view";
import {
  Heading2,
  Heading5,
} from "../../../../common/custom-field/dropstock-heading";
import TabsComponent from "../../../../common/tabs-component/view";
import ArrowIcon from "../../../../assets/steps/Arrow -Selector.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { setIsnotificationPage } from "../../../../redux/reducer/uploadSlice";

const view = ({
  displayReleases,
  displayBeats,
  submitData,
  receiveMessagesFromAnyone,
  publicProfile,
  receiveOfferFromAnyone,
  dispatch,
  getBlockedUser,
}: any) => {
  const [currentSection, setCurrentSection] = useState("Profile");
  const { isNotificationPage } = useSelector(
    (state: RootState) => state.upload
  );
  const [tabsValue, setTabValue] = useState(0);
  const [changeTabValue, setChangeTableValue] = useState<number>(0);
  useEffect(() => {
    if (isNotificationPage) {
      setTabValue(3);
      setChangeTableValue(3);
      openPopup("SocialLinksNotification", dispatch);
    }
    return () => {
      dispatch(setIsnotificationPage(false));
    };
  }, []);
  const handleSectionClick = (section: string) => {
    setCurrentSection(section);
  };

  const checkTabState = (value: number) => {
    setTabValue(value);
    setChangeTableValue(value);
  };

  return (
    <div className="invite  container container-xl  container-xxl  min-height-cover  min-h-800">
      <Heading2 className="invite-heading uni-mb-32">Settings</Heading2>
      <div className="invite-button uni-mb-56 mbl-mb-0 d-flex flex-wrap">
        <Tabs
          tabsTitle={[
            "Profile",
            "Personal",
            "Connections",
            "Privacy",
            "Security",
          ]}
          id="1"
          changeTabValue={changeTabValue}
          checkTabState={checkTabState}
        />
      </div>

      <TabsComponent
        tabsValue={tabsValue}
        components={[
          <>
            <div>
              <Heading5 className="uni-mb-56 profile-setting-head  profile-setting-mobile-spacing ">
                General
              </Heading5>
              <ButtonComponent id="DisplayName">
                <div className="d-flex justify-content-between uni-mb-56 align-items-center profile-setting-mobile-spacing outline-remover">
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    Display Name
                  </TextfieldMD>
                  <img src={ArrowIcon} className="arrow-icon-mobile" />
                </div>
              </ButtonComponent>
              <ButtonComponent id="BiographyModal">
                <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    Biography
                  </TextfieldMD>
                  <img src={ArrowIcon} className="arrow-icon-mobile" />
                </div>
              </ButtonComponent>
              <ButtonComponent id="UploadBanner">
                <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    Profile Picture
                  </TextfieldMD>
                  <img
                    src={ArrowIcon}
                    className="chevronright-mobileview arrow-icon-mobile"
                  />
                </div>
              </ButtonComponent>
              <ButtonComponent id="UploadBanner2">
                <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    Profile Banner
                  </TextfieldMD>
                  <img src={ArrowIcon} className="arrow-icon-mobile" />
                </div>
              </ButtonComponent>
              <ButtonComponent id="RolesModal">
                <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    Roles
                  </TextfieldMD>
                  <img src={ArrowIcon} className="arrow-icon-mobile" />
                </div>
              </ButtonComponent>
            </div>
            <div>
              <Heading5 className="uni-mb-56 profile-setting-head  profile-setting-mobile-spacing">
                Artists
              </Heading5>
              <ButtonComponent id="DawsModal">
                <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    DAW Preferences
                  </TextfieldMD>
                  <img src={ArrowIcon} className="arrow-icon-mobile" />
                </div>
              </ButtonComponent>

              <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                  Display Releases
                </TextfieldMD>
                <DropworksSwitch
                  className="switch-icon"
                  checked={displayReleases}
                  onChange={(e: any) => submitData({ display_releases: e })}
                />
              </div>

              <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                  Display Beats
                </TextfieldMD>
                <DropworksSwitch
                  className="switch-icon"
                  checked={displayBeats}
                  onChange={(e: any) => submitData({ display_beats: e })}
                />
              </div>
            </div>
          </>,
          <>
            <div>
              <Heading5 className="uni-mb-56 profile-setting-head  profile-setting-mobile-spacing ">
                General
              </Heading5>
              <ButtonComponent id="RegionModal">
                <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    Country / region
                  </TextfieldMD>
                  <img src={ArrowIcon} className="arrow-icon-mobile" />
                </div>
              </ButtonComponent>
              <ButtonComponent id="DisplayLanguageModal">
                <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    Display Language
                  </TextfieldMD>
                  <img src={ArrowIcon} className="arrow-icon-mobile" />
                </div>
              </ButtonComponent>
              <ButtonComponent id="EmailModal">
                <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    Email
                  </TextfieldMD>
                  <img src={ArrowIcon} className="arrow-icon-mobile" />
                </div>
              </ButtonComponent>
              <ButtonComponent id="MobilePhoneModalA">
                <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    Mobile Phone
                  </TextfieldMD>
                  <img src={ArrowIcon} className="arrow-icon-mobile" />
                </div>
              </ButtonComponent>
            </div>
          </>,
          <>
            <div>
              <Heading5 className="uni-mb-56 profile-setting-head  profile-setting-mobile-spacing ">
                General
              </Heading5>
              <ButtonComponent id="SocialLinksMain">
                <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    Social Links
                  </TextfieldMD>
                  <img src={ArrowIcon} className="arrow-icon-mobile" />
                </div>
              </ButtonComponent>
            </div>
          </>,
          <>
            <div>
              <Heading5 className="uni-mb-56 profile-setting-head align-items-center  profile-setting-mobile-spacing">
                {" "}
                General
              </Heading5>

              <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                  Receive Messages From Anyone
                </TextfieldMD>
                <DropworksSwitch
                  className="switch-icon"
                  checked={receiveMessagesFromAnyone}
                  onChange={(e: any) =>
                    submitData({ receive_messages_from_anyone: e })
                  }
                />
              </div>

              <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                  Public Profile
                </TextfieldMD>
                <DropworksSwitch
                  className="switch-icon"
                  checked={publicProfile}
                  onChange={(e: any) => submitData({ public_profile: e })}
                />
              </div>

              <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                  Receive Offers From Anyone
                </TextfieldMD>
                <DropworksSwitch
                  className="switch-icon"
                  checked={receiveOfferFromAnyone}
                  onChange={(e: any) =>
                    submitData({ receive_offer_from_anyone: e })
                  }
                />
              </div>

              <ButtonComponent id="SocialLinksNotification">
                <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    Notifications
                  </TextfieldMD>
                  <img src={ArrowIcon} className="arrow-icon-mobile" />
                </div>
              </ButtonComponent>
              <ButtonComponent id="BlockedUser1">
                <div
                  onClick={() => getBlockedUser()}
                  className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing"
                >
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    Blocked Users
                  </TextfieldMD>
                  <img src={ArrowIcon} className="arrow-icon-mobile" />
                </div>
              </ButtonComponent>
            </div>
          </>,
          <>
            <div>
              <Heading5 className="uni-mb-56 profile-setting-head  profile-setting-mobile-spacing">
                General
              </Heading5>

              <ButtonComponent id="ChangePassword">
                <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    Password
                  </TextfieldMD>
                  <img src={ArrowIcon} className="arrow-icon-mobile" />
                </div>
              </ButtonComponent>
              <ButtonComponent id="SelectPinTypeModal">
                {/* <ButtonComponent id="OneTimePinModal"> */}
                <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    Wallet Pin
                  </TextfieldMD>
                  <img src={ArrowIcon} className="arrow-icon-mobile" />
                </div>
              </ButtonComponent>
              <ButtonComponent id="SecurityQuestion">
                <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    Security Questions
                  </TextfieldMD>
                  <img src={ArrowIcon} className="arrow-icon-mobile" />
                </div>
              </ButtonComponent>
              <ButtonComponent id="LoginHistorySocials">
                <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    Login History
                  </TextfieldMD>
                  <img src={ArrowIcon} className="arrow-icon-mobile" />
                </div>
              </ButtonComponent>
            </div>
            <div>
              <Heading5 className="uni-mb-56 profile-setting-head  profile-setting-mobile-spacing ">
                Advanced options
              </Heading5>
              <ButtonComponent id="DeleteAccount">
                <div className="d-flex justify-content-between uni-mb-56 align-items-center  profile-setting-mobile-spacing">
                  <TextfieldMD className="mx-0 my-0 font-colour custom-class">
                    Delete Account
                  </TextfieldMD>
                  <img src={ArrowIcon} className="arrow-icon-mobile" />
                </div>
              </ButtonComponent>
            </div>
          </>,
        ]}
      />
    </div>
  );
};

export default view;
