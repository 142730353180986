import React, { FC, useState } from "react";
import "./styles.scss";
import { GoEye, GoEyeClosed } from "react-icons/go";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  showHide: () => void;
  show: boolean;
  className2?: string;
  className3?: boolean;
  autoComplete?: string; // Corrected here
}

export const view: FC<InputProps & { ref?: React.Ref<HTMLInputElement> }> = ({
  className = "",
  className2 = "",
  className3 = "",
  show,
  ref,
  showHide,
  placeholder = "Password",
  autoComplete,
  ...rest
}) => {
  return (
    <div className={`custom_pass_container  ${className2}`}>
      <input
        ref={ref}
        autoComplete={autoComplete} // Corrected here
        className={`custom_pass ${className}`}
        {...rest}
        type={show ? "password" : "text"}
        placeholder={placeholder}
      />
      <span className={`eye-icon text-light ${className3 && "pr-40-imp"}`}>
        {!show ? (
          <GoEye className="text-light" onClick={() => showHide()} />
        ) : (
          <GoEyeClosed onClick={() => showHide()} />
        )}
      </span>
    </div>
  );
};
