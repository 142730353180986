import { useLocation, useNavigate, useParams } from "react-router-dom"
import { view } from "./view"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getProjectByUserID, getUserById, updateUserFevourite } from "../../shared/utils/apiServices";
import { setUserModalId, setUserProfileData } from "../../../redux/reducer/reportSlice";
import { setUserBannerImg } from "../../../redux/reducer/uploadSlice";
import { setProjects } from "../../../redux/reducer/userSlice";
import { RootState } from "../../../redux/store";
import { getformatDate, mixMasterAudio } from "../../shared/utils/localStorage";

export const UserProfile = () => {
  const [userData, setUserData] = useState<any>("")
  const [trackData, setTrackData] = useState<string[]>([])
  const [recentRelaseData, setRecentRelaseData] = useState<string[]>([])
  const [beatsData, setBeatsData] = useState<string[]>([])
  const dispatch = useDispatch();
  const { slug_name } = useSelector((state: any) => state.user);
  const user = useSelector((state: RootState) => state.user)
  const [loading ,setLoading] =useState(true);

  const [beatsarr, setBeatsarr] = useState<any>([
    { heading: "", subheading: "", image: "", beatName: "", fullBeatData: "",id:"" }
  ]);

  const [recentReleasesarr, setRecentReleasesarr] = useState([
    { heading: "", subheading: "", image: "", slugName: "" }
  ]);

  const [trackarr, setTrackarr] = useState([
    { heading: "", subheading: "", image: "", slugName: "",id:"" }
  ]);

  const slugName = useParams()
  const UserId = slugName.slugName;

  const navigate = useNavigate();

  const navigateTo = (value: string) => () => {
    navigate(value)
  }

  const [isHovered, setIsHovered] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const fetchUserData = async (userId: string) => {
    const result = await getUserById(userId);
    if (result.success) {
      setUserData(result.featuredBeatsData?.data?.userData)
      dispatch(setUserProfileData(result.featuredBeatsData?.data?.userData))
      setTrackData(result.featuredBeatsData?.data?.trackData)
      setRecentRelaseData(result.featuredBeatsData?.data?.recentrelasedata)
      setBeatsData(result.featuredBeatsData?.data?.beatsData)
      dispatch(setUserModalId(result.featuredBeatsData?.data?.userData._id))
      dispatch(result.featuredBeatsData?.data?.userData?.banner_image?.[0]?.src ?
        setUserBannerImg(result.featuredBeatsData?.data?.userData?.banner_image) :
        setUserBannerImg(result.featuredBeatsData?.data?.userData?.profile_image))
        setLoading(false)
    }
    else {
      navigate("/PageNotFound");
    }

  };
  const userId = slugName.slugName;
  const location = useLocation()
  useEffect(() => {
    if (userId) {
      if (slug_name == userId) {
        navigate(`/profile`)
      }
      else {
        fetchUserData(userId);
      }


    }



  }, [location])

  useEffect(() => {
    if (Array.isArray(beatsData)) {
      console.log(beatsData)
      setBeatsarr(beatsData?.map((item: any) => (
        {
          heading: item?.beat_name,
          subheading: item?.user_id?.display_name,
          image: item?.art_work_image?.[0]?.thumbnail || item?.art_work_image?.[0]?.src,
          beatName: item?.beat_slug_name,
          artistSlug:item?.user_id?.slug_name,
          fullBeatData: [item],
          userDetails: item?.user_id,
          id:item._id
        }
      )))
    }
  }, [beatsData])

  useEffect(() => {
    if (Array.isArray(recentRelaseData)) {
      setRecentReleasesarr(recentRelaseData?.map((item: any) => (
        {
          heading: item?.track_name,
          subheading: item?.description,
          image: item?.art_work_image?.[0]?.thumbnail || item?.art_work_image?.[0]?.src,
          slugName: item?.slug_name
        }
      )))
    }
  }, [recentRelaseData])
  

  const { musicPlayer, musicPageData } = useSelector((state: RootState) => state.music)
  useEffect(() => {
    if (Array.isArray(trackData)) {
      let updatedData = trackData.map((item: any) => (
        {
          heading: item?.track_name,
          subheading: item?.description,
          image: item?.art_work_image?.[0]?.thumbnail || item?.art_work_image?.[0]?.src,
          slugName: item?.slug_name,
          createrName: item?.collaborators,
          audioUrl: mixMasterAudio(item.upload_file),
          audioLiked: Boolean(item?.favoriteData?._id),
          audioPath: `track-page/${item?.slug_name}`,
          audioName: item?.track_name,
          fullTrackData: [item],
          audioByMe: item?.user_id?._id === user.id,
          id: item?._id,
          trackDetailsReleaseDate: getformatDate(item?.created_at),
          trackDeatilsCreatorList: [item?.creator_user_name],
          trackDetailsUniqueId: item?.unique_id
        }
      ))
      setTrackarr(updatedData);
    }
  }, [trackData])


  useEffect(() => {
    if (musicPlayer.musicType === "track" && Boolean(trackarr.length)) {
      console.log(trackData, "all tRack data")
      let newUpdatedData = trackarr.map((ele) => {
        if (ele?.id && ele?.id === musicPlayer.musicId) {
          return { ...ele, favoriteData: musicPlayer.musicLiked ? { _id: "abc" } : "", audioLiked: musicPlayer.musicLiked }
        }
        else {
          return ele;
        }
      })
      console.log(newUpdatedData, "updated data")

      setTrackarr(newUpdatedData);
    }
    else if (musicPlayer.musicType === "beat" && Boolean(beatsarr.length)) {
      let newUpdatedData = beatsarr.map((ele: any) => {
        if (ele.id && ele.id === musicPlayer.musicId) {
          let newdata = [{ ...ele.fullBeatData?.[0], favoriteData: musicPlayer.musicLiked ? { _id: "abcd" } : "" }];
          return { ...ele, fullBeatData: newdata }
        }
        else {
          return ele;
        }
      })

      setBeatsarr(newUpdatedData);

    }

  }, [musicPlayer.musicId, musicPlayer.musicLiked])

  useEffect(() => {
    if (userData?.favoriteData?.[0]?.is_liked) {
      setIsFollowing(true)
    }
  }, [userData])

  const handleClick = (userId: any) => {
    setIsFollowing((prev) => !prev);
    updateUserFevourite(userId)
  };

  useEffect(() => {
    const fetchProject = async () => {
      const result = await getProjectByUserID();
      if (result.success) {
        dispatch(setProjects(result.getUserProject?.data))
      }
    };

    fetchProject();
  }, [])



  

  return (view({
    navigateTo, userData, beatsarr, recentReleasesarr, trackarr, UserId,
    dispatch, isFollowing, setIsHovered, isHovered,
    handleClick,loading
  }))
}