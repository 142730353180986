import { useNavigate } from "react-router-dom";
import  view  from "./view";
import { FC } from "react";




export const DeleteModal:FC<any>=()=>{
   const navigate=useNavigate();
    
    const navigateTo=(value:string)=>()=>{
        
        navigate(value)
    }
  
   return (view({navigateTo}))

}
export default DeleteModal