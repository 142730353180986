import { FC, useEffect, useRef, useState } from "react";
import  view  from "./view";
import { useDispatch, useSelector } from "react-redux";
import { registerSecondPopup } from "../../../utils/apiServices";
import { setModal1, setModal2 } from "../../../../../redux/reducer/modal";
import { RootState } from "../../../../../redux/store";
import { openPopup } from "../../logo-header/view";



export const Modal2:FC<any>=()=>{
  const [genres, setGenres] = useState<string[]>([]);
  const [loading,setLoading]=useState<boolean>(false);
  const [message,setMessage] =useState<string>("");
  const closeModalRef = useRef<HTMLAnchorElement>(null);
  const dispatch =useDispatch();
  useEffect(()=>{
if(genres.length>2){
    dispatch(setModal2(false));
  }
else{
    dispatch(setModal2(true))
}
  },[genres])

const OpenPopup3= async()=>{
    setLoading(true)
const response:any = await registerSecondPopup(genres);
if(response.success)
    {    
      if (closeModalRef.current) {
      closeModalRef.current.click();
    }
        setLoading(false);
        openPopup("SecureYourAccount",dispatch)

}
else{
setLoading(false);
setMessage(response.message);
setTimeout(()=>{
setMessage("")
},3000)
}
}
  const UpdateRole = (data: string) => {
    if (genres.includes(data)) {
     !loading && setGenres((pre) => pre.filter((ele) => ele !== data));
    } else {
      !loading &&   setGenres([...genres, data]);
    }
  };
  const {modal2} = useSelector((state: RootState) => state.modal);
    return view({ genres, UpdateRole,modal2 ,OpenPopup3,loading,message,closeModalRef});
}
export default Modal2