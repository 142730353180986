import React, { FC, useEffect, useState, Fragment, useRef } from "react";
import { ReretrackFormData, viewType } from "./modal";
import "./styles.scss";
import iconImg from "../../../assets/img/upload-icon.png";
import { DropworksInput } from "../../../common/custom-field/dropworks-input";
import { DropworksTextArea } from "../../../common/custom-field/dropworks-textArea";
import { GoPlus } from "react-icons/go";
import { FiMinus } from "react-icons/fi";
import {
  DropworksButton,
  DropworksDisableButton,
  DropworksNormalButton,
} from "../../../common/custom-field/dropworks-button";
import { DropworksInputIcon } from "../../../common/custom-field/dropworks-input-icon";
import { RiQuestionFill } from "react-icons/ri";
import {
  TextfieldLG,
  TextfieldMD,
  TextfieldSM,
  TextfieldXSM,
} from "../../../common/custom-field/dropstock-text";
import Tabs from "../../../common/tabs";
import { DropworksSelect } from "../../../common/custom-field/dropworks-select";
import { DropworksSwitch } from "../../../common/custom-field/dropworks-switch";
import kingRoland from "../../../assets/img/roland.png";
import { BlueTik, YelloTik } from "../../../common/all-icons";
import { MeatballsMenuDropdown } from "../../../common/icons";
import king from "../../../assets/img/king.png";
import downloadIcon from "../../../assets/steps/download.svg";
import Producer from "../../../assets/img/Producer.png";
import { UserLable } from "../../../common/user-labels";
import rightArrow from "../../../assets/steps/arrow-small-right.svg";
import exclamationTriangle from "../../../assets/steps/exclamation-triangle.svg";
import {
  ButtonComponent,
  openPopup,
} from "../../shared/components/logo-header/view";
import TabsComponent from "../../../common/tabs-component/view";
import {
  Heading1,
  Heading4,
  Heading5,
} from "../../../common/custom-field/dropstock-heading";
import { SelectArrowDropdown } from "../../../common/selection-dropdown";
import CustomTooltip from "../../../common/custom-tooltip/view";
import { trackFormData } from "../track-project-overview/modal";
import {
  createRereleaseTrackProject,
  getFile,
  getMissingStep,
  getMissingStepRerelease,
  updateInvitedCollaboratorsTrackProject,
} from "../../shared/utils/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  fetchRereleaseTrackDetails,
  fetchTrackDetails,
  getALlInvitedUserbyRereleaseTrackProject,
  setSelectedFile,
  setSetelectedCollaborator,
  setTabValueChange,
  setTrackID,
  setTrackSlugName,
  setUploadNewBeatImg,
  setUploadNewBeatImgAPI,
  setUserRole,
} from "../../../redux/reducer/uploadSlice";
import axios from "axios";
import Loader from "../../shared/components/loader/view";
import { useNavigate, useParams } from "react-router-dom";
import { InviteFriends } from "../../../common/invite-friends";
import { setProjectname } from "../../../redux/reducer/projectnameSlice";
import { setuserListData } from "../../../redux/reducer/messageChatSlice";
import { setDeleteModalData } from "../../../redux/reducer/musicSlice";
import { IMaskInput } from "react-imask";
import { dateFormat, filteredArtist } from "../../shared/utils/localStorage";
const view: FC<viewType> = ({ navigateTo }) => {
  const userData = useSelector((state: any) => state.user);
  // const [uploadIndex,setuploadIndex] =useState(0)

  const [MeatballsMenuOptions, setMeatballsMenuOptions] = useState([
    "Change Alias",
  ]);
  const [MeatballsMenuOptions2, setMeatballsMenuOptions2] = useState([
    "Change Alias",
    "Message",
    "Remove",
  ]);
  const [MeatballsMenuOptions21, setMeatballsMenuOptions21] = useState([
    "Change Alias",
    "Message",
  ]);
  const parmsSlug = useParams();
  const [missingStep, setMissingStep] = useState<any>([]);
  const [editTable, setEditTable] = useState(true);

  const GenreOptions = [
    { value: "R&B", label: "R&B" },
    { value: "Hip Hop", label: "Hip Hop" },
    { value: "Pop", label: "Pop" },
    { value: "Electropop", label: "Electropop" },
    { value: "Dance - pop", label: "Dance - pop" },
    { value: "J - pop", label: "J - pop" },
    { value: "K - pop", label: "K - pop" },
    { value: "EDM", label: "EDM" },
    { value: "House", label: "House" },
    { value: "Deep House", label: "Deep House" },
    { value: "Tech House", label: "Tech House" },
    { value: "Progressive House", label: "Progressive House" },
    { value: "Electro House", label: "Electro House" },
    { value: "Techno", label: "Techno" },
    { value: "Peak Time Techno", label: "Peak Time Techno" },
    { value: "Melodic House Techno", label: "Melodic House Techno" },
    { value: "Progressive Techno", label: "Progressive Techno" },
    { value: "Trance", label: "Trance" },
    { value: "Future Bass", label: "Future Bass" },
    { value: "Latina", label: "Latina" },
    { value: "Alterative", label: "Alterative" },
    { value: "LoFi", label: "LoFi" },
    { value: "Ballad", label: "Ballad" },
    { value: "Others", label: "Others" },
  ];

  const [MeatballsMenuOptions3, setMeatballsMenuOptions3] = useState([
    "Delete",
  ]);
  const [tabsValue, setTabValue] = useState(0);
  const [tabsValue2, setTabValue2] = useState(0);
  const [showComponent, setShowComponent] = useState(tabsValue);
  // const [primaryArtist, setPrimaryArtist] = useState<any>([]);
  // const [featuredArtist, setFeaturedArtist] = useState<any>([]);
  // const [hiddenArtist, setHiddenArtist] = useState<any>([]);
  // const [nonCredits, setNonCredits] = useState<any>([]);
  const [clickTab, setClickTab] = useState(false);
  const [clickDisableRelease, setClickDisableRelease] = useState(false);
  const pname = useSelector((state: RootState) => state.projectName);

  const {
    tabValueChange,
    uploadnewbeatImg,
    uploadnewbeatImgAPI,
    invitedUserRereleaseTrackProject,
    reReleaseTrackDetails,
    trackId,
    trackSlugname,
  } = useSelector((state: RootState) => state.upload);

  // useEffect(() => {
  //   if (Array.isArray(invitedUserRereleaseTrackProject?.data)) {
  //     const filteredPrimaryArtists =
  //       invitedUserRereleaseTrackProject.data.filter(
  //         (data: any) => data.credit_to === "Primary Artist"
  //       );
  //     setPrimaryArtist(filteredPrimaryArtists);

  //     const filteredFeaturedArtist =
  //       invitedUserRereleaseTrackProject.data.filter(
  //         (data: any) => data.credit_to === "Featured Artist"
  //       );
  //     setFeaturedArtist(filteredFeaturedArtist);

  //     const filteredHiddenArtist = invitedUserRereleaseTrackProject.data.filter(
  //       (data: any) => data.credit_to === "Hidden"
  //     );
  //     setHiddenArtist(filteredHiddenArtist);
  //   }
  // }, [invitedUserRereleaseTrackProject]);

  // useEffect(() => {
  //   if (Array.isArray(invitedUserRereleaseTrackProject?.data)) {
  //     const filteredPrimaryArtists = invitedUserRereleaseTrackProject.data
  //       .slice(1)
  //       .filter((data: any) => data.credit_to === "Primary Artist")
  //       .sort((a: any, b: any) => {
  //         if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1;
  //         if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1;

  //         return (
  //           new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
  //         );
  //       });
  //     setPrimaryArtist(filteredPrimaryArtists);

  //     const filteredFeaturedArtist = invitedUserRereleaseTrackProject.data
  //       .slice(1)
  //       .filter((data: any) => data.credit_to === "Featured Artist")
  //       .sort((a: any, b: any) => {
  //         if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1;
  //         if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1;

  //         return (
  //           new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
  //         );
  //       });
  //     setFeaturedArtist(filteredFeaturedArtist);

  //     const filteredHiddenArtist = invitedUserRereleaseTrackProject.data
  //       .slice(1)
  //       .filter((data: any) => data.credit_to === "Hidden")
  //       .sort((a: any, b: any) => {
  //         if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1;
  //         if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1;

  //         return (
  //           new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
  //         );
  //       });
  //     setHiddenArtist(filteredHiddenArtist);

  //     const filteredNonCreditArtist = invitedUserRereleaseTrackProject.data
  //       .slice(1)
  //       .filter(
  //         (data: any) =>
  //           data.credit_to == null && data.role.toLowerCase() != "curator"
  //       )
  //       .sort((a: any, b: any) => {
  //         if (a.isyellow_tick === 1 && b.isyellow_tick === 0) return -1;
  //         if (a.isyellow_tick === 0 && b.isyellow_tick === 1) return 1;

  //         return (
  //           new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
  //         );
  //       });
  //     setNonCredits(filteredNonCreditArtist);
  //   }
  // }, [invitedUserRereleaseTrackProject]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowComponent(tabsValue);
    }, 300);

    return () => clearTimeout(timeout);
  }, [tabsValue]);

  const options = [
    { value: "Primary Artist", label: "Primary Artist" },
    { value: "Featured Artist", label: "Featured Artist" },
    { value: "Hidden", label: "Hidden" },
  ];

  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userData.id == reReleaseTrackDetails?.trackData?.user_id._id) {
      setEditTable(false);
    } else {
      setEditTable(true);
    }
  }, [reReleaseTrackDetails]);

  const LanguageOption = [
    { value: "English", label: "English" },
    { value: "Spanish", label: "Spanish" },
    { value: "Portuguese", label: "Portuguese" },
    { value: "Japanese", label: "Japanese" },
    { value: "Korean", label: "Korean" },
    { value: "Chinese", label: "Chinese" },
  ];
  useEffect(() => {
    if (!parmsSlug.slugName) {
      openPopup("ProjectName2", dispatch);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        navigate("/create");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    onBlurFunction("art_work_image", uploadnewbeatImgAPI);
  }, [uploadnewbeatImgAPI]);

  const [trackformData, setTrackFormData] = useState<ReretrackFormData>({
    project_name: "Project Name",
    track_name: "",
    description: "",
    genre: "",
    language: "",
    contains_explicit_language: false,
    art_work_image: "",
    file_type: "",
    short_description: "",
    isrc_code: "",
    upc_code: "",
    track_links: [""],
    is_instrumental: false,
    released_date: "",
  });

  const [previousTrackformData, setPreviousTrackformData] = useState<any>({
    project_name: "Project Name",
    track_name: "",
    description: "",
    genre: "",
    language: "",
    contains_explicit_language: false,
    art_work_image: "",
    file_type: "",
    short_description: "",
    isrc_code: "",
    upc_code: "",
    track_links: [""],
    is_instrumental: false,
    released_date: "",
  });

  useEffect(() => {
    if (trackId) {
      dispatch(getALlInvitedUserbyRereleaseTrackProject(trackId) as any);
      dispatch(fetchRereleaseTrackDetails(trackSlugname) as any);
    }
  }, [trackId, tabsValue, tabsValue2]);

  const dispatch = useDispatch();

  const OnClickMeatBalls = async (value: any, i: number, secondi?: any) => {
    if (value === "Change Alias") {
      dispatch(
        setSetelectedCollaborator(invitedUserRereleaseTrackProject?.data?.[i])
      );
      openPopup("ChangeAlias", dispatch);
    } else if (value === "Remove") {
      dispatch(
        setSetelectedCollaborator(invitedUserRereleaseTrackProject?.data?.[i])
      );
      openPopup("RemoveCollaborator", dispatch);
      // const data = {
      //   track_id: invitedUserTrackProject?.data?.[i].track_id,
      //   remove: "remove",
      //   invited_id: invitedUserTrackProject?.data?.[i]._id,
      // };
      // const response = await updateInvitedCollaboratorsTrackProject(data);
      // if (response.success) {
      //   dispatch(
      //     getALlInvitedUserbyTrackProject(trackId) as any
      //   );
      //   dispatch(
      //     fetchRereleaseTrackDetails(trackSlugname) as any
      //   );
      // } else {
      //   // setMessage(response.message);
      // }
    } else if (value === "Delete") {
      dispatch(
        setSelectedFile(
          reReleaseTrackDetails?.collaborators?.[secondi].upload_file?.[i]
        )
      );
      dispatch(
        setSelectedFile(
          reReleaseTrackDetails?.collaborators?.[secondi].upload_file?.[i]
        )
      );
      dispatch(
        setSetelectedCollaborator(
          reReleaseTrackDetails?.collaborators?.[secondi]
        )
      );
      openPopup("DeleteFile", dispatch);
    }
    // else if(value==="Approve"){
    //   dispatch(setSelectedFile(trackDetails?.collaborators?.[secondi].upload_file?.[i]))
    //   dispatch(setSelectedFile(trackDetails?.collaborators?.[secondi].upload_file?.[i]))
    //   dispatch(setSetelectedCollaborator(trackDetails?.collaborators?.[secondi]))
    //   openPopup("ApproveFile", dispatch);
    // }
    else {
      // navigate("/message-chat");
      dispatch(
        setuserListData({
          userName: invitedUserRereleaseTrackProject?.data?.[i].display_name,
          userImg:
            invitedUserRereleaseTrackProject?.data?.[i].profile_image?.[0]
              ?.thumbnail ||
            invitedUserRereleaseTrackProject?.data?.[i].profile_image?.[0]?.src,
          alluserListId:
            invitedUserRereleaseTrackProject?.data?.[i].receiver_user_id,
          tabsValue: 0,
          chattingTab: "user",
          username:
            invitedUserRereleaseTrackProject?.data?.[i].receiver_user_id,
        })
      );
      navigate(`/message-chat`);
    }
  };

  const changeCredit = async (value: any, trackId: any, inviteuserid: any) => {
    const data = {
      track_id: trackId,
      credit_to: value,
      invited_id: inviteuserid,
    };
    const response = await updateInvitedCollaboratorsTrackProject(data);
    if (response.success) {
      dispatch(getALlInvitedUserbyRereleaseTrackProject(trackId) as any);
    } else {
    }
  };

  // const handleInputChange = (index: number, value: string) => {
  //   const updatedTrackLinks = [...trackformData.track_links];
  //   updatedTrackLinks[index] = value;
  //   setTrackFormData({ ...trackformData, track_links: updatedTrackLinks });
  // };
  const [error, setError] = useState<string[]>([""]);
  const [errorCode, setErrorCode] = useState<string>("");
  const [errorCode2, setErrorCode2] = useState<string>("");
  const handleInputChange = (index: number, value: string) => {
    const isValidUrl = (url: string) => {
      return url.startsWith("https://") || url.startsWith("http://");
    };

    const updatedTrackLinks = [...trackformData.track_links];
    updatedTrackLinks[index] = value;
    setTrackFormData({ ...trackformData, track_links: updatedTrackLinks });

    if (isValidUrl(value)) {
      const updatedArray = error;
      updatedArray[index] = "";
      setError(updatedArray);
    } else if (!value) {
      const updatedArray = error;
      updatedArray[index] = "";
      setError(updatedArray);
    } else {
      const updatedArray = error;
      updatedArray[index] = "Invalid URL entered";
      setError(updatedArray);
    }
  };

  const addInput = () => {
    setTrackFormData({
      ...trackformData,
      track_links: [...trackformData.track_links, ""],
    });
  };

  const removeInput = (index: number) => {
    if (index !== 0) {
      const updatedTrackLinks = trackformData.track_links.filter(
        (_: string, i: number) => i !== index
      );
      const updatedArray = error;
      updatedArray[index] = "";
      setError(updatedArray);
      updatedTrackLinks.filter((ele: string) => ele).length > 0 &&
        onBlurFunction("track_links", updatedTrackLinks);
      setTrackFormData({ ...trackformData, track_links: updatedTrackLinks });
    }
  };
  const navigate = useNavigate();

  const onBlurFunction = async (
    name: string,
    value: string | null | boolean | any[]
  ) => {
    if (value && previousTrackformData?.[name] !== value) {
      let newValue = value;
      if (name === "track_links") {
        newValue = Array.isArray(value)
          ? value?.filter((ele: string) => ele)
          : value;
      }
      const response = await createRereleaseTrackProject({
        [name]: newValue,
        id: trackId,
      });
      if (response.success) {
        dispatch(setTrackID(response.data._id));
        // setPreviousTrackformData(response.data);
        dispatch(setTrackSlugName(response.data.slug_name));
        dispatch(setProjectname(response.data.project_name));
        dispatch(fetchRereleaseTrackDetails(trackSlugname) as any);
      } else {
        response.errorType == "upc_code"
          ? setErrorCode(response?.message)
          : response.errorType == "isrc_code"
          ? setErrorCode2(response?.message)
          : "";
      }
    }
  };
  const onBlurFunction2 = async (
    name: string,
    value: string | null | boolean | any[]
  ) => {
    const response = await createRereleaseTrackProject({
      [name]: value,
      id: trackId,
    });
    if (response.success) {
      dispatch(setTrackID(response.data._id));
      // setPreviousTrackformData(response.data);
      dispatch(setTrackSlugName(response.data.slug_name));
      dispatch(setProjectname(response.data.project_name));
    } else {
    }
  };
  useEffect(() => {
    if (reReleaseTrackDetails) {
      const role = reReleaseTrackDetails?.collaborators?.find(
        (collaborator: any) => collaborator.receiver_user_id === userData.id
      )?.role;
      dispatch(setUserRole(role));
    }
  }, [reReleaseTrackDetails]);
  const [invitedArr, setInvitedArr] = useState([]);
  useEffect(() => {
    if (invitedUserRereleaseTrackProject && invitedUserRereleaseTrackProject) {
      const updatedArray = invitedUserRereleaseTrackProject?.invitedData?.map(
        (data: any, index: number) => ({
          img:
            data?.profile_image?.[0]?.thumbnail ||
            data?.profile_image?.[0]?.src ||
            "",
          name: data.receiver_name,
          subname: data.receiver_user_id.display_name,
          vocalist: data.role,
          userSlug: data.slug_name,
          percentage: data.percentage_offered,
          bluetik: data.is_bluetick,
          active:
            data.acceept_status === "rejected"
              ? "Declined"
              : data.acceept_status === "pending"
              ? "Active"
              : data.acceept_status === "accepted"
              ? "Accepted"
              : "Expired",
          id:
            data.acceept_status === "rejected"
              ? "DeclinedInviteSent"
              : data.acceept_status === "pending"
              ? "ActiveInviteSent"
              : data.acceept_status === "accepted"
              ? "AcceptedInviteSent"
              : "ExpiredInviteSent",
          userId: data._id,
        })
      );
      setInvitedArr(updatedArray);
    }
  }, [invitedUserRereleaseTrackProject]);

  const validateAndSubmitUPC = async (): Promise<void> => {
    const UPCInput = document.getElementById(
      "UPCInput"
    ) as HTMLInputElement | null;

    if (!UPCInput) {
      console.error("Required DOM elements are missing");
      return;
    }

    const UPC = UPCInput.value;
    // const UPC = trackformData.upc_code;

    if(UPC?.length==0){
      return;
    }
    const regAlpha = /\D/;

    if (regAlpha.test(UPC)) {
      setErrorCode("UPC is invalid: it contains non-numeric characters.");
    } else if (UPC.length < 8) {
      setErrorCode("UPC is invalid: it must be at least 8 characters.");
    } else if (UPC.length > 14) {
      setErrorCode("UPC is invalid: it exceeds 14 characters.");
    } else {
      // UPC is valid
      setErrorCode("");
      try {
        await onBlurFunction("upc_code", UPC);
      } catch (error) {
        console.error("Error calling onBlurFunction:", error);
      }
    }
  };
  // const validateAndSubmitISRC = async (): Promise<void> => {
  //   const ISRCInput = document.getElementById("ISRCInput") as HTMLInputElement | null;

  //   // Check if the input element exists
  //   if (!ISRCInput) {
  //     console.error("Required DOM elements are missing");
  //     return;
  //   }

  //   const ISRC: string = ISRCInput.value.trim(); // trim to avoid leading/trailing spaces
  //   const Valid: number = ISRC.length;

  //   // Regular expression to validate ISRC format (5 alphanumeric characters followed by 7 digits)
  //   const regISRC = /[a-zA-Z]{5}\d{7}/;
  //   // Patterns considered as unrealistic or made-up
  //   const resInvalidISRC = /abc|ABC|123|0123|1234/i;

  //   let isValid: boolean = false;

  //   // Length validation
  //   if (Valid !== 12) {
  //     setErrorCode2(`ISRC is invalid: Valid ISRC is exactly 12 characters, but it is ${Valid} characters.`);
  //   }
  //   // Unrealistic patterns check
  //   else if (resInvalidISRC.test(ISRC)) {
  //     setErrorCode2("ISRC may be made up. Avoid unrealistic patterns.");
  //   }
  //   // Format validation
  //   else if (!regISRC.test(ISRC)) {
  //     setErrorCode2("Invalid ISRC format. An ISRC should be 5 alphanumeric characters followed by 7 digits.");
  //   }
  //   // If all validations pass
  //   else {
  //     setErrorCode2(""); // clear any previous error messages
  //     isValid = true;
  //   }

  //   // If valid, call onBlurFunction to submit the data
  //   if (isValid) {
  //     try {
  //       await onBlurFunction("isrc_code", ISRC);
  //     } catch (error) {
  //       console.error("Error calling onBlurFunction:", error);
  //       setErrorCode2("An error occurred while submitting the ISRC.");
  //     }
  //   }
  // };
  const validateAndSubmitISRC = async (): Promise<void> => {
    const ISRCInput = document.getElementById(
      "ISRCInput"
    ) as HTMLInputElement | null;

    // Check if the input element exists
    if (!ISRCInput) {
      console.error("Required DOM elements are missing");
      return;
    }

    const ISRC: string = ISRCInput.value.trim();
    const length: number = ISRC.length;

    const specialCharRegex = /[^a-zA-Z0-9]/;

    let isValid: boolean = false;

    if(length==0){
      return;
    }

    if (length > 14) {
      setErrorCode2("ISRC is invalid: ISRC cannot exceed 14 characters.");
    } else if (length < 8) {
      setErrorCode2("ISRC is invalid: it must be at least 8 characters.");
    } else if (specialCharRegex.test(ISRC)) {
      setErrorCode2("ISRC is invalid: Special characters are not allowed.");
    } else {
      setErrorCode2("");
      isValid = true;
    }

    if (isValid) {
      try {
        await onBlurFunction("isrc_code", ISRC);
      } catch (error) {
        console.error("Error calling onBlurFunction:", error);
        setErrorCode2("An error occurred while submitting the ISRC.");
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const [uploadIndex, setUploadIndex] = useState<number | null>(null);
  const [filepdf, setFilepdf] = useState<Array<any>>([]);

  const fileInputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const handleButtonClick = (i: any) => {
    setUploadIndex(i);
    const fileInput = fileInputRefs.current[i];
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    insertedMonth: string
  ) => {
    const file = event.target.files?.[0];
    if (!file || uploadIndex === null) return;

    setLoading(true);

    const formData = new FormData();
    formData.append("uploadingFile", file);

    try {
      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_URL}/upload-file`,
        formData
      );

      if (uploadResponse.data.success) {
        const updatedFilepdf = [...filepdf];
        updatedFilepdf[uploadIndex] = {
          month: insertedMonth,
          value: uploadResponse.data.file_data[0],
        };
        setFilepdf(updatedFilepdf);
        onBlurFunction("royalty_income_records", updatedFilepdf);
      } else {
        console.error(
          "Error in response of uploading file",
          uploadResponse.data.message
        );
      }
    } catch (error) {
      console.error("Catch Error in response of uploading file", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkTabState2(tabValueChange);
    setChangeTableValue2(tabValueChange);
  }, [tabValueChange]);

  useEffect(() => {
    const getMissing = async () => {
      if (trackId) {
        const response: any = await getMissingStepRerelease(trackId);
        if (response) {
          setMissingStep(response.data);
        } else {
        }
      }
    };
    getMissing();
  }, [tabsValue]);

  const handleRemoveFile = (index: number) => {
    setFilepdf((prevFilepdf) => {
      const updatedFilepdf = [...prevFilepdf];
      updatedFilepdf[index] = { month: updatedFilepdf[index].month, value: "" };
      onBlurFunction("royalty_income_records", updatedFilepdf);
      return updatedFilepdf;
    });
  };
  useEffect(() => {
    return () => {
      dispatch(setUploadNewBeatImg(""));
      dispatch(setTabValueChange(0));
      setEditTable(true);
      dispatch(setTrackID(""));
      dispatch(setUploadNewBeatImgAPI(""));
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchFile = async () => {
      if (parmsSlug.slugName) {
        const response: any = await getFile(parmsSlug.slugName);
        if (response) {
          // setId(response.data.trackData._id);
          setTrackFormData({
            project_name: response?.data.trackData.project_name,
            track_name: response?.data.trackData.track_name,
            description: response?.data.trackData.description,
            genre: response?.data.trackData.genre,
            language: response.data.trackData.language,
            contains_explicit_language:
              response?.data.trackData.contains_explicit_language,
            art_work_image: "",
            file_type: "",
            short_description: "",
            isrc_code: response?.data.trackData.isrc_code,
            upc_code: response?.data.trackData.upc_code,
            track_links: response?.data.trackData.track_links,
            is_instrumental: response?.data.trackData.is_instrumental,
            released_date: response?.data.trackData.released_date
              ? dateFormat(response.data.trackData.released_date)
              : response.data.trackData.released_date,
          });
          setFilepdf(response?.data.trackData.royalty_income_records);
          // dispatch(fetchTrackDetails(response.data.trackData.slug_name));
          // setslugName(response.data.trackData.slug_name);
          dispatch(setProjectname(response?.data.trackData.project_name));
          dispatch(setTrackSlugName(response?.data.trackData.slug_name));
          dispatch(setTrackID(response?.data.trackData._id));

          dispatch(
            setUploadNewBeatImg(
              response?.data?.trackData?.art_work_image[0]?.src
            )
          );
        } else {
          //console.log("Failed to fetch data");
        }
      } else {
        dispatch(setProjectname(""));
      }
    };
    fetchFile();
  }, [parmsSlug]);

  const getLastFourMonths = () => {
    const months = [];
    const now = new Date();
    for (let i = 0; i < 4; i++) {
      const date = new Date(now.getFullYear(), now.getMonth() - i, 1);
      months.push(
        date.toLocaleString("default", { month: "long", year: "numeric" })
      );
    }
    return months;
  };

  const imageUrl = uploadnewbeatImg?.includes("http")
    ? uploadnewbeatImg
    : `${process.env.REACT_APP_IMGURL}/${uploadnewbeatImg}`;
  const [changeTabValue2, setChangeTableValue2] = useState<number>(0);
  const [changeTabValue, setChangeTableValue] = useState<number>(0);
  const checkTabState2 = (value: number) => {
    setTabValue2(value);
    setChangeTableValue2(value);
    dispatch(setTabValueChange(value));
  };
  const checkTabState = (value: number) => {
    setTabValue(value);
    setChangeTableValue(value);
  };

  const deleteproject = () => {
    dispatch(
      setDeleteModalData({
        deleteID: trackId,
        deleteName: reReleaseTrackDetails?.trackData?.project_name,
        deleteImage:
          reReleaseTrackDetails?.trackData?.art_work_image?.[0]?.thumbnail ||
          reReleaseTrackDetails?.trackData?.art_work_image?.[0]?.src ||
          reReleaseTrackDetails?.trackData?.default_artwork,
        deleteSlugName: reReleaseTrackDetails?.trackData?.description,
        deleteType: "track",
      })
    );
    openPopup("DeleteModal", dispatch);
  };

  const [mask, setMask] = useState("");
  const [dob, setDob] = useState("");
  const [dobError, setDOBError] = useState("");

  const validateDate = (value: string) => {
    if (value) {
      const [datePart, timePart] = value.split(" ");
      const [month, day, year] = datePart.split("/").map(Number);
      const [hour, minute] = timePart
        ? timePart.split(":").map(Number)
        : [0, 0];

      if (
        !day ||
        !month ||
        !year ||
        month < 1 ||
        month > 12 ||
        day < 1 ||
        day > 31 ||
        year > 2050
      ) {
        setDOBError("Invalid date (MM/DD/YYYY). Enter a valid date");
        return;
      }
      const isValidDate = (year: number, month: number, day: number) => {
        const date = new Date(year, month - 1, day);
        return (
          date.getFullYear() === year &&
          date.getMonth() === month - 1 &&
          date.getDate() === day
        );
      };
      if (!isValidDate(year, month, day)) {
        setDOBError("Invalid date. Check the day and month.");
        return;
      }
      if (
        hour < 0 ||
        hour > 23 ||
        minute < 0 ||
        minute > 59 ||
        !hour ||
        isNaN(minute) ||
        isNaN(hour)
      ) {
        setDOBError("Invalid time. Enter a valid time (HH:MM).");
        return;
      }
      const enteredDate = new Date(
        Date.UTC(year, month - 1, day, hour, minute)
      );
      const currentDate = new Date();
      const currentUtcDate = new Date(
        Date.UTC(
          currentDate.getUTCFullYear(),
          currentDate.getUTCMonth(),
          currentDate.getUTCDate(),
          currentDate.getUTCHours(),
          currentDate.getUTCMinutes(),
          currentDate.getUTCSeconds()
        )
      );
      const fifteenDaysFromNow = new Date(currentUtcDate);
      fifteenDaysFromNow.setUTCDate(currentUtcDate.getUTCDate() + 15);

      if (enteredDate < fifteenDaysFromNow) {
        setDOBError("Date and time must be at least 15 days in the future.");
        return;
      }
      setDOBError("");
      onBlurFunction("released_date", value);
    }
  };

  const handleFocus = () => {
    setMask("MM/DD/YYYY HH:MM");
  };
  const handleBlur = () => {
    setMask("");
  };

  const spotifyRerelease = (i: number) => {
    dispatch(
      setSetelectedCollaborator(invitedUserRereleaseTrackProject?.data?.[i])
    );
    openPopup("SpotifyVerifyRerelease", dispatch);
  };

  return (
    <div className="container container-xxl min-height-cover">
      <DropworksInput
        placeholder="Project Name"
        // disabled={!id}
        disabled={reReleaseTrackDetails?.trackData?.is_released || editTable}
        className={`bg-transparent text-capitalize project-font  ps-0 ${
          reReleaseTrackDetails?.trackData?.is_released || editTable
            ? "disable-item"
            : ""
        }`}
        defaultValue={pname.Data ? pname.Data : trackformData.project_name}
        value={trackformData.project_name}
        onBlur={() =>
          onBlurFunction("project_name", trackformData.project_name)
        }
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setTrackFormData((pre: any) => ({
            ...pre,
            project_name: e.target.value,
          }))
        }
        maxLength={30}
      />
      <TextfieldSM className=" mx-0  uni-mb-24 fg-color">
        Note: If you don't enter a project name, the default name will be{" "}
        {reReleaseTrackDetails?.trackData?.user_id?.username || "UserName"}'s
        Project'
      </TextfieldSM>
      <Tabs
        id="15"
        tabsTitle={[
          "Overview",
          "Collaborators",
          "File Center",
          "Track Details",
          "Drop",
        ]}
        className="uni-mb-40"
        checkTabState={checkTabState}
        changeTabValue={changeTabValue}
      />
      <TabsComponent
        tabsValue={tabsValue}
        components={[
          <>
            <div className="row d-flex justify-content-between">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 p-0 track-overview-col">
                <Heading4 className="uni-mb-16">Overview</Heading4>
                <TextfieldLG className="mt-0">
                  {editTable
                    ? "Track's basic information"
                    : "Fill out all information"}
                </TextfieldLG>
                <TextfieldSM className="fg-color uni-mb-40 mt-0">
                  You can navigate among sections any time
                </TextfieldSM>

                <TextfieldLG className="uni-mb-16 mt-0 content-sunheading">
                  Track name
                </TextfieldLG>
                <DropworksInput
                  placeholder="Name"
                  className={`uni-mb-32 form-control ${
                    reReleaseTrackDetails?.trackData?.is_released || editTable
                      ? "disable-item disable-field"
                      : ""
                  }
                   ${
                     clickTab &&
                     (trackformData.track_name == "" ||
                       trackformData.track_name == null)
                       ? "is-invalid b-red-2"
                       : ""
                   }
                  `}
                  disabled={
                    reReleaseTrackDetails?.trackData?.is_released || editTable
                  }
                  value={trackformData.track_name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setTrackFormData((pre: any) => ({
                      ...pre,
                      track_name: e.target.value,
                    }))
                  }
                  maxLength={30}
                  onBlur={() =>
                    onBlurFunction("track_name", trackformData.track_name)
                  }
                />
                <TextfieldLG className="uni-mb-16 content-sunheading mt-0">
                  Descriptions
                </TextfieldLG>

                <DropworksTextArea
                  className={`track-textarea ${
                    reReleaseTrackDetails?.trackData?.is_released || editTable
                      ? "disable-item disable-field"
                      : ""
                  }`}
                  maxCharacter={250}
                  isAutoSave={true}
                  placeholder="Description (Optional)"
                  // disabled={!id}
                  disabled={
                    reReleaseTrackDetails?.trackData?.is_released || editTable
                  }
                  rows={2}
                  hideIcon={true}
                  value={trackformData.description}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setTrackFormData((pre: any) => ({
                      ...pre,
                      description: e.target.value,
                    }))
                  }
                  onBlur={() =>
                    onBlurFunction("description", trackformData.description)
                  }
                />
                {/* <TextfieldLG className="uni-mb-16 content-sunheading mt-0">
                  Release Date
                </TextfieldLG> */}
                {/* <IMaskInput
                id="dob"
                mask={mask}
                disabled={
                  reReleaseTrackDetails?.trackData?.is_released || editTable
                }
                placeholder="(MM/DD/YYYY HH:MM)"
                className={`custom_inp form-control ${
                  reReleaseTrackDetails?.trackData?.is_released || editTable
                    ? "disable-item disable-field"
                    : ""
                } ${
                 dobError
                    ? "is-invalid b-red-2"
                    : ""
                }
            
                
                `}
                value={trackformData.released_date}
                onAccept={(value:any) => {
                  setTrackFormData((pre: any) => ({
                    ...pre,
                    released_date: value,
                  }));
                  validateDate(value);
                }}
                lazy={false}
                definitions={{
                  M:{
                    mask: /(.*\d)/,
                    placeholderChar: "M"
                  },
                  D:{
                    mask: /(.*\d)/,
                    placeholderChar: "D"
                  },
                  Y:{
                    mask: /(.*\d)/,
                    placeholderChar: "Y"
                  },
                  H: {
                    mask: /(.*\d)/,
                    placeholderChar: "H"
                  },
                  m: {
                    mask: /(.*\d)/,
                    placeholderChar: "M"
                  }
         
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
              /> */}
                {dobError && <p className="invalid-feedback ">{dobError}</p>}
                <TextfieldLG className="uni-mb-16 content-sunheading ">
                  Genre
                </TextfieldLG>
                <DropworksDisableButton
                  className={`custom-select-dropdown track-overview-dropdown uni-mb-56 form-control ${
                    reReleaseTrackDetails?.trackData?.is_released || editTable
                      ? "disable-item disable-field"
                      : ""
                  }
                    ${
                      clickTab &&
                      (trackformData.genre == "" || trackformData.genre == null)
                        ? "is-invalid b-red-2"
                        : ""
                    }
                  `}
                  // disabled={!id}
                  disabled={
                    reReleaseTrackDetails?.trackData?.is_released || editTable
                  }
                >
                  <SelectArrowDropdown
                    options={GenreOptions}
                    placeHolderValue="Select"
                    value={trackformData.genre}
                    onChangeValue={(value: null | string) => {
                      onBlurFunction("genre", value);
                      setTrackFormData((pre: any) => ({
                        ...pre,
                        genre: value,
                      }));
                    }}
                  ></SelectArrowDropdown>
                </DropworksDisableButton>
                <TextfieldLG className="uni-mb-16 content-sunheading mt-0">
                  Language
                </TextfieldLG>
                <DropworksDisableButton
                  className={`custom-select-dropdown track-overview-dropdown uni-mb-56 form-control ${
                    reReleaseTrackDetails?.trackData?.is_released || editTable
                      ? "disable-item disable-field"
                      : ""
                  }
                    ${
                      clickTab &&
                      (trackformData.language == "" ||
                        trackformData.language == null)
                        ? "is-invalid b-red-2"
                        : ""
                    }
                  `}
                  // disabled={!id}
                  disabled={
                    reReleaseTrackDetails?.trackData?.is_released || editTable
                  }
                >
                  <SelectArrowDropdown
                    options={LanguageOption}
                    placeHolderValue="Select"
                    value={trackformData.language}
                    onChangeValue={(value: null | string) => {
                      onBlurFunction("language", value);
                      setTrackFormData((pre: any) => ({
                        ...pre,
                        language: value,
                      }));
                    }}
                  ></SelectArrowDropdown>
                </DropworksDisableButton>

                <div className="d-flex justify-content-between uni-py-16 uni-mb-40">
                  <TextfieldSM className="mx-0 my-0 fg-color-g5">
                    Contains Explicit Language
                  </TextfieldSM>
                  <DropworksSwitch
                    checked={trackformData.contains_explicit_language}
                    disabled={
                      reReleaseTrackDetails?.trackData?.is_released || editTable
                    }
                    className={`${
                      reReleaseTrackDetails?.trackData?.is_released || editTable
                        ? "disable-item "
                        : ""
                    }`}
                    // disabled={!id}
                    onChange={(e: boolean) => {
                      onBlurFunction2("contains_explicit_language", e);
                      setTrackFormData((pre: any) => ({
                        ...pre,
                        contains_explicit_language: e,
                      }));
                    }}
                  />
                </div>

                <div className="d-flex justify-content-between uni-py-16 uni-mb-40">
                  <TextfieldSM className="mx-0 my-0 fg-color-g5">
                    Only Instrumental
                    {/* <CustomTooltip
                      title="Select only instrumental file"
                      position="right"
                    >
                      <RiQuestionFill className="uni-ml-16 track-tooltip" />
                    </CustomTooltip> */}
                  </TextfieldSM>
                  <DropworksSwitch
                    checked={trackformData.is_instrumental}
                    className={`${
                      reReleaseTrackDetails?.trackData?.is_released || editTable
                        ? "disable-item"
                        : ""
                    }`}
                    // disabled={!id}
                    disabled={
                      reReleaseTrackDetails?.trackData?.is_released || editTable
                    }
                    onChange={(e: boolean) => {
                      onBlurFunction2("is_instrumental", e);
                      setTrackFormData((pre: any) => ({
                        ...pre,
                        is_instrumental: e,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 d-flex justify-content-end p-0">
                <div>
                  <TextfieldLG className="me-0 ml-auto beat-empty-artwork ">
                    Artwork
                  </TextfieldLG>
                  <TextfieldLG className="uni-mb-8 uni-mt-16 ctm-txt ctm-md-lh uni-mb-32 modal-second-head-font">
                    Use a 1:1 aspect ratio with dimensions of 3000px or higher
                  </TextfieldLG>

                  <div
                    className={`beatEmpty_uploadbox2 flex-column mb-3 ${
                      clickTab && !uploadnewbeatImg ? "is-invalid b-red-2" : ""
                    }`}
                  >
                    {uploadnewbeatImg ? (
                      <ButtonComponent
                        id="trackartwork"
                        className="w-100 h-100"
                        disabled={
                          reReleaseTrackDetails?.trackData?.is_released ||
                          editTable
                        }
                      >
                        <img
                          src={imageUrl}
                          className={`upload-img-2 w-100 h-100 ${
                            reReleaseTrackDetails?.trackData?.is_released ||
                            editTable
                              ? "disable-item"
                              : ""
                          }`}
                        />
                      </ButtonComponent>
                    ) : (
                      <>
                        {/* <ButtonComponent id="trackartwork" disabled={!id}> */}
                        <ButtonComponent
                          id="trackartwork"
                          disabled={
                            reReleaseTrackDetails?.trackData?.is_released ||
                            editTable
                          }
                        >
                          <img
                            src={iconImg}
                            alt="Upload Icon"
                            className={`${
                              reReleaseTrackDetails?.trackData?.is_released ||
                              editTable
                                ? "disable-item"
                                : ""
                            }`}
                          />
                        </ButtonComponent>
                        <label className="mt-4"> Track Artwork</label>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>,
          <>
            <div className="d-flex invite-acexp flex-wrap uni-mb-32">
              <button
                className={`invite-acexp-btn ${
                  tabsValue2 == 0 ? "active" : ""
                }`}
                onClick={() => checkTabState2(0)}
              >
                Team
              </button>
              <button
                className={`invite-acexp-btn ${
                  tabsValue2 == 1 ? "active" : ""
                }`}
                onClick={() => checkTabState2(1)}
              >
                Invited
              </button>
            </div>
            <TabsComponent
              tabsValue={tabsValue2}
              components={[
                <>
                  <div className="">
                    <Heading4 className="uni-mb-16">Collaborators</Heading4>
                    <TextfieldLG>
                      {editTable
                        ? "Check your Team"
                        : "Manage your Collaborators"}
                    </TextfieldLG>
                    <TextfieldSM className="fg-color uni-mb-40">
                      You can navigate among sections any time
                    </TextfieldSM>
                    <div className="d-flex row align-items-center collaborators-content uni-mb-32 flex-wrap">
                      {
                        invitedUserRereleaseTrackProject.data?.length > 0
                          ? invitedUserRereleaseTrackProject.data?.map(
                              (ele: any, i: number) => {
                                return (
                                  <div className="collaborators-content-under col-6 p-0 uni-mb-32">
                                    <h2 className="text-capitalize">
                                      {ele.role.toLowerCase() ==
                                      "cover designer"
                                        ? "Artwork Designer"
                                        : ele.role}{" "}
                                      {/* {ele.isyellow_tick == 1 || i == 0 ? (
                                        <YelloTik  className="uni-ml-8"/>
                                      ) : (
                                        ""
                                      )} */}
                                    </h2>
                                    <div className="d-flex align-items-center uni-py-16">
                                      <img
                                        onClick={() =>
                                          userData.id == ele?.receiver_user_id
                                            ? navigate(`/profile`)
                                            : navigate(
                                                `/user-profile/${ele.slug_name}`
                                              )
                                        }
                                        src={`${process.env.REACT_APP_IMGURL}/${
                                          ele.profile_image?.[0]?.thumbnail ||
                                          ele.profile_image?.[0]?.src
                                        }`}
                                        // onError={(e) => {
                                        //   e.currentTarget.src = UserIcon;
                                        // }}
                                        className="img-king uni-mr-8 cursor-pointer"
                                        alt=""
                                      />
                                      <div className="uni-mr-16   d-flex">
                                        <CustomTooltip
                                          title={ele?.display_name}
                                          className="w-auto"
                                          position={"top"}
                                        >
                                          <Heading5
                                            className="king-roland-text cursor-pointer text-capitalize m-0"
                                            onClick={() =>
                                              userData.id ==
                                              ele?.receiver_user_id
                                                ? navigate(`/profile`)
                                                : navigate(
                                                    `/user-profile/${ele.slug_name}`
                                                  )
                                            }
                                          >
                                            {ele.display_name?.length > 11
                                              ? ele.display_name.substring(
                                                  0,
                                                  11
                                                ) + "..."
                                              : ele.display_name}
                                            {/* <BlueTik position="top" /> */}
                                          </Heading5>
                                        </CustomTooltip>
                                        {ele.is_bluetick == 1 ? (
                                          <BlueTik
                                            position="top"
                                            className="uni-ml-8"
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      {/* <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                            {i==0?ele.percentage_offered-1:ele.percentage_offered}%
                          </DropworksNormalButton> */}
                                      {editTable ? (
                                        userData.id == ele?.receiver_user_id ? (
                                          <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                                            {i == 0
                                              ? ele.percentage_offered
                                              : ele.percentage_offered}
                                            %
                                          </DropworksNormalButton>
                                        ) : (
                                          <></>
                                        )
                                      ) : (
                                        <DropworksNormalButton className=" my-0 percent-btn uni-mr-16">
                                          {i == 0
                                            ? ele.percentage_offered
                                            : ele.percentage_offered}
                                          %
                                        </DropworksNormalButton>
                                      )}
                                      {editTable ||
                                      ele?.role?.toLocaleLowerCase() ==
                                        "curator" ||
                                      ele?.role?.toLocaleLowerCase() ==
                                        "cover designer"  ? (
                                        ""
                                      ) : (
                                        <DropworksDisableButton
                                          className={`custom-selectarrow uni-mr-16 ${
                                            clickTab && ele.credit_to == null
                                              ? "is-invalid b-red-2"
                                              : ""
                                          }`}
                                          disabled={
                                            reReleaseTrackDetails?.trackData
                                              ?.is_released ||
                                            editTable 
                                          }
                                        >
                                          <SelectArrowDropdown
                                            options={options}
                                            placeHolderValue="Credit"
                                            className="credit-selectdropdown"
                                            value={ele.credit_to}
                                            onChangeValue={(
                                              value: null | string
                                            ) => {
                                              changeCredit(
                                                value,
                                                ele.track_id,
                                                ele._id
                                              );
                                            }}
                                          ></SelectArrowDropdown>
                                        </DropworksDisableButton>
                                      )}
                                      {reReleaseTrackDetails?.trackData
                                        ?.is_released ? (
                                        ""
                                      ) : (
                                        <>
                                          {" "}
                                          {editTable ? (
                                            userData.id ==
                                            ele?.receiver_user_id ? (
                                              <DropworksNormalButton
                                                className={`spotify-btn my-0  uni-mr-16 uni-fw-400`}
                                                onClick={() =>
                                                  spotifyRerelease(i)
                                                }
                                              >
                                                Spotify Link
                                              </DropworksNormalButton>
                                            ) : (
                                              ""
                                            )
                                          ) : (
                                            <DropworksNormalButton
                                              className={`spotify-btn my-0  uni-mr-16  uni-fw-400 ${
                                                clickTab &&
                                                ele.spotify_link == null
                                                  ? "is-invalid b-red-2"
                                                  : ""
                                              } `}
                                              onClick={() =>
                                                spotifyRerelease(i)
                                              }
                                            >
                                              Spotify Link
                                            </DropworksNormalButton>
                                          )}{" "}
                                        </>
                                      )}

                                      {reReleaseTrackDetails?.trackData
                                        ?.is_released || editTable ? (
                                        ""
                                      ) : (
                                        <>
                                          <MeatballsMenuDropdown
                                            arr={
                                              i == 0
                                                ? MeatballsMenuOptions
                                                : ele?.isyellow_tick
                                                ? MeatballsMenuOptions21
                                                : MeatballsMenuOptions2
                                            }
                                            pagename="call-funtion"
                                            onClickOptions={(stelected: any) =>
                                              OnClickMeatBalls(stelected, i)
                                            }
                                            className="custom-metaballs"
                                          ></MeatballsMenuDropdown>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            )
                          : ""
                        // (
                        //   <TextfieldLG>No Collaborators invited.</TextfieldLG>
                        // )
                      }

                      <div className=" p-0 col-6">
                        <div className=" collaborators-content-under pe-0 uni-mt-16">
                          {reReleaseTrackDetails?.trackData?.is_released ||
                          editTable ? (
                            ""
                          ) : (
                            <ButtonComponent
                              id="InviteModal8"
                              disabled={
                                reReleaseTrackDetails?.trackData?.is_released ||
                                editTable
                              }
                            >
                              <div
                                className={`track-plus-icon ${
                                  reReleaseTrackDetails?.trackData
                                    ?.is_released || editTable
                                    ? "disable-item"
                                    : ""
                                }`}
                              >
                                <span className="under-plus-icon">+</span>
                              </div>
                            </ButtonComponent>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>,
                <>
                  <div className="invite-usericon">
                    {invitedArr?.length > 0 ? (
                      <InviteFriends
                        arr={invitedArr}
                        showCondition={editTable}
                        page="track"
                      />
                    ) : (
                      <TextfieldLG>No collaborators are invited</TextfieldLG>
                    )}
                  </div>
                </>,
              ]}
            />
          </>,
          <>
            <Heading4 className="uni-mb-16">File Center</Heading4>
            <div className="d-flex">
              <div className="uni-mr-56">
                <TextfieldLG className="mt-0">
                  Upload and manage your files
                </TextfieldLG>
                <TextfieldSM className="fg-color uni-mb-40">
                  You can navigate among sections any time
                </TextfieldSM>
              </div>
              {editTable || reReleaseTrackDetails?.trackData?.is_released ? (
                ""
              ) : (
                <ButtonComponent
                  id="UploadModal"
                  disabled={
                    reReleaseTrackDetails?.trackData?.is_released || editTable
                  }
                >
                  <DropworksNormalButton
                    className={
                      reReleaseTrackDetails?.trackData?.is_released || editTable
                        ? " custom-inactive-btn text-secondary disable-item"
                        : ""
                    }
                  >
                    Upload
                  </DropworksNormalButton>
                </ButtonComponent>
              )}
            </div>
            <div className="d-flex justify-content-between file-center-main align-items-center uni-mb-32">
              {reReleaseTrackDetails?.collaborators?.map(
                (data: any, i: any) => {
                  return (
                    <div className=" p-0 col-6 uni-mb-32">
                      <h2 className="uni-mb-16 text-capitalize">
                        {data?.role.toLowerCase() == "cover designer"
                          ? "Artwork Designer"
                          : data?.role}
                        {/* <YelloTik className="uni-ml-8" />{" "} */}
                      </h2>

                      <div
                        className="d-flex align-items-center uni-mb-16 invite_firstcolumn cursor-pointer"
                        onClick={() =>
                          userData.id == data?.receiver_user_id
                            ? navigate(`/profile`)
                            : navigate(`/user-profile/${data?.slug_name}`)
                        }
                      >
                        <img
                          src={`${process.env.REACT_APP_IMGURL}/${
                            data.profile_image[0]?.thumbnail ||
                            data.profile_image[0]?.src
                          }`}
                          className="img-king"
                          alt=""
                        />
                        <div>
                        <CustomTooltip
                                          title={data?.display_name}
                                          className="w-auto"
                                          position={"top"}
                                        >
                          <Heading5 className="fw-700 d-flex align-items-center m-0 text-capitalize">
                            {/* {data?.display_name} */}
                            {data?.display_name?.length > 11
                                              ? data?.display_name.substring(
                                                  0,
                                                  11
                                                ) + "..."
                                              : data?.display_name}
                           
                            {/* <BlueTik className="uni-ml-8" />{" "} */}
                          </Heading5>
                          </CustomTooltip>
                          {data?.is_bluetick == 1 ? (
                              <BlueTik position="top" className="uni-ml-8" />
                            ) : (
                              ""
                            )}
                        </div>
                      </div>
                      {data.upload_file && data.upload_file.length > 0 && (
                        <div className="producer-border producer-main">
                          {data.upload_file?.map((data2: any, index: any) => {
                            return (
                              <>
                                <div className="d-flex align-items-center justify-content-between uni-mt-16 border-style">
                                  <div>
                                    <TextfieldSM className="fs-color-g3 m-0">
                                      Type:{" "}
                                      <span className="fw-400-wh">
                                        {data2?.file_type}
                                      </span>
                                    </TextfieldSM>
                                    <TextfieldSM className="fs-color-g3 m-0">
                                      Description:
                                      <span className="fw-400-wh">
                                        {" "}
                                        {data2?.short_description}
                                      </span>
                                    </TextfieldSM>
                                    <TextfieldSM className="fs-color-g3 m-0">
                                      Date:{" "}
                                      <span className="fw-400-wh">
                                        {" "}
                                        {new Date(
                                          data2.upload_date
                                        ).toLocaleString("en-US", {
                                          month: "2-digit",
                                          day: "2-digit",
                                          year: "numeric",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: false,
                                        })}
                                      </span>
                                    </TextfieldSM>
                                  </div>
                                  <div className="d-flex downloadicon-metaballs-file-center">
                                    <h5>
                                      <a
                                        href={`${process.env.REACT_APP_IMGURL}/${data2?.src}`}
                                        download={data2?.fileName}
                                      >
                                        <img
                                          src={downloadIcon}
                                          alt="Download music"
                                        />
                                      </a>
                                    </h5>
                                    <h5>
                                      {reReleaseTrackDetails?.trackData
                                        ?.is_released || editTable ? (
                                        ""
                                      ) : (
                                        <MeatballsMenuDropdown
                                          className="custom-metaballs2"
                                          pagename="call-funtion"
                                          onClickOptions={(stelected: any) =>
                                            OnClickMeatBalls(
                                              stelected,
                                              index,
                                              i
                                            )
                                          }
                                          arr={MeatballsMenuOptions3}
                                        ></MeatballsMenuDropdown>
                                      )}
                                    </h5>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </>,
          <>
            <div className="row d-flex justify-content-between">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 p-0">
                <Heading4 className="uni-mb-16">Track Details</Heading4>
                <TextfieldLG className="mx-0 my-0 fs-color-g3">
                  {editTable
                    ? "Track's basic information"
                    : "Fill out all information"}
                </TextfieldLG>
                <TextfieldSM className=" mx-0  uni-mb-32 fg-color">
                  You can navigate among sections any time
                </TextfieldSM>

                <TextfieldLG className="mt-0 uni-mb-16">UPC code</TextfieldLG>
                <DropworksInput
                  id="UPCInput"
                  placeholder="UPC Code"
                  className={`form-control ${
                    reReleaseTrackDetails?.trackData?.is_released || editTable
                      ? "disable-item disable-field"
                      : ""
                  } ${
                    trackformData.upc_code
                      ? errorCode && errorCode
                        ? "is-invalid b-red-2"
                        : ""
                      : ""
                  }
                    ${
                      clickTab &&
                      (trackformData.upc_code == "" ||
                        trackformData.upc_code == null)
                        ? "is-invalid b-red-2"
                        : ""
                    }
                  `}
                  value={trackformData.upc_code}
                  disabled={
                    reReleaseTrackDetails?.trackData?.is_released || editTable
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setTrackFormData((pre: any) => ({
                      ...pre,
                      upc_code: e.target.value,
                    }));
                    setErrorCode("")
                  }}
                  onBlur={() =>
                    validateAndSubmitUPC()
                  }
                  maxLength={14}
                />
                {errorCode && <p className="invalid-feedback ">{errorCode}</p>}

                <TextfieldLG className=" uni-mb-16 uni-mt-32">
                  ISRC code
                </TextfieldLG>
                <DropworksInput
                  id="ISRCInput"
                  placeholder="ISRC Code"
                  className={` form-control  ${
                    reReleaseTrackDetails?.trackData?.is_released || editTable
                      ? "disable-item disable-field"
                      : ""
                  } ${
                    trackformData.isrc_code
                      ? errorCode2 && errorCode2
                        ? "is-invalid b-red-2"
                        : ""
                      : ""
                  }
                    ${
                      clickTab &&
                      (trackformData.isrc_code == "" ||
                        trackformData.isrc_code == null)
                        ? "is-invalid b-red-2"
                        : ""
                    }
                  `}
                  value={trackformData.isrc_code}
                  disabled={
                    reReleaseTrackDetails?.trackData?.is_released || editTable
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setTrackFormData((pre: any) => ({
                      ...pre,
                      isrc_code: e.target.value,
                    }));
                    setErrorCode2("")
                  }}
                  onBlur={() =>
                    validateAndSubmitISRC()
                  }
                  maxLength={14}
                />
                {errorCode2 && (
                  <p className="invalid-feedback ">{errorCode2}</p>
                )}
                {/* {reReleaseTrackDetails?.trackData?.royalty_income_records?.map((item)=>item.value.fileType)} */}
                <TextfieldLG
                  className={`mx-0 uni-mt-32 ${
                    clickTab &&
                    (!reReleaseTrackDetails?.trackData?.royalty_income_records
                      ?.length ||
                      reReleaseTrackDetails?.trackData?.royalty_income_records?.some(
                        (item: any) => !item.value.fileType
                      ))
                      ? "textFeild-error"
                      : ""
                  }`}
                >
                  Royalty income records
                </TextfieldLG>

               <div className="uni-mb-56">
               {getLastFourMonths().map((data: any, index: any) => {
                  const currentFile = filepdf[index];
                  const isUploading = loading && uploadIndex === index;

                  return (
                    <>
                      <hr />
                      <div className="d-flex justify-content-between align-items-center">
                        <TextfieldSM className="mx-0 my-0">{data}</TextfieldSM>

                        {currentFile?.value?.fileType ? (
                          <TextfieldSM className="mx-0 my-0 fg-color-g5">
                            {currentFile.value?.fileName}
                            {reReleaseTrackDetails?.trackData?.is_released ||
                            editTable ? (
                              ""
                            ) : (
                              <span
                                className="uni-ml-16 cursor-pointer"
                                onClick={() => handleRemoveFile(index)}
                              >
                                x
                              </span>
                            )}
                          </TextfieldSM>
                        ) : (
                          <>
                            {isUploading ? (
                              <Loader className="" />
                            ) : (
                              <>
                                <input
                                  type="file"
                                  accept="application/pdf"
                                  onChange={(e) => {
                                    handleUpload(e, data);
                                  }}
                                  className="d-none"
                                  ref={(el) =>
                                    (fileInputRefs.current[index] = el)
                                  }
                                />
                                {reReleaseTrackDetails?.trackData
                                  ?.is_released || editTable ? (
                                  ""
                                ) : (
                                  <DropworksNormalButton
                                    className="upload-btn my-0"
                                    // onClick={() => setUploadIndex(index)}
                                    onClick={() => handleButtonClick(index)}
                                  >
                                    Upload
                                  </DropworksNormalButton>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  );
                })}
               </div>

              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 track-links">
                <TextfieldLG className="uni-mb-16 mt-0 track-links-align">
                  Track links
                  <CustomTooltip
                    title="Add the direct links to all platforms where this track has been released."
                    position="right"
                  >
                    <RiQuestionFill className="uni-ml-16 track-tooltip" />
                  </CustomTooltip>
                </TextfieldLG>
                <DropworksInput
                  placeholder="Track Links"
                  className={` form-control
            ${
              reReleaseTrackDetails?.trackData?.is_released || editTable
                ? "disable-item disable-field"
                : ""
            }
            ${
              trackformData.track_links[0]?.length > 0
                ? error?.[0]
                  ? "is-invalid b-red-2"
                  : !error?.[0]
                  ? "is-valid b-green-2"
                  : ""
                : ""
            }
            ${
              clickTab &&
              (trackformData.track_links[0] == "" ||
                trackformData.track_links[0] == null)
                ? "is-invalid b-red-2"
                : ""
            }
          `}
                  // maxLength={30}
                  disabled={
                    reReleaseTrackDetails?.trackData?.is_released || editTable
                  }
                  value={trackformData.track_links[0]} // Bind value from state
                  onChange={(e) => handleInputChange(0, e.target.value)} // Update value in state
                  onBlur={() =>
                    onBlurFunction("track_links", trackformData.track_links)
                  }
                />
                {error[0] && <p className="invalid-feedback">{error[0]}</p>}

                {trackformData.track_links
                  .slice(1)
                  .map((value: string, index: number) => (
                    <Fragment key={index}>
                      <div className="d-flex align-items-center">
                        <DropworksInput
                          placeholder="Track Links"
                          className={`uni-mt-32  form-control ${
                            reReleaseTrackDetails?.trackData?.is_released ||
                            editTable
                              ? "disable-item disable-field"
                              : ""
                          }   ${
                            trackformData.track_links[index + 1]?.length > 0
                              ? error?.[index + 1]
                                ? "is-invalid b-red-2"
                                : !error?.[index + 1]
                                ? "is-valid b-green-2"
                                : ""
                              : ""
                          }`}
                          disabled={
                            reReleaseTrackDetails?.trackData?.is_released ||
                            editTable
                          }
                          // maxLength={30}
                          value={value}
                          onChange={
                            (e) => handleInputChange(index + 1, e.target.value) // Adjust index for additional inputs
                          }
                          onBlur={() =>
                            onBlurFunction(
                              "track_links",
                              trackformData.track_links
                            )
                          }
                        />
                        {reReleaseTrackDetails?.trackData?.is_released ||
                        editTable ? (
                          ""
                        ) : (
                          <div className="plus-icon2 remove-icon  uni-ml-16  cursor-pointer">
                            <FiMinus onClick={() => removeInput(index + 1)} />
                          </div>
                        )}
                      </div>
                      {/* Show delete button for inputs other than the first */}

                      <div>
                        {error[index + 1] && (
                          <p className="invalid-feedback">{error[index + 1]}</p>
                        )}
                      </div>
                    </Fragment>
                  ))}

                {editTable || reReleaseTrackDetails?.trackData?.is_released ? (
                  <>
                    <button disabled className="disabled-btn-track">
                      <div className="plus-icon2 cursor-pointer  uni-mt-32 ">
                        <GoPlus onClick={addInput} />
                      </div>
                    </button>
                  </>
                ) : (
                  <>
                    {trackformData?.track_links.length < 10 ? (
                      <div className="plus-icon2 cursor-pointer  uni-mt-32 ">
                        <GoPlus onClick={addInput} />
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
          </>,
          <>
            <Heading4 className="uni-mb-16">Drop</Heading4>

            <TextfieldLG>Review all information before Drop </TextfieldLG>
            <TextfieldSM className="fg-color uni-mb-40">
              {" "}
              You can navigate among sections any time
            </TextfieldSM>

            <div className="d-flex uni-mb-40 justify-content-between align-items-end release-content-border">
              <div className="d-flex align-items-end">
                <img
                  src={
                    uploadnewbeatImg
                      ? imageUrl
                      : `${process.env.REACT_APP_IMGURL}/${reReleaseTrackDetails?.trackData?.default_artwork}`
                  }
                  className="uni-mr-24 treeImage-track"
                />
                <div>
                  <Heading1>
                    {trackformData?.track_name || trackformData?.project_name}{" "}
                  </Heading1>
                  <div className="fc-gray4 text-capitalize link-btm">
                    {/* <span
                      className="underline-opening"
                      onClick={() =>
                        userData.id ==
                        reReleaseTrackDetails?.collaborators?.[0]
                          ?.receiver_user_id
                          ? navigate(`/profile`)
                          : navigate(
                              `/user-profile/${reReleaseTrackDetails?.collaborators?.[0]?.slug_name}`
                            )
                      }
                    >
                      {reReleaseTrackDetails?.collaborators?.[0]?.display_name}
                      {(primaryArtist.length > 0 ||
                        featuredArtist.length > 0 ||
                        nonCredits.length > 0 ||
                        hiddenArtist.length > 0) &&
                        ", "}
                    </span> */}

                    {/* Primary Artist Artist Artist name Link */}
                    {/* { primaryArtist && featuredArtist && hiddenArtist && nonCredits &&
                      [...primaryArtist,...featuredArtist,...hiddenArtist,...nonCredits].map((data: any, index: number, arr: any) => (
                        <React.Fragment key={data.slugName}>
                          <TextfieldMD
                            className="underline-opening mb-0 mt-0 m-0"
                            onClick={() =>
                              userData.id == data?.receiver_user_id
                                ? navigate(`/profile`)
                                : navigate(`/user-profile/${data?.slug_name}`)
                            }
                          >
                            {data.display_name}
                          </TextfieldMD>
                          {index < arr?.length - 1 && ", "}
                        </React.Fragment>
                      ))} */}

                        {filteredArtist(invitedUserRereleaseTrackProject?.data)?.map((data: any, index: number, arr: any) => (
                                              <React.Fragment key={data.slugName}>
                                                <TextfieldMD
                                                  className="underline-opening mb-0 mt-0 m-0"
                                                  onClick={() =>
                                                    userData.id == data?.receiver_user_id
                                                      ? navigate(`/profile`)
                                                      : navigate(`/user-profile/${data?.slug_name}`)
                                                  }
                                                >
                                                  {data.display_name}
                                                </TextfieldMD>
                                                {index < arr?.length - 1 && ", "}
                                              </React.Fragment>
                                            ))}
                  </div>

                  {reReleaseTrackDetails &&
                  reReleaseTrackDetails?.trackData?.genre ? (
                    <UserLable>
                      {reReleaseTrackDetails?.trackData?.genre}
                    </UserLable>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="d-flex">
                {editTable || reReleaseTrackDetails?.trackData?.is_released ? (
                  ""
                ) : (
                  <>
                    {/* <ButtonComponent id="DeleteModal"> */}
                    <DropworksNormalButton
                      className="release-btn3 mb-0 uni-mr-8"
                      onClick={deleteproject}
                    >
                      Delete
                    </DropworksNormalButton>
                    {/* </ButtonComponent> */}
                  </>
                )}
                {missingStep &&
                missingStep?.[0]?.pageOne == true &&
                missingStep?.[0]?.pageTwo == true &&
                missingStep?.[0]?.pageThree == true &&
                missingStep?.[0]?.pageFour == true ? (
                  <>
                    {reReleaseTrackDetails?.trackData?.is_released ? (
                      <DropworksNormalButton
                        className="release-btn mb-0 disable-item"
                        disabled
                      >
                        Released
                      </DropworksNormalButton>
                    ) : (
                      <ButtonComponent
                        id="ReleaseModal"
                        disabled={
                          reReleaseTrackDetails?.trackData?.is_released ||
                          editTable
                        }
                      >
                        <DropworksNormalButton className="release-btn2 mb-0">
                          Release
                        </DropworksNormalButton>
                      </ButtonComponent>
                    )}
                  </>
                ) : (
                  <>
                    {reReleaseTrackDetails?.trackData?.is_released ? (
                      <DropworksNormalButton
                        className="release-btn mb-0 disable-item"
                        disabled
                      >
                        Released
                      </DropworksNormalButton>
                    ) : (
                      <DropworksNormalButton
                        className={`release-btn mb-0 cursor-pointer ${
                          editTable ? "disable-item" : ""
                        }`}
                        disabled={editTable}
                        onClick={() => {
                          setClickDisableRelease(true);
                          window.scrollTo({
                            top: document.documentElement.scrollHeight,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Release
                      </DropworksNormalButton>
                    )}
                  </>
                )}
              </div>
            </div>
            {(missingStep && missingStep?.[0]?.pageOne == false) ||
            missingStep?.[0]?.pageTwo == false ||
            missingStep?.[0]?.pageThree == false ||
            missingStep?.[0]?.pageFour == false ? (
              <>
                <div
                  className={`uni-p-40 missing-steps ${
                    clickDisableRelease ? "is-invalid b-red-2" : ""
                  }`}
                >
                  <h2 className="missing-text uni-mb-24">
                    {/* <img src={exclamationTriangle} className="uni-mr-16" /> */}
                    <span className="textFeild-error textFeild-error-missing"></span>
                    Missing Steps
                  </h2>

                  {missingStep && missingStep?.[0]?.pageOne == false ? (
                    <>
                      <TextfieldLG
                        className="fc-gray4 uni-mr-8 mt-0 cursor-pointer textfield-track"
                        onClick={() => {
                          {
                            setClickTab(true);
                            checkTabState(0);
                          }
                        }}
                      >
                        Fill all the base information of the Track{" "}
                        <img src={rightArrow} className="cursor-pointer" />
                      </TextfieldLG>
                    </>
                  ) : (
                    <></>
                  )}
                  {missingStep && missingStep?.[0]?.pageTwo == false ? (
                    <>
                      <TextfieldLG
                        className="fc-gray4 uni-mr-8  mt-0 cursor-pointer textfield-track"
                        onClick={() => {
                          {
                            setClickTab(true);
                            checkTabState(1);
                          }
                        }}
                      >
                        Credit Collaborators{" "}
                        <img src={rightArrow} className="cursor-pointer" />
                      </TextfieldLG>
                    </>
                  ) : (
                    <></>
                  )}
                  {missingStep && missingStep?.[0]?.pageThree == false ? (
                    <>
                      <TextfieldLG
                        className="fc-gray4 uni-mr-8  mt-0 cursor-pointer textfield-track"
                        onClick={() => {
                          {
                            setClickTab(true);
                            checkTabState(2);
                          }
                        }}
                      >
                        Upload final Track file{" "}
                        <img src={rightArrow} className="cursor-pointer" />
                        {missingStep?.[0]?.pageThreeDetails?.final == false ? (
                          <>
                            <br />* Final mix and master
                          </>
                        ) : (
                          ""
                        )}
                        {missingStep?.[0]?.pageThreeDetails?.mix == false ? (
                          <>
                            <br />* Mix master minus one
                          </>
                        ) : (
                          ""
                        )}
                      </TextfieldLG>
                    </>
                  ) : (
                    <></>
                  )}
                  {missingStep && missingStep?.[0]?.pageFour == false ? (
                    <>
                      <TextfieldLG
                        className="fc-gray4 uni-mr-8  mt-0 cursor-pointer textfield-track"
                        onClick={() => {
                          {
                            setClickTab(true);
                            checkTabState(3);
                          }
                        }}
                      >
                        Fill out all required information{" "}
                        <img src={rightArrow} className="cursor-pointer" />
                      </TextfieldLG>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </>,
        ]}
      />
    </div>
  );
};
export default view;
