import React, { FC, useState, useRef, useEffect } from "react";
import "./styles.scss";
import meatballsIcon from "../../assets/img/icons/meatballs-menu-icon.svg";
import { ButtonComponent } from "../../app/shared/components/logo-header/view";
import { Link } from "react-router-dom";

interface DropdownProps extends React.InputHTMLAttributes<HTMLInputElement> {
  arr: string[];
  height?: string;
  icon?: any;
  className?: string;
  pagename?: string;
  onClickOptions?: (data: any) => void;
}
export const view: FC<DropdownProps> = ({
  arr,
  icon = meatballsIcon,
  className,
  height,
  pagename,
  onClickOptions,
}) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const moreopen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(!open);
  };
  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div
      className="d-flex align-items-center justify-content-center mobile-arrow-track position-relative"
      onClick={moreopen}
      ref={dropdownRef}
    >
      <div className="">
        <div className="">
          <span className="right-margin">More</span>
          <img
            src={icon}
            className={`more-mobile-view-image ${className}`}
            style={{ height: `${height}` }}
            alt="img"
          />
        </div>
        {open ? (
          <ul className="more-mobile-view-ul">
            {arr.map((data, i) => {
              if (pagename === "user-profile") {
                return (
                  <li key={i} className="moreMobile-option cursor-pointer">
                    <Link
                      className="dropdown-item"
                      to={
                        data === "Spotify"
                          ? "https://open.spotify.com/"
                          : data === "Apple Music"
                          ? "https://music.apple.com/us/artist/web-player/1559317097"
                          : data === "Tidal"
                          ? "https://tidal.com/browse/artist/24034301"
                          : data === "Deezer"
                          ? "https://www.deezer.com/soon"
                          : data === "Instagram"
                          ? "https://www.instagram.com"
                          : data === "X"
                          ? "https://x.com/home"
                          : data === "Youtube"
                          ? "https://www.youtube.com/"
                          : ""
                      }
                    >
                      {data}
                    </Link>
                  </li>
                );
              } else if (pagename === "call-function") {
                return (
                  <li
                    key={i}
                    className="moreMobile-option cursor-pointer"
                    onClick={() => {
                      onClickOptions && onClickOptions(data);
                    }}
                  >
                    <span>{data}</span>
                  </li>
                );
              } else if (pagename === "track")
                return (
                  <ButtonComponent
                    id={data === "Report" ? "TrackReportModal" : ""}
                  >
                    <li
                      key={i}
                      className="dropdown-menu-meatballs-Icon-li  cursor-pointer"
                    >
                      <a
                        className="dropdown-item dropdown-item-meatballs"
                        href="#"
                      >
                        {data}
                      </a>
                    </li>
                  </ButtonComponent>
                );
              else if (pagename === "beat")
                return (
                  <ButtonComponent
                    id={
                      data === "Report"
                        ? "BeatReportModal"
                        : data === "Delete"
                        ? "DeleteModal"
                        : ""
                    }
                  >
                    <li
                      key={i}
                      className="dropdown-menu-meatballs-Icon-li  cursor-pointer"
                    >
                      <a
                        className="dropdown-item dropdown-item-meatballs"
                        href="#"
                      >
                        {data}
                      </a>
                    </li>
                  </ButtonComponent>
                );
              else if (pagename === "project")
                return (
                  <ButtonComponent
                    id={data === "Report" ? "ProjectReportModal" : ""}
                  >
                    <li
                      key={i}
                      className="dropdown-menu-meatballs-Icon-li  cursor-pointer"
                    >
                      <a
                        className="dropdown-item dropdown-item-meatballs"
                        href="#"
                      >
                        {data}
                      </a>
                    </li>
                  </ButtonComponent>
                );
              else {
                return (
                  <ButtonComponent
                    id={
                      data === "Report"
                        ? "ReportModal1"
                        : data === "Block"
                        ? "BlockedModal"
                        : data === "Delete"
                        ? "DeleteModal"
                        : ""
                    }
                    key={i}
                  >
                    <li className="moreMobile-option cursor-pointer">
                      <span>{data}</span>
                    </li>
                  </ButtonComponent>
                );
              }
            })}
          </ul>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
