import { FC, useState } from "react";
import { viewType } from "./modal";
import "./styles.scss";
import { DropworksNormalButton } from "../../../common/custom-field/dropworks-button";
import { TextfieldMD } from "../../../common/custom-field/dropstock-text";
import {
  Heading0,
  Heading4,
} from "../../../common/custom-field/dropstock-heading";
import InfiniteImageScroll from "../../../common/infinite-image-scroll";
import { useDispatch } from "react-redux";

const view: FC<viewType> = ({ navigateTo }) => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 2, // Adjust to 2, since you are using 2 rows and want to fit the grid
    speed: 500,
    rows: 3, // 2 rows of slides
    slidesPerRow: 1, // Display 2 slides per row
    autoplay: false, // Enable autoplay
    autoplaySpeed: 1000, // Time between slides in ms
    arrows: false,
  };
  const [tabsValue, setTabValue] = useState(0);
  const checkTabState = (value: number) => {
    setTabValue(value);
  };

  const dispatch = useDispatch();

  return (
    <div className="marketplace-dw-composite-main overflow-hidden">
      <div className="d-flex marketplace-dw-composite-main-firstrow">
        {/* Left Section */}
        <div className="w-full marketplace-dw-composite-main-firstrow-col1 px-0 uni-px-24">
          <div className="marketplace-dw-composite-main-firstrow-col1-content">
            <Heading0 className="text-wrap uni-mb-40 marketplace-select-head">
              <div data-aos="fade-left">DropWorks</div>
              <div className="gradient-text" data-aos="fade-right">
                Select
              </div>
            </Heading0>             
            <TextfieldMD className="uni-mb-40 mt-0 marketplace-dw-composite-main-firstrow-col1-content-sm mb-32-mbl px-0 mx-0">
              Upon a Released Track is Dropped, you have the unique opportunity
              to apply your Released Track to be included in DropWorks Select’s
              portfolio.This allows your Released Track to be a part of your
              fans’ ownerships and collections.
            </TextfieldMD>
            <div className="d-flex  marketplace-dw-btns">
              <DropworksNormalButton className="w-auto uni-px-72 mb-0 fs-18 home-mobileview-btn">
                Coming Soon
              </DropworksNormalButton>
              {/* <DropworksNormalButton className="marketplaceProduct-main-thirdrow-content-title-tradebtn fs-18 home-mobileview-btn trade-btn uni-px-72 uni-ml-56 w-auto mb-0" onClick={() => {
                    navigateTo("/dropworks-select");
                  }}>
                Trade Now!
              </DropworksNormalButton> */}
            </div>
          </div>
        </div>

        <div className="marketplace-dw-composite-main-firstrow-col2 pe-0">
          <InfiniteImageScroll />
        </div>
      </div>

      <div className="marketplace-dw-composite-main-thirdrow mx-auto  justify-content-center px-0 ">
        <div
          className="marketplace-dw-composite-main-thirdrow-left mx-auto px-0"
          data-aos="fade-right"
        >
          {/* <Heading4 className="text-center marketplace-dw-content-space uni-mb-16">
            Ownership Opportunity
          </Heading4>
          <TextfieldMD className="text-center marketplace-dw-subcontent-space uni-mb-56">
            If You Hold DropStocks of a Re-released Track, You Have the Unique
            Opportunity to Propose Your Track for Inclusion in DropWorks Select.
            This Allows Your Music to Reach a Wider Audience and Potentially
            Increase in Value
          </TextfieldMD> */}
          <Heading4 className="text-center marketplace-dw-content-space uni-mb-16">
            Submit an Application
          </Heading4>
          <TextfieldMD className="text-center marketplace-dw-subcontent-space uni-mb-56">
            Producers can apply their Track to include in DropWorks Select
            portfolio by submitting Track metadata and performance information.​
          </TextfieldMD>
          <Heading4 className="text-center marketplace-dw-content-space uni-mb-16">
            Evaluate Your Proposal​
          </Heading4>
          <TextfieldMD className="text-center marketplace-dw-subcontent-space uni-mb-56">
            The application submission will be assessed timely.​
          </TextfieldMD>
          <Heading4 className="text-center marketplace-dw-content-space uni-mb-16">
            Approve and Integrate​
          </Heading4>
          <TextfieldMD className="text-center  marketplace-dw-subcontent-space">
            Upon proposal approval, your Track will be featured in DropWorks
            Select through purchasing of your DropStocks by DropWorks, of which
            DropWorks Select will be available for Musicpreneurs as
            collections.​
          </TextfieldMD>
        </div>
        <div
          className="marketplace-dw-composite-main-thirdrow-right"
          data-aos="fade-left"
        >
          <div className="marketplace-dw-composite-main-thirdrow-right-content">
            <Heading0 className="uni-mb-40">
              Advance Your Released Track's value
            </Heading0>

            <DropworksNormalButton className="w-auto uni-px-72 mb-0 fs-18 home-mobileview-btn ">
              Coming Soon
            </DropworksNormalButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default view;
