import { TextfieldLG, TextfieldSM, TextfieldXSM } from '../../../../../common/custom-field/dropstock-text'
import { DropworksInput } from '../../../../../common/custom-field/dropworks-input'
import { DropworksSmallButton } from '../../../../../common/custom-field/dropworks-button'
import "../popup.scss";
import { RxCross2 } from "react-icons/rx";
import crossIcon from '../../../../../assets/steps/modal-cross-icon.svg';
import { CloseModalButton } from '../../logo-header/view';
import { DropworksRadioButton } from '../../../../../common/custom-field/dropworks-radio-button';
import "./styles.scss"
import { ButtonComponent } from '../../logo-header/view';
import { Heading3, Heading4 } from '../../../../../common/custom-field/dropstock-heading';
import { DropworksRadioCheckbox } from '../../../../../common/custom-field/dropworks-radio-checkbox';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSomethingReport } from '../../../../../redux/reducer/reportSlice';
import ModalWrapper from '../../../../../common/open-modal/ModalWrapper';

const view = (userreportreasonsubmit:any,selectedValue:any,setSelectedValue:any,customReason:any,setCustomReason:any,popupId:any,setPopupId:any) => {
 
  const dispatch = useDispatch();
  const[nextDisabled, setNextDisabled] = useState(true);
  const reasons = [
    "They are pretending to be someone else",
    "Their profile contains inappropriate content",
    "Something else",
  ];

  // const handleReasonClick = (reason: string, index: number) => {
  //   setSelectedValue(reason); // Set the selected reason
  //   setNextDisabled(false); // Enable the Next button
  //   setPopupId(index === 0 ? `ImpersonatingReportModal` : `ThankYouModal`); // Set appropriate popup ID
  // };
  const handleReasonClick = (reason: string, index: number) => {
    setSelectedValue(reason); 
    setPopupId(index === 0 ? `ImpersonatingReportModal` : `ThankYouModal`); 
    setNextDisabled(reason === "Something else" && !customReason);
    if (reason !== "Something else") {
      setNextDisabled(false);
    }
  };
  const openModal=()=>{
    setSelectedValue("");
    setCustomReason("");
    setNextDisabled(true);
  }

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setCustomReason(value); 
    setNextDisabled(value.trim() === "");
  };
  const handleNextClick = () => {
    if (selectedValue && selectedValue !== "They are pretending to be someone else") {
      const reasonToDispatch =
        selectedValue === "Something else" ? customReason : selectedValue;
      dispatch(setSomethingReport(reasonToDispatch));
      userreportreasonsubmit();
    }
    else if(selectedValue=== "They are pretending to be someone else"){
      dispatch(setSomethingReport(selectedValue))
    }
  };
  
  return (
    <ModalWrapper modalId="UserReportReasonModal" onBackdropClick={openModal}>
    <div
    className="modal fade"
    id="UserReportReasonModal"
    aria-hidden="true"
    aria-labelledby="UserReportReasonModal"
    tabIndex={-1}
    data-bs-theme="dark"
  >
    <div className="modal-dialog modal-dialog-centered uni-md-popup">
      <div className="modal-content">
        <div className="modal-cross-icon">
          <CloseModalButton>
            <img src={crossIcon} className="fs-1 uni-mr-16" />
          </CloseModalButton>
        </div>
        <div className="modal-header ps-0 pt-0 uni-mb-40">
          <Heading4 className="modal-title-fs-32">
            Report something about this user
          </Heading4>
        </div>
        <div className="modal-body ps-0 pt-0 pb-0 uni-mb-56">
          {reasons.map((reason, index) => (
            <p
              key={index}
              className="d-flex justify-content-between uni-p-16 popup-space-mobile p-lh uni-mb-8"
              onClick={() => handleReasonClick(reason, index)}
              style={{ cursor: 'pointer' }} 
            >
              <TextfieldSM className="font-col-wh my-0 mx-0 cstm-width-report-reason pop-up-max-width">{reason}</TextfieldSM>
              <DropworksRadioCheckbox checked={selectedValue === reason} /> 
            </p>
          ))}
               {selectedValue === 'Something else' && (
              <div className="uni-mt-16">
                <textarea
                  className="form-control custom-textarea"
                  value={customReason}
                  onChange={handleTextareaChange}
                  placeholder="Specify your reason"
                  rows={3}
                />
              </div>
            )}
        </div>
        <div className="modal-footer mbl-modal-footer ps-0 pt-0 pb-0">
          <ButtonComponent id="ReportModal1" className="mbl-anchor-full-btn">
            <DropworksSmallButton className="btn-wid btn-bg-gray3  mbl-full-width-btn  my-0 ml-0 uni-mr-16">
              Back
            </DropworksSmallButton>
          </ButtonComponent>
          <ButtonComponent id={`${popupId}`} className="mbl-anchor-full-btn">
            <DropworksSmallButton
              className="btn-wid m-0  mbl-full-width-btn " 
              onClick={handleNextClick}
              disabled={nextDisabled}
            >
              Next
            </DropworksSmallButton>
          </ButtonComponent>
        </div>
      </div>
    </div>
  </div>
  </ModalWrapper>
  )
}

export default view;


