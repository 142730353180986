import { FC, useEffect, useState } from "react";
import  view  from "./view";
import { useDispatch, useSelector } from "react-redux";
import { getUserData,updateUser } from "../../../utils/apiServices";
import { setUserData } from "../../../../../redux/reducer/userSlice";



export const BiographyModal:FC<any>=()=>{
    const dispatch = useDispatch();

    const [bioGraphy , setBioGraphy] = useState<string>()
    
    const biography = useSelector((state: any) => state.user.bioGraphy)
    const displyabiography =biography;
    useEffect(()=>{
        setBioGraphy(biography)
    },[biography])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBioGraphy(e.target.value)
    };

    const openModal=()=>{
        setBioGraphy(displyabiography)
    }

    const submitData = async(data: { biography: string })=>{
        let response: any = await updateUser(data);
        if (response) {
            const result = await getUserData(); // Call your API function
            if (result.success && result.userData) { // Adjust based on your API response
                dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
            }
          } else {
            //console.log("fail");
          }
    }


    return view(
        { bioGraphy, onChange:handleChange,submitData,openModal}
    );
}
export default BiographyModal