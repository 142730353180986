import { useDispatch, useSelector } from "react-redux";
import view from "./view";
import { useState } from "react";
import { setBlokedUser, setUserData } from "../../../../redux/reducer/userSlice";
import { getBlockedUserList, getUserData, updateUser } from "../../../shared/utils/apiServices";

export const ProfilSetting = () => {
  const dispatch = useDispatch();

  const [blockedUsers, setBlockedUsers] = useState<any>([]);


  const submitData = async (data: any) => {
    let response: any = await updateUser(data);
    if (response) {
      const result = await getUserData(); // Call your API function
      if (result.success && result.userData) { // Adjust based on your API response
        dispatch(setUserData(result.userData.data.userdata)); // Dispatch the action to set user data
      }
    }
  }
  const displayReleases = useSelector((state: any) => state.user.displayReleases)
  const displayBeats = useSelector((state: any) => state.user.displayBeats)
  const receiveMessagesFromAnyone = useSelector((state: any) => state.user.receiveMessagesFromAnyone)
  const publicProfile = useSelector((state: any) => state.user.publicProfile)
  const receiveOfferFromAnyone = useSelector((state: any) => state.user.receiveOfferFromAnyone)


  const fetchBlockUserData = async () => {
    const response = await getBlockedUserList();
    if (response.success) {
      setBlockedUsers(response.userData?.blockedItems?.users);
      dispatch(setBlokedUser(response.userData?.blockedItems?.users))
    }
    else if (response.status === 404) {
      setBlockedUsers([]);
      dispatch(setBlokedUser([]));
    } 
  }

  const getBlockedUser = () => {
    fetchBlockUserData()
  }


  return view(
    {
      displayReleases, displayBeats, submitData, receiveMessagesFromAnyone, publicProfile,
      receiveOfferFromAnyone, blockedUsers, dispatch, getBlockedUser
    }
  );
}