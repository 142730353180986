import { FC } from "react";
import "./styles.scss";

interface ViewProps {
  text: string;
  className: string;
  handleCopy: () => void;
  handleShow: () => void;
  isCopied: boolean;
  isShow: boolean;
  props:any;
}

export const view: FC<ViewProps> = ({
  text,
  className,
  handleCopy,
  handleShow,
  isShow,
  isCopied,
  props
}) => {
  return (
    <div className={`copy-box-container ${className}`}>
   {/* Replace input with div */}
   <div
        className="copy-box-text"
        style={{ whiteSpace: "no-wrap", overflowX: "scroll", fontWeight: isShow ? "400" : "700",wordBreak:isShow ?"break-all":"inherit"}}
      >
        {isShow ? text : "•".repeat(text.length)}
      </div>
      {props.showButton && <button  onClick={handleShow} className="copy-box-button copy-box-ml-auto">
        {isShow ? "Hide" : "Show"}
      </button>}
      <button  onClick={handleCopy} className="copy-box-button">
        {isCopied ? "Copied!" : "Copy"}
      </button>
    </div>
  );
};
