import { io, Socket } from 'socket.io-client';

let socket: Socket | null = null;

interface MessageData {
  chat_type: number;
  message_to?: string;
  group_id?: string; 
  message: string | null;
  is_attached?:number,
  is_attached_file?:[{
    fileName:string,
    src:string,
    fileType:string
  }]
}
export const connectSocket = (token: any): Socket => {
  socket = io(`${process.env.REACT_APP_BASE_URL}`, {  
    query: { token },
    transports: ['websocket'],
  });
 
  socket.on('connect', () => console.log(`Connected: ${socket?.id}`));
  socket.on('connect_error', (err: Error) => console.error('Connection Error:', err));
 
  return socket;
};

// export const sendMessage = (messageData: MessageData,activeChatUserId:string): void => {
//   if (socket) {
//   console.log(messageData,"message senttttttttttttttttttttttttttttt")
//   socket.emit('message', JSON.stringify(messageData)); 
//   socket.off('messageSeen');
//   socket.on('messageSeen', (data) => {
//     console.log( `in Message seen unseen `,data);
//      if (data.messageCount > 0) {
//       console.log( "seennnnnnnnnnnnnnnnnnnnnnnnnnnn");
//     } else {
//       console.log( "unseeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeen");
//     }
//   });
// } 
// };

export const sendMessage = async (
  messageData: MessageData,
  activeChatUserId: string
): Promise<number> => {
  if (!socket) {
    console.error("Socket is not initialized");
    return 0; 
  }

  console.log(messageData, "message sent");

  return new Promise<number>((resolve, reject) => {
    try {

      socket &&  socket.emit('message', JSON.stringify(messageData));


      socket &&   socket.off('messageSeen'); // Clear previous listeners
      socket &&   socket.once('messageSeen', (data) => {
        console.log(`In message seen/unseen`, data);
        resolve(data.messageCount);
      });

      // Optional: Add a timeout to handle cases where the server doesn't respond
      const timeout = setTimeout(() => {
        socket &&   socket.off('messageSeen'); // Cleanup on timeout
        // reject(new Error("Timeout waiting for 'messageSeen' event"));
      }, 10000); // Adjust timeout duration as needed

      // Clear timeout upon success
      socket &&   socket.once('messageSeen', (data) => {
        clearTimeout(timeout); // Clear the timeout on event success
        resolve(data.messageCount);
      });
    } catch (error) {
      socket &&    socket.off('messageSeen'); 
      console.log("we havent recieved any response from backend of seen or unsen")// Ensure cleanup on error
      resolve(1);
    }
  });
};




export const disconnectSocket = (): void => {
  if (socket) {
    socket.disconnect();
  }
};

export const joinGroup = (groupId: string): void => {
  if (socket) {
    socket.emit('joinGroup', groupId);  
    console.log(`Joined group: ${groupId}`);
  }
};

export const leaveGroup = (groupId: string): void => {
  if (socket) {
    socket.emit('leaveGroup', groupId); 
    console.log(`Left group: ${groupId}`);
  }
};

export const messageSeen=(keyName:string,keyValue:string)=>{
  console.log({ [keyName]:keyValue },"connected :")
  if (socket && keyValue) {
    // Emit the `chatViewed` event when the active chat changes
    socket.emit("chatViewed", { [keyName]:keyValue });
 
    // Listen for the `chatMarkedAsRead` event
    socket.on("chatMarkedAsRead", (data) => {
      console.log("Messages marked as read:", data);
    });
}
}
export const onMessageReceived = (callback: (message: any) => void): void => {
  if (socket) {
    socket.on('message', (message: any) => {
      callback(message); 
    });
  } else {
    console.log('Socket not connected'); 
  }
};

export const onInviteReceived = (callback: (newInvitationNotification: any) => void): void => {
  if (socket) {
    socket.on('newInvitationNotification', (newInvitationNotification: any) => {
      callback(newInvitationNotification); 
    });
  } else {
    console.log('Socket not connected'); 
  }
};






