import { FC, useEffect, useState } from "react";
import view from "./view";
import { TabsInterface } from "./modal";

const Tabs: FC<TabsInterface> =({tabsTitle,disabled=false,className,checkTabState,home,id,onTabClick,changeTabValue=0})=>{
    const [currentSection, setCurrentSection] = useState<number>(0);
    useEffect(()=>{
        changeTabValue !=currentSection && changeState(changeTabValue)
    },[changeTabValue])
    const changeState=(value:number)=>{
        if(!disabled)
            {
            setCurrentSection(value);
            checkTabState && checkTabState(value);
        }
    }
    return(view({tabsTitle,currentSection,changeState,className,home,id,onTabClick}))
}
export default Tabs;
