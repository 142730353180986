import "./styles.scss";
import { Heading1 } from "../../../common/custom-field/dropstock-heading";
import { TextfieldSM } from "../../../common/custom-field/dropstock-text";
import { DropworksPrimaryButton } from "../../../common/custom-field/dropworks-button";
import { useNavigate } from "react-router-dom";

const view = () => {
  const navigate = useNavigate();
  return (
    <div className="not-foundcontent d-flex justify-content-between mt-0">
      <div className="not-foundcontent-main ">
        <Heading1 className="beats-page-name">
          Whoops, the beat dropped...
        </Heading1>
        <Heading1 className="uni-mb-56 beats-page-name">
          {" "}
          and so did this page.
        </Heading1>
        <TextfieldSM className="uni-mb-56 page-not-found-text">
          404 pages happen, even to the best beats. Let’s find you something
          fresh.
        </TextfieldSM>
        <DropworksPrimaryButton
          className="not-found-return-home"
          onClick={() => navigate("/")}
        >
          Return Home
        </DropworksPrimaryButton>
      </div>
    </div>
  );
};

export default view;
