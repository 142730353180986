import { useNavigate } from "react-router-dom"
import view from "./view";
import { useEffect } from "react";
import { sendTokenEmailLink } from "../../../shared/utils/apiServices";
import { openPopup } from "../../../shared/components/logo-header/view";
import { useDispatch } from "react-redux";
import { setIsFirstTime } from "../../../../redux/reducer/auth";

export const HomePage = () => {

  const navigate = useNavigate();

  const navigateTo = (value: string) => {
    navigate(value);
  };

  const tokenSent = async (token: string) => {
    const response = await sendTokenEmailLink(token);
    if (response?.success) {
      if (response?.token) {
        localStorage.setItem("dropworksToken", response.token);
        window.location.href = "/";
      }

    }
    else {
      if (response?.message === "Invalid token") {
        localStorage.removeItem("dw-register-token")
        window.location.href = "/login";
      }
    }
  }

  const dispatch = useDispatch();
  useEffect(() => {
    const url = new URL(window.location.href);
    const tokenParam = url.searchParams.get("token");
    const openPopupId = url.searchParams.get("openPopup") || '1';
    const displayName_by_param = url.searchParams.get("display_name");
    const imgUrl_by_param = url.searchParams.get("profile_image");
    const token = localStorage.getItem('dropworksToken');

    if (tokenParam && token) {
      window.location.href = "/";
    }
    else if (tokenParam) {
      tokenSent(tokenParam)
      localStorage.setItem('dw-register-token', tokenParam);
      if (displayName_by_param && imgUrl_by_param) {
        dispatch(setIsFirstTime({ displayName: displayName_by_param, ImgUrl: imgUrl_by_param }));
      }
      if (openPopupId === '1') {
        openPopup("Modal1", dispatch);
      }
      else if (openPopupId === '2') {
        openPopup("Modal2", dispatch);
      }
      else if (openPopupId === '3'|| openPopupId === '4') {
        openPopup("SecureYourAccount", dispatch);
      }
      else if (openPopupId === '5') {
        openPopup("acc-secured", dispatch);
      }
    }
  }, []);


  return view({ navigateTo });
}