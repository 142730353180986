import { DropworksSmallButton } from "../../../../../common/custom-field/dropworks-button";
import "../popup.scss";
import "./styles.scss";
import { DropworksPassword } from "../../../../../common/custom-field/dropworks-password";
import { Heading3 } from "../../../../../common/custom-field/dropstock-heading";
import { FC } from "react";
import Loader from "../../loader/view";
import ModalWrapper from "../../../../../common/open-modal/ModalWrapper";
import {TextfieldLG,TextfieldXSM,} from "../../../../../common/custom-field/dropstock-text";
import { setOneTimePin } from "../../../../content/guest-pages/login/modal";
import {  CloseModalButton } from "../../logo-header/view";

export const view: FC<setOneTimePin> = ({
  errors,
  isSubmitted,
  loading,
  register,
  onSubmit,
  handleSubmit,
  closeModalRef,
  openModal,
}) => {
  return (
    <ModalWrapper modalId="OneTimePinModal" onBackdropClick={openModal} >
      <div
        className="modal fade"
        id="OneTimePinModal"
        aria-hidden="true"
        aria-labelledby="OneTimePinModal"
        tabIndex={-1}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        data-bs-theme="dark"
      >
        <CloseModalButton
          ref={closeModalRef}
          className="d-none"
        ></CloseModalButton>
        <div className="modal-dialog modal-dialog-centered uni-md-popup">
          <div className="modal-content ">
            <div className="modal-cross-icon"></div>
            <div className="modal-header ps-0 pt-0 ">
              <Heading3 className="modal-title">Set Wallet Pin</Heading3>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
              <div className="modal-body ps-0 pt-0 pb-0 uni-mb-56">
                <TextfieldLG className="uni-mt-16 mbl-mt-8 ctm-txt ctm-md-lh">
                  Protect your wallet with a secure PIN
                </TextfieldLG>
                <TextfieldXSM className="uni-mb-56 ds-ctm-txt">
                  Remember, never share your PIN with anyone
                </TextfieldXSM>
                <div className="custom-pass-space">
                  <DropworksPassword
                    {...register("pin")}
                    placeholder="Pin"
                    maxLength={6}
                    autoComplete={"off"}
                    className="mb-0 relative login-specing "
                    className3={isSubmitted}
                    className2={`uni-mt-16 form-control login-cstm-pass p-0  ${
                      isSubmitted &&
                      (errors.pin
                        ? "is-invalid b-red-2"
                        : !errors.pin
                        ? "is-valid   b-green-2"
                        : "")
                    }`}
                  />
                  {errors.pin && (
                    <p className="invalid-feedback error-red">
                      {errors.pin.message}
                    </p>
                  )}
                </div>
                <div className="uni-mb-56 custom-pass-space">
                  <DropworksPassword
                    {...register("confirmPin")}
                    placeholder="Confirm Pin"
                    maxLength={6}
                    autoComplete={"off"}
                    className="mb-0 relative login-specing "
                    className3={isSubmitted}
                    className2={`uni-mt-16 form-control login-cstm-pass p-0  ${
                      isSubmitted &&
                      (errors.confirmPin
                        ? "is-invalid b-red-2"
                        : !errors.confirmPin
                        ? "is-valid   b-green-2"
                        : "")
                    }`}
                  />
                  {errors.confirmPin && (
                    <p className="invalid-feedback error-red">
                      {errors.confirmPin.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="modal-footer mbl-modal-footer ps-0 pt-0 pb-0">
                {loading ? (
                  <Loader className="uni-mt-16" />
                ) : (
                  <>
                    <DropworksSmallButton
                      className="btn-wid m-0 my-0 mbl-full-width-btn"
                      type="submit"
                    >
                      Next
                    </DropworksSmallButton>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};
