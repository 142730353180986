import { FC } from "react";
import  view  from "./view";
import { useNavigate } from "react-router-dom";



export const ExpiredInviteReceived:FC<any>=()=>{
    const navigate=useNavigate();
    
    const navigateTo=(value:string)=>()=>{
        
        navigate(value)
    }

    return(view({navigateTo}))
}
export default ExpiredInviteReceived